import axios from './axios'
import { BaseAPI } from './base-api'

class KDDIAPI extends BaseAPI {
  public search = (request: any, url: any) => {
    const headers = Object.assign({}, this.headers(), { 'Content-Type': 'text/xml' })
    return axios.post(`${this.getConfigKey('baseURL')}${this.endpoints.findStub}/${url}`, request, { headers: headers })
  }

  public save = (request: any) => {
    return axios.post(`${this.getConfigKey('baseURL')}${this.endpoints.createStub}`, request, {
      headers: this.headers(),
    })
  }
}

export default KDDIAPI
