export enum actions {
  LOAD_CLAIM_DATA_REQUEST = 'LOAD_CLAIM_DATA_REQUEST',
  LOAD_CLAIM_DATA_SUCCESS = 'LOAD_CLAIM_DATA_SUCCESS',
  LOAD_CLAIM_DATA_ERROR = 'LOAD_CLAIM_DATA_ERROR',

  RELEASE_LOCK_REQUEST = 'RELEASE_LOCK_REQUEST',
  RELEASE_LOCK_SUCCESS = 'RELEASE_LOCK_SUCCESS',
  RELEASE_LOCK_ERROR = 'RELEASE_LOCK_ERROR',

  LOAD_LOGS_REQUEST = 'LOAD_LOGS_REQUEST',
  LOAD_LOGS_SUCCESS = 'LOAD_LOGS_SUCCESS',
  LOAD_LOGS_ERROR = 'LOAD_LOGS_ERROR',
}

export const claimInfoCreator = {
  loadClaimData: (input: any) => ({
    type: actions.LOAD_CLAIM_DATA_REQUEST,
    input,
  }),
  releaseLock: (input: any) => ({
    type: actions.RELEASE_LOCK_REQUEST,
    input,
  }),
  loadLogs: (input: any) => ({
    type: actions.LOAD_LOGS_REQUEST,
    input,
  }),
}
