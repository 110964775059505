export default {
  query: `select distinct
    hld.AGREEMENT_ID as AgreementId,
    hld.SERVICE_REQUEST_ID as ServiceRequestId,
    hld.HOLD_ID as HoldId,
    hld.HOLD_NBR as HoldNumber,
    hld.HOLD_CATEGORY_CODE as HoldCategory,
    hld.hold_type_code as HoldTypeCode,
    ifnull(ifnull(lk.lookup_list_desc, lk.lookup_list_name), hld.hold_type_code) as HoldType,
    hld.hold_subtype_code as HoldSubTypeCode,
    ifnull(ifnull(lk1.lookup_list_desc, lk1.lookup_list_name), hld.hold_subtype_code) as HoldSubType,
    ifnull(ifnull(lk2.lookup_list_desc, lk2.lookup_list_name), hld.hold_status_code) as HoldStatus,
    hld.HOLD_RESOLUTION_CODE as HoldResolution,
    hld.CUSTOMER_CASE_ID as CustomerCaseId,
    hld.CUSTOMER_CASE_NBR as CustomerCaseNumber,
    hld.SERVICE_REQUEST_NBR as ServiceRequestNumber,
    hld.INTERACTION_LINE_ID as InteractionLineId,
    hld.SUBSCRIPTION_NBR as SubscriptionNumber,
    hld.START_TIME as StartTime,
    hld.RELEASE_TIME as ReleaseTime,
    hld.RELEASED_BY as ReleasedBy,
    hld.HOLD_RELEASE_REASON_CODE as HoldReleaseReason,
    hld.HOLD_RELEASE_SUB_REASON_CODE as HoldReleaseSubReason,
    hld.approved_by as ApprovedBy,
    hld.approved_time as ApprovedTime,
    hld.OVERRIDE_BY as OverrideBy,
    hld.OVERRIDE_TIME as OverrideTime,
    hld.HOLD_OVERRIDE_REASON_CODE as HoldOverrideReason,
    hld.OVERRIDE_REMARK_TEXT as OverrideRemarkText,
    hld.CREATED_BY as CreatedBy,
    hld.CREATED_DATE as CreatedDate,
    hld.UPDATED_BY as UpdatedBy,
    hld.UPDATED_DATE as UpdatedDate
  from
    customer.hold hld
  inner join
    customer.service_request sr on
    hld.service_request_id = sr.service_request_id
    and sr.active_ind = 1
    and hld.active_ind = 1
  inner join asset.asset a on 
    a.asset_id = sr.asset_id 
    and a.active_ind = 1
  left join bpm.lookup_list lk on
    lk.lookup_list_code = hld.hold_type_code
    and lk.lookup_context_code = 'HLDTYP'
    and lk.CLIENT_CHANNEL_ID = '7D42D83E285511E9A38A063D3002FD4C'
  left join bpm.lookup_list lk1 on
    lk1.lookup_list_code = hld.hold_subtype_code
    and lk1.lookup_context_code = 'HLDSBTYP'
    and lk1.CLIENT_CHANNEL_ID = '7D42D83E285511E9A38A063D3002FD4C'
  left join bpm.lookup_list lk2 on
    lk2.lookup_list_code = hld.hold_status_code
    and lk2.lookup_context_code = 'HLDSTS'
    and lk2.CLIENT_CHANNEL_ID = '7D42D83E285511E9A38A063D3002FD4C'
  where
    a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr
  order by
    sr.created_date asc, hld.created_date asc;`,
  mapper: [
    { o: 'AgreementId', d: 'AgreementId' },
    { o: 'ServiceRequestId', d: 'ServiceRequestId' },
    { o: 'HoldId', d: 'HoldId' },
    { o: 'HoldNumber', d: 'HoldNumber' },
    { o: 'HoldCategory', d: 'HoldCategory' },
    { o: 'HoldTypeCode', d: 'HoldTypeCode' },
    { o: 'HoldType', d: 'HoldType' },
    { o: 'HoldSubTypeCode', d: 'HoldSubTypeCode' },
    { o: 'HoldSubType', d: 'HoldSubType' },
    { o: 'HoldStatus', d: 'HoldStatus' },
    { o: 'HoldResolution', d: 'HoldResolution' },
    { o: 'CustomerCaseId', d: 'CustomerCaseId' },
    { o: 'CustomerCaseNumber', d: 'CustomerCaseNumber' },
    { o: 'ServiceRequestNumber', d: 'ServiceRequestNumber' },
    { o: 'InteractionLineId', d: 'InteractionLineId' },
    { o: 'SubscriptionNumber', d: 'SubscriptionNumber' },
    { o: 'StartTime', d: 'StartTime' },
    { o: 'ReleaseTime', d: 'ReleaseTime' },
    { o: 'ReleasedBy', d: 'ReleasedBy' },
    { o: 'HoldReleaseReason', d: 'HoldReleaseReason' },
    { o: 'HoldReleaseSubReason', d: 'HoldReleaseSubReason' },
    { o: 'ApprovedBy', d: 'ApprovedBy' },
    { o: 'ApprovedTime', d: 'ApprovedTime' },
    { o: 'OverrideBy', d: 'OverrideBy' },
    { o: 'OverrideTime', d: 'OverrideTime' },
    { o: 'HoldOverrideReason', d: 'HoldOverrideReason' },
    { o: 'OverrideRemarkText', d: 'OverrideRemarkText' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'UpdatedBy', d: 'UpdatedBy' },
    { o: 'UpdatedDate', d: 'UpdatedDate' },
  ],
}
