import { connect } from 'react-redux'
import xmlFormat from 'xml-formatter'
import React, { Component, ReactNode } from 'react'
import {
  Container,
  Row,
  Col,
  Tab,
  Alert,
  Tabs,
  Table,
  Badge,
  Button,
  OverlayTrigger,
  Tooltip,
  Pagination,
} from 'react-bootstrap'

import { actionCreator } from '../../store/rootActions'
import { orangeActionCreator } from '../../store/orange/actions'
import { claimInfoCreator } from '../../store/claim/actions'

import { IComponents, IState } from '../../store/types'
import Spinner from '../../components/spinner'

import ModalMessage from '../../components/modal'
import { getDateIfDate } from '../../utils/helper'
import SearchForm from '../../components/analysis-form'
import { isClientEnvironmentSelected, activeConfig } from '../../store/selectors'

const isLocalHost = window.location.hostname.includes('localhost')
const isProductionEnvironment = isLocalHost ? false : !window.location.hostname.includes('nonprod')

interface ITabs {
  activeTab: string
  horizon: IChildTab
  legacy: IChildTab
}

interface IChildTab {
  activeChildTab: number
}

interface IClaimInformationState {
  activePage: number
  tabs: ITabs
  showModal: boolean
  message: string
  header: string
}

const mapStateToProps = (state: IState) => {
  return {
    ActiveConfig: activeConfig(),
    Errors: state.ClaimInformation.Errors,
    Details: state.ClaimInformation.Details,
    IsLoading: state.ClaimInformation.IsLoading,
    IsSuccess: state.ClaimInformation.IsSuccess,
    SearchType: state.OrangeInformation.Form.SearchType,
    SearchValue: state.OrangeInformation.Form.SearchValue,
    IsClientEnvironmentSelected: isClientEnvironmentSelected(),
    IsOtherOperationLoading: state.ClaimInformation.IsOtherOperationLoading,
    IsOtherOperationSuccess: state.ClaimInformation.IsOtherOperationSuccess,
  }
}

const mapDispatchToProps = {
  loadLogs: claimInfoCreator.loadLogs,
  resetError: actionCreator.resetError,
  releaseLock: claimInfoCreator.releaseLock,
  loadClaimData: claimInfoCreator.loadClaimData,
  resetSuccessFlag: actionCreator.resetSuccessFlag,
  updateSearchForm: orangeActionCreator.updateOrangeSearchForm,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents
const environment = localStorage.getItem('environment') || 'sqa1'

class ClaimInformation extends Component<IProps, IClaimInformationState> {
  initialFormData: any

  constructor(props: any) {
    super(props)
    this.initialFormData = {
      SearchValue: this.props.SearchValue || '',
      SearchType: this.props.SearchType || '1',
    }
    this.state = {
      activePage: 1,
      tabs: { activeTab: 'tb-hz', horizon: { activeChildTab: 6 }, legacy: { activeChildTab: 4 } },
      showModal: false,
      message: '',
      header: '',
    }
  }

  componentDidMount() {
    this.props.resetSuccessFlag()
  }

  private renderErrors(): React.ReactNode {
    return (
      !(this.props.IsLoading || this.props.IsOtherOperationLoading) &&
      this.props.Errors &&
      this.props.Errors.length > 0 && (
        <Alert variant="danger" onClose={() => this.props.resetError()} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          {this.props.Errors.map((e, i) => {
            return <p key={i}>{e || 'Details not available!!!'}</p>
          })}
        </Alert>
      )
    )
  }

  private createPaginationItems(pages, type): React.ReactNode {
    const items: any = []
    for (let i = 0; i < pages; i++) {
      items.push(
        <Pagination.Item
          onClick={() => {
            this.props.loadLogs({ value: this.props.SearchValue, pageNumber: i + 1, type: type })
            this.setState({ activePage: i + 1 })
          }}
          key={i + 1}
          active={i + 1 === this.state.activePage}
        >
          {i + 1}
        </Pagination.Item>
      )
    }
    return items
  }

  private createPagination(
    prp: string,
    totalPages: number
  ): React.ReactElement<React.JSXElementConstructor<typeof Pagination>> {
    return (
      <Pagination size="sm" className="mt-2 float-end">
        <Pagination.First
          onClick={() => {
            this.props.loadLogs({ value: this.props.SearchValue, pageNumber: 1, type: prp })
            this.setState({ activePage: 1 })
          }}
        />
        <Pagination.Prev
          onClick={() => {
            const currentPage = this.state.activePage
            const page = currentPage - 1 > 0 ? currentPage - 1 : 1
            this.props.loadLogs({
              value: this.props.SearchValue,
              pageNumber: page,
              type: prp,
            })
            this.setState({ activePage: page })
          }}
        />
        {this.createPaginationItems(totalPages, prp)}
        <Pagination.Next
          onClick={() => {
            const currentPage = this.state.activePage
            const page = currentPage + 1 > totalPages ? totalPages : currentPage + 1
            this.props.loadLogs({
              value: this.props.SearchValue,
              pageNumber: page,
              type: prp,
            })
            this.setState({ activePage: page })
          }}
        />
        <Pagination.Last
          onClick={() => {
            this.props.loadLogs({
              value: this.props.SearchValue,
              pageNumber: totalPages,
              type: prp,
            })
            this.setState({ activePage: totalPages })
          }}
        />
      </Pagination>
    )
  }

  private createTab(details, type): React.ReactNode {
    const defaultTabState = this.state.tabs[type]
    return (
      details && (
        <fieldset className="" style={{ height: '38rem', minHeight: '38rem', overflowY: 'auto', marginTop: '1rem' }}>
          <ModalMessage
            backdrop="static"
            show={this.state.showModal}
            message={this.state.message}
            header={this.state.header}
            onHide={() => this.setState(Object.assign({}, this.state, { showModal: false, message: '', header: '' }))}
          />
          <Tabs
            activeKey={'tb' + type + defaultTabState.activeChildTab}
            onSelect={(k) => {
              const activeIndex = parseInt((k || '').split(`tb${type}`)[1])
              const newState = {}
              newState[type] = Object.assign({}, this.state.tabs[type], {
                activeChildTab: activeIndex,
              })
              this.setState(Object.assign({}, this.state, { tabs: Object.assign({}, this.state.tabs, newState) }))
            }}
            id="noanim-tab"
            className="mb-4"
            fill
          >
            {Object.keys(details).map((tabsq, ti) => {
              const tabData = details[tabsq]
              const isLogsTab = tabsq && tabsq.toUpperCase().endsWith('LOGS')
              return (
                <Tab key={'tab' + type + (ti + 1)} eventKey={'tb' + type + (ti + 1)} title={tabsq}>
                  {Object.keys(tabData).map((prp, j) => {
                    const pageSize = 25
                    const totalRecords = isLogsTab ? tabData[prp].total : 0
                    const totalPages = Math.ceil(totalRecords / pageSize)
                    const tableData = tabData[prp].data
                    return tabData[prp] && tabData[prp].data && tabData[prp].data.length > 0 ? (
                      <div key={type + j}>
                        <legend className="w-auto">
                          <Badge pill bg="secondary">
                            {prp}
                          </Badge>
                        </legend>
                        <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
                          <thead>
                            <tr>
                              {!isLogsTab ? <th>#</th> : null}
                              {tabData[prp].headers.map((k: any, i) => {
                                return <th key={type + i.toString()}>{k.o}</th>
                              })}
                              {tabsq.toUpperCase() === 'OWNERSHIP' && prp.toUpperCase() === 'CUSTOMERCASE' ? (
                                <th>ACTION</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((object, i) => {
                              return (
                                <tr key={type + i.toString()}>
                                  {!isLogsTab ? <td> {i + 1}</td> : null}
                                  {tabData[prp].headers.map((h: any, j) => {
                                    switch (h.d.toUpperCase()) {
                                      case 'MESSAGETEXT':
                                      case 'ASU_MESSAGEXML':
                                        return (
                                          <td key={type + j.toString()}>
                                            <Button
                                              variant="outline-dark float-start"
                                              size="sm"
                                              onClick={() => {
                                                this.setState(
                                                  Object.assign({}, this.state, {
                                                    showModal: true,
                                                    message: object[h.d],
                                                    header: object['asu_name']
                                                      ? `${object['asu_name']}`
                                                      : `${object['Transaction']}:${object['LogType']}`,
                                                  })
                                                )
                                              }}
                                            >
                                              Show Detailed Message
                                            </Button>
                                          </td>
                                        )
                                      case 'MESSAGEXML':
                                      case 'XML':
                                      case 'REQUEST_XML':
                                      case 'RESPONSE_XML':
                                        return (
                                          <td key={type + j.toString()}>
                                            <pre className="xml-body">
                                              {xmlFormat(object[h.d], {
                                                indentation: '  ',
                                                filter: (node) => node.type !== 'Comment',
                                                collapseContent: true,
                                                lineSeparator: '\n',
                                                throwOnFailure: false,
                                              })}
                                            </pre>
                                          </td>
                                        )
                                      case 'CUSTOMER_CASE_NBR':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id="button-tooltip-2">click for DAX Biz event logs</Tooltip>
                                              }
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${
                                                      this.props.ActiveConfig[environment].kibanaLink
                                                    }/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(_source),filters:!(),index:'${
                                                      this.props.ActiveConfig[environment].kibanaIndex
                                                    }',interval:auto,query:(language:kuery,query:'(APILog.payload.request.body:%20%22*${
                                                      object[h.d]
                                                    }*%22%20AND%20logGroup:%20%22*externalint-daxa%22)%20%20OR%20%20(message:%22*${
                                                      object[h.d]
                                                    }*%22%20AND%20message:%22*snsMessageindex:%20%7B%20BusinessEventId*%22)'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      case 'ASU_NAME':
                                        return (
                                          <td key={type + j.toString()}>{object[h.d].split('-')[1] || object[h.d]}</td>
                                        )
                                      case 'SERVICE_LOGS':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-1">click for logs</Tooltip>}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${this.props.ActiveConfig[environment].kibanaLink}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(message,logGroup,APILog.context.user,APILog.payload.response.statusCode,APILog.payload.response.responseTime),filters:!(),index:'${this.props.ActiveConfig[environment].kibanaIndex}',interval:auto,query:(language:kuery,query:'APILog.context.interactionLineId:${object['INTERACTION_LINE_ID']}%20and%20NOT%20message:%22GET%20%2Fexternalintegration%2Fv1%2Fconversationclassifier%22%20and%20NOT%20message:%20%22GET%20%2Fproductfeatureadministration%2Fv1%2Fquestionnaire%2F$%2Fgraph%22%20and%20NOT%20message:%20%22POST%20%2Fproductfeatureadministration%2Fv1%2Fproductfeatures%22'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      case 'GRAPHQL_LOGS':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-2">click for logs</Tooltip>}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${this.props.ActiveConfig[environment].kibanaLink}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(operation,logGroup,APILog.payload.request.body,APILog.payload.response.body,APILog.payload.response.statusCode),filters:!(),index:'${this.props.ActiveConfig[environment].kibanaGQLIndex}',interval:auto,query:(language:kuery,query:'APILog.req.url:%2Fgraphql and APILog.context.correlationId:${object['INTERACTION_ID']}'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      case 'ORANGE_API_LOGS':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-1">click for logs</Tooltip>}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${this.props.ActiveConfig[environment].kibanaLink}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(logGroup,APILog.payload.request.NaileSendData.HeaderBu.GamenId,APILog.payload.request.NaileSendData.GyoumuBu.GyoumuData,APILog.payload.response.NaileReceiveData.ReceiveData.Data),filters:!(),hideChart:!t,index:'${this.props.ActiveConfig[environment].kibanaIndex}',interval:auto,query:(language:kuery,query:'APILog.context.interactionLineId:%20${object['INTERACTION_LINE_ID']}%20AND%20category:%20OrangeAPI%20AND%20APILog.payload.step:%20InvokeOrangeAPI'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      case 'SIGMA_API_LOGS':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-1">click for logs</Tooltip>}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${this.props.ActiveConfig[environment].kibanaLink}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(logGroup,APILog.payload.request.acyanRcptnHisRegReqEntity.memo,APILog.payload.request.acyanRcptnHisRegReqEntity.cdv,APILog.payload.request.acyanRcptnHisRegReqEntity.outainame),filters:!(),hideChart:!t,index:'${this.props.ActiveConfig[environment].kibanaIndex}',interval:auto,query:(language:kuery,query:'APILog.context.interactionLineId:%20${object['INTERACTION_LINE_ID']}%20AND%20category:%20SigmaAPI%20AND%20APILog.payload.step:%20InvokeSigmaAPI'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      case 'COMPLETE_ORDER_JOB_LOGS':
                                        return (
                                          <td key={type + j.toString()}>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="button-tooltip-1">click for logs</Tooltip>}
                                            >
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                  window.open(
                                                    `${this.props.ActiveConfig[environment].kibanaLink}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-90d,to:now))&_a=(columns:!(logGroup,APILog.payload.step,message),filters:!(),hideChart:!t,index:'${this.props.ActiveConfig[environment].kibanaIndex}',interval:auto,query:(language:kuery,query:'APILog.context.interactionLineId:%20${object['INTERACTION_LINE_ID']}%20AND%20((category:%20OrangeAPI%20AND%20APILog.payload.request.NaileSendData.HeaderBu.GamenId:%20is%20one%20of%20ORCLLS,%20ORCLES%20AND%20APILog.payload.request.NaileSendData.HeaderBu.DenbunCode:%20P81)%20OR%20(category:%20SigmaAPI%20AND%20APILog.payload.request.acyanRcptnHisRegReqEntity.cdv:%20is%20one%20of%20ISA478,%20ISA480,%20IS0768,%20IS0611,%20ISA479,%20ISA481,%20IS0769,%20IS0612))'),sort:!(!('@timestamp',desc)))`,
                                                    '_blank'
                                                  )
                                                }}
                                                href="#!"
                                              >
                                                {object[h.d]}
                                              </a>
                                            </OverlayTrigger>
                                          </td>
                                        )
                                      default:
                                        return (
                                          <td key={type + j.toString()}>
                                            {object[h.d] !== undefined ? getDateIfDate(object[h.d]) : object[h.d]}
                                          </td>
                                        )
                                    }
                                  })}
                                  {prp.toUpperCase() === 'CUSTOMERCASE' &&
                                  tabData[prp].headers.map((h) => h.d).includes('ENTITY_OWNERSHIP_STATUS_CODE') ? (
                                    <td key={type + 'action' + j.toString()}>
                                      <Button
                                        variant="success"
                                        type="submit"
                                        disabled={
                                          object['ENTITY_OWNERSHIP_STATUS_CODE'] &&
                                          object['ENTITY_OWNERSHIP_STATUS_CODE'].toUpperCase() === 'INACTV'
                                        }
                                        onClick={() => {
                                          this.props.releaseLock(object)
                                        }}
                                      >
                                        Release Lock
                                      </Button>
                                    </td>
                                  ) : null}
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                        {isLogsTab && this.createPagination(prp, totalPages)}
                      </div>
                    ) : (
                      <Alert
                        key={'alert' + type + (j + 1)}
                        style={{ marginTop: '2em' }}
                        variant="warning"
                      >{`${prp} details not present.`}</Alert>
                    )
                  })}
                </Tab>
              )
            })}
          </Tabs>
        </fieldset>
      )
    )
  }

  render(): ReactNode {
    return (
      <Container fluid>
        {(this.props.IsLoading || this.props.IsOtherOperationLoading) && <Spinner />}
        <Row>
          <Col className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
            <SearchForm
              initialFormData={this.initialFormData}
              search={(form) => {
                this.props.loadClaimData({
                  key: form.SearchType,
                  value: form.SearchValue,
                })
              }}
              updateForm={(e) => this.props.updateSearchForm(e)}
            />
          </Col>
          <Col className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12">{this.renderErrors()}</Col>
        </Row>
        {!this.props.IsLoading && (
          <Row>
            <Col md={12} className="formDiv1 mx-auto">
              {this.props.Details && (
                <fieldset className="border p-2" style={{ marginTop: '1em' }}>
                  <Tabs
                    activeKey={this.state.tabs.activeTab}
                    onSelect={(selectedTabId) => {
                      this.setState(
                        Object.assign({}, this.state, {
                          tabs: Object.assign({}, this.state.tabs, { activeTab: selectedTabId }),
                        })
                      )
                    }}
                    id="parent-tab"
                    fill
                  >
                    <Tab key="tb-hz" eventKey="tb-hz" title="Horizon">
                      {this.createTab(this.props.Details.horizon, 'horizon')}
                    </Tab>
                    {isProductionEnvironment ? null : (
                      <Tab key="tb-legacy" eventKey="tb-legacy" title="Legacy">
                        {this.createTab(this.props.Details.legacy, 'legacy')}
                      </Tab>
                    )}
                  </Tabs>
                </fieldset>
              )}
            </Col>
          </Row>
        )}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimInformation)
