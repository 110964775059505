import { call, put } from 'redux-saga/effects'
import API from '../../../services'
import { IMessageType } from '../../types'
import { sagawaActionCreator } from '../actions'

export function* getServiceOrderDetails(input: any) {
  try {
    const request: any = {
      fetchstubrequest: {
        orderid: input?.ServiceOrderNumber || '',
      },
    }
    yield put(sagawaActionCreator.updateLoaderState(true))
    const response = yield call(API.SagawaService.getServiceOrderDetails, request)
    if (response && response.status === 200) {
      yield put(sagawaActionCreator.getServiceOrderDetails.getServiceOrderDetailsSuccess(response.data))
    }
    if (response && response.status === 204) {
      yield put(
        sagawaActionCreator.updateMessage.setMessage({
          MessageType: IMessageType.ERROR,
          Message: `Order details not present for Service Order number -${input?.ServiceOrderNumber}!!!`,
        })
      )
    }
    if (response && response.status !== 200 && response.status !== 204) {
      yield put(
        sagawaActionCreator.updateMessage.setMessage({
          MessageType: IMessageType.ERROR,
          Message: `Oh snap! You got an error!`,
        })
      )
    }
  } catch (error) {
    yield put(
      sagawaActionCreator.updateMessage.setMessage({
        MessageType: IMessageType.ERROR,
        Message: `Oh snap! You got an error!`,
      })
    )
  } finally {
    yield put(sagawaActionCreator.updateLoaderState(false))
  }
}
