module.exports.ForceCompleteSuccess = {
  ApplicationCategory: 'A1',
  ApplicationName: 'A11',
  ApplicationNumber: 'QA4001534',
  RegisteredDate: '01012020',
  PascalID: 'E200001',
  ShopCode: 'C000008',
  PascalForcedCompletionAvailable: '1',
  FamilyDiscountGroup: 'FDC00001',
}

module.exports.ForceCompleteError = { ErrorCode: 'KHE184' }
