import queryAgreement from './queryAgreement'
import queryAssetCatalog from './queryAssetCatalog'
import queryCustomerCases from './queryCustomerCases'
import queryInteractionLine from './queryInteractionLine'
import queryInteractionLineLogs from './queryInteractionLineLogs'
import queryInteractionLineAttributes from './queryInteractionLineAttributes'
import queryQuestionairGraph from './queryQuestionairGraph'
import queryServiceOrder from './queryServiceOrder'
import queryServiceOrderAttributes from './queryServiceOrderAttributes'
import queryServiceOrderLines from './queryServiceOrderLines'
import queryServiceRequest from './queryServiceRequest'
import queryServiceRequestAttributes from './queryServiceRequestAttributes'
import queryShippingOrder from './queryShippingOrder'
import queryNotes from './queryNotes'
import queryAgreementAttributes from './queryAgreementAttributes'
import queryClientAccount from './queryClientAccount'
import queryClientAccountAttribute from './queryClientAccountAttribute'
import queryAssetAttribute from './queryAssetAttribute'
import queryAssetCatalogAttribute from './queryAssetCatalogAttribute'
import queryVendorAssetAttributes from './queryVendorAssetAttributes'
import queryReturnLogistics from './queryReturnLogistics'
import queryOtherCases from './queryOtherCases'
import queryOwnership from './queryOwnership'
import queryReplacementMatrix from './queryReplacementMatrix'
import queryChargeOrder from './queryChargeOrder'
import queryChargeOrderLine from './queryChargeOrderLine'
import queryDiscount from './queryDiscount'
import queryHold from './queryHold'
import queryAccountHold from './queryAccountHold'
import queryChargeOrderTransactions from './queryChargeOrderTransactions'
import queryOrderTransactions from './queryOrderTransactions'
import queryOrderXml from './queryOrderXml'

export default {
  ClientAccount: {
    Account: queryClientAccount,
    Attribute: queryClientAccountAttribute,
  },
  Agreement: {
    Agreement: queryAgreement,
    Attributes: queryAgreementAttributes,
  },
  Asset: {
    Asset: queryAssetCatalog,
    EnrolledAssetReplacements: queryReplacementMatrix,
    AssetAttribute: queryAssetAttribute,
    CatalogAttribute: queryAssetCatalogAttribute,
    VendorAttributes: queryVendorAssetAttributes,
  },
  Interaction: {
    InteractionLine: queryInteractionLineLogs,
    InteractionLineDetails: queryInteractionLine,
    InteractionLineAttributes: queryInteractionLineAttributes,
  },
  Ownership: {
    CustomerCase: queryOwnership,
  },
  Cases: {
    CustomerCase: queryCustomerCases,
    ServiceRequest: queryServiceRequest,
    ServiceOrder: queryServiceOrder,
    ServiceOrderLine: queryServiceOrderLines,
    ShippingOrder: queryShippingOrder,
    ChargeOrder: queryChargeOrder,
    ChargeOrderLine: queryChargeOrderLine,
    Discount: queryDiscount,
    ServiceRequestAttributes: queryServiceRequestAttributes,
    ServiceOrderAttributes: queryServiceOrderAttributes,
    QuestionairGraph: queryQuestionairGraph,
    Notes: queryNotes,
    ReturnLogistics: queryReturnLogistics,
  },
  Holds: {
    AccounHold: queryAccountHold,
    ServiceRequestHold: queryHold,
  },
  OtherCases: {
    CRM: queryOtherCases,
  },
  Integration: {
    OrderXml: queryOrderXml,
    OrderTransmission: queryOrderTransactions,
    ChargeOrderTransmission: queryChargeOrderTransactions,
  },
}
