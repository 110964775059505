export default {
  query: `select
	ASU_ISSTATUS,
	CASE
		WHEN ASU_ISSTATUS = 0 THEN 'ITEM SELECTION HOLD'
		WHEN ASU_ISSTATUS = 1 THEN 'PAYMENT HOLD'
		WHEN ASU_ISSTATUS = 2 THEN 'READY'
		WHEN ASU_ISSTATUS = 3 THEN 'CANCELLED'
		WHEN ASU_ISSTATUS = 4 THEN 'SHIPPING INFORMATION HOLD'
		WHEN ASU_ISSTATUS = 5 THEN 'INSTORE HOLD'
		WHEN ASU_ISSTATUS = 6 THEN 'WBKO'
		WHEN ASU_ISSTATUS = 7 THEN 'WBOR'
		WHEN ASU_ISSTATUS = 8 THEN 'SHIPPED'
		WHEN ASU_ISSTATUS = 9 THEN 'EXPECT RETURN'
		WHEN ASU_ISSTATUS = 10 THEN 'RETURNED'
		WHEN ASU_ISSTATUS = 11 THEN 'WOOS'
		WHEN ASU_ISSTATUS = 12 THEN 'ALLOCATION CANCELLED'
		WHEN ASU_ISSTATUS = 13 THEN 'CANCELLED - PERIL RECLASS'
		WHEN ASU_ISSTATUS = 14 THEN ''
		WHEN ASU_ISSTATUS = 15 THEN 'ISP HOLD'
	END ISSTATUS ,
	st.*
from
	Axapta..SALESTABLE st with(nolock)
inner join intelliset.dbo.claim c with(nolock) on
	c.claim_id = st.asu_claimid
inner join intelliset.dbo.subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by st.CREATEDDATETIME asc;`,
  mapper: [
    { o: 'ASU_ISSTATUS', d: 'ASU_ISSTATUS' },
    { o: 'ISSTATUS', d: 'ISSTATUS' },
    { o: 'SALESID', d: 'SALESID' },
    { o: 'SALESNAME', d: 'SALESNAME' },
    { o: 'RESERVATION', d: 'RESERVATION' },
    { o: 'CUSTACCOUNT', d: 'CUSTACCOUNT' },
    { o: 'INVOICEACCOUNT', d: 'INVOICEACCOUNT' },
    { o: 'DELIVERYDATE', d: 'DELIVERYDATE' },
    { o: 'DELIVERYADDRESS', d: 'DELIVERYADDRESS' },
    { o: 'URL', d: 'URL' },
    { o: 'PURCHORDERFORMNUM', d: 'PURCHORDERFORMNUM' },
    { o: 'SALESTAKER', d: 'SALESTAKER' },
    { o: 'FREIGHTSLIPTYPE', d: 'FREIGHTSLIPTYPE' },
    { o: 'DOCUMENTSTATUS', d: 'DOCUMENTSTATUS' },
    { o: 'INTERCOMPANYORIGINALSALESID', d: 'INTERCOMPANYORIGINALSALESID' },
    { o: 'CURRENCYCODE', d: 'CURRENCYCODE' },
    { o: 'PAYMENT', d: 'PAYMENT' },
    { o: 'CASHDISC', d: 'CASHDISC' },
    { o: 'TAXGROUP', d: 'TAXGROUP' },
    { o: 'LINEDISC', d: 'LINEDISC' },
    { o: 'CUSTGROUP', d: 'CUSTGROUP' },
    { o: 'DISCPERCENT', d: 'DISCPERCENT' },
    { o: 'INTERCOMPANYORIGINALCUSTACCO22', d: 'INTERCOMPANYORIGINALCUSTACCO22' },
    { o: 'DIMENSION', d: 'DIMENSION' },
    { o: 'DIMENSION2_', d: 'DIMENSION2_' },
    { o: 'DIMENSION3_', d: 'DIMENSION3_' },
    { o: 'DIMENSION4_', d: 'DIMENSION4_' },
    { o: 'DIMENSION5_', d: 'DIMENSION5_' },
    { o: 'PRICEGROUPID', d: 'PRICEGROUPID' },
    { o: 'MULTILINEDISC', d: 'MULTILINEDISC' },
    { o: 'ENDDISC', d: 'ENDDISC' },
    { o: 'CUSTOMERREF', d: 'CUSTOMERREF' },
    { o: 'LISTCODE', d: 'LISTCODE' },
    { o: 'DLVTERM', d: 'DLVTERM' },
    { o: 'DLVMODE', d: 'DLVMODE' },
    { o: 'PURCHID', d: 'PURCHID' },
    { o: 'SALESSTATUS', d: 'SALESSTATUS' },
    { o: 'MARKUPGROUP', d: 'MARKUPGROUP' },
    { o: 'SALESTYPE', d: 'SALESTYPE' },
    { o: 'SALESPOOLID', d: 'SALESPOOLID' },
    { o: 'POSTINGPROFILE', d: 'POSTINGPROFILE' },
    { o: 'TRANSACTIONCODE', d: 'TRANSACTIONCODE' },
    { o: 'INTERCOMPANYAUTOCREATEORDERS', d: 'INTERCOMPANYAUTOCREATEORDERS' },
    { o: 'INTERCOMPANYDIRECTDELIVERY', d: 'INTERCOMPANYDIRECTDELIVERY' },
    { o: 'INTERCOMPANYDIRECTDELIVERYORIG', d: 'INTERCOMPANYDIRECTDELIVERYORIG' },
    { o: 'DELIVERYZIPCODE', d: 'DELIVERYZIPCODE' },
    { o: 'DELIVERYCOUNTY', d: 'DELIVERYCOUNTY' },
    { o: 'DELIVERYCOUNTRYREGIONID', d: 'DELIVERYCOUNTRYREGIONID' },
    { o: 'SETTLEVOUCHER', d: 'SETTLEVOUCHER' },
    { o: 'DELIVERYSTATE', d: 'DELIVERYSTATE' },
    { o: 'INTERCOMPANYALLOWINDIRECTCRE48', d: 'INTERCOMPANYALLOWINDIRECTCRE48' },
    { o: 'INTERCOMPANYALLOWINDIRECTCRE49', d: 'INTERCOMPANYALLOWINDIRECTCRE49' },
    { o: 'DELIVERYNAME', d: 'DELIVERYNAME' },
    { o: 'ONETIMECUSTOMER', d: 'ONETIMECUSTOMER' },
    { o: 'COVSTATUS', d: 'COVSTATUS' },
    { o: 'PAYMENTSCHED', d: 'PAYMENTSCHED' },
    { o: 'INTERCOMPANYORIGIN', d: 'INTERCOMPANYORIGIN' },
    { o: 'EMAIL', d: 'EMAIL' },
    { o: 'FREIGHTZONE', d: 'FREIGHTZONE' },
    { o: 'RETURNITEMNUM', d: 'RETURNITEMNUM' },
    { o: 'CASHDISCPERCENT', d: 'CASHDISCPERCENT' },
    { o: 'CONTACTPERSONID', d: 'CONTACTPERSONID' },
    { o: 'DEADLINE', d: 'DEADLINE' },
    { o: 'PROJID', d: 'PROJID' },
    { o: 'INVENTLOCATIONID', d: 'INVENTLOCATIONID' },
    { o: 'ADDRESSREFTABLEID', d: 'ADDRESSREFTABLEID' },
    { o: 'VATNUM', d: 'VATNUM' },
    { o: 'PORT', d: 'PORT' },
    { o: 'INCLTAX', d: 'INCLTAX' },
    { o: 'EINVOICELINESPEC', d: 'EINVOICELINESPEC' },
    { o: 'NUMBERSEQUENCEGROUP', d: 'NUMBERSEQUENCEGROUP' },
    { o: 'FIXEDEXCHRATE', d: 'FIXEDEXCHRATE' },
    { o: 'LANGUAGEID', d: 'LANGUAGEID' },
    { o: 'AUTOSUMMARYMODULETYPE', d: 'AUTOSUMMARYMODULETYPE' },
    { o: 'GIROTYPE', d: 'GIROTYPE' },
    { o: 'SALESORIGINID', d: 'SALESORIGINID' },
    { o: 'ESTIMATE', d: 'ESTIMATE' },
    { o: 'TRANSPORT', d: 'TRANSPORT' },
    { o: 'PAYMMODE', d: 'PAYMMODE' },
    { o: 'PAYMSPEC', d: 'PAYMSPEC' },
    { o: 'FIXEDDUEDATE', d: 'FIXEDDUEDATE' },
    { o: 'DELIVERYCITY', d: 'DELIVERYCITY' },
    { o: 'DELIVERYSTREET', d: 'DELIVERYSTREET' },
    { o: 'EXPORTREASON', d: 'EXPORTREASON' },
    { o: 'STATPROCID', d: 'STATPROCID' },
    { o: 'INTERCOMPANYCOMPANYID', d: 'INTERCOMPANYCOMPANYID' },
    { o: 'INTERCOMPANYPURCHID', d: 'INTERCOMPANYPURCHID' },
    { o: 'INTERCOMPANYORDER', d: 'INTERCOMPANYORDER' },
    { o: 'DLVREASON', d: 'DLVREASON' },
    { o: 'QUOTATIONID', d: 'QUOTATIONID' },
    { o: 'RECEIPTDATEREQUESTED', d: 'RECEIPTDATEREQUESTED' },
    { o: 'RECEIPTDATECONFIRMED', d: 'RECEIPTDATECONFIRMED' },
    { o: 'SHIPPINGDATEREQUESTED', d: 'SHIPPINGDATEREQUESTED' },
    { o: 'SHIPPINGDATECONFIRMED', d: 'SHIPPINGDATECONFIRMED' },
    { o: 'EINVOICEACCOUNTCODE', d: 'EINVOICEACCOUNTCODE' },
    { o: 'ITEMTAGGING', d: 'ITEMTAGGING' },
    { o: 'CASETAGGING', d: 'CASETAGGING' },
    { o: 'PALLETTAGGING', d: 'PALLETTAGGING' },
    { o: 'ADDRESSREFRECID', d: 'ADDRESSREFRECID' },
    { o: 'CUSTINVOICEID', d: 'CUSTINVOICEID' },
    { o: 'INVENTSITEID', d: 'INVENTSITEID' },
    { o: 'CREDITCARDCUSTREFID', d: 'CREDITCARDCUSTREFID' },
    { o: 'SHIPCARRIERACCOUNT', d: 'SHIPCARRIERACCOUNT' },
    { o: 'SHIPCARRIERID', d: 'SHIPCARRIERID' },
    { o: 'SHIPCARRIERFUELSURCHARGE', d: 'SHIPCARRIERFUELSURCHARGE' },
    { o: 'SHIPCARRIERBLINDSHIPMENT', d: 'SHIPCARRIERBLINDSHIPMENT' },
    { o: 'CREDITCARDPROCESSORTRANSACT140', d: 'CREDITCARDPROCESSORTRANSACT140' },
    { o: 'SHIPCARRIERDELIVERYCONTACT', d: 'SHIPCARRIERDELIVERYCONTACT' },
    { o: 'CREDITCARDAPPROVALAMOUNT', d: 'CREDITCARDAPPROVALAMOUNT' },
    { o: 'CREDITCARDAUTHORIZATION', d: 'CREDITCARDAUTHORIZATION' },
    { o: 'RETURNDEADLINE', d: 'RETURNDEADLINE' },
    { o: 'RETURNREPLACEMENTID', d: 'RETURNREPLACEMENTID' },
    { o: 'RETURNSTATUS', d: 'RETURNSTATUS' },
    { o: 'RETURNREASONCODEID', d: 'RETURNREASONCODEID' },
    { o: 'CREDITCARDAUTHORIZATIONERROR', d: 'CREDITCARDAUTHORIZATIONERROR' },
    { o: 'SHIPCARRIERACCOUNTCODE', d: 'SHIPCARRIERACCOUNTCODE' },
    { o: 'RETURNREPLACEMENTCREATED', d: 'RETURNREPLACEMENTCREATED' },
    { o: 'SHIPCARRIERDLVTYPE', d: 'SHIPCARRIERDLVTYPE' },
    { o: 'ATPINCLPLANNEDORDERS', d: 'ATPINCLPLANNEDORDERS' },
    { o: 'ATPTIMEFENCE', d: 'ATPTIMEFENCE' },
    { o: 'DELIVERYDATECONTROLTYPE', d: 'DELIVERYDATECONTROLTYPE' },
    { o: 'SHIPCARRIEREXPEDITEDSHIPMENT', d: 'SHIPCARRIEREXPEDITEDSHIPMENT' },
    { o: 'SHIPCARRIERRESIDENTIAL', d: 'SHIPCARRIERRESIDENTIAL' },
    { o: 'SALESRESPONSIBLE', d: 'SALESRESPONSIBLE' },
    { o: 'SALESUNITID', d: 'SALESUNITID' },
    { o: 'SMMSALESAMOUNTTOTAL', d: 'SMMSALESAMOUNTTOTAL' },
    { o: 'SMMCAMPAIGNID', d: 'SMMCAMPAIGNID' },
    { o: 'CFPSID', d: 'CFPSID' },
    { o: 'CONSOLIDATIONDATE', d: 'CONSOLIDATIONDATE' },
    { o: 'CUSTOMSEXPORTORDER_IN', d: 'CUSTOMSEXPORTORDER_IN' },
    { o: 'SERVICECODEONDLVADDRESS', d: 'SERVICECODEONDLVADDRESS' },
    { o: 'CUSTOMSSHIPPINGBILL_IN', d: 'CUSTOMSSHIPPINGBILL_IN' },
    { o: 'TDSGROUP_IN', d: 'TDSGROUP_IN' },
    { o: 'TCSGROUP_IN', d: 'TCSGROUP_IN' },
    { o: 'NATUREOFASSESSEE_IN', d: 'NATUREOFASSESSEE_IN' },
    { o: 'DELIVERYADDRESSNUMBER', d: 'DELIVERYADDRESSNUMBER' },
    { o: 'DELIVERYADDRESSCOMPLEMENT', d: 'DELIVERYADDRESSCOMPLEMENT' },
    { o: 'DELIVERYCUSTCATEGORY', d: 'DELIVERYCUSTCATEGORY' },
    { o: 'DELIVERYCUSTCNPJCPFNUM', d: 'DELIVERYCUSTCNPJCPFNUM' },
    { o: 'DELIVERYCUSTIENUM', d: 'DELIVERYCUSTIENUM' },
    { o: 'DELIVERYCFOPID', d: 'DELIVERYCFOPID' },
    { o: 'CUSTFINALUSER', d: 'CUSTFINALUSER' },
    { o: 'CUSTINVOICETYPE', d: 'CUSTINVOICETYPE' },
    { o: 'CUSTCFOPID', d: 'CUSTCFOPID' },
    { o: 'OPERATIONID', d: 'OPERATIONID' },
    { o: 'TAXREMITTANCE', d: 'TAXREMITTANCE' },
    { o: 'INVENTCONTROL', d: 'INVENTCONTROL' },
    { o: 'LEDGERPOST', d: 'LEDGERPOST' },
    { o: 'DLVTRANSITDEPOSIT', d: 'DLVTRANSITDEPOSIT' },
    { o: 'CUSTTRANSCONTROL', d: 'CUSTTRANSCONTROL' },
    { o: 'CUSTREFUSEDNOTE', d: 'CUSTREFUSEDNOTE' },
    { o: 'TARGETOFCONSOLIDATION_JP', d: 'TARGETOFCONSOLIDATION_JP' },
    { o: 'DDEDSEID', d: 'DDEDSEID' },
    { o: 'RENUMBER', d: 'RENUMBER' },
    { o: 'REDATE', d: 'REDATE' },
    { o: 'DELIVERYDISTRICTNAME', d: 'DELIVERYDISTRICTNAME' },
    { o: 'INVOICESERIES', d: 'INVOICESERIES' },
    { o: 'SUFRAMA', d: 'SUFRAMA' },
    { o: 'SALESPURCH', d: 'SALESPURCH' },
    { o: 'FININTERESTCODE', d: 'FININTERESTCODE' },
    { o: 'FINECODE', d: 'FINECODE' },
    { o: 'SUFRAMAPISCOFINS', d: 'SUFRAMAPISCOFINS' },
    { o: 'SUFRAMANUMBER', d: 'SUFRAMANUMBER' },
    { o: 'TAXWITHHOLDCALCULATE', d: 'TAXWITHHOLDCALCULATE' },
    { o: 'INVOICEREFRECID', d: 'INVOICEREFRECID' },
    { o: 'AUTHORITY_BR', d: 'AUTHORITY_BR' },
    { o: 'REFPROCESSNO_BR', d: 'REFPROCESSNO_BR' },
    { o: 'ASU_PROGRAMID', d: 'ASU_PROGRAMID' },
    { o: 'ASU_CLIENTMARKETID', d: 'ASU_CLIENTMARKETID' },
    { o: 'ASU_CLAIMID', d: 'ASU_CLAIMID' },
    { o: 'ASU_CLIENTID', d: 'ASU_CLIENTID' },
    { o: 'ASU_CELLNO', d: 'ASU_CELLNO' },
    { o: 'ASU_DELIVERYCONTACTNO', d: 'ASU_DELIVERYCONTACTNO' },
    { o: 'ASUDELIVERED', d: 'ASUDELIVERED' },
    { o: 'ASU_EMAIL', d: 'ASU_EMAIL' },
    { o: 'ASU_RESHIPREASON', d: 'ASU_RESHIPREASON' },
    { o: 'ASU_ORDERTYPE', d: 'ASU_ORDERTYPE' },
    { o: 'ASU_CLAIMDATETIME', d: 'ASU_CLAIMDATETIME' },
    { o: 'ASU_LOSSTYPE', d: 'ASU_LOSSTYPE' },
    { o: 'ASU_PAYMENTFORM', d: 'ASU_PAYMENTFORM' },
    { o: 'ASU_SHIPCARRIERID', d: 'ASU_SHIPCARRIERID' },
    { o: 'ASU_TRACKINGNUMBER', d: 'ASU_TRACKINGNUMBER' },
    { o: 'ASU_FULFILLTYPE', d: 'ASU_FULFILLTYPE' },
    { o: 'ASU_SHIPTOLASTNAME', d: 'ASU_SHIPTOLASTNAME' },
    { o: 'ASU_SHIPTOFIRSTNAME', d: 'ASU_SHIPTOFIRSTNAME' },
    { o: 'ASU_SHIPTOCITY', d: 'ASU_SHIPTOCITY' },
    { o: 'ASU_SHIPTOADDRESS3', d: 'ASU_SHIPTOADDRESS3' },
    { o: 'ASU_SHIPTOADDRESS2', d: 'ASU_SHIPTOADDRESS2' },
    { o: 'ASU_SHIPTOADDRESS1', d: 'ASU_SHIPTOADDRESS1' },
    { o: 'ASU_PROCESSFLAG', d: 'ASU_PROCESSFLAG' },
    { o: 'ASU_DEDUCTIBLE', d: 'ASU_DEDUCTIBLE' },
    { o: 'ASU_SHIPPINGCOST', d: 'ASU_SHIPPINGCOST' },
    { o: 'ASU_SHIPPINGINSTRUCTIONS', d: 'ASU_SHIPPINGINSTRUCTIONS' },
    { o: 'ASU_PAYMENTTYPE', d: 'ASU_PAYMENTTYPE' },
    { o: 'ASU_MANUFACTURERID', d: 'ASU_MANUFACTURERID' },
    { o: 'ASU_WIRELESSCARRIER', d: 'ASU_WIRELESSCARRIER' },
    { o: 'ASU_ENROLLDATE', d: 'ASU_ENROLLDATE' },
    { o: 'ASU_ORDERSTATUS', d: 'ASU_ORDERSTATUS' },
    { o: 'ASU_DECLAREDVALUE', d: 'ASU_DECLAREDVALUE' },
    { o: 'ASU_NOTES', d: 'ASU_NOTES' },
    { o: 'ENTPURCHID', d: 'ENTPURCHID' },
    { o: 'ASU_INSURANCECO', d: 'ASU_INSURANCECO' },
    { o: 'ASU_SALESORDERTYPEFORPICKLIST', d: 'ASU_SALESORDERTYPEFORPICKLIST' },
    { o: 'ASU_LOSSDATE', d: 'ASU_LOSSDATE' },
    { o: 'ASU_CODAMOUNT', d: 'ASU_CODAMOUNT' },
    { o: 'ASU_BATCHID', d: 'ASU_BATCHID' },
    { o: 'ASU_C_BATCHID', d: 'ASU_C_BATCHID' },
    { o: 'ASU_SHIPTO_ZIP', d: 'ASU_SHIPTO_ZIP' },
    { o: 'ASU_SHIPTO_STATE', d: 'ASU_SHIPTO_STATE' },
    { o: 'ASU_ISITOLDSO', d: 'ASU_ISITOLDSO' },
    { o: 'ASU_C_BATCHID_DATE', d: 'ASU_C_BATCHID_DATE' },
    { o: 'ASU_BATCHID_DATE', d: 'ASU_BATCHID_DATE' },
    { o: 'ASU_FULLFILLMENTID', d: 'ASU_FULLFILLMENTID' },
    { o: 'ASU_INSTOREINVAMT', d: 'ASU_INSTOREINVAMT' },
    { o: 'ASU_INSTOREINVID', d: 'ASU_INSTOREINVID' },
    { o: 'ASU_INSTOREVENDID', d: 'ASU_INSTOREVENDID' },
    { o: 'ASU_INSTOREPURCHDATE', d: 'ASU_INSTOREPURCHDATE' },
    { o: 'ASU_INSTOREWITHDATA_YN', d: 'ASU_INSTOREWITHDATA_YN' },
    { o: 'ASU_CLAIMTYPE', d: 'ASU_CLAIMTYPE' },
    { o: 'ASU_USPS', d: 'ASU_USPS' },
    { o: 'ASU_BANKTRANSID', d: 'ASU_BANKTRANSID' },
    { o: 'ASU_SOTYPE', d: 'ASU_SOTYPE' },
    { o: 'ASU_ISORDERID', d: 'ASU_ISORDERID' },
    { o: 'ASU_SALVAGESTATUS', d: 'ASU_SALVAGESTATUS' },
    { o: 'ASU_SALVAGEITEMRECEIVED', d: 'ASU_SALVAGEITEMRECEIVED' },
    { o: 'ASUPOSTPACKETNUMBER', d: 'ASUPOSTPACKETNUMBER' },
    { o: 'ASUSNREXTENTIONDATE', d: 'ASUSNREXTENTIONDATE' },
    { o: 'ASUSNRFINANCESTATUS', d: 'ASUSNRFINANCESTATUS' },
    { o: 'ASUSNRTLCSTATUS', d: 'ASUSNRTLCSTATUS' },
    { o: 'ASUSNRCARESTATUS', d: 'ASUSNRCARESTATUS' },
    { o: 'ASUSNRTLCNOTES', d: 'ASUSNRTLCNOTES' },
    { o: 'ASUORDERCHANNEL', d: 'ASUORDERCHANNEL' },
    { o: 'ASUSERVICETYPE', d: 'ASUSERVICETYPE' },
    { o: 'ASUORIGINALFEE', d: 'ASUORIGINALFEE' },
    { o: 'ASUSNRSENDDATE', d: 'ASUSNRSENDDATE' },
    { o: 'ASUREQUESTEDDELIVERYDATE', d: 'ASUREQUESTEDDELIVERYDATE' },
    { o: 'ASUREQUESTEDDELIVERYTIME', d: 'ASUREQUESTEDDELIVERYTIME' },
    { o: 'MODIFIEDDATETIME', d: 'MODIFIEDDATETIME' },
    { o: 'MODIFIEDBY', d: 'MODIFIEDBY' },
    { o: 'CREATEDDATETIME', d: 'CREATEDDATETIME' },
    { o: 'DEL_CREATEDTIME', d: 'DEL_CREATEDTIME' },
    { o: 'CREATEDBY', d: 'CREATEDBY' },
    { o: 'DATAAREAID', d: 'DATAAREAID' },
    { o: 'RECVERSION', d: 'RECVERSION' },
    { o: 'RECID', d: 'RECID' },
    { o: 'ASURECEIPTTIMECONFIRMED', d: 'ASURECEIPTTIMECONFIRMED' },
    { o: 'ASUDELIVERYFIRSTATTEMPT', d: 'ASUDELIVERYFIRSTATTEMPT' },
    { o: 'ASUDELIVERYFIRSTATTEMPTTZID', d: 'ASUDELIVERYFIRSTATTEMPTTZID' },
    { o: 'ASUDELIVERYSERVICETYPE', d: 'ASUDELIVERYSERVICETYPE' },
    { o: 'ASU_CUSTOMERCODE', d: 'ASU_CUSTOMERCODE' },
    { o: 'ASU_SERVICEORDERID', d: 'ASU_SERVICEORDERID' },
    { o: 'ASUORDERCANCELDATE', d: 'ASUORDERCANCELDATE' },
    { o: 'ASUORDERCLOSUREDATE', d: 'ASUORDERCLOSUREDATE' },
    { o: 'ASUDELIVERYACTUAL', d: 'ASUDELIVERYACTUAL' },
    { o: 'ASUDELIVERYACTUALTZID', d: 'ASUDELIVERYACTUALTZID' },
    { o: 'ASUSERVICEORDERUPDATEDTM', d: 'ASUSERVICEORDERUPDATEDTM' },
    { o: 'ASUSERVICEORDERUPDATEDTMTZID', d: 'ASUSERVICEORDERUPDATEDTMTZID' },
    { o: 'ASULOANERORDERID', d: 'ASULOANERORDERID' },
    { o: 'ASUDEVICEPURCHASEDATE', d: 'ASUDEVICEPURCHASEDATE' },
    { o: 'ASUANSHINSERVICESTARTDATE', d: 'ASUANSHINSERVICESTARTDATE' },
    { o: 'ASUOLDPROGRAMFLAG', d: 'ASUOLDPROGRAMFLAG' },
    { o: 'ASUNOLOANERDISCOUNT', d: 'ASUNOLOANERDISCOUNT' },
    { o: 'ASUWEBDISCOUNT', d: 'ASUWEBDISCOUNT' },
    { o: 'ASUNETWORKMATRIXTYPE', d: 'ASUNETWORKMATRIXTYPE' },
    { o: 'ASUCONVENIENCESTOREID', d: 'ASUCONVENIENCESTOREID' },
    { o: 'ASUSTORETYPE', d: 'ASUSTORETYPE' },
    { o: 'ASUDATARECOVERYREQUEST', d: 'ASUDATARECOVERYREQUEST' },
    { o: 'ASUSHIPPINGGROUPID', d: 'ASUSHIPPINGGROUPID' },
    { o: 'ASUREALLOCEXCEPTFLAG', d: 'ASUREALLOCEXCEPTFLAG' },
    { o: 'ASUAUTOCANCELLEDFLAG', d: 'ASUAUTOCANCELLEDFLAG' },
    { o: 'ASUSHOPCODE', d: 'ASUSHOPCODE' },
    { o: 'ASUCRMCANCELLATIONDATE', d: 'ASUCRMCANCELLATIONDATE' },
    { o: 'ASUDISASTERFLAG', d: 'ASUDISASTERFLAG' },
    { o: 'ASUPLANID', d: 'ASUPLANID' },
    { o: 'ASUSHIPPINGMETHOD', d: 'ASUSHIPPINGMETHOD' },
    { o: 'ASUDEDUCTIBLEFREE_4HD', d: 'ASUDEDUCTIBLEFREE_4HD' },
    { o: 'ASUREQUESTEDDELIVERYTIME_4HD', d: 'ASUREQUESTEDDELIVERYTIME_4HD' },
    { o: 'ASUTRACKINGNUMBER2', d: 'ASUTRACKINGNUMBER2' },
    { o: 'ASUISAPICONNDISABLED', d: 'ASUISAPICONNDISABLED' },
    { o: 'ASUORANGEDEDAMT', d: 'ASUORANGEDEDAMT' },
    { o: 'ASUCRMCANCELLATIONREASON', d: 'ASUCRMCANCELLATIONREASON' },
    { o: 'ASUPENALTYFEE', d: 'ASUPENALTYFEE' },
    { o: 'ASUENROLLESIM', d: 'ASUENROLLESIM' },
  ],
}
