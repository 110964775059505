export default {
  query: `select
	distinct il.SERVICE_REQUEST_ID, il.INTERACTION_ID, il.INTERACTION_LINE_ID,il.INTERACTION_LINE_STATUS_CODE, il.CHANNEL_NAME, il.AGENT_NAME, 'SERVICE_LOGS', 'GRAPHQL_LOGS', 'ORANGE_API_LOGS', 'SIGMA_API_LOGS', 'COMPLETE_ORDER_JOB_LOGS', INTERACTION_LINE_START_TIME
from
	interaction.interaction_line il
join asset.asset a on
	a.asset_id = il.ASSET_ID
where
	(a.mobile_device_nbr = :mdn
		or a.subscription_nbr = :subnbr)
	and il.created_system_id = 1
order by
	il.created_date asc
`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'InteractionId', d: 'INTERACTION_ID' },
    { o: 'InteractionLineId', d: 'INTERACTION_LINE_ID' },
    { o: 'InteractionLineStatus', d: 'INTERACTION_LINE_STATUS_CODE' },
    { o: 'ChannelName', d: 'CHANNEL_NAME' },
    { o: 'AgentName', d: 'AGENT_NAME' },
    { o: 'StartTime', d: 'INTERACTION_LINE_START_TIME' },
    { o: 'HCore Logs', d: 'SERVICE_LOGS' },
    { o: 'Graphql Logs', d: 'GRAPHQL_LOGS' },
    { o: 'Orange Logs', d: 'ORANGE_API_LOGS' },
    { o: 'Sigma Logs', d: 'SIGMA_API_LOGS' },
    { o: 'Claim Submission Job', d: 'COMPLETE_ORDER_JOB_LOGS' },
  ],
}
