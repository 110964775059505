module.exports.LoanerSuccess = {
  ApplicationNumber: 'QA9001540',
  IcCardReissue: '6',
  NumberOfReplacements: '',
  RegisteredDate: '20190915',
  SalvageImei: '180000000000382',
  ShippedImei: '',
  ShippedIccId: '',
  DeductibleAmount: '003000',
  PointsUsed: '000000',
  PerilType: '',
  Plus1ProductBillingAmount: '000000',
  NoLoanerDiscount: '001000',
  WebDiscount: '005000',
  IcCardCharge: '000000',
  LossCompensationServiceAmount: '000000',
  LossCompensationCardExchangeAmount: '000000',
  Reserve4: '000000',
  Reserve5: '',
  Reserve6: '000000',
  Reserve7: '000000',
  KanryouKubun: '',
}

module.exports.LoanerError = { ErrorCode: 'OREP20' }
