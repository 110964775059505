import { config } from '../config'
import { Auth } from '../services/auth'
import { clients } from '../data/master-data'

export const isClientEnvironmentSelected = () => {
  const stack = localStorage.getItem('stack')
  const client = localStorage.getItem('client')
  const environment = localStorage.getItem('environment')
  if (!client || !stack || !environment) {
    return false
  }
  Auth.configure(config[client][stack])
  return Auth.hasValidIdentity(stack) && Auth.loadIdentity(stack)
}

export const isProdEnv = () => {
  const env = (localStorage.getItem('environment') || '').toUpperCase()
  return env.startsWith('PRD')
}

export const activeStack = () => {
  const stack = localStorage.getItem('stack')
  return stack ? stack : ''
}

export const activeClient = () => {
  const client = localStorage.getItem('client')
  return client ? client : ''
}

export const activeConfig = () => {
  const stack = localStorage.getItem('stack')
  const client = localStorage.getItem('client')
  if (!client || !stack) {
    return null
  }
  return config[client][stack]
}

export const getEligibleStacks = (selectedClient) => {
  const isLocalHost = window.location.hostname.includes('localhost')
  const isProductionAccount = isLocalHost ? false : !window.location.hostname.includes('nonprod')
  const selectedClientObject = clients.filter((c: any) => c.Enable).find((c) => c.Code === selectedClient)
  return selectedClientObject
    ? selectedClientObject.Stacks.filter((e: any) =>
        isLocalHost ? true : e.IsProductionAccount === isProductionAccount
      ).sort((a, b) => a.DisplayOrder - b.DisplayOrder)
    : []
}

export const formatStackName = (name) => {
  const split_string = (name || '').toUpperCase().split(/(\d+)/)
  return `${split_string[0]}${split_string[1] ? '-' : ''}${split_string[1] || ''}`
}

export const isSupperAdminUser = (username: string) => {
  return ['parvez', 'sachin', 'rajan'].some((u) => username?.toLowerCase()?.includes(u))
}

export const stubModeChangeAllowedUsers = (users: string[], username: string) => {
  return users.some((u) => username?.toLowerCase()?.includes(u))
}
