import queries from '../../sql'
import API from '../../services'
import { actions } from './actions'
import { activeConfig } from '../selectors'
import { call, take, put, all } from 'redux-saga/effects'
import queryAssetByReferenceNbr from '../../sql/horizon/queryAssetByReferenceNbr'

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    rv[x[key]] = rv[x[key]] || []
    rv[x[key]].push(x)
    return rv
  }, {})
}

function isSuccess(response) {
  return response && (response.status === 200 || response.status === 204)
}

function getData(response) {
  return response.data || []
}

function* getBaseRequest(data: any) {
  if (data.key === '3') {
    const response = yield call(API.DataService.searchData, {
      params: { mdn: data.value },
      query: queryAssetByReferenceNbr.query,
    })
    return { params: { mdn: response?.data[0]?.MOBILE_DEVICE_NBR || data.value, subnbr: null } }
  }
  return data.key === '1'
    ? { params: { mdn: data.value, subnbr: null } }
    : { params: { mdn: null, subnbr: data.value } }
}

function* getClaimData(data: any) {
  const config = activeConfig()
  const baseRequest = yield getBaseRequest(data)

  try {
    let count = 0
    const operations: any = []
    const response: any = {}
    Object.keys(queries).forEach((app) => {
      if (!Object.prototype.hasOwnProperty.call(response, app)) {
        response[app] = {}
      }
      Object.keys(queries[app]).forEach((group) => {
        if (!Object.prototype.hasOwnProperty.call(response[app], group)) {
          response[app][group] = {}
        }
        Object.keys(queries[app][group]).forEach((query) => {
          const selectedQuery = queries[app][group][query]
          response[app][group][query] = { responseIndex: count, headers: selectedQuery.mapper }
          const request = Object.assign({}, baseRequest, { query: selectedQuery.query })
          if (app === 'legacy') {
            operations.push(
              config.constants.allowLegacySearch ? call(API.CIFService.searchData, request) : Promise.resolve([])
            )
          } else {
            operations.push(call(API.DataService.searchData, request))
          }
          count++
        })
      })
    })

    const result = yield all(operations)
    Object.keys(response).forEach((app) => {
      Object.keys(response[app]).forEach((group) => {
        Object.keys(queries[app][group]).forEach((query) => {
          if (query?.toUpperCase() === 'ORDERXML' && app?.toUpperCase() === 'HORIZON') {
            const groupedXmls = groupBy(getData(result[response[app][group][query].responseIndex]), 'REFERENCE_2')
            response[app][group][query]['data'] = Object.keys(groupedXmls).map((xml) => {
              const xmls = groupedXmls[xml]
              return xmls.sort(
                (b: any, a: any) => new Date(a.CREATED_DATE).getTime() - new Date(b.CREATED_DATE).getTime()
              )[0]
            })
          } else {
            response[app][group][query]['data'] = getData(result[response[app][group][query].responseIndex])
          }
        })
      })
    })
    response.horizon.Asset.EnrolledAssetReplacements.data =
      response.horizon.Asset.EnrolledAssetReplacements.data.filter((r) =>
        response.horizon.ClientAccount.Account.data.map((x) => x.client_channel_id).includes(r.client_channel_id)
      )
    yield put({
      type: actions.LOAD_CLAIM_DATA_SUCCESS,
      input: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_CLAIM_DATA_ERROR,
      input: error,
    })
  }
}

function* releaseLock(input) {
  try {
    const request = {
      UpdateEntityParameters: {
        ReturnEntity: true,
        EntityOwnership: {
          EntityOwnershipId: input.ENTITY_OWNERSHIP_ID,
          EntityOwnershipStatus: 'INACTV',
          UpdatedBy: 'devtools',
          UpdatedDate: new Date().toISOString(),
        },
      },
    }
    const response = yield call(API.EntityService.update, request, 'EntityOwnership', input.ENTITY_OWNERSHIP_ID)
    if (isSuccess(response)) {
      yield put({
        type: actions.RELEASE_LOCK_SUCCESS,
        input: getData(response).UpdateEntityResults.EntityOwnership,
      })
    } else {
      yield put({
        type: actions.RELEASE_LOCK_ERROR,
        input: getData(response),
      })
    }
  } catch (error) {
    yield put({
      type: actions.RELEASE_LOCK_ERROR,
      input: error,
    })
  }
}

function* loadLogs(input) {
  try {
    const request = { MobileNumber: input.value, Type: input.type, PageNumber: input.pageNumber, PageSize: 25 }
    const response = yield call(API.CIFService.searchLogs, request)
    if (isSuccess(response)) {
      yield put({
        type: actions.LOAD_LOGS_SUCCESS,
        input: {
          data: JSON.parse(getData(response).logs || '[]'),
          total: getData(response).totalRecords || 1,
          type: input.type,
        },
      })
    } else {
      yield put({
        type: actions.LOAD_LOGS_ERROR,
        input: getData(response),
      })
    }
  } catch (error) {
    yield put({
      type: actions.LOAD_LOGS_ERROR,
      input: error,
    })
  }
}

export function* getClaimDataFlow() {
  while (true) {
    const { input } = yield take(actions.LOAD_CLAIM_DATA_REQUEST)
    yield call(getClaimData, input)
  }
}

export function* releaseLockFlow() {
  while (true) {
    const { input } = yield take(actions.RELEASE_LOCK_REQUEST)
    yield call(releaseLock, input)
  }
}

export function* loadLogsFlow() {
  while (true) {
    const { input } = yield take(actions.LOAD_LOGS_REQUEST)
    yield call(loadLogs, input)
  }
}
