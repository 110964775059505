import { IParameterStore } from '../types'

export enum actions {
  REQUEST_PRODUCT_FEATURE = 'REQUEST_PRODUCT_FEATURE',
  SUCCESS_PRODUCT_FEATURE = 'SUCCESS_PRODUCT_FEATURE',
  ERROR_PRODUCT_FEATURE = 'ERROR__PRODUCT_FEATURE',

  REQUEST_PRODUCT_FEATURE_DETAILS = 'REQUEST_PRODUCT_FEATURE_DETAILS',
  SUCCESS_PRODUCT_FEATURE_DETAILS = 'SUCCESS_PRODUCT_FEATURE_DETAILS',
  ERROR_PRODUCT_FEATURE_DETAILS = 'ERROR__PRODUCT_FEATURE_DETAILS',

  UPDATE_CONFIGURATION_FORM_FIELDS = 'UPDATE_CONFIGURATION_FORM_FIELDS',

  REQUEST_PARAMETER_STORE = 'REQUEST_PARAMETER_STORE',
  SUCCESS_PARAMETER_STORE = 'SUCCESS_PARAMETER_STORE',
  ERROR_PARAMETER_STORE = 'ERROR_PARAMETER_STORE',

  REQUEST_UPDATE_PARAMETER_STORE = 'REQUEST_UPDATE_PARAMETER_STORE',
  SUCCESS_UPDATE_PARAMETER_STORE = 'SUCCESS_UPDATE_PARAMETER_STORE',
}

export const configurationActionCreator = {
  getProductFeatureList: (input: any) => ({
    type: actions.REQUEST_PRODUCT_FEATURE,
    input,
  }),
  getProductFeatureDetails: (input: any) => ({
    type: actions.REQUEST_PRODUCT_FEATURE_DETAILS,
    input,
  }),
  updateProductConfigurationForm: (input: any) => ({
    type: actions.UPDATE_CONFIGURATION_FORM_FIELDS,
    input,
  }),
  getParameterStoreDetails: (env = '') => ({
    type: actions.REQUEST_PARAMETER_STORE,
    env,
  }),
  updateParameterStore: (input: IParameterStore, userName: string, env = '') => ({
    type: actions.REQUEST_UPDATE_PARAMETER_STORE,
    input,
    userName,
    env,
  }),
}
