export enum actions {
  RESET_SUCCESS_FLAG = 'RESET_SUCCESS_FLAG',
  RESET_ERROR = 'RESET_ERROR',
  ADD_ERROR = 'ADD_ERROR',
}

export const actionCreator = {
  resetSuccessFlag: () => ({
    type: actions.RESET_SUCCESS_FLAG,
  }),
  resetError: () => ({
    type: actions.RESET_ERROR,
  }),
  addError: (input: any) => ({
    type: actions.ADD_ERROR,
    input,
  }),
}
