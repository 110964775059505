export default {
  query: `select distinct rl.return_logistics_id, rl.return_logistics_status, rl.asset_return_id, rl.payment_order_id, rl.service_order_id, rl.parent_service_order_id, rl.customer_case_id, rl.service_request_id, rl.charge_order_id, rl.return_logistics_resolution, rl.rtrn_lgstcs_resolution_rsn, rl.rtrn_lgstcs_resolution_sub_rsn, rl.return_logistics_type_code, rl.grace_period_extension, rl.expected_return_date, rl.device_expected_ind, rl.non_returnfee_authorized_ind, rl.overriden_by, rl.override_date, rl.override_reason_code, rl.action_date, rl.created_system_id, rl.created_by, rl.created_date, rl.updated_by, rl.updated_date, rl.bulk_updated_by, rl.bulk_updated_date, rl.received_date, rl.active_ind
    from customer.service_request sr
    join customer.return_logistics rl on rl.service_request_id = sr.service_request_id and sr.active_ind = 1 and rl.active_ind = 1
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by rl.created_date asc`,
  mapper: [
    { o: 'ReturnLogisticsId', d: 'return_logistics_id' },
    { o: 'ReturnLogisticsStatus', d: 'return_logistics_status' },
    { o: 'AssetReturnId', d: 'asset_return_id' },
    { o: 'PaymentOrderId', d: 'payment_order_id' },
    { o: 'ServiceOrderId', d: 'service_order_id' },
    { o: 'ParentServiceOrderId', d: 'parent_service_order_id' },
    { o: 'CustomerCaseId', d: 'customer_case_id' },
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ChargeOrderId', d: 'charge_order_id' },
    { o: 'ReturnLogisticsResolution', d: 'return_logistics_resolution' },
    { o: 'RtrnLgstcsResolutionRsn', d: 'rtrn_lgstcs_resolution_rsn' },
    { o: 'RtrnLgstcsResolutionSubRsn', d: 'rtrn_lgstcs_resolution_sub_rsn' },
    { o: 'ReturnLogisticsType', d: 'return_logistics_type_code' },
    { o: 'GracePeriodExtension', d: 'grace_period_extension' },
    { o: 'ExpectedReturnDate', d: 'expected_return_date' },
    { o: 'DeviceExpectedInd', d: 'device_expected_ind' },
    { o: 'NonReturnfeeAuthorizedInd', d: 'non_returnfee_authorized_ind' },
    { o: 'OverridenBy', d: 'overriden_by' },
    { o: 'OverrideDate', d: 'override_date' },
    { o: 'OverrideReason', d: 'override_reason_code' },
    { o: 'ActionDate', d: 'action_date' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'BulkUpdatedBy', d: 'bulk_updated_by' },
    { o: 'BulkUpdatedDate', d: 'bulk_updated_date' },
    { o: 'ReceivedDate', d: 'received_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
