import { IEndpoint, IKibanaConfig } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-sqa4.uap.jpnmob-acyan.npr.aws.asurion.net/api',
  searchLogEnvironment: 'SQAD365',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: 'aa275550-270c-11ef-8e62-1b550b2b5585',
  kibanaGQLIndex: 'd1870680-270d-11ef-91d6-698f0d392d95',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}
