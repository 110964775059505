import axios from 'axios'
import { Auth } from './auth'
import * as log from 'loglevel'
import { config } from '../config'

// Request interceptor
axios.interceptors.request.use(
  (req) => {
    return new Promise((resolve, reject) => {
      const stack = localStorage.getItem('stack') || ''
      const client = localStorage.getItem('client') || ''
      if (!client || !stack) {
        reject('Environment/Client not selected')
      }
      Auth.configure(config[client][stack])
      Auth.currentSession('', '').then((token) => {
        if (req && req.headers) {
          req.headers['Authorization'] = 'Bearer ' + token.session?.idToken.jwtToken
          req.headers['cache-control'] = 'no-cache'
          req.headers['Pragma'] = 'no-cache' // IE cache
          req.timeout = req.timeout || 150000
          req.headers['Accept-Language'] = 'ja-JP'
        }
        resolve(req)
      })
    })
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res)
  },
  function (error: any) {
    if (error.response && error.response.status === 403) {
      return
    }

    const errorObj: any = {
      raw: '',
      statusCode: -1,
      message: 'Unknown error',
      type: 'default',
    }
    errorObj.raw = error

    if (error.response) {
      let message = error.message
      if (error.response.data && error.response.data.message) {
        message = error.response.data.message
      }

      errorObj.type = 'request'
      errorObj.method = error.config.method
      errorObj.statusCode = error.response.status
      errorObj.message = message
      errorObj.url = error.config.url
    } else if (error.request) {
      const status = error.request.status

      errorObj.type = 'request'
      errorObj.method = error.config.method
      errorObj.statusCode = status
      errorObj.message = 'Request error'
      errorObj.url = error.config.url
    }
    log.error('INTERCEPTOR ERROR', error)
    return Promise.reject(error)
  }
)

export default axios
