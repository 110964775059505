export default {
  query: `select
            a.MOBILE_DEVICE_NBR
          from
            asset.asset a
          where
            a.external_reference_id = :mdn and a.asset_instance_code = 'ENROLLED' and a.asset_status_code = 'ACTV'
          order 
            by a.created_date desc limit 1;`,
  mapper: [{ o: 'MobileDeviceNbr', d: 'MOBILE_DEVICE_NBR' }],
}
