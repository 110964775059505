import API from '../../services'
import uuid from '../../utils/uuid'
import { OrangeServiceType } from '../../utils/constants'
import { clientChannels } from '../../data/master-data'
import { call, take, all, put } from 'redux-saga/effects'
import { actions, pascalActionCreator as actionCreator } from './actions'
import { xml2json, generateDefaultStubResponses } from '../../utils/helper'
import { isProdEnv } from '../selectors'

function* creatEnrollment(input: { XML: string; isUpdateOperation: any; Form: any }) {
  const data = xml2json(input.XML)
  const isOpenKFS = ['2', '3', '4'].includes(data.OrangeResponseInterface.ResponseData.KyakuKbn.toString())
  const skipSyncEnrollment = isProdEnv() || isOpenKFS
  const mobileNbr = (data?.OrangeResponseInterface?.ResponseData?.KyakuShokai?.AuTelNum || '').toString()
  const subscriberNbr = (data?.OrangeResponseInterface?.ResponseData?.KyakuShokai?.KyakuCd || '').toString()
  const request = {
    CreateStubRequest: {
      Uri: 'kddi/enrollment',
      Key: mobileNbr,
      Body: input.XML.replace('\n', ''),
      ContentType: 'xml',
    },
  }
  const subrequest = {
    CreateStubRequest: {
      Uri: 'kddi/subenrollment',
      Key: subscriberNbr,
      Body: input.XML.replace('\n', ''),
      ContentType: 'xml',
    },
  }
  const loanerStubRequest: any = generateDefaultStubResponses(OrangeServiceType.LonerOrderResponse, mobileNbr, '0')
  const noLoanerStubRequest: any = generateDefaultStubResponses(OrangeServiceType.NoLonerOrderResponse, mobileNbr, '0')
  const icActivationStubRequest: any = generateDefaultStubResponses(
    OrangeServiceType.ICActivationResponse,
    mobileNbr,
    ''
  )
  const forceCompleteStubRequest: any = generateDefaultStubResponses(
    OrangeServiceType.ForceCompleteResponse,
    mobileNbr,
    ''
  )
  const selectedClientChannel: any = clientChannels.find((p) => p.Code === input.Form.ClientChannel) || {}

  const createInteractionRequest = {
    CreateEntityParameters: {
      ReturnEntity: true,
      Interaction: {
        ClientChannelId: selectedClientChannel.Code,
        ChannelName: 'CSR',
        CreatedBy: 'stub-tool',
        InboundDirection: true,
        InteractionStatus: 'INPRGRS',
        InteractionType: 'DEFAULT',
        InteractionStartTime: new Date().toISOString(),
      },
    },
  }
  try {
    let response: any[],
      isSuccess: any,
      subenrollmentResponse: { status: number; data: any },
      enrollmentResponse: { status: number; data: any },
      loanerResponse: { status: number; data: any },
      noLoanerResponse: { status: number; data: any },
      icActivationResponse1: { status: number; data: any },
      icActivationResponse2: { status: number; data: any },
      createInteractionResponse: {
        status: number
        data: { CreateEntityResults: { Interaction: { InteractionId: any } } }
      }

    if (input.isUpdateOperation) {
      response = yield all([
        call(API.OrangeService.save, request),
        call(API.EntityService.create, createInteractionRequest, 'Interaction'),
        call(API.OrangeService.save, subrequest),
      ])
      enrollmentResponse = response[0]
      createInteractionResponse = response[1]
      subenrollmentResponse = response[2]

      isSuccess =
        enrollmentResponse &&
        enrollmentResponse.status === 200 &&
        createInteractionResponse &&
        createInteractionResponse.status === 200 &&
        subenrollmentResponse &&
        subenrollmentResponse.status === 200
    } else {
      response = yield all([
        call(API.OrangeService.save, request),
        call(API.OrangeService.save, subrequest),
        call(API.OrangeService.save, loanerStubRequest),
        call(API.OrangeService.save, noLoanerStubRequest),
        call(API.OrangeService.save, icActivationStubRequest),
        call(API.OrangeService.save, forceCompleteStubRequest),
        call(API.EntityService.create, createInteractionRequest, 'Interaction'),
      ])
      enrollmentResponse = response[0]
      subenrollmentResponse = response[1]
      loanerResponse = response[2]
      noLoanerResponse = response[3]
      icActivationResponse1 = response[4]
      icActivationResponse2 = response[5]
      createInteractionResponse = response[6]

      isSuccess =
        enrollmentResponse &&
        enrollmentResponse.status === 200 &&
        subenrollmentResponse &&
        subenrollmentResponse.status === 200 &&
        loanerResponse &&
        loanerResponse.status === 200 &&
        noLoanerResponse &&
        noLoanerResponse.status === 200 &&
        icActivationResponse1 &&
        icActivationResponse1.status === 200 &&
        icActivationResponse2 &&
        icActivationResponse2.status === 200 &&
        createInteractionResponse &&
        createInteractionResponse.status === 200
    }

    if (isSuccess) {
      if (!skipSyncEnrollment) {
        const createInteractionLineRequest = {
          CreateEntityParameters: {
            ReturnEntity: true,
            InteractionLine: {
              InteractionLineId: uuid.generateRandom().toString('hex'),
              ExternalReferenceId: uuid.generateRandom().toString('hex'),
              InteractionId: createInteractionResponse.data.CreateEntityResults.Interaction.InteractionId,
              ClientChannelId: selectedClientChannel.Code,
              ChannelName: 'CSR',
              Language: 'Ja-jp',
              InteractionLineStatus: 'INPRGRS',
              InteractionLineStartTime: new Date().toISOString(),
              CreatedBy: 'stub-tool',
              Caller: 'stub-tool',
              CallRoute: 'stub-tool',
            },
          },
        }
        const createInteractionLineResponse = yield call(
          API.EntityService.create,
          createInteractionLineRequest,
          'InteractionLine'
        )
        const processEnrollmentRequest = {
          SyncEnrolmentRequest: {
            ClientId: selectedClientChannel.ClientId,
            InteractionLineId: createInteractionLineResponse.data.CreateEntityResults.InteractionLine.InteractionLineId,
            ClientChannelId: selectedClientChannel.Code,
            MobileDeviceNumber: mobileNbr,
            SubscriptionNumber: subscriberNbr,
            Mode: 'stub',
            VerificationCriteria: {},
          },
        }
        const processEnrollmentResponse = yield call(API.PascalService.syncEnrollment, processEnrollmentRequest)
        const ramdomNumber = yield call(API.SequenceService.generate)

        const mobileDeviceNumber =
          processEnrollmentResponse?.data?.SyncEnrolmentResponse?.Agreement?.find((x) => x.MobileDeviceNumber)
            ?.MobileDeviceNumber || request.CreateStubRequest.Key
        yield put(
          actionCreator.creatEnrollmentSucess({
            MDN: mobileDeviceNumber,
            initialFormData: {
              MobileDeviceNumber: ramdomNumber,
              SubscriberNumber: ramdomNumber.substring(0, 8),
              IMEI: ramdomNumber.concat('0000'),
            },
          })
        )
      } else {
        const ramdomNumber = yield call(API.SequenceService.generate)
        yield put(
          actionCreator.creatEnrollmentSucess({
            SubscriberNumber: subscriberNbr,
            initialFormData: {
              MobileDeviceNumber: ramdomNumber,
              SubscriberNumber: ramdomNumber.substring(0, 8),
              IMEI: ramdomNumber.concat('0000'),
            },
          })
        )
      }
    } else {
      yield put(actionCreator.createEnrollmentFail(enrollmentResponse.data || createInteractionResponse.data))
    }
  } catch (error) {
    yield put(actionCreator.createEnrollmentFail(error))
  }
}

export function* creatEnrollmentFlow() {
  while (true) {
    const { input } = yield take(actions.REQUEST_CREATE_ENROLLMENT)
    yield call(creatEnrollment, input)
  }
}
