export default {
  query: `select distinct sra.service_request_attribute_id, sra.service_request_id, sra.attribute_name, sra.attribute_value, sra.effective_start_date, sra.effective_end_date, sra.created_system_id, sra.created_by, sra.created_date, sra.updated_by, sra.updated_date, sra.bulk_updated_by, sra.bulk_updated_date, sra.active_ind
    from customer.service_request sr
    join customer.service_request_attribute sra on sra.service_request_id = sr.service_request_id and sr.active_ind = 1 and sra.active_ind = 1
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by sra.created_date asc;`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ServiceRequestAttributeId', d: 'service_request_attribute_id' },
    { o: 'AttributeName', d: 'attribute_name' },
    { o: 'AttributeValue', d: 'attribute_value' },
    { o: 'EffectiveStartDate', d: 'effective_start_date' },
    { o: 'EffectiveEndDate', d: 'effective_end_date' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'BulkUpdatedBy', d: 'bulk_updated_by' },
    { o: 'BulkUpdatedDate', d: 'bulk_updated_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
