import { produce } from 'immer'
import { Reducer } from 'redux'
import { actions } from './actions'
import { initialStateSagawa } from './initialState'
import DefaultOrderOprationFormData from '../../utils/sample-data-templates/order-opration'

export const sagawaReducer: Reducer<typeof initialStateSagawa> = (state = initialStateSagawa, { type, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case actions.UPDATE_ORDER_FORM_FIELDS:
        draft.Form = Object.assign({}, draft.Form, input)
        return draft
      case actions.RESET_ORDER_FORM:
        draft.Form = DefaultOrderOprationFormData
        return draft
      case actions.UPDATE_LOADER:
        draft.isLoading = input
        return draft
      case actions.GET_SERVICE_ORDER_DETAILS_SUCCESS:
        draft.OrderDetailsResponse = {
          IsSuccess: true,
          OrderId: input.order_id,
          MessageId: input.message_id,
          DeliveryBilling: input.delivery_billing,
          PlatformOrderId: input.platform_order_id,
          OrderStatus: input.order_status,
        }
        return draft
      case actions.GET_SERVICE_ORDER_DETAILS_ERROR:
        draft.OrderDetailsResponse.IsSuccess = false
        return draft
      case actions.RESET_SERVICE_ORDER_DETAILS_DATA:
        draft.OrderDetailsResponse = initialStateSagawa.OrderDetailsResponse
        draft.OrderDetailsResponse.IsSuccess = false
        return draft
      case actions.SET_MESSAGE:
        draft.Message.MessageType = input.MessageType
        draft.Message.MessageCode = input.MessageCode
        draft.Message.Message = input.Message
        return draft
      case actions.RESET_MESSAGE:
        draft.Message = initialStateSagawa.Message
        return draft
      default:
        return draft
    }
  })
}
