import React from 'react'
import { Formik } from 'formik'
import { Col, Form, Button, Row, FormSelect } from 'react-bootstrap'

import searchFormSchema from '../../form-schemas/search-form-v3'

const SearchFormV2: React.FunctionComponent<{
  initialFormData: any
  search: any
  updateForm: any
  searchByAccountNbr?: boolean
  showInstruction?: boolean
}> = (props) => {
  const onSubmit = () => {
    props.search()
  }

  const handleFormChange = (handleChange, e) => {
    const {
      target: { name, value, checked, type },
    } = e
    const input = {}
    input[name] = type === 'checkbox' ? checked : value
    props.updateForm(input)
    handleChange(e)
  }

  return (
    <Formik validationSchema={searchFormSchema} onSubmit={onSubmit} initialValues={props.initialFormData}>
      {({ values, touched, errors, handleBlur, handleSubmit, handleReset, handleChange, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" controlId="formGrideSearchType">
              <Form.Label>Search By</Form.Label>
              <FormSelect
                name="SearchType"
                value={values.SearchType}
                isInvalid={!!errors.SearchType}
                isValid={!errors.SearchType}
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(handleChange, e)}
              >
                <option>---</option>
                <option value="1">Mobile Number</option>
                <option value="2">Subscriber Number</option>
              </FormSelect>
              {errors.SearchType && touched.SearchType ? (
                <Form.Control.Feedback type="invalid">{!errors.SearchType}</Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" controlId="formGridSearchValue">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="text"
                placeholder={values.SearchType === '1' ? '80-1234-5678' : 'HRZN3800'}
                name="MobileDeviceNumber"
                value={values.MobileDeviceNumber}
                isInvalid={!!errors.MobileDeviceNumber && !!touched.MobileDeviceNumber}
                isValid={touched.MobileDeviceNumber && !errors.MobileDeviceNumber}
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(handleChange, e)}
              />
              {errors.MobileDeviceNumber && touched.MobileDeviceNumber && (
                <Form.Control.Feedback type="invalid">
                  <>{errors.MobileDeviceNumber}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              className="text-end col-xl-12 pt-xl-3 col-lg-12 pt-lg-3 col-md-12 pt-md-3 col-sm-12 col-12 pt-1"
              controlId="formGridButton"
            >
              <Form.Label>&nbsp;</Form.Label>
              <Button variant="success" type="submit" disabled={!isValid || !values.MobileDeviceNumber}>
                Search
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="outline-secondary"
                type="button"
                onClick={handleReset}
                disabled={!values.MobileDeviceNumber}
              >
                Reset
              </Button>
            </Form.Group>
            <Form.Group as={Col} className="col-xl-4 col-lg-4 col-md-2 col-12" controlId="formGridMDN">
              <Form.Label>{` `}</Form.Label>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default SearchFormV2
