import { IActiveConfig, IConstants } from '../types'

import { defaultEndpointConfig as SQA1Endpoints, kibanaConfig as SQA1KibanaConfig } from './sqa1'
// import { defaultEndpointConfig as SQA2Endpoints } from './sqa2'
// import { defaultEndpointConfig as SQA3Endpoints } from './sqa3'
import { defaultEndpointConfig as SQA4Endpoints, kibanaConfig as SQA4KibanaConfig } from './sqa4'
// import { defaultEndpointConfig as SQA5Endpoints } from './sqa5'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: 'uap-acyan-npr-api',
  kibanaGQLIndex: 'uap-acyan-npr-cluster',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-sqa-pool-jpnmob',
    region: 'ap-northeast-1',
    userPoolWebClientId: '4u2vnbtu52vgks5m2umq39vhrc',
    userPoolId: 'ap-northeast-1_NlZ3qdidg',
    identityPoolId: '',
    identityProvider: 'acyan-sqa-SSO',
  },
  constants: defaultConstants,
  sqa1: SQA1KibanaConfig,
  sqa4: SQA4KibanaConfig,
  endpoints: [
    {
      key: 'sqa1',
      value: SQA1Endpoints,
    },
    // {
    //   key: 'sqa2',
    //   value: SQA2Endpoints,
    // },
    // {
    //   key: 'sqa3',
    //   value: SQA3Endpoints,
    // },
    {
      key: 'sqa4',
      value: SQA4Endpoints,
    },
    // {
    //   key: 'sqa5',
    //   value: SQA5Endpoints,
    // },
  ],
}
