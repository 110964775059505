export default {
  query: `select distinct sr.SERVICE_REQUEST_ID, sho.SHIPPING_ORDER_ID, sho.ASSET_ID, sho.SHIPPING_METHOD_TYPE_CODE, sho.SHIPMENT_TYPE_CODE, sho.SHIPPING_STATUS_CODE, sho.DAX_SALES_ORDER_ID, sho.CARRIER_NBR, sho.TRACKING_NBR, sho.SHIPMENT_LABEL_NBR, sho.SHIPMENT_DATE, sho.SENDER_NAME, sho.STORE_CODE, sho.STORE_TYPE, sho.STORE_SUB_TYPE, sho.RECEIVER_NAME, sho.SERVICE_ORDER_ID, sho.EXTERNAL_ASSET_SKU_NBR, sho.ASSET_CATALOG_ID, sho.SENDER_ADDRESS_ID, sho.RECEIVER_ADDRESS_ID, sho.EXPECTED_DELIVERY_DATE, sho.EXPECTED_DELIVERY_START, sho.EXPECTED_DELIVERY_END, sho.BUSINESS_NAME, sho.DELIVERED_DATE, sho.SIGNATURE_REQUIRED_IND, sho.SHIPMENT_ORIGIN_TYPE_CODE, sho.WEEKEND_DELIVERY_TYPE_CODE, sho.DELIVERY_OPTION_TYPE_CODE, sho.SPLIT_IND, sho.EXTERNAL_REFERENCE_ID, sho.SHIPPING_LABEL, sho.SHIPPING_INSTRUCTION, sho.DELIVERY_INSTRUCTION, sho.OVERRIDE_BY, sho.APPROVED_BY, sho.EARLIEST_DELIVERY_DATE, sho.EARLIEST_DELIVERY_START, sho.EARLIEST_DELIVERY_END, sho.CREATED_SYSTEM_ID, sho.CREATED_BY, sho.CREATED_DATE, sho.UPDATED_BY, sho.UPDATED_DATE, sho.BULK_UPDATED_BY, sho.BULK_UPDATED_DATE, sho.ACTIVE_IND
    from customer.service_request sr
    join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
    join customer.shipping_order sho on sho.shipping_order_id = ccc.item_id
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by sho.created_date asc;`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ServiceOrderId', d: 'SERVICE_ORDER_ID' },
    { o: 'ShippingOrderId', d: 'SHIPPING_ORDER_ID' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'ShippingMethodType', d: 'SHIPPING_METHOD_TYPE_CODE' },
    { o: 'ShipmentType', d: 'SHIPMENT_TYPE_CODE' },
    { o: 'ShippingStatus', d: 'SHIPPING_STATUS_CODE' },
    { o: 'DaxSalesOrderId', d: 'DAX_SALES_ORDER_ID' },
    { o: 'CarrierNbr', d: 'CARRIER_NBR' },
    { o: 'TrackingNbr', d: 'TRACKING_NBR' },
    { o: 'ShipmentLabelNbr', d: 'SHIPMENT_LABEL_NBR' },
    { o: 'ShipmentDate', d: 'SHIPMENT_DATE' },
    { o: 'SenderName', d: 'SENDER_NAME' },
    { o: 'ReceiverName', d: 'RECEIVER_NAME' },
    { o: 'StoreType', d: 'STORE_TYPE' },
    { o: 'StoreCode', d: 'STORE_CODE' },
    { o: 'StoreSubType', d: 'STORE_SUB_TYPE' },
    { o: 'ExternalAssetSkuNbr', d: 'EXTERNAL_ASSET_SKU_NBR' },
    { o: 'AssetCatalogId', d: 'ASSET_CATALOG_ID' },
    { o: 'SenderAddressId', d: 'SENDER_ADDRESS_ID' },
    { o: 'ReceiverAddressId', d: 'RECEIVER_ADDRESS_ID' },
    { o: 'ExpectedDeliveryDate', d: 'EXPECTED_DELIVERY_DATE' },
    { o: 'ExpectedDeliveryStart', d: 'EXPECTED_DELIVERY_START' },
    { o: 'ExpectedDeliveryEnd', d: 'EXPECTED_DELIVERY_END' },
    { o: 'BusinessName', d: 'BUSINESS_NAME' },
    { o: 'DeliveredDate', d: 'DELIVERED_DATE' },
    { o: 'SignatureRequiredInd', d: 'SIGNATURE_REQUIRED_IND' },
    { o: 'ShipmentOriginType', d: 'SHIPMENT_ORIGIN_TYPE_CODE' },
    { o: 'WeekendDeliveryType', d: 'WEEKEND_DELIVERY_TYPE_CODE' },
    { o: 'DeliveryOptionType', d: 'DELIVERY_OPTION_TYPE_CODE' },
    { o: 'SplitInd', d: 'SPLIT_IND' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ShippingLabel', d: 'SHIPPING_LABEL' },
    { o: 'ShippingInstruction', d: 'SHIPPING_INSTRUCTION' },
    { o: 'DeliveryInstruction', d: 'DELIVERY_INSTRUCTION' },
    { o: 'OverrideBy', d: 'OVERRIDE_BY' },
    { o: 'ApprovedBy', d: 'APPROVED_BY' },
    { o: 'EarliestDeliveryDate', d: 'EARLIEST_DELIVERY_DATE' },
    { o: 'EarliestDeliveryStart', d: 'EARLIEST_DELIVERY_START' },
    { o: 'EarliestDeliveryEnd', d: 'EARLIEST_DELIVERY_END' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
