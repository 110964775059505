import React from 'react'
import { Col, Row, Table, Button, Badge } from 'react-bootstrap'

interface IProps {
  OrderId: string
  MessageId: string
  DeliveryBilling: string
  PlatformOrderId: string
  OrderStatus: string
}

export const SagawaOrderDetails: React.FunctionComponent<IProps> = (props) => {
  return (
    <Row style={{ marginTop: '1em' }}>
      <Col md={8} className="formDiv mx-auto">
        <Button variant="primary">Order Details</Button>
        <Table striped bordered hover responsive style={{ marginTop: '1em' }}>
          <thead>
            <tr>
              <th>Order Id</th>
              <th>Message Id</th>
              <th>Delivery Billing</th>
              <th>Platform Order Id</th>
              <th>Order Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.OrderId}</td>
              <td>{props.MessageId}</td>
              <td>{props.DeliveryBilling}</td>
              <td>{props.PlatformOrderId}</td>
              {props.OrderStatus ? (
                <td>
                  <legend className="w-auto">
                    <Badge pill bg="primary">
                      {props.OrderStatus}
                    </Badge>
                  </legend>
                </td>
              ) : (
                <td>-</td>
              )}
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col md={4} className="formDiv"></Col>
    </Row>
  )
}
