import DefaultOrderOprationFormData from '../../utils/sample-data-templates/order-opration'
import { IMessageType, ISagawaOrderTracking } from '../types'

export const initialStateSagawa: ISagawaOrderTracking = {
  Form: DefaultOrderOprationFormData,
  OrderDetailsResponse: {
    IsSuccess: false,
    OrderId: '',
    MessageId: '',
    DeliveryBilling: '',
    PlatformOrderId: '',
    OrderStatus: '',
  },
  Message: {
    MessageType: IMessageType.NONE,
    MessageCode: '',
    Message: '',
  },
  isLoading: false,
}
