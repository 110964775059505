module.exports.NoLoanerSuccess = {
  ApplicationNumber: 'QA4001534',
  IcCardReissue: '3',
  RegisteredDate: '20190915',
  ClosureDate: '00000000',
  SalvageImei: '170000000000475',
  SalvageProductCode: 'SHL25MLA',
  ShippedImei: '170000000000103',
  ShippedProductCode: 'SHL25MLA',
  ShippedIccId: '8981300172100001525',
  DeductibleAmount: '007000',
  PointsUsed: '000000',
  ProcessingNumber: '',
  PerilType: '1',
  ShopCode: 'E200001',
  Plus1ProductBillingAmount: '000000',
  NoLoanerDiscount: '001000',
  WebDiscount: '000500',
  IcCardCharge: '002000',
  LossCompensationServiceAmount: '000000',
  LossCompensationCardExchangeAmount: '000000',
  Reserve4: '000000',
  Reserve5: '000000',
  Reserve6: '000000',
  Reserve7: '000000',
  KanryouKubun: '1',
}

module.exports.NoLoanerError = { ErrorCode: 'OREP20' }
