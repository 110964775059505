import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './index.css'
import store from './store'
import Application from './application'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <React.Suspense>
    <Provider store={store}>
      <Application />
    </Provider>
  </React.Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
