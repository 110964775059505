module.exports.ICActivationSuccess = {
  ContractName: '山﨑賢人',
  OldICCID: '8991101200003204514',
  SoldOut: 'B',
  NewICCID: '8931346000000000001',
  ApplicationCategory: 'C1',
  ApplicationName: 'N11',
}

module.exports.ICActivationError = { ErrorCode: 'KHE029' }
