export enum actions {
  REQUEST_GET_ORANGE_RESPONSE = 'REQUEST_GET_ORANGE_RESPONSE',
  SUCCESS_GET_ORANGE_RESPONSE = 'SUCCESS_GET_ORANGE_RESPONSE',
  ERROR_GET_ORANGE_RESPONSE = 'ERROR_GET_ORANGE_RESPONSE',
  UPDATE_ORANGE_RESPONSE = 'UPDATE_ORANGE_RESPONSE',

  REQUEST_SAVE_ORANGE_RESPONSE = 'REQUEST_SAVE_ORANGE_RESPONSE',
  SUCCESS_SAVE_ORANGE_RESPONSE = 'SUCCESS_SAVE_ORANGE_RESPONSE',
  ERROR_SAVE_ORANGE_RESPONSE = 'ERROR_SAVE_ORANGE_RESPONSE',
  UPDATE_ORANGE_FORM_FIELDS = 'UPDATE_ORANGE_FORM_FIELDS',
  RESET_ORANGE_SEARCH_FORM = 'RESET_ORANGE_SEARCH_FORM',

  LOAD_SAMPLE_ERROR_RESPONSE = 'LOAD_SAMPLE_ERROR_RESPONSE',
  LOAD_SAMPLE_SUCCESS_RESPONSE = 'LOAD_SAMPLE_SUCCESS_RESPONSE',
}

export const orangeActionCreator = {
  getOrangeServiceResponse: (input: any) => ({
    type: actions.REQUEST_GET_ORANGE_RESPONSE,
    input,
  }),
  getOrangeServiceResponseSuccess: (input: any) => ({
    type: actions.SUCCESS_GET_ORANGE_RESPONSE,
    input,
  }),
  getOrangeServiceResponseError: (input: any) => ({
    type: actions.ERROR_GET_ORANGE_RESPONSE,
    input,
  }),
  updateOrangeServiceResponse: (input: any) => ({
    type: actions.UPDATE_ORANGE_RESPONSE,
    input,
  }),
  saveOrangeServiceResponse: (input: any) => ({
    type: actions.REQUEST_SAVE_ORANGE_RESPONSE,
    input,
  }),
  saveOrangeServiceResponseSuccess: (input: any) => ({
    type: actions.SUCCESS_SAVE_ORANGE_RESPONSE,
    input,
  }),
  saveOrangeServiceResponseError: (input: any) => ({
    type: actions.ERROR_SAVE_ORANGE_RESPONSE,
    input,
  }),
  updateOrangeSearchForm: (input: any) => ({
    type: actions.UPDATE_ORANGE_FORM_FIELDS,
    input,
  }),
  resetOrangeServiceSearchForm: () => ({
    type: actions.RESET_ORANGE_SEARCH_FORM,
  }),
  loadSampleSuccessResponse: (input: any) => ({
    type: actions.LOAD_SAMPLE_SUCCESS_RESPONSE,
    input,
  }),
  loadSampleErrorResponse: (input: any) => ({
    type: actions.LOAD_SAMPLE_ERROR_RESPONSE,
    input,
  }),
}
