import axios from './axios'
import { BaseAPI } from './base-api'

class KDDIAPI extends BaseAPI {
  public create = (request: any, entityType: string) => {
    return axios.post(
      `${this.getConfigKey('baseURL')}${this.endpoints.enterprisedataserviceEntity}/${entityType}`,
      request,
      {
        headers: this.headers(),
      }
    )
  }

  public update = (request: any, entityType: string, entityId: string) => {
    return axios.put(
      `${this.getConfigKey('baseURL')}${this.endpoints.enterprisedataserviceEntity}/${entityType}/${entityId}`,
      request,
      {
        headers: this.headers(),
      }
    )
  }
}

export default KDDIAPI
