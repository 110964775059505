import { connect } from 'react-redux'
import React, { Component, ReactNode } from 'react'
import { clientChannels, productFeatureMaster } from '../../data/master-data'
import { Row, Container, Alert, Col, Form, Table, FormSelect, Tabs, Tab, ListGroup, Badge } from 'react-bootstrap'

import { actionCreator } from '../../store/rootActions'
import { IState, IConfigurationTabs, IComponents, IParameterStore } from '../../store/types'
import { isClientEnvironmentSelected, stubModeChangeAllowedUsers } from '../../store/selectors'
import { configurationActionCreator } from '../../store/configuration/actions'

import Spinner from '../../components/spinner'
import Confirmation from '../../components/confirmation-popup'

interface IProductFeatureConfiguration {
  tabs: IConfigurationTabs

  showModal: boolean
  message: string
  header: string

  data?: any
}

const mapStateToProps = (state: IState) => {
  return {
    AgentInfo: state.LoginInfo.agentInfo,
    State: state.ProductConfiguration,
    ClientInfo: state.LoginInfo.clientInfo.Form,
    IsClientEnvironmentSelected: isClientEnvironmentSelected(),
  }
}

const mapDispatchToProps = {
  addError: actionCreator.addError,
  resetError: actionCreator.resetError,
  resetSuccessFlag: actionCreator.resetSuccessFlag,
  updateParameterStore: configurationActionCreator.updateParameterStore,
  getProductFeatureList: configurationActionCreator.getProductFeatureList,
  getProductFeatureDetails: configurationActionCreator.getProductFeatureDetails,
  getParameterStoreDetails: configurationActionCreator.getParameterStoreDetails,
  updateProductConfigurationForm: configurationActionCreator.updateProductConfigurationForm,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents

class Configuration extends Component<IProps, IProductFeatureConfiguration> {
  constructor(props: any) {
    super(props)

    this.state = {
      tabs: { activeTab: 'tb-gn', admin: { activeChildTab: 6 }, general: { activeChildTab: 4 } },
      showModal: false,
      message: '',
      header: '',
    }

    this.onSelectTab = this.onSelectTab.bind(this)
    this.cancelSwitch = this.cancelSwitch.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.confirmSwitch = this.confirmSwitch.bind(this)
  }

  componentDidMount() {
    this.props.resetSuccessFlag()
    if (!this.props.State.IsLoading && this.state.tabs.activeTab === 'tb-admin') {
      this.props.getParameterStoreDetails(this.props.ClientInfo.Environment)
    }
  }

  private renderTable(tableData: any, width: number) {
    if (tableData?.data?.length > 0) {
      return (
        <Col md={width} className="formDiv">
          <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
            <thead>
              <tr>
                <th>#</th>
                {tableData.headers.map((k: any, i) => {
                  return <th key={i}>{k.o}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((object, i) => {
                return object.map((innerObject, j) => {
                  if (j === 0) {
                    return (
                      <tr key={j}>
                        <td rowSpan={object.length}> {i + 1}</td>
                        {tableData.headers.map((h: any, z) => {
                          if (h.unique) {
                            return h.d === 'AttributeName' && innerObject[h.d]?.toUpperCase() === 'SWITCH' ? (
                              <td key={z}>
                                <b>{innerObject[h.d] || '-'}</b>
                              </td>
                            ) : (
                              <td key={z}>{innerObject[h.d] || '-'}</td>
                            )
                          }
                          return (
                            <td key={z} rowSpan={object.length}>
                              {innerObject[h.d] || '-'}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  }
                  return (
                    <tr key={j}>
                      {tableData.headers
                        .filter((h) => h.unique)
                        .map((h: any, j) => {
                          return <td key={j}>{innerObject[h.d] || '-'}</td>
                        })}
                    </tr>
                  )
                })
              })}
            </tbody>
          </Table>
        </Col>
      )
    }
    return null
  }

  private getDisplayParameterName(name) {
    return name
      ?.split('_')
      ?.filter((d) => d.toUpperCase() !== 'MODE' && d.toUpperCase() !== 'REFER' && d.toUpperCase() !== 'LEGACY')
      ?.join(' ')
  }

  private getDisplayUserName(name) {
    return name
      ?.split('.')
      ?.filter((d) => d.toUpperCase() !== 'CWR')
      ?.join(' ')
  }

  private getLastUpdatedDuration(date) {
    const date1 = new Date(date)
    const date2 = new Date()
    const differenceInTime = date2.getTime() - date1.getTime()
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24))
    const differenceInHrs = Math.floor(differenceInTime / (1000 * 3600))

    return differenceInDays === 0 ? `${differenceInHrs} hrs ago` : `${differenceInDays} days ago`
  }

  private handleToggle(input: IParameterStore) {
    this.cancelSwitch()
    const userName = this.props.AgentInfo?.UserName?.split('_')[1]?.split('@')[0] || 'devtools'
    this.props.updateParameterStore(input, userName, this.props.ClientInfo.Environment)
  }

  private confirmSwitch(data: IParameterStore) {
    this.setState(
      Object.assign({}, this.state, {
        showModal: true,
        message: 'Are you sure you want to switch API/Reference mode?',
        header: 'Confirmation',
        data: data,
      })
    )
  }

  private cancelSwitch() {
    this.setState(Object.assign({}, this.state, { showModal: false, message: '', header: '', data: null }))
  }

  private renderClientConfigurationTab() {
    return (
      <Row style={{ marginTop: '1rem' }} className="formDiv">
        <Col md={2}>
          <Form.Group as={Col} controlId="formSelectedClient">
            <Form.Label>Brand</Form.Label>
            <FormSelect
              name="SelectedClient"
              value={this.props.State.SelectedClient}
              onChange={(e: any) =>
                this.props.getProductFeatureList({
                  clientchannelid: e.target.value,
                })
              }
            >
              <option value="">...</option>
              {clientChannels
                .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                .map((p) => (
                  <option key={p.Code} value={p.Code}>
                    {p.DisaplayName}
                  </option>
                ))}
            </FormSelect>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group as={Col} controlId="formSelectedFeature">
            <Form.Label>Feature</Form.Label>
            <FormSelect
              name="SelectedFeature"
              value={this.props.State.SelectedFeature}
              onChange={(e: any) =>
                this.props.getProductFeatureDetails({
                  code: e.target.value,
                  clientchannelid: this.props.State.SelectedClient,
                })
              }
            >
              <option value="">...</option>
              {this.props.State.FeatureList &&
                this.props.State.FeatureList.map((p) => (
                  <option key={p.FeatureName} value={p.FeatureName}>
                    {productFeatureMaster[p.FeatureName] || p.FeatureName}
                  </option>
                ))}
            </FormSelect>
          </Form.Group>
        </Col>
        <Col md={8}></Col>
        <div>
          <hr />
        </div>
        {this.props.State.Details && this.renderTable(this.props.State.Details.criteria, 4)}
        {this.props.State.Details && this.renderTable(this.props.State.Details.configuration, 8)}
      </Row>
    )
  }

  private renderAdminConfigurationTab() {
    const legacyParameters = this.props.State.ParameterStore?.filter(
      (p: IParameterStore) => p.Name.indexOf('REFER_LEGACY') > -1 && p.Name !== 'DEV_S_USERS'
    )

    const nonLegacyParameters = this.props.State.ParameterStore?.filter(
      (p: IParameterStore) => p.Name.indexOf('REFER_LEGACY') === -1 && p.Name !== 'DEV_S_USERS'
    )
    const allowedUserForModeChange =
      this.props.State.ParameterStore?.find((param: IParameterStore) => param.Name === 'DEV_S_USERS')?.Value?.split(
        ','
      ) || []
    //const isLegacy = p.Name.indexOf('REFER_LEGACY') > -1

    return (
      <Row style={{ marginTop: '1rem' }}>
        <div className="admin-config-header">INTEGRATION SWITHCES</div>
        {nonLegacyParameters?.map((p: IParameterStore) => (
          <Col key={p.Name} className="">
            <Confirmation
              data={this.state.data}
              show={this.state.showModal}
              message={this.state.message}
              header={this.state.header}
              onHide={this.cancelSwitch}
              onOk={this.handleToggle}
            />
            <ListGroup key={p.Name} horizontal={p.Name} className="my-2">
              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="fw-bold">{`${this.getDisplayParameterName(p.Name)} SWITCH`}</div>
              </ListGroup.Item>
              <ListGroup.Item>
                Last Updated By: {this.getDisplayUserName(p.UpdatedBy)}
                <div>
                  <Badge bg="primary" pill>
                    {this.getLastUpdatedDuration(p.UpdatedDate)}
                  </Badge>
                </div>
              </ListGroup.Item>
              <ListGroup.Item variant={p.Value === 'LIVE' ? 'success' : 'info'}>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${p.Name}`}
                  label={'Enable LIVE Mode'}
                  checked={p.Value === 'LIVE'}
                  onChange={() => this.confirmSwitch(p)}
                  disabled={!stubModeChangeAllowedUsers(allowedUserForModeChange, this.props.AgentInfo?.UserName || '')}
                />
              </ListGroup.Item>
            </ListGroup>

            {/* <ListGroup horizontal className="my-2">
              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="ms-1 me-auto">
                  <div className="fw-bold">{`${this.getDisplayParameterName(p.Name)} SWITCH`}</div>
                  <small>Last updated by: {this.getDisplayUserName(p.UpdatedBy)}</small>
                </div>
                <Badge bg="primary" pill>
                  {this.getLastUpdatedDuration(p.UpdatedDate)}
                </Badge>
              </ListGroup.Item>
              <ListGroup.Item variant={p.Value === 'LIVE' ? 'success' : 'info'}>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${p.Name}`}
                  label={'Enable LIVE Mode'}
                  checked={p.Value === 'LIVE'}
                  onChange={() => this.confirmSwitch(p)}
                  disabled={!stubModeChangeAllowedUsers(allowedUserForModeChange, this.props.AgentInfo?.UserName || '')}
                />
              </ListGroup.Item>
            </ListGroup> */}
          </Col>
        ))}

        <div className="admin-config-header">PII SWITHCES</div>
        {legacyParameters?.map((p: IParameterStore) => (
          <Col key={p.Name} className="">
            <Confirmation
              data={this.state.data}
              show={this.state.showModal}
              message={this.state.message}
              header={this.state.header}
              onHide={this.cancelSwitch}
              onOk={this.handleToggle}
            />
            <ListGroup key={p.Name} horizontal={p.Name} className="my-2">
              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                <div className="fw-bold">{`${this.getDisplayParameterName(p.Name)} SWITCH`}</div>
              </ListGroup.Item>
              <ListGroup.Item>
                Last Updated By: {this.getDisplayUserName(p.UpdatedBy)}
                <div>
                  <Badge bg="primary" pill>
                    {this.getLastUpdatedDuration(p.UpdatedDate)}
                  </Badge>
                </div>
              </ListGroup.Item>
              <ListGroup.Item variant={p.Value === 'LIVE' ? 'success' : 'info'}>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${p.Name}`}
                  label={'Enable LEGACY Reference'}
                  checked={p.Value === '1'}
                  onChange={() => this.confirmSwitch(p)}
                  disabled={!stubModeChangeAllowedUsers(allowedUserForModeChange, this.props.AgentInfo?.UserName || '')}
                />
              </ListGroup.Item>
            </ListGroup>
            {/* <ListGroup horizontal className="my-2">
                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                  <div className="ms-1 me-auto">
                    <div className="fw-bold">{`${this.getDisplayParameterName(p.Name)} SWITCH`}</div>
                    <small>Last updated by: {this.getDisplayUserName(p.UpdatedBy)}</small>
                  </div>
                  <Badge bg="primary" pill>
                    {this.getLastUpdatedDuration(p.UpdatedDate)}
                  </Badge>
                </ListGroup.Item>
                <ListGroup.Item variant={p.Value === '1' ? 'success' : 'info'}>
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${p.Name}`}
                    label={'Enable LEGACY Reference'}
                    checked={p.Value === '1'}
                    onChange={() => this.confirmSwitch(p)}
                    disabled={!stubModeChangeAllowedUsers(allowedUserForModeChange, this.props.AgentInfo?.UserName || '')}
                  />
                </ListGroup.Item>
              </ListGroup> */}
          </Col>
        ))}
      </Row>
    )
  }

  private onSelectTab(selectedTabId: string | null) {
    if (selectedTabId) {
      this.setState(
        Object.assign({}, this.state, {
          tabs: Object.assign({}, this.state.tabs, { activeTab: selectedTabId }),
        })
      )
    }
    if (selectedTabId === 'tb-admin') {
      this.props.getParameterStoreDetails(this.props.ClientInfo.Environment)
    }
  }

  render(): ReactNode {
    const h = `${(window.innerWidth * 2.75) / 100}rem`
    return (
      <Container fluid>
        {this.props.State.IsLoading && <Spinner />}

        {!this.props.State.IsLoading && this.props.State.Errors && this.props.State.Errors.length > 0 && (
          <Alert variant="danger" onClose={() => this.props.resetError()} dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            {this.props.State.Errors.map((e, i) => {
              return <p key={i}>{e || 'Details not available!!!'}</p>
            })}
          </Alert>
        )}
        <fieldset className="border p-3" style={{ height: h, minHeight: h, overflowY: 'auto', marginTop: '1rem' }}>
          <Tabs activeKey={this.state.tabs.activeTab} onSelect={this.onSelectTab} id="parent-tab" fill>
            <Tab key="tb-admin" eventKey="tb-admin" title="Admin Configuration">
              {this.renderAdminConfigurationTab()}
            </Tab>
            <Tab key="tb-gn" eventKey="tb-gn" title="Client Configuration">
              {this.renderClientConfigurationTab()}
            </Tab>
          </Tabs>
        </fieldset>
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)
