/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const setState = (state: string) => {
  localStorage.setItem('oauth_state', state)
}

export const getState = () => {
  const oauthState = localStorage.getItem('oauth_state')
  localStorage.removeItem('oauth_state')
  return oauthState
}

export const setPKCE = (privateKey: string) => {
  localStorage.setItem('oauth_pkce_key', privateKey)
}

export const getPKCE = () => {
  const oauthPkceKey = localStorage.getItem('oauth_pkce_key')
  return oauthPkceKey
}

export const clearAll = () => {
  //localStorage.removeItem('stack')
  localStorage.removeItem('client')
  localStorage.removeItem('environment')
  localStorage.removeItem('oauth_state')
  localStorage.removeItem('oauth_pkce_key')
}

export const clearAuthToken = () => {
  const listofStorage = Object.keys(localStorage)
  listofStorage.forEach((item) => {
    if (item.startsWith('Cognito')) {
      localStorage.removeItem(item)
    }
  })
}
