import { ICognitoUserSession } from '../../services/auth/types'

export enum actions {
  RESET_CLIENT_SETTING = 'RESET_CLIENT_SETTING',
  COGNITO_AUTHENTICATED = 'COGNITO_AUTHENTICATED',
  UPDATE_CLIENT_FORM_FIELDS = 'UPDATE_CLIENT_FORM_FIELDS',
}

export const loginInfoCreator = {
  authenticated: (err?: any, session?: ICognitoUserSession) => ({
    type: actions.COGNITO_AUTHENTICATED,
    error: err,
    session: session,
  }),
  resetClientSetting: () => ({
    type: actions.RESET_CLIENT_SETTING,
  }),
  updateClientForm: (input: any) => ({
    type: actions.UPDATE_CLIENT_FORM_FIELDS,
    input,
  }),
}
