export default {
  query: `select
	distinct 
	doc.XML,
	doc.REFERENCE_1,
	doc.REFERENCE_2,
	doc.CREATED_BY,
	doc.CREATED_DATE,
	doc.UPDATED_BY,
	doc.UPDATED_DATE,
	doc.ACTIVE_IND
from
  	customer.service_request sr
inner join asset.asset a on
  	a.asset_id = sr.asset_id
  	and sr.active_ind = 1
  	and a.active_ind = 1
inner join integration.xml_documents doc on
  	doc.reference_1 = sr.external_reference_id
  	and doc.active_ind = 1
where
  (a.mobile_device_nbr = :mdn
	  or a.subscription_nbr = :subnbr)
  and message_type <> 'cancelOrder'
order by
  doc.created_date desc`,
  mapper: [
    { o: 'XML', d: 'XML' },
    { o: 'Reference1', d: 'REFERENCE_1' },
    { o: 'Reference2', d: 'REFERENCE_2' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
  ],
}
