export default {
  query: `select distinct sr.service_request_id, so.service_order_id, soa.attribute_name, soa.attribute_value
      from customer.service_request sr
      join asset.asset a on a.asset_id = sr.asset_id and sr.active_ind = 1 and a.active_ind = 1
      join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and ccc.active_ind = 1
      join customer.service_order so on so.service_order_id = ccc.item_id -- and so.active_ind = 1
      join customer.service_order_attribute soa on soa.service_order_id = so.service_order_id and soa.active_ind = 1
      where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr
      order by soa.created_date asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ServiceOrderId', d: 'service_order_id' },
    { o: 'AttributeName', d: 'attribute_name' },
    { o: 'AttributeValue', d: 'attribute_value' },
  ],
}
