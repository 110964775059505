import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { getFormSchema } from '../../form-schemas/sagwa-order-opration-form'
import Spinner from '../../components/spinner'
import { OrderOprationForm } from '../../components/sagawa-order-opration-form'
import { sagawaActionCreator } from '../../store/sagawa/actions'
import { IComponents, IState } from '../../store/types'
import { SagawaOrderDetails } from '../../components/sagawa-order-details'
import { AlterMessage } from '../../components/alert-message'
import DefaultOrderOprationFormData from '../../utils/sample-data-templates/order-opration'
import { SagawaOpration } from '../../utils/constants'
import { isClientEnvironmentSelected } from '../../store/selectors'

const mapStateToProps = (state: IState) => {
  return {
    serviceOrderNumber: state.SagawaOrderTracking.Form.ServiceOrderNumber,
    OrderDetails: state.SagawaOrderTracking.OrderDetailsResponse,
    isLoading: state.SagawaOrderTracking.isLoading,
    Message: state.SagawaOrderTracking.Message,
    isClientEnvironmentSelected: isClientEnvironmentSelected(),
  }
}

const mapDispatchToProps = {
  updateSOForm: sagawaActionCreator.updateOrderForm,
  resetSOForm: sagawaActionCreator.resetOrderForm,
  getServiceOrderDetails: sagawaActionCreator.getServiceOrderDetails.getServiceOrderDetailsRequest,
  resetServiceOrderDetails: sagawaActionCreator.getServiceOrderDetails.resetServiceOrderDetailsData,
  resetMessage: sagawaActionCreator.updateMessage.reSetMessage,
  orderOpration: sagawaActionCreator.performServiceOrderOpration.request,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents

const SagawaOrderOpration: React.FunctionComponent<IProps> = (props) => {
  let initialFormData: any = Object.assign({}, DefaultOrderOprationFormData)
  const resetForm = () => {
    initialFormData = Object.assign({}, DefaultOrderOprationFormData)
    props.resetMessage()
    props.resetSOForm()
    props.resetServiceOrderDetails()
  }

  useEffect(() => {
    resetForm()
  }, []) // eslint-disable-line

  const perFormOpration = () => {
    props.resetServiceOrderDetails()
    if (props.opration === SagawaOpration.Search) {
      props.getServiceOrderDetails({ ServiceOrderNumber: props.serviceOrderNumber })
    } else {
      props.orderOpration({ action: props.opration })
    }
  }
  const shouldShowOrderDetails =
    !props.isLoading &&
    !props.Message.Message &&
    props.OrderDetails.IsSuccess &&
    (props.opration === SagawaOpration.Search || props.opration === SagawaOpration.Create)

  const shouldShowErrorMessage = !props.isLoading && !!props.Message.Message
  const formSchema = getFormSchema(props.opration)
  const lgColSize = props.opration === SagawaOpration.Search || props.opration === SagawaOpration.Delete ? 4 : 7

  return (
    <Container fluid>
      {props.isLoading && <Spinner />}
      <Row>
        <Col sm={12} md={7} lg={lgColSize} className="formDiv mx-auto">
          <OrderOprationForm
            schema={formSchema}
            initialFormData={initialFormData}
            updateForm={(e) => props.updateSOForm(e)}
            opration={props.opration}
            performAction={() => perFormOpration()}
            resetForm={() => resetForm()}
          />
        </Col>
        <Col sm={12} md={5} lg={12 - lgColSize} className="formDiv"></Col>
      </Row>
      {shouldShowOrderDetails && <SagawaOrderDetails {...props.OrderDetails} />}
      {shouldShowErrorMessage && <AlterMessage {...props.Message} />}
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SagawaOrderOpration)
