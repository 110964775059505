export default {
  query: `select distinct sr.service_request_id, col.charge_order_line_id, col.charge_order_id, col.charge_order_line_type_code, col.fee_amount, col.tax_amount, col.total_amount, col.calculation_audit, col.adjustment_amount, col.adjusted_by, col.adjustment_date, col.adjustment_reason_code, col.cancellation_reason_code, col.cancellation_sub_reason_code, col.charge_order_line_status_code, col.vendor_tax_code, col.estimated_amount, col.created_system_id, col.created_by, col.created_date, col.updated_by, col.updated_date, col.bulk_updated_by, col.bulk_updated_date, col.active_ind
    from customer.service_request sr
    join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
    join customer.charge_order co on co.charge_order_id = ccc.item_id and co.active_ind = 1
    join customer.charge_order_line col on col.charge_order_id = co.charge_order_id and col.active_ind = 1
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where (a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr) and charge_order_line_status_code = 'ACTV' order by co.created_date asc;`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ChargeOrderId', d: 'charge_order_id' },
    { o: 'ChargeOrderLineId', d: 'charge_order_line_id' },
    { o: 'ChargeOrderLineType', d: 'charge_order_line_type_code' },
    { o: 'FeeAmount', d: 'fee_amount' },
    { o: 'TaxAmount', d: 'tax_amount' },
    { o: 'TotalAmount', d: 'total_amount' },
    { o: 'CalculationAudit', d: 'calculation_audit' },
    { o: 'AdjustmentAmount', d: 'adjustment_amount' },
    { o: 'AdjustedBy', d: 'adjusted_by' },
    { o: 'AdjustmentDate', d: 'adjustment_date' },
    { o: 'AdjustmentReason', d: 'adjustment_reason_code' },
    { o: 'CancellationReason', d: 'cancellation_reason_code' },
    { o: 'CancellationSubReason', d: 'cancellation_sub_reason_code' },
    { o: 'ChargeOrderLineStatus', d: 'charge_order_line_status_code' },
    { o: 'VendorTax', d: 'vendor_tax_code' },
    { o: 'EstimatedAmount', d: 'estimated_amount' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'BulkUpdatedBy', d: 'bulk_updated_by' },
    { o: 'BulkUpdatedDate', d: 'bulk_updated_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
