export default {
  query: `select
  oe.OrderExtension_ID as OrderExtensionId,
  oe.Order_ID as OrderId,
  oe.Discount,
  oe.ApplicationNumber,
  oe.ShopCode,
  oe.RegisteredDate,
  oe.Irregular_Matrix_Flag as IrregularMatrixFlag,
  oe.Pascal_Lost_Flag as PascalLostFlag,
  oe.LTESimDiscount,
  oe.IsSimCharged,
  oe.QANumber,
  oe.Deductible_Exemption_Flag as DeductibleExemptionFlag,
  oe.MatrixNetworkType,
  oe.IsSimSelected,
  oe.LoanerFlag,
  oe.WebDiscount,
  oe.NoLoanerDiscount,
  oe.IC_Card_Amount as ICCardAmount,
  oe.IC_Tax_Amount as ICTaxAmount,
  oe.Deductible_Tax_Amount as DeductibleTaxAmount,
  oe.ShippingBaseWarehouse,
  oe.OMATriageId,
  oe.DamageDescription,
  oe.Plan_Id as PlanId,
  oe.[4HD_Amount] as '4HDAmount',
  oe.[4HD_Tax_Amount] as '4HDTaxAmount',
  oe.MonthlyFeeMismatch,
  oe.SelectedBrand,
  oe.Created_By as CreatedBy,
  oe.Created_Dtm as CreatedDate,
  oe.Modified_By as ModifiedBy,
  oe.Modified_Dtm as ModifiedDate,
  oe.Deleted_Flg as DeletedFlag
from
  intelliset.dbo.OrderExtension oe with(nolock)
inner join intelliset.dbo.[order] o with(nolock) on
  oe.Order_ID = o.order_id
inner join intelliset.dbo.claim c with(nolock) on
  o.claim_ID = c.claim_id
inner join intelliset..subscriber s with(nolock) on
  s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by oe.Created_Dtm asc;`,
  mapper: [
    { o: 'OrderExtensionId', d: 'OrderExtensionId' },
    { o: 'OrderId', d: 'OrderId' },
    { o: 'Discount', d: 'Discount' },
    { o: 'ApplicationNumber', d: 'ApplicationNumber' },
    { o: 'ShopCode', d: 'ShopCode' },
    { o: 'RegisteredDate', d: 'RegisteredDate' },
    { o: 'IrregularMatrixFlag', d: 'IrregularMatrixFlag' },
    { o: 'PascalLostFlag', d: 'PascalLostFlag' },
    { o: 'LTESimDiscount', d: 'LTESimDiscount' },
    { o: 'IsSimCharged', d: 'IsSimCharged' },
    { o: 'QANumber', d: 'QANumber' },
    { o: 'DeductibleExemptionFlag', d: 'DeductibleExemptionFlag' },
    { o: 'MatrixNetworkType', d: 'MatrixNetworkType' },
    { o: 'IsSimSelected', d: 'IsSimSelected' },
    { o: 'LoanerFlag', d: 'LoanerFlag' },
    { o: 'WebDiscount', d: 'WebDiscount' },
    { o: 'NoLoanerDiscount', d: 'NoLoanerDiscount' },
    { o: 'ICCardAmount', d: 'ICCardAmount' },
    { o: 'ICTaxAmount', d: 'ICTaxAmount' },
    { o: 'DeductibleTaxAmount', d: 'DeductibleTaxAmount' },
    { o: 'ShippingBaseWarehouse', d: 'ShippingBaseWarehouse' },
    { o: 'OMATriageId', d: 'OMATriageId' },
    { o: 'DamageDescription', d: 'DamageDescription' },
    { o: 'PlanId', d: 'PlanId' },
    { o: '4HDAmount', d: '4HDAmount' },
    { o: '4HDTaxAmount', d: '4HDTaxAmount' },
    { o: 'MonthlyFeeMismatch', d: 'MonthlyFeeMismatch' },
    { o: 'SelectedBrand', d: 'SelectedBrand' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
