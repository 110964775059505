import parser from 'xml-js'
import loanerMapper from '../utils/response-mappers/loaner-mapper.json'
import noLoanerMapper from '../utils/response-mappers/noloaner-mapper.json'
import icActivationMapper from '../utils/response-mappers/ic-activation-mapper.json'
import forceCompleteMapper from '../utils/response-mappers/force-complete-mapper.json'
import chargeOrderMapper from '../utils/response-mappers/charge-order-mapper.json'
import submitOrderMapper from '../utils/response-mappers/submit-order-mapper.json'
import defaultLoanerResponse from './default-xml-response/loaner'
import defaultNoLoanerResponse from './default-xml-response/no-loaner'
import defaultICActivationResponse from './default-xml-response/ic-activation'
import defaultForceCompleteResponse from './default-xml-response/force-complete'
import defaultChargeOrderResponse from './default-xml-response/charge-order'
import defaultSubmitOrderResponse from './default-xml-response/submit-order'
import { OrangeServiceType, URITypes, OperationTypes } from './constants'

function nativeType(value) {
  const bValue = value.toLowerCase()
  if (bValue === 'true') {
    return true
  } else if (bValue === 'false') {
    return false
  }
  return value
}

function removeJsonTextAttribute(value, parentElement) {
  try {
    const keyNo = Object.keys(parentElement._parent).length
    const keyName = Object.keys(parentElement._parent)[keyNo - 1]
    parentElement._parent[keyName] = nativeType(value)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function getErrorCode(businessData, errorCodeStartIndex) {
  return businessData.substring(errorCodeStartIndex, errorCodeStartIndex + 8)
}

function getMapper(operation) {
  let mapper
  switch (operation) {
    case OrangeServiceType.LonerOrderResponse:
      mapper = loanerMapper
      break
    case OrangeServiceType.NoLonerOrderResponse:
      mapper = noLoanerMapper
      break
    case OrangeServiceType.ICActivationResponse:
      mapper = icActivationMapper
      break
    case OrangeServiceType.ForceCompleteResponse:
      mapper = forceCompleteMapper
      break
    case OrangeServiceType.ChargeOrderResponse:
      mapper = chargeOrderMapper
      break
    case OrangeServiceType.SubmitOrderResponse:
      mapper = submitOrderMapper
      break
    default:
      mapper = ''
      break
  }
  return mapper
}

function extractPropertyIndexWithValue(response, propertyName, value) {
  const matchingKey = Object.keys(response).find((r) => {
    const splitProps = r.split(propertyName)
    return splitProps.length === 2 && !isNaN(splitProps[1] as any) && response[r] === value
  })

  return matchingKey ? matchingKey.split(propertyName)[1] : 0
}

function extractPropertyValueWithIndex(response, propertyName, index) {
  const value = response[propertyName + index]
  if (JSON.stringify(value) === JSON.stringify({})) {
    return ''
  }
  return value
}

function getOfferStartEndDate(response) {
  let kfsstartdate = ''
  let kfsenddate = ''
  let oldkfsstartdate = ''
  let oldkfsenddate = ''
  let solutostartdate = ''
  let solutoenddate = ''
  let simonlystartdate = ''
  let simonlyenddate = ''

  kfsstartdate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoKaisiYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K205')
  )

  oldkfsenddate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoShuryoYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K203')
  )

  oldkfsstartdate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoKaisiYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K203')
  )

  kfsenddate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoShuryoYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K205')
  )

  solutostartdate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoKaisiYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K211')
  )

  solutoenddate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoShuryoYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K211')
  )

  simonlystartdate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoKaisiYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K213')
  )

  simonlyenddate = extractPropertyValueWithIndex(
    response.KyakuShokai,
    'KanyuShaOptionTekiyoShuryoYmd',
    extractPropertyIndexWithValue(response.KyakuShokai, 'KanyuShaOption', 'K213')
  )

  return {
    OldKFSStartDate: oldkfsstartdate,
    OldKFSEndDate: oldkfsenddate,
    KFSStartDate: kfsstartdate,
    KFSEndDate: kfsenddate,
    SimOnlyStartDate: simonlystartdate,
    SimOnlyEndDate: simonlyenddate,
    SolutoStartDate: solutostartdate,
    SolutoEndDate: solutoenddate,
  }
}

export function getBase64EncodedData(data) {
  return Buffer.from(data).toString('base64')
}

export function getBase64DecodedData(data) {
  return Buffer.from(data, 'base64').toString('utf-8')
}

export function xml2json(data) {
  const options = {
    compact: true,
    trim: true,
    spaces: 2,
    ignoreDeclaration: true,
    ignoreInstruction: true,
    ignoreAttributes: true,
    ignoreComment: true,
    ignoreCdata: true,
    ignoreDoctype: true,
    textFn: removeJsonTextAttribute,
  }

  return JSON.parse(parser.xml2json(data, options))
}

export function mapKDDIResponseObject(encodedData, operation) {
  const masterMapper = getMapper(operation)
  const jsonBody = xml2json(encodedData)
  if (operation === OrangeServiceType.EnrollmentResponse) {
    return jsonBody && jsonBody.OrangeResponseInterface ? jsonBody.OrangeResponseInterface : null
  }
  if (Object.prototype.hasOwnProperty.call(jsonBody, 'errors')) {
    return { ErrorCode: jsonBody.errors.error[Object.keys(jsonBody.errors.error)[0]] }
  }
  const inputString = getBase64DecodedData(jsonBody?.NaileReceiveData?.ReceiveData?.Data || '')
  const errorCode = getErrorCode(inputString, masterMapper.ErrorCodeStartIndex).trim()

  let response: any = []
  if (errorCode !== '' && errorCode !== 'OHE217' && errorCode !== 'OHE177') {
    response = { ErrorCode: errorCode }
  } else {
    const dataMapper = masterMapper.Mapper
    let startIndex = masterMapper.StartIndex
    if (
      [
        OrangeServiceType.ICActivationResponse,
        OrangeServiceType.ForceCompleteResponse,
        OrangeServiceType.ChargeOrderResponse,
        OrangeServiceType.SubmitOrderResponse,
      ].includes(operation)
    ) {
      response = {}
      for (const element of dataMapper) {
        if (inputString.substring(startIndex, startIndex + element.Length).trim() !== '') {
          response[element.Name] = inputString.substring(startIndex, startIndex + element.Length).trim()
        }
        startIndex += element.Length
      }
      const excludeArray = dataMapper.filter((x) => x.Exclude === true)
      for (const element of excludeArray) {
        delete response[element.Name]
      }
      return response
    }
    const orderCount = !inputString || inputString === errorCode ? 1 : 5
    for (let i = 0; i < dataMapper.length; i++) {
      for (let j = 0; j < orderCount; j++) {
        if (i === 0) {
          response.push({})
          response[j][dataMapper[i].Name] = inputString.substring(startIndex, startIndex + dataMapper[i].Length).trim()
        } else if (response[j]) {
          response[j][dataMapper[i].Name] = inputString.substring(startIndex, startIndex + dataMapper[i].Length).trim()
        }
        startIndex += dataMapper[i].Length
      }
    }
    if (orderCount > 1) {
      response = response.filter((o) => {
        return Object.values(o).some((x) => x !== null && x !== '')
      })
    }
  }
  return response
}

export function mapKDDIRequestObject(input, operation) {
  const master: any = getMapper(operation)
  const dataMapper = master.Mapper
  const respose: any = [' '.repeat(master.StartIndex)]
  if (
    [
      OrangeServiceType.ICActivationResponse,
      OrangeServiceType.ForceCompleteResponse,
      OrangeServiceType.ChargeOrderResponse,
      OrangeServiceType.SubmitOrderResponse,
    ].includes(operation)
  ) {
    for (const element of dataMapper) {
      const _input = input[element.Name] || ''
      const whiteSpaces = ' '.repeat(element.Length - _input.length)
      respose.push(_input + whiteSpaces)
    }
  } else {
    if (input.length < 5) {
      const upperBound = 5 - input.length
      for (let index = 1; index <= upperBound; index++) {
        input.push({})
      }
    }
    for (const element of dataMapper) {
      respose.push(
        input
          .map((d) => {
            const _input = d[element.Name] || ''
            const whiteSpaces = ' '.repeat(element.Length - _input.length)
            return _input + whiteSpaces
          })
          .join('')
      )
    }
  }
  return `<?xml version='1.0' encoding='UTF-8'?><NaileReceiveData xml:space='preserve' xmlns='http://au-pascal.kddi.com/common/NaileReceiveData.xsd'><ReceiveData><Data>${getBase64EncodedData(
    respose.join('')
  )}</Data></ReceiveData></NaileReceiveData>`
}

export function generateGetStubRequest(type, mdn, command) {
  switch (type) {
    case OrangeServiceType.LonerOrderResponse:
      return `<?xml version='1.0' encoding='UTF-8'?>
        <NaileSendData
            xmlns='http://au-pascal.kddi.com/common/NaileSendData.xsd'>
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData(mdn + 'xxxxxxxxxxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>P91</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCLMS</GamenId>
                <Command2>${command}</Command2>
                <Version>03</Version>
                <ReceiveByteSize>738</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.NoLonerOrderResponse:
      return `<?xml version='1.0' encoding='UTF-8'?>
        <NaileSendData
            xmlns='http://au-pascal.kddi.com/common/NaileSendData.xsd'>
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData(mdn + 'xxxxxxxxxxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>O11</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCLFS</GamenId>
                <Command2>${command}</Command2>
                <Version>05</Version>
                <ReceiveByteSize>1067</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.ICActivationResponse:
      return `<?xml version="1.0" encoding="UTF-8"?>
        <NaileSendData
            xmlns="http://au-pascal.kddi.com/common/NaileSendData.xsd">
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData('xxxxxxxxx' + mdn + 'xxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>081</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCE3S</GamenId>
                <Command2>${command}</Command2>
                <Version>04</Version>
                <ReceiveByteSize>113</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.ForceCompleteResponse:
      return `<?xml version="1.0" encoding="UTF-8"?>
        <NaileSendData
            xmlns="http://au-pascal.kddi.com/common/NaileSendData.xsd">
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData('xxxxxxxx' + mdn + 'xxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>K11</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCKVS</GamenId>
                <Command2>${command}</Command2>
                <Version>01</Version>
                <ReceiveByteSize>208</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.ChargeOrderResponse:
      return `<?xml version='1.0' encoding='UTF-8'?>
        <NaileSendData
            xmlns='http://au-pascal.kddi.com/common/NaileSendData.xsd'>
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData('xxxxxxxxxx' + mdn + 'xxxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>V81</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCMVS</GamenId>
                <Command2>${command}</Command2>
                <Version>01</Version>
                <ReceiveByteSize>25</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.SubmitOrderResponse:
      return `<?xml version='1.0' encoding='UTF-8'?>
        <NaileSendData
            xmlns='http://au-pascal.kddi.com/common/NaileSendData.xsd'>
            <GyoumuBu>
                <GyoumuData>${getBase64EncodedData('xxxxxxxxxx' + mdn + 'xxxxxxxxx')}</GyoumuData>
            </GyoumuBu>
            <HeaderBu>
                <DenbunCode>P81</DenbunCode>
                <GyoumuCode>ORCB2S</GyoumuCode>
                <NyuuryokuTanmatsuShubetsu>00</NyuuryokuTanmatsuShubetsu>
                <GamenId>ORCLLS</GamenId>
                <Command2>${command}</Command2>
                <Version>04</Version>
                <ReceiveByteSize>484</ReceiveByteSize>
            </HeaderBu>
        </NaileSendData>`
    case OrangeServiceType.EnrollmentResponse:
      return `<?xml version="1.0" encoding="Windows-31J"?>
      <OrangeRequestInterface>
          <RequestHeader>
              <RequestId>ORH1234567890123</RequestId>
              <KyotenCd>EZAN001</KyotenCd>
              <TantoushaId>AUE18472</TantoushaId>
              <TerminalName>A000</TerminalName>
          </RequestHeader>
          <RequestData>
              <AuTelNum>${mdn}</AuTelNum>   
              <KanyuShaCd>${mdn}</KanyuShaCd> 
          </RequestData>
      </OrangeRequestInterface>`
    default:
      return ''
  }
}

export function generateDefaultStubResponses(operation, key, type = '') {
  let body
  switch (operation) {
    case OrangeServiceType.LonerOrderResponse:
      body = defaultLoanerResponse
      break
    case OrangeServiceType.NoLonerOrderResponse:
      body = defaultNoLoanerResponse
      break
    case OrangeServiceType.ICActivationResponse:
      body = defaultICActivationResponse
      break
    case OrangeServiceType.ForceCompleteResponse:
      body = defaultForceCompleteResponse
      break
    case OrangeServiceType.ChargeOrderResponse:
      body = defaultChargeOrderResponse
      break
    case OrangeServiceType.SubmitOrderResponse:
      body = defaultSubmitOrderResponse
      break
    default:
      body = ''
      break
  }
  return {
    CreateStubRequest: {
      Uri: URITypes[operation],
      Key: key,
      OperationName: OperationTypes[operation],
      Type: type,
      ContentType: 'xml',
      Body: body,
    },
  }
}

export function generateErrorStubResponses(errorCode, operation) {
  if (errorCode && errorCode.length > 7) {
    return `<?xml version="1.0" encoding="UTF-8"?>
    <errors>
        <error>
            <error-code>${errorCode}</error-code>
            <error-message>${errorCode}</error-message>
        </error>
    </errors>`
  }
  const master: any = getMapper(operation)
  const errorResponse = ' '.repeat(master.ErrorCodeStartIndex) + errorCode
  return `<?xml version='1.0' encoding='UTF-8'?><NaileReceiveData xml:space='preserve' xmlns='http://au-pascal.kddi.com/common/NaileReceiveData.xsd'><ReceiveData><Data>${getBase64EncodedData(
    errorResponse
  )}</Data></ReceiveData></NaileReceiveData>`
}

export function extractClientOffer(response) {
  let freeplan
  let clientProductNumber = ''
  const offerDates = getOfferStartEndDate(response)
  const clientChannelId =
    response.KyakuShokai.MvnoSikibetuKbn && ['87', '88'].includes(response.KyakuShokai.MvnoSikibetuKbn)
      ? '1EAA245F60F40364938AFF1A774566E5'
      : '7D42D83E285511E9A38A063D3002FD4C'

  if (response.KyakuShokai.SaisinTorokuIdokiSinHoshuFlg === '1') {
    freeplan = 2
  } else if (response.KyakuShokai.SaisinTorokuIdokiSinHoshuFlg === '0') {
    freeplan = 4
  } else {
    freeplan = ''
  }

  //SIM Only DP member + KFS non member
  if (
    offerDates.SimOnlyStartDate &&
    !offerDates.SimOnlyEndDate &&
    offerDates.KFSStartDate &&
    !offerDates.KFSEndDate &&
    offerDates.SolutoStartDate
  ) {
    clientProductNumber = '1815'
  }
  //SIM Only DP member + KFS member
  else if (
    offerDates.SimOnlyStartDate &&
    !offerDates.SimOnlyEndDate &&
    offerDates.KFSStartDate &&
    !offerDates.KFSEndDate
  ) {
    clientProductNumber = '181'
  }
  //SIM Only DP member + KFS non member
  else if (
    offerDates.SimOnlyStartDate &&
    !offerDates.SimOnlyEndDate &&
    offerDates.KFSStartDate &&
    offerDates.KFSEndDate
  ) {
    clientProductNumber = '182'
  }
  //SIM Only DP member + Soluto member
  else if (offerDates.SimOnlyStartDate && !offerDates.SimOnlyEndDate && offerDates.SolutoStartDate) {
    clientProductNumber = '185'
  }
  //SIM Only DP member
  else if (offerDates.SimOnlyStartDate && !offerDates.SimOnlyEndDate) {
    clientProductNumber = '18'
  }
  //SIM Only DP canceled + KFS member
  else if (
    offerDates.SimOnlyStartDate &&
    offerDates.SimOnlyEndDate &&
    offerDates.KFSStartDate &&
    !offerDates.KFSEndDate
  ) {
    clientProductNumber = '191'
  }
  //SIM Only DP canceled + KFS non member
  else if (
    offerDates.SimOnlyStartDate &&
    offerDates.SimOnlyEndDate &&
    offerDates.KFSStartDate &&
    offerDates.KFSEndDate
  ) {
    clientProductNumber = '192'
  }
  //SIM Only DP canceled + Soluto member
  else if (offerDates.SimOnlyStartDate && offerDates.SimOnlyEndDate && offerDates.SolutoStartDate) {
    clientProductNumber = '195'
  }
  //SIM Only DP canceled
  else if (offerDates.SimOnlyStartDate && offerDates.SimOnlyEndDate) {
    clientProductNumber = '19'
  }
  //1~5 NewPaid+Soluto
  else if (offerDates.KFSEndDate === '' && offerDates.SolutoEndDate === '') {
    clientProductNumber = '15'
  }
  //3~5 OldPaid+Soluto
  else if (offerDates.OldKFSEndDate === '' && offerDates.SolutoEndDate === '') {
    clientProductNumber = '35'
  }
  //2~5 Soluto+NF
  else if (freeplan !== undefined && freeplan === 2 && offerDates.SolutoEndDate === '') {
    clientProductNumber = '25'
  }
  //4~5 Old Free+Soluto
  else if (freeplan !== undefined && freeplan === 4 && offerDates.SolutoEndDate === '') {
    clientProductNumber = '45'
  }
  //New Paid
  else if (offerDates.KFSEndDate === '') {
    clientProductNumber = '1'
  }
  //Old Paid
  else if (offerDates.OldKFSEndDate === '') {
    clientProductNumber = '3'
  }
  //Soluto
  else if (offerDates.SolutoEndDate === '') {
    clientProductNumber = '5'
  }
  //Old Free
  else if (freeplan !== undefined && freeplan === 4) {
    clientProductNumber = '4'
  }
  //New Free
  else if (freeplan !== undefined && freeplan === 2) {
    clientProductNumber = '2'
  } else {
    //Non-sub: For simonly customer having no device information
    clientProductNumber = '99'
  }
  return { ClientProductNumber: clientProductNumber, ClientChannelId: clientChannelId }
}

export function extractDateFromEraDate(eraDate) {
  if (!eraDate || eraDate.length < 7) return ''
  const era = parseInt(eraDate.substring(0, 1))
  const year = parseInt(eraDate.substring(1, 3))
  const month = parseInt(eraDate.substring(3, 5))
  const day = parseInt(eraDate.substring(5, 7))
  let baseYear = 0
  switch (era) {
    // Meiji
    case 0:
      baseYear = 1867
      break
    // Taisho
    case 1:
      baseYear = 1911
      break
    // Showa
    case 2:
      baseYear = 1925
      break
    // Heisei
    case 3:
      baseYear = 1988
      break
    // Reiwa
    case 4:
      baseYear = 2018
      break
    // default
    default:
      break
  }
  return `${baseYear + year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
}

export function extractEraDateFromDate(date) {
  const dateArray = date.split('-')
  let era = '0'
  let year = 0
  if (dateArray[0] >= 2018) {
    era = '4'
    year = dateArray[0] - 2018
  } else if (dateArray[0] >= 1988) {
    era = '3'
    year = dateArray[0] - 1988
  } else if (dateArray[0] >= 1925) {
    era = '2'
    year = dateArray[0] - 1925
  } else if (dateArray[0] >= 1911) {
    era = '1'
    year = dateArray[0] - 1911
  } else {
    era = '0'
    year = dateArray[0] - 1867 < 0 ? 0 : dateArray[0] - 1867
  }
  const month = parseInt(dateArray[1]) < 10 ? '0' + parseInt(dateArray[1]) : '' + parseInt(dateArray[1])
  const day = parseInt(dateArray[2]) < 10 ? '0' + parseInt(dateArray[2]) : '' + parseInt(dateArray[2])
  return `${era}${year < 10 ? '0' + year : year}${month}${day}`
}

export function getHeaders(data) {
  if (!data || data.length === 0) {
    return []
  }
  const headers = data.map((d) => {
    return Object.keys(d)
  })
  return []
    .concat(...headers)
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter((h: any) => h && h.toUpperCase() !== 'NUMBER')
}

export function getDateIfDate(d) {
  if (typeof d === 'string') {
    const m = d.match(/\/Date\((\d+)\)\//)
    if (m) {
      return new Date(+m[1]).toISOString() //new Date(+m[1]).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
    }
  }
  if (typeof d === 'boolean') {
    return d ? 'true' : 'false'
  }
  return d
}

export const clientSpecificPayload = (data) => {
  return (client) => data[client]
}

export const groupBy = (array, f) => {
  const groups = {}
  array.forEach(function (o) {
    const group = JSON.stringify(f(o))
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return Object.keys(groups).map(function (group) {
    return groups[group]
  })
}
