export default {
  query: `select
            distinct a.asset_id ,
            ac.asset_catalog_id ,
            a.SUBSCRIPTION_NBR,
            a.MOBILE_DEVICE_NBR,
            a.external_reference_id,
            a.MOBILE_EQUIPMENT_ID,
            a.ASSET_INSTANCE_CODE,
            ac.asset_catalog_name ,
            ac.asset_catalog_desc ,
            null VENDOR_ASSET_SKU_NBR,
            am.asset_make_name ,
            null VENDOR_ASSET_MAKE,
            am1.asset_model_nbr ,
            null VENDOR_ASSET_MODEL,
            ac1.asset_color_name ,
            a.ASSET_STATUS_CODE,
            a.ASSET_CONDITION_CODE,
            a.ASSET_START_DATE,
            a.ORIGINAL_PURCHASE_DATE,
            a.SERIAL_NBR,
            ac.asset_catalog_start_date ,
            ac.asset_catalog_end_date ,
            am.asset_make_start_date ,
            am.asset_make_end_date,
            am1.asset_model_start_date ,
            am1.asset_model_end_date ,
            ac1.asset_color_start_date ,
            ac1.asset_color_end_date,
            null VENDOR_ASSET_START_DATE,
            null VENDOR_ASSET_END_DATE,
            null client_asset_sku_nbr,
            a.created_date asset_created_date
          from
            asset.asset a
          left join asset.asset_catalog ac on
            ac.asset_catalog_id = a.asset_catalog_id
          left join asset.asset_make am on
            am.asset_make_id = ac.asset_make_id
            and am.active_ind = 1
          left join asset.asset_model am1 on
            am1.asset_model_id = ac.asset_model_id
            and am1.active_ind = 1
          left join asset.asset_color ac1 on
            ac1.asset_color_id = ac.asset_color_id
            and ac1.active_ind = 1
          where
            (a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr)
            and a.asset_catalog_id is null
            and a.asset_instance_code = 'ENROLLED' and a.asset_status_code = 'ACTV'
          union
          select
            distinct a.asset_id ,
            ac.asset_catalog_id ,
            a.SUBSCRIPTION_NBR,
            a.MOBILE_DEVICE_NBR,
            a.external_reference_id,
            a.MOBILE_EQUIPMENT_ID,
            a.ASSET_INSTANCE_CODE,
            ac.asset_catalog_name ,
            ac.asset_catalog_desc ,
            va.VENDOR_ASSET_SKU_NBR,
            am.asset_make_name ,
            va.VENDOR_ASSET_MAKE,
            am1.asset_model_nbr ,
            va.VENDOR_ASSET_MODEL,
            ac1.asset_color_name ,
            a.ASSET_STATUS_CODE,
            a.ASSET_CONDITION_CODE,
            a.ASSET_START_DATE,
            a.ORIGINAL_PURCHASE_DATE,
            a.SERIAL_NBR,
            ac.asset_catalog_start_date ,
            ac.asset_catalog_end_date ,
            am.asset_make_start_date ,
            am.asset_make_end_date,
            am1.asset_model_start_date ,
            am1.asset_model_end_date ,
            ac1.asset_color_start_date ,
            ac1.asset_color_end_date,
            va.VENDOR_ASSET_START_DATE,
            va.VENDOR_ASSET_END_DATE,
            cca.client_asset_sku_nbr,
            a.created_date asset_created_date
          from
            asset.asset a
          inner join asset.asset_catalog ac on
            ac.asset_catalog_id = a.asset_catalog_id
          inner join asset.asset_make am on
            am.asset_make_id = ac.asset_make_id
            and am.active_ind = 1
          inner join asset.asset_model am1 on
            am1.asset_model_id = ac.asset_model_id
            and am1.active_ind = 1
          inner join asset.asset_color ac1 on
            ac1.asset_color_id = ac.asset_color_id
            and ac1.active_ind = 1
          inner join client.vendor_asset_catalog_xref vacx on
            vacx.asset_catalog_id = ac.asset_catalog_id
          inner join client.vendor_asset va on
            va.vendor_asset_id = vacx.vendor_asset_id
            and va.active_ind = 1
            and va.asset_condition_code = a.asset_condition_code
          inner join asset.asset_ctlg_clnt_chnl_ast_xref acccax on
            ac.asset_catalog_id = acccax.asset_catalog_id
            and now() between acccax.effective_start_date and acccax.effective_end_date
            and ac.active_ind = 1
          inner join
            asset.client_channel_asset cca on
            cca.client_channel_asset_id = acccax.client_channel_asset_id
            and cca.active_ind = 1
            and cca.base_item_ind = 1
          where
            a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr
          order by asset_created_date asc;`,
  mapper: [
    { o: 'AssetId', d: 'asset_id' },
    { o: 'AssetCatalogId', d: 'asset_catalog_id' },
    { o: 'SubscriptionNbr', d: 'SUBSCRIPTION_NBR' },
    { o: 'MobileDeviceNbr', d: 'MOBILE_DEVICE_NBR' },
    { o: 'ReferenceMobileNbr', d: 'external_reference_id' },
    { o: 'MobileEquipmentId', d: 'MOBILE_EQUIPMENT_ID' },
    { o: 'AssetInstance', d: 'ASSET_INSTANCE_CODE' },
    { o: 'AssetStatus', d: 'ASSET_STATUS_CODE' },
    { o: 'AssetCatalogName', d: 'asset_catalog_name' },
    { o: 'AssetCatalogDesc', d: 'asset_catalog_desc' },
    { o: 'VendorAssetSkuNbr', d: 'VENDOR_ASSET_SKU_NBR' },
    { o: 'ClientAssetSKU', d: 'client_asset_sku_nbr' },
    { o: 'AssetStartDate', d: 'ASSET_START_DATE' },
    { o: 'OriginalPurchaseDate', d: 'ORIGINAL_PURCHASE_DATE' },
    { o: 'AssetMakeName', d: 'asset_make_name' },
    // { o: 'VendorAssetMake', d: 'VENDOR_ASSET_MAKE' },
    { o: 'AssetModelNbr', d: 'asset_model_nbr' },
    // { o: 'VendorAssetModel', d: 'VENDOR_ASSET_MODEL' },
    { o: 'AssetColorName', d: 'asset_color_name' },
    // { o: 'AssetCondition', d: 'ASSET_CONDITION_CODE' },
    // { o: 'SerialNbr', d: 'SERIAL_NBR' },AssetCondition
    // { o: 'AssetCatalogStartDate', d: 'asset_catalog_start_date' },
    // { o: 'AssetCatalogEndDate', d: 'asset_catalog_end_date' },
    // { o: 'AssetMakeStartDate', d: 'asset_make_start_date' },
    // { o: 'AssetMakeEndDate', d: 'asset_make_end_date' },
    // { o: 'AssetModelStartDate', d: 'asset_model_start_date' },
    // { o: 'AssetModelEndDate', d: 'asset_model_end_date' },
    // { o: 'AssetColorStartDate', d: 'asset_color_start_date' },
    // { o: 'AssetColorEndDate', d: 'asset_color_end_date' },
    // { o: 'VendorAssetStartDate', d: 'VENDOR_ASSET_START_DATE' },
    // { o: 'VendorAssetEndDate', d: 'VENDOR_ASSET_END_DATE' },
  ],
}
