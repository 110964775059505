import { all, fork } from 'redux-saga/effects'

import { creatEnrollmentFlow } from './pascal/saga'
import { cognitoAuthenticatedFlow } from './login/saga'
import { getClaimDataFlow, releaseLockFlow, loadLogsFlow } from './claim/saga'
import { getOrangeServiceResponseFlow, saveOrangeServiceResponseFlow } from './orange/saga'
import { getServiceOrderDetailsFlow, serviceOrderOprationFlow } from './sagawa/saga'
import {
  getProductFeatureFlow,
  getParameterStoreFlow,
  updateParameterStoreFlow,
  getProductFeatureDetailsFlow,
} from './configuration/saga'

export function* rootSaga() {
  yield all([
    fork(creatEnrollmentFlow),
    fork(getOrangeServiceResponseFlow),
    fork(saveOrangeServiceResponseFlow),
    fork(cognitoAuthenticatedFlow),
    fork(getClaimDataFlow),
    fork(releaseLockFlow),
    fork(loadLogsFlow),
    fork(getServiceOrderDetailsFlow),
    fork(serviceOrderOprationFlow),
    fork(getProductFeatureFlow),
    fork(getProductFeatureDetailsFlow),
    fork(getParameterStoreFlow),
    fork(updateParameterStoreFlow),
  ])
}
