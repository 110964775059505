export enum OrangeServiceType {
  EnrollmentResponse = 'Enrollment',
  LonerOrderResponse = 'LonerOrder',
  NoLonerOrderResponse = 'NoLonerOrder',
  ICActivationResponse = 'ICActivation',
  ForceCompleteResponse = 'ForceComplete',
  ChargeOrderResponse = 'ChargeOrder',
  SubmitOrderResponse = 'SubmitOrder',
}

export enum OperationTypes {
  LonerOrder = 'ORCLMS',
  NoLonerOrder = 'ORCLFS',
  ICActivation = 'ORCE3S',
  ForceComplete = 'ORCKVS',
  ChargeOrder = 'ORCMVS',
}

export enum URITypes {
  Enrollment = 'kddi/enrollment',
  LonerOrder = 'kddi/claiminquiry',
  NoLonerOrder = 'kddi/claiminquiry',
  ICActivation = 'kddi/icactivation',
  ForceComplete = 'kddi/forceclaimcompletion',
  ChargeOrder = 'kddi/chargecustomer',
  SubmitOrder = 'kddi/submitorder',
  SubEnrollment = 'kddi/subenrollment',
}

export enum ApplicationRoute {
  CreateEnrollment = 'pascal/register',
  UpdateEnrollment = 'pascal/modify',
  CreateLoanerClaim = 'orange/order/loaner',
  CreateNoLoanerClaim = 'orange/order/noloaner',
  SimActivation = 'orange/sim/activation',
  ForceCompleteClaim = 'orange/order/forcecomplete',
  DataAnalysis = 'claim/information',
  Configuration = 'configuration',
  ChargeClaim = 'orange/order/charge',
  SubmitOrder = 'orange/order/submit',
}

export enum SagawaOprationRoute {
  Create = 'sagawa/create',
  Update = 'sagawa/update',
  Delete = 'sagawa/delete',
  Search = 'sagawa/search',
}

export enum SagawaOpration {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Search = 'Search',
}
