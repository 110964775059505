export default {
  query: `select
	distinct cot2.TRANSMISSION_ID AS INTERACTION_LINE_ID,
	cot2.TRANSMISSION_TYPE_CODE,
	cot2.TRANSMISSION_STATUS_CODE,
	cot2.CLIENT_CHANNEL_ID,
	cot2.AGREEMENT_ID,
	cot2.MOBILE_DEVICE_NBR,
	cot2.SUBSCRIPTION_NBR,
	cot2.CUSTOMER_CASE_ID,
	cot2.SERVICE_REQUEST_ID,
	cot2.SERVICE_REQUEST_CREATED_DATE,
	cot2.SERVICE_REQUEST_CREATED_BY,
	cot2.EXTERNAL_REFERENCE_ID,
	cot2.DAX_SALES_ORDER_ID,
	cot2.SHIPMENT_DATE,
	cot2.DELIVERED_DATE,
	cot2.SERVICE_CODE,
	cot2.SERVICE_CATEGORY,
	cot2.DEDUCTIBLE_AMOUNT,
	cot2.AU_POINTS_SERVICE_CODE,
	cot2.AU_POINTS_SERVICE_CATEGORY,
	cot2.AU_POINTS_USED,
	cot2.AU_POINTS_ACTUAL,
	cot2.ERROR_DETAILS,
	cot2.CREATED_BY,
	cot2.CREATED_DATE,
	cot2.UPDATED_BY,
	cot2.UPDATED_DATE,
	cot2.ACTIVE_IND,
	cot2.CREATED_SYSTEM_ID
from
	customer.service_request sr
inner join asset.asset a on
	a.asset_id = sr.asset_id
	and sr.active_ind = 1
	and a.active_ind = 1
inner join integration.charge_order_transmission cot2
	on cot2.customer_case_id = sr.customer_case_id
	and cot2.service_request_id = sr.service_request_id
	and cot2.active_ind = 1
where
	a.mobile_device_nbr = :mdn
	or a.subscription_nbr = :subnbr
order by
	sr.created_date asc`,
  mapper: [
    { o: 'TransmissionId', d: 'INTERACTION_LINE_ID' },
    { o: 'TransmissionType', d: 'TRANSMISSION_TYPE_CODE' },
    { o: 'TransmissionStatus', d: 'TRANSMISSION_STATUS_CODE' },
    { o: 'ClientChannelId', d: 'CLIENT_CHANNEL_ID' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'MobileDeviceNumber', d: 'MOBILE_DEVICE_NBR' },
    { o: 'SubscriptionNumber', d: 'SUBSCRIPTION_NBR' },
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ServiceRequestCreatedDate', d: 'SERVICE_REQUEST_CREATED_DATE' },
    { o: 'ServiceRequestCreatedBy', d: 'SERVICE_REQUEST_CREATED_BY' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'DaxSalesOrderId', d: 'DAX_SALES_ORDER_ID' },
    { o: 'ShipmentDate', d: 'SHIPMENT_DATE' },
    { o: 'DeliveredDate', d: 'DELIVERED_DATE' },
    { o: 'ServiceCode', d: 'SERVICE_CODE' },
    { o: 'ServiceCategory', d: 'SERVICE_CATEGORY' },
    { o: 'DeductibleAmount', d: 'DEDUCTIBLE_AMOUNT' },
    { o: 'AuPointsServiceCode', d: 'AU_POINTS_SERVICE_CODE' },
    { o: 'AuPointsServiceCategory', d: 'AU_POINTS_SERVICE_CATEGORY' },
    { o: 'AuPointsUsed', d: 'AU_POINTS_USED' },
    { o: 'AuPointsActual', d: 'AU_POINTS_ACTUAL' },
    { o: 'ErrorDetails', d: 'ERROR_DETAILS' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'Active', d: 'ACTIVE_IND' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
  ],
}
