import { IEnrollmentDetails } from '../types'
import DefaultEnrollmentFormData from '../../utils/sample-data-templates/enrollment'

export const initialState: IEnrollmentDetails = {
  Form: DefaultEnrollmentFormData,
  FormInitialized: false,
  XML: ``,
  IsLoading: false,
  IsSuccess: false,
  Errors: [],
  EditXML: false,
  StagedMDN: '',
  StagedSubNo: '',
}
