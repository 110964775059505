/* eslint-disable no-console */
import { createLogger } from 'redux-logger'
import { rootSaga } from './store/rootSagas'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './store/rootReducer'
import createSagaMiddleware, { END } from 'redux-saga'

function configureMasterStore() {
  // the logger master switch
  const enableDevTools = process.env.NODE_ENV === 'development'
  console.log(`enableDevTools: ${enableDevTools}`)

  // create the logger
  const logger = createLogger({ predicate: () => enableDevTools })

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  // configure store with dev tools
  const store = enableDevTools
    ? configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware, logger],
        devTools: {
          trace: true,
          maxAge: 25,
          latency: 1000,
          autoPause: true,
          shouldRecordChanges: false,
        },
      })
    : configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware, logger],
        devTools: false,
      })

  sagaMiddleware.run(rootSaga)

  return {
    ...store,
    runSaga: sagaMiddleware.run,
    close: () => store.dispatch(END),
  }
}

export default configureMasterStore()
