import { IProductConfiguration } from '../types'

export const initialState: IProductConfiguration = {
  SelectedClient: '',
  SelectedFeature: '',
  IsLoading: false,
  IsSuccess: false,
  Errors: [],
  Details: null,
}
