import React from 'react'
import { Formik } from 'formik'
import { config } from '../../config'
import { clients } from '../../data/master-data'
import schema from '../../form-schemas/client-selection'
import { Col, Form, Button, Badge, Row, FormSelect } from 'react-bootstrap'
import { formatStackName, getEligibleStacks } from '../../store/selectors'

const ClientSelectionForm: React.FunctionComponent<{
  initialFormData: any
  confirmSelection: any
}> = (props) => {
  const isLocalHost = window.location.hostname.includes('localhost')
  const onSubmit = async (values) => {
    props.confirmSelection(values)
  }
  const handleFormChange = (handleChange, e) => {
    handleChange(e)
  }
  const getStacks = (selectedClient) => {
    return getEligibleStacks(selectedClient).map((p) => (
      <option key={p.Code} value={p.Code}>
        {p.DisaplayName}
      </option>
    ))
  }
  const getEnvironments = (selectedClient, selectedEnvironment) => {
    const selectedEnvironmentObject =
      selectedClient && selectedEnvironment && config[selectedClient][selectedEnvironment]
    return selectedEnvironmentObject?.endpoints?.map((p) => (
      <option key={p.key} value={p.key}>
        {formatStackName(p.key)}
      </option>
    ))
  }
  return (
    <Col md={5} className="formDiv">
      <fieldset className="border p-4">
        <legend className="w-auto">
          <Badge pill bg="secondary">
            Select Stage & Environment
          </Badge>
        </legend>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          // onChange={onSubmit}
          // validateOnMount={true}
          initialValues={props.initialFormData}
          enableReinitialize={true}
        >
          {({ values, touched, errors, handleBlur, handleSubmit, handleChange }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  {isLocalHost ? (
                    <Form.Group as={Col} controlId="formClientGridClient">
                      <Form.Label>Client</Form.Label>
                      <FormSelect
                        name="Client"
                        value={values.Client}
                        isInvalid={!!errors.Client}
                        isValid={!errors.Client}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="">...</option>
                        {clients
                          .filter((c: any) => c.Enable)
                          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                          .map((p) => (
                            <option key={p.Code} value={p.Code}>
                              {p.DisaplayName}
                            </option>
                          ))}
                      </FormSelect>
                      {errors.Client && touched.Client && (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.Client}</>
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  ) : null}

                  <Form.Group as={Col} controlId="formClientGridEnrivornment">
                    <Form.Label>Stage</Form.Label>
                    <FormSelect
                      name="Stack"
                      value={values.Stack}
                      isInvalid={!!errors.Stack}
                      isValid={!errors.Stack}
                      onBlur={handleBlur}
                      onChange={(e) => handleFormChange(handleChange, e)}
                    >
                      <option value="">...</option>
                      {getStacks(values.Client)}
                    </FormSelect>
                    {errors.Stack && touched.Stack && (
                      <Form.Control.Feedback type="invalid">
                        <>{errors.Stack}</>
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formClientGridSubEnrivornment">
                    <Form.Label>Environment</Form.Label>
                    <FormSelect
                      name="Environment"
                      value={values.Environment}
                      isInvalid={!!errors.Environment}
                      isValid={!errors.Environment}
                      onBlur={handleBlur}
                      onChange={(e) => handleFormChange(handleChange, e)}
                    >
                      <option value="">...</option>
                      {getEnvironments(values.Client, values.Stack)}
                    </FormSelect>
                    {errors.Environment && touched.Environment && (
                      <Form.Control.Feedback type="invalid">
                        <>{errors.Environment}</>
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      disabled={
                        !!errors.Stack ||
                        !!errors.Client ||
                        !!errors.Environment ||
                        !values.Stack ||
                        !values.Client ||
                        !values.Environment
                      }
                      style={{ marginTop: '1rem' }}
                    >
                      Confirm
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            )
          }}
        </Formik>
      </fieldset>
    </Col>
  )
}

export default ClientSelectionForm
