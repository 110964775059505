import React from 'react'
import { Formik } from 'formik'
import { SagawaOpration } from '../../utils/constants'
import { sagawaOrderStatus, eventOptions } from '../../data/master-data'
import { Col, Form, Button, FormLabel, Row, FormSelect } from 'react-bootstrap'

interface IProps {
  initialFormData: any
  updateForm: any
  opration: string
  schema: any
  performAction: any
  resetForm: any
}

export const OrderOprationForm: React.FunctionComponent<IProps> = (props) => {
  const onSubmit = () => {
    props.performAction()
  }
  const handleResetForm = (handleReset) => {
    props.resetForm()
    handleReset()
  }
  const handleFormChange = (handleChange, e) => {
    const {
      target: { name, value, checked, type },
    } = e
    const input = {}
    input[name] = type === 'checkbox' ? checked : value
    props.updateForm(input)
    handleChange(e)
  }
  return (
    <fieldset>
      <FormLabel>{`${props.opration} by Service Order Number`}</FormLabel>
      <Formik validationSchema={props.schema} onSubmit={onSubmit} initialValues={props.initialFormData}>
        {({ values, touched, errors, handleBlur, handleSubmit, handleReset, handleChange, isValid }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} controlId="formGridMDN">
                <Form.Label>{`Order Number`}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="12345678"
                  name="ServiceOrderNumber"
                  value={values.ServiceOrderNumber}
                  isInvalid={!!errors.ServiceOrderNumber && !!touched.ServiceOrderNumber}
                  isValid={touched.ServiceOrderNumber && !errors.ServiceOrderNumber}
                  onBlur={handleBlur}
                  onChange={(e) => handleFormChange(handleChange, e)}
                />
                {errors.ServiceOrderNumber && touched.ServiceOrderNumber && (
                  <Form.Control.Feedback type="invalid">
                    <>{errors.ServiceOrderNumber}</>
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {props.opration === SagawaOpration.Update && (
                <Form.Group as={Col} controlId="formClientGridClient" sm={4}>
                  <Form.Label>Status</Form.Label>
                  <FormSelect
                    name="OrderStatus"
                    value={values.OrderStatus}
                    isInvalid={!!errors.OrderStatus}
                    isValid={!errors.OrderStatus}
                    onBlur={handleBlur}
                    onChange={(e) => handleFormChange(handleChange, e)}
                  >
                    {sagawaOrderStatus.map((status, i) => {
                      return (
                        <option key={i} value={status.value}>
                          {status.disaplyName}
                        </option>
                      )
                    })}
                  </FormSelect>
                  {errors.OrderStatus && touched.OrderStatus && (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.OrderStatus}</>
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              {props.opration === SagawaOpration.Create && (
                <Form.Group as={Col} controlId="formClientGridClient" sm={4}>
                  <Form.Label>Event</Form.Label>
                  <FormSelect
                    name="Event"
                    value={values.Event}
                    isInvalid={!!errors.Event}
                    isValid={!errors.Event}
                    onBlur={handleBlur}
                    onChange={(e) => handleFormChange(handleChange, e)}
                  >
                    <option value="">...</option>
                    {eventOptions
                      .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                      .map((p) => (
                        <option key={p.Code} value={p.Code}>
                          {p.DisaplayName}
                        </option>
                      ))}
                  </FormSelect>
                  {errors.Event && touched.Event && (
                    <Form.Control.Feedback type="invalid">
                      <>{errors.Event}</>
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              <Form.Group as={Col} controlId="formGridButton" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Form.Label>&nbsp;</Form.Label>
                <Button
                  variant="success"
                  type="submit"
                  style={{ marginTop: '1.3rem' }}
                  disabled={!isValid || !values.ServiceOrderNumber}
                >
                  {props.opration}
                </Button>
                <Button
                  variant="outline-secondary"
                  type="button"
                  style={{ marginLeft: '1em', marginTop: '1.3rem' }}
                  onClick={() => handleResetForm(handleReset)}
                  disabled={!values.ServiceOrderNumber}
                >
                  Reset
                </Button>
              </Form.Group>
            </Row>
          </Form>
        )}
      </Formik>
    </fieldset>
  )
}
