export interface IState {
  LoginInfo: ILoginInfoState
  ClaimInformation: IClaimInformation
  OrangeInformation: IOrangeServiceResponseDetails
  PascalInformation: IEnrollmentDetails
  SagawaOrderTracking: ISagawaOrderTracking
  ProductConfiguration: IProductConfiguration
}

export interface ILoginInfoState {
  authenticationError?: string
  agentLoaded: boolean
  agentInfo?: IUserPermissions
  session?: any
  clientInfo: IClientDetails
}

interface IUserPermissions {
  UserId: string
  FirstName: string
  LastName: string
  DisplayName: string
  TeamName?: string
  UserName: string
  Sites?: string[]
  UserAttributes?: IUserAttribute[]
}

interface IUserAttribute {
  UserAttributesId: string
  AttributeName: string
  AttributeValue: string
}

interface IClientDetails {
  Form: {
    Stack?: string
    Client?: string
    Environment?: string
  }
}

export interface IOrangeServiceResponseDetails {
  Form: IForm
  LonerOrder: any
  NoLonerOrder: any
  ICActivation: any
  ForceComplete: any
  ChargeOrder: any
  SubmitOrder: any
  IsLoading: boolean
  IsSuccess: string
  Errors: string[]
  SearchKey?: string
}

export interface IEnrollmentDetails {
  Form: IForm
  FormInitialized: boolean
  XML: any
  EditXML: boolean
  IsLoading: boolean
  IsSuccess: boolean
  Errors: string[]
  StagedMDN?: string
  StagedSubNo?: string
}

interface IForm {
  ClientChannel?: string
  ProgramType?: string
  MobileDeviceNumber?: string
  IMEI?: string
  SubscriberNumber?: string
  KeiyakushaKanaName?: string
  KeiyakushaKanjiName?: string
  ICCId?: string
  KeiyakushaPostNo?: string
  KeiyakushaAddress?: string
  KeiyakushaAddress2?: string
  KeiyakushaAddress3?: string
  KeiyakushaHomeTelNo?: string
  SeikyushaKanaName?: string
  SeikyushaKanjiName?: string
  SeikyushaPostNo?: string
  SeikyushaAddress?: string
  SeikyushaHomeTelNo?: string
  PastAssetInfo: IPastAssetInfo[]
  HandsetType?: string
  ClientAssetSku?: string
  eSIMPlanType?: string
  eSIMProfileDownloadStatus?: string
  LoyaltyPoints?: string
  ClientAccountType?: string
  DateOfBirth?: string

  AgreementStartDate?: string
  ClientAccountStartDate?: string

  PurchaseDate?: string

  SearchType?: string
  SearchValue?: string
  AUID?: string
  ServiceFlag?: string
  PastAssets?: string
  IsLatestDeviceSubscriber?: string
  IsPastDeviceSubscriber?: string
}

interface IPastAssetInfo {
  ClientAssetSku?: string
  IMEI?: string
  PurchaseDate?: string
  AssetStartDate?: string
  AssetEndDate?: string
}

export interface IClaimInformation {
  IsLoading: boolean
  IsSuccess: boolean
  IsOtherOperationLoading: boolean
  IsOtherOperationSuccess: boolean
  Errors: any
  Details: any
}

export interface IProductConfiguration {
  IsLoading: boolean
  IsSuccess: boolean
  Errors: any
  Details: any
  SelectedClient: string
  SelectedFeature: string
  FeatureList?: any
  ParameterStore?: [IParameterStore]
}

export interface IParameterStore {
  ParameterId: string
  Name: string
  Value: string
  CreatedBy: string
  CreatedDate: string
  UpdatedBy: string
  UpdatedDate: string
  ActiveInd: string
}

export interface ISagawaOrderTracking {
  Form: IServiceOrderIForm
  OrderDetailsResponse: IOrderDetailsResponse
  Message: IMessage
  isLoading: boolean
}

interface IServiceOrderIForm {
  ServiceOrderNumber: string
  ClientChannelId: string
  CarrierCode: string
  OrderStatus?: string
  Event?: string
  SdsDeliverTo: string
}

export interface IOrderDetailsResponse {
  IsSuccess: boolean
  OrderId: string
  MessageId: string
  DeliveryBilling: string
  PlatformOrderId: string
  OrderStatus: string
}

export interface IServiceOrderOprationParam {
  ClientChannelId: string
  CarrierCode: string
  Action: string
  ServiceOrderNumber: string
  OrderStatus?: string
  Event?: string
  SdsDeliverTo?: string
}

export interface IServiceOrderOprationRequestParam {
  CarrierIntegrationRequest: IServiceOrderOprationParam
}

export interface IServiceOrderRequest {
  action: ISagawaOpration
}

export enum IMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  NONE = '',
}

export interface IMessage {
  MessageType: IMessageType
  MessageCode?: string
  Message?: string
}

export enum ISagawaOpration {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export interface IChildTab {
  activeChildTab: number
}

export interface IConfigurationTabs {
  activeTab: string
  admin: IChildTab
  general: IChildTab
}

export interface IComponents {
  type?: any
  opration?: any
}
