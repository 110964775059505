import { config as KDDISQA } from './kddi/sqa'
import { config as KDDIUAT } from './kddi/uat'
import { config as KDDITRN } from './kddi/trn'
import { config as KDDILT } from './kddi/lt'
import { config as KDDIPRD } from './kddi/prd'

import { config as DOCOMOSQA } from './docomo/sqa'
import { config as DOCOMOUAT } from './docomo/uat'
import { config as DOCOMOTRN } from './docomo/trn'

const config = {
  '42818A88285511E9A38A063D3002FD4C': {
    sqa: KDDISQA,
    uat: KDDIUAT,
    trn: KDDITRN,
    lt: KDDILT,
    prd: KDDIPRD,
  },
  '1E8EBE7748A60600865EE185A02770C7': {
    sqa: DOCOMOSQA,
    uat: DOCOMOUAT,
    trn: DOCOMOTRN,
  },
}

export { config }
