export default {
  query: `select distinct sr.service_request_id, dd.discount_detail_id,dd.reference_order_id,dd.reference_order_type_code,dd.discount_type_code,dd.discount_amount,dd.discount_tax_amount,dd.created_by,dd.created_date,dd.updated_by,dd.updated_date,dd.created_system_id,dd.active_ind
  from customer.service_request sr
  join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
  join customer.charge_order co on co.charge_order_id = ccc.item_id and co.active_ind = 1
  join customer.charge_order_line col on col.charge_order_id = co.charge_order_id and col.active_ind = 1
  join customer.discount_detail dd on dd.reference_order_id = co.charge_order_id and dd.active_ind = 1
  join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
  where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by co.created_date asc;`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ChargeOrderLineId', d: 'reference_order_id' },
    { o: 'DiscountDetailId', d: 'discount_detail_id' },
    { o: 'ReferenceOrderType', d: 'reference_order_type_code' },
    { o: 'DiscountType', d: 'discount_type_code' },
    { o: 'DiscountAmount', d: 'discount_amount' },
    { o: 'DiscountTaxAmount', d: 'discount_tax_amount' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
