export default {
  query: `SELECT
  c.[claim_id] as ClaimId,
  c.claimstatus_id as ClaimStatusId,
  d.[name] ClaimStatus,
  c.[subscriber_id] as SubscriberId,
  f.[name] as LossCategoryType ,
  c.[fullfillmentmethod_id] as FullfillmentMethodId,
  e.[name] as ClaimType,
  c.[claim_state_xml] as ClaimStatusXML,
  c.[loss_date] as LossDate,
  c.[loss_description] as LossDescription,
  c.[insurance_company] as InsuranceCompany,
  c.[equipment_non_return_fee] as EquipmentNonReturnFee,
  c.[replacement_xml] as ReplacementXml,
  g.[name] as ClaimWorkFlow,
  c.[TransactionID],
  c.[PaymentType],
  c.[created_by] as CreatedBy,
  c.[created_dtm] as CreatedDateTime,
  c.[modified_by] as ModifiedBy,
  c.[modified_dtm] as ModifiedDateTime,
  c.[deleted_flg] as DeletedFlag
from
  intelliset.dbo.claim c with(nolock)
inner join intelliset..subscriber s with(nolock) on
  s.subscriber_id = c.subscriber_id
inner join intelliset.dbo.code d with(nolock) on
  d.code_id = c.claimstatus_id
left outer join intelliset.dbo.code e with(nolock) on
  c.claimtype_id = e.code_id
left join intelliset.dbo.code f with(nolock) on
  c.losscategorytype_id = f.code_id
left join intelliset.dbo.code g on
  c.claimworkflow_id = g.code_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by c.[created_dtm] asc;`,
  mapper: [
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'ClaimStatusId', d: 'ClaimStatusId' },
    { o: 'ClaimStatus', d: 'ClaimStatus' },
    { o: 'SubscriberId', d: 'SubscriberId' },
    { o: 'LossCategoryType', d: 'LossCategoryType' },
    { o: 'FullfillmentMethodId', d: 'FullfillmentMethodId' },
    { o: 'ClaimType', d: 'ClaimType' },
    { o: 'ClaimStatusXML', d: 'ClaimStatusXML' },
    { o: 'LossDate', d: 'LossDate' },
    { o: 'LossDescription', d: 'LossDescription' },
    { o: 'InsuranceCompany', d: 'InsuranceCompany' },
    { o: 'EquipmentNonReturnFee', d: 'EquipmentNonReturnFee' },
    { o: 'ReplacementXml', d: 'ReplacementXml' },
    { o: 'ClaimWorkFlow', d: 'ClaimWorkFlow' },
    { o: 'TransactionID', d: 'TransactionID' },
    { o: 'PaymentType', d: 'PaymentType' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDateTime', d: 'CreatedDateTime' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDateTime', d: 'ModifiedDateTime' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
