import API from '../../services'
import { actions } from './actions'
import { call, take, put, all } from 'redux-saga/effects'

import { groupBy } from '../../utils/helper'
import queryParameterStore from '../../sql/horizon/queryParameterStore'
import queryFAMByFeatureCode from '../../sql/horizon/queryFAMByFeatureCode'
import queryMonthlyFeeByClientChannel from '../../sql/horizon/queryMonthlyFeeByClientChannel'
import queryProductFeatureCriteriaByCode from '../../sql/horizon/queryProductFeatureCriteriaByCode'
import queryProductFeatureByClientChannel from '../../sql/horizon/queryProductFeatureByClientChannelList'

function* getProductFeatures(input) {
  try {
    const request = { params: input, query: queryProductFeatureByClientChannel.query }
    const result = yield call(API.DataService.searchData, request)
    yield put({
      type: actions.SUCCESS_PRODUCT_FEATURE,
      input: result.data,
    })
  } catch (error) {
    yield put({
      type: actions.ERROR_PRODUCT_FEATURE,
      input: error,
    })
  }
}

export function* getProductFeatureFlow() {
  while (true) {
    const { input } = yield take(actions.REQUEST_PRODUCT_FEATURE)
    yield call(getProductFeatures, input)
  }
}

function* getProductFeatureDetails(input) {
  try {
    const operations: any = []
    const featureConfiguration =
      input.code && input.code.toLowerCase() === 'monthly fee' ? queryMonthlyFeeByClientChannel : queryFAMByFeatureCode
    const request = {
      params: input,
      query: featureConfiguration.query,
    }
    operations.push(call(API.DataService.searchData, request))

    const request1 = {
      params: input,
      query: queryProductFeatureCriteriaByCode.query,
    }
    operations.push(
      input.code && input.code.toLowerCase() === 'monthly fee'
        ? Promise.resolve({ data: [{ FeatureType: 'COMPLEX', Criteria: 'TIER' }] })
        : call(API.DataService.searchData, request1)
    )
    const result = yield all(operations)

    const data = groupBy(result[0].data, (item) => {
      return [item.TIER, item.DTRNG, item.INCTYP, item.NTWRKTYP, item.CLAIMCOUNT, item.SVCUSD, item.DSSTRAID]
    })

    yield put({
      type: actions.SUCCESS_PRODUCT_FEATURE_DETAILS,
      input: {
        configuration: {
          headers: featureConfiguration.mapper,
          data:
            input.code.toLowerCase() === 'data recovery eligibilty'
              ? data.map((d) => {
                  return d.some((e) => e.AttributeName === 'Switch' && e.AttributeValue === '0')
                    ? d.filter((e) => e.AttributeName === 'Switch')
                    : d
                })
              : data,
        },
        criteria: {
          headers: queryProductFeatureCriteriaByCode.mapper,
          data: groupBy(
            result[1].data.map((d) => {
              d.Criteria = (featureConfiguration.mapper.find((h) => h.d === d.Criteria) || {}).o || d.Criteria
              return d
            }),
            (item) => {
              return [item.FeatureType]
            }
          ),
        },
      },
    })
  } catch (error) {
    yield put({
      type: actions.ERROR_PRODUCT_FEATURE_DETAILS,
      input: error,
    })
  }
}

export function* getProductFeatureDetailsFlow() {
  while (true) {
    const { input } = yield take(actions.REQUEST_PRODUCT_FEATURE_DETAILS)
    yield call(getProductFeatureDetails, input)
  }
}

function* getParameterStore(env) {
  try {
    const request = {
      params: { env: env },
      query: queryParameterStore.query,
    }
    const result = yield call(API.DataService.searchData, request)

    yield put({
      type: actions.SUCCESS_PARAMETER_STORE,
      input: result.data,
    })
  } catch (error) {
    yield put({
      type: actions.ERROR_PARAMETER_STORE,
      input: error,
    })
  }
}

export function* getParameterStoreFlow() {
  while (true) {
    const { env } = yield take(actions.REQUEST_PARAMETER_STORE)
    yield call(getParameterStore, env)
  }
}

function* updateParameterStore(input, userName, env) {
  try {
    const updateRequest = {
      UpdateEntityParameters: {
        ReturnEntity: true,
        ParameterStore: {
          ParameterId: input.ParameterId,
          Value:
            input.Name.indexOf('REFER_LEGACY') > -1
              ? input.Value === '1'
                ? '0'
                : '1'
              : input.Value === 'LIVE'
              ? 'STUB'
              : 'LIVE',
          UpdatedBy: userName,
          UpdatedDate: new Date().toISOString(),
        },
      },
    }
    yield call(API.EntityService.update, updateRequest, 'ParameterStore', input.ParameterId)

    const request = {
      params: { env: env },
      query: queryParameterStore.query,
    }
    const result = yield call(API.DataService.searchData, request)

    yield put({
      type: actions.SUCCESS_PARAMETER_STORE,
      input: result.data,
    })
  } catch (error) {
    yield put({
      type: actions.ERROR_PARAMETER_STORE,
      input: error,
    })
  }
}

export function* updateParameterStoreFlow() {
  while (true) {
    const { input, userName, env } = yield take(actions.REQUEST_UPDATE_PARAMETER_STORE)
    yield call(updateParameterStore, input, userName, env)
  }
}
