export default {
  query: `
  select
    distinct
    AccountHold_ID as AccountHoldId,
    AccountNumber,
    HoldType,
    HoldName,
    Released_By as ReleasedBy,
    Released_Dtm as ReleasedDate,
    FraudPopUpReason as FraudReason,
    a.Created_By as CreatedBy,
    a.Created_Dtm as CreatedDate,
    a.Modified_By as ModifiedBy,
    a.Modified_Dtm as ModifiedDate,
    a.Deleted_Flg as DeletedFlg
  from
    intelliset.dbo.AccountHold a with(nolock)
  inner join hsenroll.dbo.enrollment_naksp s with(nolock) on
    a.accountnumber collate database_default = s.Account_Number
  where
    s.cell_number = @mdn or s.account_number = @subnbr
  union all
  select
    distinct
    AccountHold_ID as AccountHoldId,
    AccountNumber,
    HoldType,
    HoldName,
    Released_By as ReleasedBy,
    Released_Dtm as ReleasedDate,
    FraudPopUpReason as FraudReason,
    a.Created_By as CreatedBy,
    a.Created_Dtm as CreatedDate,
    a.Modified_By as ModifiedBy,
    a.Modified_Dtm as ModifiedDate,
    a.Deleted_Flg as DeletedFlg
  from
    intelliset.dbo.AccountHold a with(nolock)
  inner join hsenroll.dbo.enrollment s with(nolock) on
    a.accountnumber collate database_default = s.Account_Number
  where
    s.cell_number = @mdn or s.account_number = @subnbr
  order by
    CreatedDate asc;`,
  mapper: [
    { o: 'AccountHoldId', d: 'AccountHoldId' },
    { o: 'AccountNumber', d: 'AccountNumber' },
    { o: 'HoldType', d: 'HoldType' },
    { o: 'HoldName', d: 'HoldName' },
    { o: 'ReleasedBy', d: 'ReleasedBy' },
    { o: 'ReleasedDate', d: 'ReleasedDate' },
    { o: 'FraudReason', d: 'FraudReason' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlg', d: 'DeletedFlg' },
  ],
}
