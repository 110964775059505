import { combineReducers } from 'redux'

import { orangeInfoReducer } from './orange/reducer'
import { pascalReducer } from './pascal/reducer'
import { sagawaReducer } from './sagawa/reducer'
import { loginReducer } from './login/reducer'
import { claimInfoReducer } from './claim/reducer'
import { productConfigurationReducer } from './configuration/reducer'

export const rootReducer = combineReducers({
  //currently using Data as name,after refact Pravez will change as per expected
  ClaimInformation: claimInfoReducer,
  OrangeInformation: orangeInfoReducer,
  LoginInfo: loginReducer,
  PascalInformation: pascalReducer,
  SagawaOrderTracking: sagawaReducer,
  ProductConfiguration: productConfigurationReducer,
})
