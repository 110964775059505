import { call, put, select } from 'redux-saga/effects'
import API from '../../../services'
import {
  IMessageType,
  ISagawaOpration,
  IServiceOrderOprationRequestParam,
  IServiceOrderRequest,
  IState,
} from '../../types'
import { sagawaActionCreator } from '../actions'

export function* serviceOrderOpration(input: IServiceOrderRequest) {
  try {
    const state: IState = yield select()
    const formValues = state.SagawaOrderTracking.Form
    const request: IServiceOrderOprationRequestParam = {
      CarrierIntegrationRequest: {
        ClientChannelId: formValues.ClientChannelId,
        CarrierCode: formValues.CarrierCode,
        ServiceOrderNumber: formValues.ServiceOrderNumber,
        Action: input.action,
      },
    }
    yield put(sagawaActionCreator.updateLoaderState(true))
    if (input.action === ISagawaOpration.Create) {
      request.CarrierIntegrationRequest.Event = formValues.Event
      request.CarrierIntegrationRequest.SdsDeliverTo = formValues.SdsDeliverTo
    }
    if (input.action === ISagawaOpration.Update) {
      request.CarrierIntegrationRequest.OrderStatus = formValues.OrderStatus
    }

    const response = yield call(API.SagawaService.serviceOrderOpration, request)

    if (response && response.status === 200) {
      const exceptionName = (response.data?.CarrierIntegrationResponse?.name || '').toUpperCase()
      if (response.data?.CarrierIntegrationResponse?.ErrorCode) {
        yield put(
          sagawaActionCreator.updateMessage.setMessage({
            MessageCode: response.data?.CarrierIntegrationResponse?.ErrorCode || '',
            MessageType: IMessageType.ERROR,
            Message: response.data?.CarrierIntegrationResponse?.Message || '',
          })
        )
        return
      }

      if (exceptionName === 'SERVICEORDERNOTFOUNDEXCEPTION') {
        yield put(
          sagawaActionCreator.updateMessage.setMessage({
            MessageType: IMessageType.ERROR,
            Message: `Order details not present for Service Order number -${formValues.ServiceOrderNumber}!!!`,
          })
        )
        return
      }

      if (response.data?.CarrierIntegrationResponse?.Message) {
        yield put(
          sagawaActionCreator.updateMessage.setMessage({
            MessageType: IMessageType.SUCCESS,
            Message: `Order Details ${response.data?.CarrierIntegrationResponse?.Message} For -${formValues.ServiceOrderNumber} Order Number...`,
          })
        )
        return
      }

      if (input.action === ISagawaOpration.Create) {
        yield put(
          sagawaActionCreator.getServiceOrderDetails.getServiceOrderDetailsSuccess(
            response.data?.CarrierIntegrationResponse
          )
        )
        return
      }
    }
    if (response && response.status !== 200) {
      yield put(
        sagawaActionCreator.updateMessage.setMessage({
          MessageType: IMessageType.ERROR,
          Message: `Oh snap! You got an error!`,
        })
      )
      return
    }
  } catch (error) {
    yield put(
      sagawaActionCreator.updateMessage.setMessage({
        MessageType: IMessageType.ERROR,
        Message: `Oh snap! You got an error!`,
      })
    )
  } finally {
    yield put(sagawaActionCreator.updateLoaderState(false))
  }
}
