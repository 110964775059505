import { config } from '../config'

export class BaseAPI {
  public headers = () => {
    return {
      'Content-Type': 'application/json',
      'asurion-enduser': 'stub-tool',
    }
  }

  public getConfigKey(key: string) {
    const stack = localStorage.getItem('stack') || ''
    const client = localStorage.getItem('client') || ''
    const environment = localStorage.getItem('environment') || ''
    if (!client || !stack || !environment) {
      return Promise.resolve('Client or Stack or Enviroment not selected')
    }
    return config[client][stack].endpoints.find(
      (x) => x.key && environment && x.key.toLowerCase() === environment.toLowerCase()
    )?.value[key]
  }

  public endpoints = {
    findLogs: '/logs',
    dal: '/datamangement/v1/sql',
    msDal: '/msdatamangement/v2/sql',
    sagawaOrders: '/ajkk/sagawa-orders',
    findStub: '/externalintegration/v2/stub/fetch',
    createStub: '/externalintegration/v2/stub/create',
    syncenrolment: '/externalintegration/v1/syncenrolment',
    carrierServices: '/integrationservices/v1/carrierservices',
    sequence: '/sequence/v1/generateids/Asurion-DevTool-RandomId',
    enterprisedataserviceEntity: '/enterprisedataservice/v1/entities',
  }
}
