export default {
  query: `SELECT
	  crp.ID as Id,
	  crp.claim_id as ClaimId,
	  crp.RewardPoints as RewardPoints,
	  crp.ConvFactorRewardPoints_ID as ConversationFactor,
	  crp.ActualRewardPoints as ActualRewardPoints,
	  crp.AutoCharge as AutoCharge,
	  crp.created_by as CreatedBy,
	  crp.created_dtm as CreatedDateTime,
	  crp.modified_by as ModifiedBy,
	  crp.modified_dtm as ModifiedDateTime
  FROM
	  intelliset.dbo.ClaimRewardPoints crp
  inner join
	   intelliset.dbo.claim c with(nolock) on
	   crp.claim_id = c.claim_id
  inner join intelliset..subscriber s with(nolock) on
	  s.subscriber_id = c.subscriber_id
  where
	  s.wireless_number = @mdn or s.account_number = @subnbr
  order by
	  crp.created_dtm asc;`,
  mapper: [
    { o: 'Id', d: 'Id' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'RewardPoints', d: 'RewardPoints' },
    { o: 'ConversationFactor', d: 'ConversationFactor' },
    { o: 'ActualRewardPoints', d: 'ActualRewardPoints' },
    { o: 'AutoCharge', d: 'AutoCharge' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDateTime', d: 'CreatedDateTime' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDateTime', d: 'ModifiedDateTime' },
  ],
}
