import { actions } from './actions'
import { call, take } from 'redux-saga/effects'
import { ICognitoUserSession } from '../../services/auth/types'

type CallbackFunction = (session: ICognitoUserSession) => void

const afterAuthentication: CallbackFunction[] = []

function* cognitoAuthenticated(session: ICognitoUserSession) {
  if (session) {
    afterAuthentication.forEach((func) => {
      func(session)
    })
    yield true
  }
}

export function* cognitoAuthenticatedFlow() {
  while (true) {
    const { session } = yield take(actions.COGNITO_AUTHENTICATED)
    yield call(cognitoAuthenticated, session)
  }
}
