import React from 'react'
import * as log from 'loglevel'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Container } from 'react-bootstrap'

import { config } from '../../config'
import { Auth } from '../../services/auth'
import { IState } from '../../store/types'
import Spinner from '../../components/spinner'
import { loginInfoCreator } from '../../store/login/actions'
import ClientSelection from '../../components/client-selection'

const mapStateToProps = (state: IState) => {
  return {
    session: state.LoginInfo.session,
    formData: state.LoginInfo.clientInfo.Form,
    authenticationError: state.LoginInfo.authenticationError,
  }
}

const mapDispatchToProps = {
  updateClientForm: loginInfoCreator.updateClientForm,
  authenticated: loginInfoCreator.authenticated,
}

function HOCClientSelection(props) {
  const navigate = useNavigate()
  const Client = localStorage.getItem('client') || ''
  const Stack = localStorage.getItem('stack') || ''
  const authenticationTriggered = localStorage.getItem('authenticationTriggered') === 'true'

  if (window.location.href.indexOf('code') > -1 && Client && Stack && !authenticationTriggered) {
    localStorage.setItem('authenticationTriggered', 'true')
    const defaultURL = config[Client][Stack].constants.defaultUrl || ''
    Auth.configure(config[Client][Stack])
    Auth.currentSession(Stack, Client)
      .then((result) => {
        props.authenticated(undefined, result.session)
        localStorage.setItem('authenticationTriggered', 'false')
        window.history.replaceState(null, '', `${defaultURL}`)
        window.location =
          result.redirect.indexOf(defaultURL) > -1
            ? result.redirect
            : ((result.redirect +
                `${result.redirect.indexOf('/#/') > -1 ? '' : '#/'}` +
                defaultURL) as unknown as Location)
      })
      .catch((error: any) => {
        log.error(error)
        props.authenticated(error, undefined)
      })
  }

  if (props.authenticationError && !props.session) {
    return (
      <div style={{ marginTop: '64px', textAlign: 'center' }}>
        {'ユーザーが見つかりませんでした。管理者に問い合わせてください。'}
      </div>
    )
  }

  const getDefaultFormData = () => {
    const isLocalHost = window.location.hostname.includes('localhost')
    if (!props.formData.Client) {
      const defaultClient = window.location.hostname.includes('acyan')
        ? '42818A88285511E9A38A063D3002FD4C'
        : '1E8EBE7748A60600865EE185A02770C7'
      return Object.assign({}, props.formData, {
        Client: isLocalHost ? '' : defaultClient,
        Stack: localStorage.getItem('stack'),
      })
    }
    return Object.assign({}, props.formData, {
      Stack: localStorage.getItem('stack'),
    })
  }

  const confirmSelection = (values) => {
    const { Stack, Client } = values
    const defaultURL = config[Client][Stack].constants.defaultUrl
    if (Stack && Client && config[Client][Stack]) {
      props.updateClientForm(values)
      Auth.configure(config[Client][Stack])
      Auth.currentSession(Stack, Client)
        .then((result) => {
          props.authenticated(undefined, result.session)
          if (result.newlySignedIn && result.redirect) {
            window.history.replaceState(null, '', `${defaultURL}`)
            window.location =
              result.redirect.indexOf(defaultURL) > -1
                ? result.redirect
                : ((result.redirect +
                    `${result.redirect.indexOf('/#/') > -1 ? '' : '#/'}` +
                    defaultURL) as unknown as Location)
          }
          return navigate(`/${defaultURL}`)
        })
        .catch((error: any) => {
          log.error(error)
          props.authenticated(error, undefined)
        })
    }
  }

  return (
    <Container fluid>
      {window.location.href.indexOf('code') > -1 && <Spinner />}
      {getDefaultFormData() && (
        <Row
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClientSelection initialFormData={getDefaultFormData()} confirmSelection={(e) => confirmSelection(e)} />
        </Row>
      )}
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(HOCClientSelection)
