export default {
  query: `select
  distinct cot2.TRANSMISSION_ID,
  cot2.TRANSMISSION_TYPE_CODE,
  cot2.TRANSMISSION_SUB_TYPE_CODE,
  cot2.TRANSMISSION_STATUS_CODE,
  cot2.MOBILE_DEVICE_NBR,
  cot2.SUBSCRIPTION_NBR,
  cot2.REFERENCE_1,
  cot2.REFERENCE_2,
  cot2.REQUEST as REQUEST_XML,
  cot2.RESPONSE as RESPONSE_XML,
  cot2.ERROR_CODE,
  cot2.ERROR_DESCRIPTION,
  cot2.INTERACTION_LINE_ID,
  cot2.CREATED_BY,
  cot2.CREATED_DATE,
  cot2.UPDATED_BY,
  cot2.UPDATED_DATE,
  cot2.ACTIVE_IND
from
  customer.service_request sr
inner join asset.asset a on
  a.asset_id = sr.asset_id
  and sr.active_ind = 1
  and a.active_ind = 1
inner join integration.order_transmission cot2
  on
  cot2.REFERENCE_1 = sr.EXTERNAL_REFERENCE_ID
  and cot2.active_ind = 1
where
  a.mobile_device_nbr = :mdn
  or a.subscription_nbr = :subnbr
order by
  cot2.created_date asc`,
  mapper: [
    { o: 'TransmissionId', d: 'TRANSMISSION_ID' },
    { o: 'TransmissionType', d: 'TRANSMISSION_TYPE_CODE' },
    { o: 'TransmissionSubType', d: 'TRANSMISSION_SUB_TYPE_CODE' },
    { o: 'TransmissionStatus', d: 'TRANSMISSION_STATUS_CODE' },
    { o: 'MobileDeviceNumber', d: 'MOBILE_DEVICE_NBR' },
    { o: 'SubscriptionNumber', d: 'SUBSCRIPTION_NBR' },
    { o: 'Reference1', d: 'REFERENCE_1' },
    { o: 'Reference2', d: 'REFERENCE_2' },
    { o: 'RequestXml', d: 'REQUEST_XML' },
    { o: 'ResponseXml', d: 'RESPONSE_XML' },
    { o: 'ErrorCode', d: 'ERROR_CODE' },
    { o: 'ErrorDescription', d: 'ERROR_DESCRIPTION' },
    { o: 'InteractionLineId', d: 'INTERACTION_LINE_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'Active', d: 'ACTIVE_IND' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
  ],
}
