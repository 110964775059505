export default {
  query: ` SELECT
	o.[order_id] as OrderId,
	o.[parent_order_id] as ParentOrderId,
	o.[claim_id] as ClaimId,
	o.[fulfillment_method] as FulfillmentMethod ,
	o.[order_state_xml] as OrderStateXml,
	o.order_status_id as OrderStatusId,
	d.[name] as OrderStatus,
	o.[payment_status] as PaymentStatus,
	o.[claimed_item_id] as ClaimedItemId,
	o.[autoPaymentAuth_flg] as AutoPaymentAuthFlag,
	o.[salvageExpectation_status] as SalvageExpectationStatus,
	o.[SNR_fee] as SNRFee,
	o.[salvageExpectation_reason] as SalvageExpectationReason,
	o.[salvageExpectation_note] as SalvageExpectationNote,
	o.[noCharge_Override] as NoChargeOverride,
	o.[Company_Code] as CompanyCode,
	o.[SimVersion] as SimVersion,
	o.[created_by] as CreatedBy,
	o.[created_dtm] as CreatedDate,
	o.[modified_by] as ModifiedBy,
	o.[modified_dtm] as ModifiedDate,
	o.[deleted_flg] as DeletedFlag
from
	intelliset.dbo.[order] o with(nolock)
left join intelliset.dbo.code d with(nolock) on
	d.code_id = o.order_status_id
inner join intelliset.dbo.claim c with(nolock) on
	o.claim_ID = c.claim_id
inner join intelliset..subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
	s.wireless_number = @mdn or s.account_number = @subnbr
order by o.[created_dtm] asc;`,
  mapper: [
    { o: 'OrderId', d: 'OrderId' },
    { o: 'ParentOrderId', d: 'ParentOrderId' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'FulfillmentMethod ', d: 'FulfillmentMethod' },
    { o: 'OrderStateXml', d: 'OrderStateXml' },
    { o: 'OrderStatusId', d: 'OrderStatusId' },
    { o: 'OrderStatus', d: 'OrderStatus' },
    { o: 'PaymentStatus', d: 'PaymentStatus' },
    { o: 'ClaimedItemId', d: 'ClaimedItemId' },
    { o: 'AutoPaymentAuthFlag', d: 'AutoPaymentAuthFlag' },
    { o: 'SalvageExpectationStatus', d: 'SalvageExpectationStatus' },
    { o: 'SNRFee', d: 'SNRFee' },
    { o: 'SalvageExpectationReason', d: 'SalvageExpectationReason' },
    { o: 'SalvageExpectationNote', d: 'SalvageExpectationNote' },
    { o: 'NoChargeOverride', d: 'NoChargeOverride' },
    { o: 'CompanyCode', d: 'CompanyCode' },
    { o: 'SimVersion', d: 'SimVersion' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
