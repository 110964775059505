import * as uuidLib from 'uuid'
import { Buffer } from 'buffer/'

let emptyUuid: Uuid
// const uuidLib = require('uuid')

// let emptyUuid: any

/** Represents a universally unique identifier (UUID). */
export default class Uuid {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private __data: any
  /**
   * Create a UUID.
   */
  public constructor(data: Uuid | ArrayBuffer | ArrayLike<number>) {
    if (data instanceof Uuid) {
      this.__data = new Uint8Array(data.__data)
    } else if (data instanceof ArrayBuffer) {
      if (data.byteLength !== 16) {
        throw new RangeError('Invalid array length')
      }
      this.__data = new Uint8Array(data.slice(0))
    } else {
      const array = Uint8Array.from(data)
      if (array.length !== 16) {
        throw new RangeError('Invalid array length')
      }
      this.__data = array
    }
  }

  /**
   * Gets an empty (Nil) UUID.
   * @return {Uuid} A  Uuid object with all of its bytes set to zero.
   */
  public static get EMPTY() {
    if (!emptyUuid) {
      emptyUuid = new Uuid(new Uint8Array(16))
    }
    return emptyUuid
  }

  /**
   * Generates a new time-based (v1) UUID.
   * @return {Uuid} A new Uuid object.
   */
  public static generateTimeBased() {
    return new Uuid(uuidLib.v1({}, new Uint8Array(16)))
  }

  /**
   * Generates a new random (v4) UUID.
   * @return {Uuid} A new Uuid object.
   */
  public static generateRandom() {
    return new Uuid(uuidLib.v4(null, new Uint8Array(16)))
  }

  /**
   * Generates a new sequential (time-sortable) UUID.
   * @return {Uuid} A new Uuid object.
   */
  public static generateSequential() {
    // Version 1 UUIDs are stored with the following byte layout:
    // time_low                     0-3     unsigned 32 bit integer
    // time_mid                     4-5     unsigned 16 bit integer
    // time_high_and_version        6-7     unsigned 16 bit integer     most significant 4 bits is version (1)
    // clock_seq_hi_and_reserved    8       unsigned 8 bit integer      most significant 2 bits is variant
    // clock_seq_low                9       unsigned 8 bit integer
    // node                         10-15   unsigned 48 bit integer

    // Our sequential UUIDs are stored with the following byte layout:
    // time_high                    0-3     unsigned 32 bit integer
    // time_mid                     4-5     unsigned 16 bit integer
    // time_low_and_version         6-7     unsigned 16 bit integer     most significant 4 bits is version (0)
    // clock_seq_hi_and_reserved    8       unsigned 8 bit integer      most significant 2 bits is variant
    // clock_seq_low                9       unsigned 8 bit integer
    // node                         10-15   unsigned 48 bit integer

    const buffer = new ArrayBuffer(16)
    uuidLib.v1({}, new Uint8Array(buffer), 0)

    const dataView = new DataView(buffer)

    const timeHigh = dataView.getUint16(6) & 0x0fff
    const timeMid = dataView.getUint16(4)
    const timeLow = dataView.getUint32(0)

    // >>> 0 converts a signed integer to an unsigned one.
    // Used here for defensive programming just in case setUint32 doesn't properly handle a signed integer.
    dataView.setUint32(0, ((timeHigh << 20) | (timeMid << 4) | ((timeLow & 0xf0000000) >>> 28)) >>> 0)
    dataView.setUint16(4, (timeLow & 0x0ffff000) >>> 12)
    dataView.setUint16(6, timeLow & 0x0fff)

    return new Uuid(buffer)
  }

  /**
   * Determines whether the UUID is empty (a nil UUID).
   * @return {}
   */
  public get isEmpty() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.__data.every((b: any) => b === 0)
  }

  /**
   * Gets the version of the UUID.
   * @return {number} The version of the UUID.
   */
  public get version() {
    return (this.__data[6] & 0xf0) >> 4
  }

  /**
   * Gets the variant of the UUID.
   * @return {number} The variant of the UUID. 0 for NCS, 1 for RFC 4122, 2 for Microsoft, 3 for future use.
   */
  public get variant() {
    if (~this.__data[8] & 0x80) {
      return 0 // Reserved for NCS backward compatibility. A blank UUID will return 0.
    } else if (~this.__data[8] & 0x40) {
      return 1 // RFC 4122.
    } else if (~this.__data[8] & 0x20) {
      return 2 // Reserved for Microsoft Corporation backward compatibility.
    } else {
      return 3 // Reserved for future use.
    }
  }

  /**
   * Gets the timestamp contained within the UUID.
   * @return {Date} The timestamp of the UUID.
   */
  public get timestamp() {
    const version = this.version
    if (version !== 0 && version !== 1) {
      throw Error('Can only get timestamps for version 1 and sequential UUIDs')
    }

    if (this.variant !== 1) {
      throw Error('Can only get timestamps for non-empty RFC 4122 UUIDs')
    }

    const dataView = new DataView(this.__data.buffer)

    // Read least significant 32 bits.
    // The >>> 0 converts a signed integer to unsigned.
    const timeLsb =
      version === 1
        ? dataView.getUint32(0)
        : (((dataView.getUint8(3) & 0x0f) << 28) | (dataView.getUint16(4) << 12) | (dataView.getUint16(6) & 0x0fff)) >>>
          0

    // Read most significant 28 bits.
    const timeMsb =
      version === 1
        ? dataView.getUint16(4) | ((dataView.getUint16(6) & 0x0fff) << 16)
        : (dataView.getUint32(0) & 0xfffffff0) >>> 4

    // Convert raw bits to number of milliseconds since Gregorian epoch.
    // Strip nanoseconds here to avoid overflowing the Javascript number datatype.
    let msecs = (timeMsb * 0x100000000) / 10000 + timeLsb / 10000

    // Convert from Gregorian epoch to unix epoch.
    msecs -= 12219292800000

    // Return Javascript date.
    return new Date(msecs)
  }

  /**
   * Returns the UUID as an array of 16 bytes.
   * @return {Uint8Array} An array containing the internal representation of the UUID.
   */
  public toArray() {
    return new Uint8Array(this.__data)
  }

  /**
   * Returns a string representing the UUID.
   * @param {string} [format] The textual format of the UUID. Can be 'uuid' for the standard format or 'hex' for hexadecimal. The default is 'uuid'.
   */
  public toString(format: string) {
    const hex = Buffer.from(this.__data).toString('hex').toUpperCase()
    if (format === 'uuid' || typeof format === 'undefined') {
      return (
        hex.substring(0, 8) +
        '-' +
        hex.substring(8, 12) +
        '-' +
        hex.substring(12, 16) +
        '-' +
        hex.substring(16, 20) +
        '-' +
        hex.substring(20, 32)
      )
    } else if (format === 'hex') {
      return hex
    } else {
      throw Error('Invalid UUID string format.')
    }
  }
}
