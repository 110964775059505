import React from 'react'
import { Formik } from 'formik'
import { Col, Form, Button, Row } from 'react-bootstrap'
import searchFormSchema from '../../form-schemas/search-form-v1'
import searchFormSchemaV2 from '../../form-schemas/search-form-v2'

const SearchFormV1: React.FunctionComponent<{
  initialFormData: any
  search: any
  updateForm: any
  searchByAccountNbr?: boolean
  showInstruction?: boolean
}> = (props) => {
  const onSubmit = () => {
    props.search()
  }

  const handleFormChange = (handleChange, e) => {
    const {
      target: { name, value, checked, type },
    } = e
    const input = {}
    input[name] = type === 'checkbox' ? checked : value
    props.updateForm(input)
    handleChange(e)
  }

  return (
    <Formik
      validationSchema={props.searchByAccountNbr ? searchFormSchemaV2 : searchFormSchema}
      onSubmit={onSubmit}
      initialValues={props.initialFormData}
    >
      {({ values, touched, errors, handleBlur, handleSubmit, handleReset, handleChange, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} className="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12" controlId="formGridMDN">
              <Form.Label>
                {props.searchByAccountNbr ? 'Search by Subscriber Number' : 'Search by Mobile Number'}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={props.searchByAccountNbr ? 'HRZN3800' : '80-1234-5678'}
                name="MobileDeviceNumber"
                value={values.MobileDeviceNumber}
                isInvalid={!!errors.MobileDeviceNumber && !!touched.MobileDeviceNumber}
                isValid={touched.MobileDeviceNumber && !errors.MobileDeviceNumber}
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(handleChange, e)}
              />
              {errors.MobileDeviceNumber && touched.MobileDeviceNumber && (
                <Form.Control.Feedback type="invalid">
                  <>{errors.MobileDeviceNumber}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} className="col-xl-4 col-lg-4 col-md-2 col-12" controlId="formGridMDN">
              <Form.Label>{` `}</Form.Label>
            </Form.Group>
            <Form.Group
              as={Col}
              className="text-end col-xl-8 pt-xl-3 col-lg-8 pt-lg-3 col-md-10 pt-md-3 col-sm-12 col-12 pt-1"
              controlId="formGridButton"
            >
              <Form.Label>&nbsp;</Form.Label>
              <Button variant="success" type="submit" disabled={!isValid || !values.MobileDeviceNumber}>
                Search
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="outline-secondary"
                type="button"
                onClick={handleReset}
                disabled={!values.MobileDeviceNumber}
              >
                Reset
              </Button>
            </Form.Group>
            <Form.Group as={Col} className="col-xl-4 col-lg-4 col-md-2 col-12" controlId="formGridMDN">
              <Form.Label>{` `}</Form.Label>
            </Form.Group>
          </Row>
          {props.showInstruction && (
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formInstructions" className="col-md-12">
                <br /> <strong>Note: </strong>
                <br /> <strong>1. </strong>Don&apos;t change structure and property names of response.
                <br /> <strong>2. </strong>Success response is array and it can have up to 5 orders.
              </Form.Group>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default SearchFormV1
