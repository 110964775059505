import { string, object, array } from 'yup'

const isPureOpen = ['4']
const simOnlyOffers = ['18', '19']

const schema = object({
  MobileDeviceNumber: string().when(['ServiceFlag'], {
    is: (ServiceFlag: string) => isPureOpen.includes(ServiceFlag),
    then: string().nullable(),
    otherwise: string().length(11).required('Required'),
  }),
  SubscriberNumber: string().length(8).required('Required'),
  KeiyakushaKanaName: string().required('Required'),
  KeiyakushaKanjiName: string().required('Required'),
  KeiyakushaPostNo: string().required('Required'),
  KeiyakushaAddress: string().required('Required'),
  KeiyakushaAddress2: string(),
  KeiyakushaAddress3: string(),
  KeiyakushaHomeTelNo: string().required('Required'),
  SeikyushaKanaName: string().required('Required'),
  SeikyushaKanjiName: string().required('Required'),
  SeikyushaPostNo: string().required('Required'),
  SeikyushaAddress: string().required('Required'),
  SeikyushaHomeTelNo: string().required('Required'),

  ClientAssetSku: string(),
  IMEI: string().when(['ClientAssetSku', 'ProgramType'], {
    is: (ClientAssetSku, ProgramType) => Boolean(ClientAssetSku) && !simOnlyOffers.includes(ProgramType),
    then: string().length(15).required('Required'),
    otherwise: string().nullable(),
  }),
  HandsetType: string().when(['ClientAssetSku', 'ProgramType'], {
    is: (ClientAssetSku, ProgramType) => Boolean(ClientAssetSku) && !simOnlyOffers.includes(ProgramType),
    then: string().required('Required'),
    otherwise: string().nullable(),
  }),
  ICCId: string().when(['ClientAssetSku', 'ProgramType'], {
    is: (ClientAssetSku, ProgramType) => Boolean(ClientAssetSku) && !simOnlyOffers.includes(ProgramType),
    then: string().required('Required'),
    otherwise: string().nullable(),
  }),
  PurchaseDate: string().when(['ClientAssetSku', 'ProgramType'], {
    is: (ClientAssetSku, ProgramType) => Boolean(ClientAssetSku) && !simOnlyOffers.includes(ProgramType),
    then: string().required('Required'),
    otherwise: string().nullable(),
  }),
  eSIMPlanType: string(),
  eSIMProfileDownloadStatus: string(),

  ProgramType: string(), //.required('Required'),
  ClientChannel: string().required('Required'),
  LoyaltyPoints: string().required('Required'),
  ClientAccountType: string().required('Required'),
  DateOfBirth: string().required('Required'),

  AgreementStartDate: string().required('Required'),
  ClientAccountStartDate: string().required('Required'),

  PastAssetInfo: array().of(
    object({
      ClientAssetSku: string(),
      IMEI: string().when(['ClientAssetSku'], {
        is: (ClientAssetSku: string) => !!ClientAssetSku,
        then: string().length(15, 'IMEI must be exactly 15 characters').required('Required'),
        otherwise: string().nullable(),
      }),
      PurchaseDate: string().when(['ClientAssetSku'], {
        is: (ClientAssetSku: string) => !!ClientAssetSku,
        then: string().required('Required'),
        otherwise: string().nullable(),
      }),
      AssetStartDate: string().when(['ClientAssetSku'], {
        is: (ClientAssetSku: string) => !!ClientAssetSku,
        then: string().required('Required'),
        otherwise: string().nullable(),
      }),
      AssetEndDate: string(),
    })
  ),
  AUID: string(),
  ServiceFlag: string().required('Required'),
})

export default schema
