import CIFService from './cif-service'
import EntityService from './entity-service'
import OrangeService from './orange-service'
import DataService from './data-service'
import PascalService from './pascal-service'
import SagawaService from './sagawa-service'
import SequenceService from './seq-service'

class API {
  public CIFService = new CIFService()
  public DataService = new DataService()
  public EntityService = new EntityService()
  public OrangeService = new OrangeService()
  public PascalService = new PascalService()
  public SagawaService = new SagawaService()
  public SequenceService = new SequenceService()
}

const api = new API()

export default api
