import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DropdownSubmenu, NavDropdownMenu } from '../nav-submenu'
import { Container, Navbar, Nav, NavDropdown, ButtonGroup, DropdownButton, Dropdown, Offcanvas } from 'react-bootstrap'

import { config } from '../../config'
import { IState } from '../../store/types'
import { clients } from '../../data/master-data'
import { loginInfoCreator } from '../../store/login/actions'
import menuConfigurationData from '../../data/menu-configuration'
import { formatStackName, getEligibleStacks, isSupperAdminUser } from '../../store/selectors'

const mapStateToProps = (state: IState) => {
  return {
    agentInfo: state.LoginInfo.agentInfo,
    agentLoaded: state.LoginInfo.agentLoaded,
    selectedClientDetails: state.LoginInfo.clientInfo.Form,
  }
}

const mapDispatchToProps = {
  updateClientForm: loginInfoCreator.updateClientForm,
  resetClientSetting: loginInfoCreator.resetClientSetting,
}

const NavBar = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedClientDetails, agentInfo } = props
  const clientName = clients.find((c) => c.Code === selectedClientDetails.Client)?.DisaplayName || ''
  const menuConfiguration = menuConfigurationData(clientName) || {}
  const activeKey = (location.pathname === '/' ? '/' : location.pathname).replace('/', '')
  const activeParentKey =
    Object.keys(menuConfiguration).find((pk) => menuConfiguration[pk].child.find((c) => c.path === activeKey)) || '0'
  const showNarBar = !['/'].includes(location.pathname)
  const selectedClient = clients.find((c) => c.Code === selectedClientDetails.Client)
  const selectedStack = selectedClient?.Stacks.find((c) => c.Code === selectedClientDetails.Stack)
  const selectedStackEnvironments =
    selectedClientDetails.Client &&
    selectedClientDetails.Stack &&
    config[selectedClientDetails.Client][selectedClientDetails.Stack]?.endpoints?.map((p) => p.key.toUpperCase())

  const changeEnvironment = (eventkey) => {
    props.updateClientForm({ Environment: eventkey.toLowerCase() })
  }

  const changeStack = (eventkey) => {
    props.updateClientForm({ Stack: eventkey.toLowerCase() })
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top" style={{ height: '3rem' }}>
      <Container fluid>
        {/* <Navbar.Brand href="#">Horizon DevTools</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
        {showNarBar && (
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">Horizon DevTools</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav activeKey={`#${activeKey}`} className="me-auto my-2 my-lg-0">
                {Object.keys(menuConfiguration).map((item) => {
                  if (
                    selectedStack?.IsProductionEnvironment &&
                    menuConfiguration[item].specialPermissionRequiredForProd &&
                    !isSupperAdminUser(agentInfo?.UserName || '')
                  ) {
                    return null
                  }
                  if (menuConfiguration[item].child.length === 1) {
                    return (
                      <Nav.Link
                        key={item}
                        href={`#${menuConfiguration[item].child[0].path}`}
                        onClick={(e) => {
                          e.preventDefault()
                          if (menuConfiguration[item].externalLink) {
                            window.open(menuConfiguration[item].externalLink, '_blank')
                          } else {
                            navigate(`/${menuConfiguration[item].child[0].path}`)
                          }
                        }}
                      >
                        <span className={`fa fa-fw fa-${menuConfiguration[item].icon}`}></span> &nbsp;
                        {menuConfiguration[item].child[0].title}
                      </Nav.Link>
                    )
                  }
                  return (
                    <NavDropdownMenu
                      key={item}
                      title={
                        <span>
                          <i className={`fa fa-${menuConfiguration[item].icon} fa-fw`}></i>{' '}
                          {menuConfiguration[item].title}
                        </span>
                      }
                      id="collasible-nav-dropdown"
                      active={activeParentKey === item}
                    >
                      {menuConfiguration[item].child.map((c, j) => {
                        if (c.child) {
                          return (
                            <DropdownSubmenu
                              key={`Menu${j}`}
                              title={
                                <>
                                  <span className={`fa fa-fw fa-${c.icon}`}></span> &nbsp; {c.title}
                                </>
                              }
                            >
                              {c.child.map((subc, z) => {
                                return (
                                  <NavDropdown.Item
                                    key={`SubMenu${j}${z}`}
                                    href={`#${subc.path}`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      navigate(`/${subc.path}`)
                                    }}
                                  >
                                    <span className={`fa fa-fw fa-${subc.icon}`}></span> &nbsp; {subc.title}
                                  </NavDropdown.Item>
                                )
                              })}
                            </DropdownSubmenu>
                          )
                        }
                        return (
                          <NavDropdown.Item
                            key={`${item}${j}`}
                            href={`#${c.path}`}
                            onClick={(e) => {
                              e.preventDefault()
                              navigate(`/${c.path}`)
                            }}
                          >
                            <span className={`fa fa-fw fa-${c.icon}`}></span> &nbsp; {c.title}
                          </NavDropdown.Item>
                        )
                      })}
                    </NavDropdownMenu>
                  )
                })}
              </Nav>
              <ButtonGroup className="d-flex grp-dropdown">
                <DropdownButton
                  as={ButtonGroup}
                  title={`Stage: ${selectedStack?.DisaplayName} `}
                  id="bg-stack-dropdown"
                  onSelect={(e) => {
                    changeStack(e)
                    props.resetClientSetting()
                    navigate(`/`)
                  }}
                >
                  {getEligibleStacks(selectedClientDetails?.Client).map((c) =>
                    selectedClientDetails?.Stack !== c.Code ? (
                      <Dropdown.Item key={c.Code} eventKey={c.Code}>
                        {c.DisaplayName}
                      </Dropdown.Item>
                    ) : null
                  )}
                </DropdownButton>
                <DropdownButton
                  as={ButtonGroup}
                  title={`Environment: ${formatStackName(selectedClientDetails?.Environment)}`}
                  id="bg-environment-dropdown"
                  onSelect={(e) => changeEnvironment(e)}
                >
                  {(selectedStackEnvironments || []).map((s) => (
                    <Dropdown.Item key={s} eventKey={s}>
                      {formatStackName(s)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </ButtonGroup>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        )}
      </Container>
    </Navbar>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
