export default {
  query: `select distinct n.NOTE_ID, n.CLIENT_CHANNEL_ID, n.CUSTOMER_ID, n.ASSET_ID, n.AGREEMENT_ID, n.CUSTOMER_CASE_ID, n.SERVICE_REQUEST_ID, n.SERVICE_ORDER_ID, n.NOTE_TYPE_CODE, n.NOTE_TEXT, n.INTERACTION_LINE_ID, n.CLIENT_ACCOUNT_ID, n.TEMPLATE_ID, n.PRIORITY, n.CREATED_SYSTEM_ID, n.CREATED_BY, n.CREATED_DATE, n.UPDATED_BY, n.UPDATED_DATE, n.ACTIVE_IND
    from asset.asset a
    join interaction.interaction_line il on il.asset_id = a.asset_id and a.active_ind = 1 and il.active_ind = 1
    join customer.note n on n.interaction_line_id = il.interaction_line_id and n.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by n.created_date asc;`,
  mapper: [
    { o: 'NoteId', d: 'NOTE_ID' },
    { o: 'ClientChannelId', d: 'CLIENT_CHANNEL_ID' },
    { o: 'CustomerId', d: 'CUSTOMER_ID' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ServiceOrderId', d: 'SERVICE_ORDER_ID' },
    { o: 'NoteType', d: 'NOTE_TYPE_CODE' },
    { o: 'NoteText', d: 'NOTE_TEXT' },
    { o: 'InteractionLineId', d: 'INTERACTION_LINE_ID' },
    { o: 'ClientAccountId', d: 'CLIENT_ACCOUNT_ID' },
    { o: 'TemplateId', d: 'TEMPLATE_ID' },
    { o: 'Priority', d: 'PRIORITY' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
