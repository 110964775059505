import { IEndpoint, IKibanaConfig } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-uat1.uap.jpnmob-acyan.npr.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: 'b3029110-270e-11ef-8e62-1b550b2b5585',
  kibanaGQLIndex: 'bd0d8520-270e-11ef-84b9-b919e236a59a',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}
