export default {
  query: `select
		top 100 subscriber_id as SubscriberId,
		enrollment_id as EnrollmentId,
		policy_id as PolicyId,
		program_id as ProgramId,
		client_id as ClientId,
		market_id as MarketId,
		device_id as DeviceId,
		wireless_number as WirelessNumber,
		account_number as AccountNumber,
		ESN,
		last_name as LastName,
		last_name_ml as LastNameML,
		first_name as FirstName,
		first_name_ml as FirstNameML,
		business_name as BusinessName,
		address1 as Address1,
		address2 as Address2,
		city as City,
		region_id as RegionId,
		postal_code as PostalCode,
		country_id as CountryId,
		enrollment_date as EnrollmentDate,
		purchase_date as PurchaseDate,
		mobile_idn as MobileIDN,
		end_enrollment_date as EnrollmentEndDate,
		Email,
		SubscriberDeclinedEmail,
		ContractIDAtTimeOfLoss,
		Subscriber_Claim_ID as SubscriberClaimId,
		SimVersion,
		plan_id as PlanId,
		created_by as CreatedBy,
		created_dtm as CreatedDate,
		modified_by as ModifiedBy,
		modified_dtm as ModifiedDate,
		deleted_flg as DeletedFlg
	  from
		  intelliset..subscriber with(nolock)
	  where
		  wireless_number = @mdn or account_number = @subnbr
	  order by created_dtm asc;`,
  mapper: [
    { o: 'SubscriberId', d: 'SubscriberId' },
    { o: 'EnrollmentId', d: 'EnrollmentId' },
    { o: 'PolicyId', d: 'PolicyId' },
    { o: 'ProgramId', d: 'ProgramId' },
    { o: 'ClientId', d: 'ClientId' },
    { o: 'MarketId', d: 'MarketId' },
    { o: 'DeviceId', d: 'DeviceId' },
    { o: 'WirelessNumber', d: 'WirelessNumber' },
    { o: 'AccountNumber', d: 'AccountNumber' },
    { o: 'ESN', d: 'ESN' },
    { o: 'LastName', d: 'LastName' },
    { o: 'LastNameML', d: 'LastNameML' },
    { o: 'FirstName', d: 'FirstName' },
    { o: 'FirstNameML', d: 'FirstNameML' },
    { o: 'BusinessName', d: 'BusinessName' },
    { o: 'Address1', d: 'Address1' },
    { o: 'Address2', d: 'Address2' },
    { o: 'City', d: 'City' },
    { o: 'RegionId', d: 'RegionId' },
    { o: 'PostalCode', d: 'PostalCode' },
    { o: 'CountryId', d: 'CountryId' },
    { o: 'EnrollmentDate', d: 'EnrollmentDate' },
    { o: 'PurchaseDate', d: 'PurchaseDate' },
    { o: 'MobileIDN', d: 'MobileIDN' },
    { o: 'EnrollmentEndDate', d: 'EnrollmentEndDate' },
    { o: 'Email', d: 'Email' },
    { o: 'SubscriberDeclinedEmail', d: 'SubscriberDeclinedEmail' },
    { o: 'ContractIDAtTimeOfLoss', d: 'ContractIDAtTimeOfLoss' },
    { o: 'SubscriberClaimId', d: 'SubscriberClaimId' },
    { o: 'SimVersion', d: 'SimVersion' },
    { o: 'PlanId', d: 'PlanId' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlg', d: 'DeletedFlg' },
  ],
}
