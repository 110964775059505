import React from 'react'
import { Col, Badge } from 'react-bootstrap'
import AceEditor from 'react-ace'
import requestXMLGenerator from '../../utils/xml-request-generator'

// import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/snippets/xml'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'

const Editor: React.FunctionComponent<{
  customeStyle: { fieldsetMargin: number; formMargin: number }
  data: any
  externalFormRef: any
  toggleEditXML: any
  handleFormChange: any
  handleOnSave: any
}> = (props) => {
  const onChange = (value) => {
    props.handleFormChange(value)
  }
  const onLoad = (editor) => {
    editor.setShowPrintMargin(false)
    editor.container.style.height = '84.5vh'
    editor.resize()
  }
  return (
    <Col md={!props.data.EditXML ? 4 : 6}>
      <fieldset
        className="border p-2"
        style={{ margin: `${props.customeStyle.fieldsetMargin}em` }}
        disabled={!props.data.EditXML}
      >
        <legend className="w-auto">
          {' '}
          <Badge pill bg="secondary"></Badge>
        </legend>
        {props.data.EditXML && (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault()
              props.toggleEditXML()
            }}
            className="editXmlBtn"
            style={{ float: 'right', marginLeft: '1em' }}
          >
            <strong>{'Discard'} </strong>
          </a>
        )}
        {props.data.EditXML && props.data.XML && (
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault()
              props.handleOnSave(props.data)
            }}
            className="editXmlBtn"
            style={{ float: 'right' }}
          >
            <strong>Save Enrollment</strong>
          </a>
        )}
        {!props.data.IsLoading && (
          <AceEditor
            name="copxmlEditor"
            theme="github"
            mode="xml"
            onLoad={(e) => onLoad(e)}
            onChange={(e) => onChange(e)}
            width="100%"
            fontSize={10}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            debounceChangePeriod={3000}
            value={props.data.XML ? props.data.XML : requestXMLGenerator(props.data.Form)}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              readOnly: !props.data.EditXML,
              highlightActiveLine: props.data.EditXML,
              highlightGutterLine: props.data.EditXML,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              showLineNumbers: true,
              autoScrollEditorIntoView: true,
              fixedWidthGutter: true,
              animatedScroll: true,
            }}
          />
        )}
      </fieldset>
    </Col>
  )
}

export default Editor
