import { produce } from 'immer'
import { Reducer } from 'redux'
import { actions } from './actions'
import { initialState } from './initialState'
import { clearAuthToken, clearAll } from '../../services/auth/oauth/oauth-storage'

export const loginReducer: Reducer<typeof initialState> = (state = initialState, { type, error, session, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case actions.COGNITO_AUTHENTICATED:
        if (error) {
          draft.authenticationError = error
          return draft
        }
        draft.agentInfo = {
          UserId: session?.idToken?.payload['cognito:username'],
          FirstName: session?.idToken?.payload?.given_name,
          LastName: session?.idToken?.payload?.family_name,
          DisplayName: session?.idToken?.payload?.name,
          UserName: session?.idToken?.payload['cognito:username'],
        }
        draft.agentLoaded = true
        draft.session = session
        return draft
      case actions.RESET_CLIENT_SETTING:
        clearAll()
        clearAuthToken()
        draft.agentLoaded = false
        draft.agentInfo = undefined
        draft.clientInfo.Form = Object.assign({}, draft.clientInfo.Form, {
          //Client: '',
          Stack: '',
          Environment: '',
        })
        return draft
      case actions.UPDATE_CLIENT_FORM_FIELDS:
        draft.clientInfo.Form = Object.assign({}, draft.clientInfo.Form, input)
        Object.keys(input).forEach((key) => localStorage.setItem(key.toLowerCase(), input[key]))
        return draft
      default:
        return draft
    }
  })
}
