export default {
  query: `SELECT
  n.[claimnote_id] as ClaimNoteId,
  n.[claim_id] as ClaimId,
  n.[call_id] as CallId,
  c1.[name] as NoteType,
  n.[note_text] as NoteText,
  n.[Rule_Id] as RuleId,
  n.[created_by] as CreatedBy,
  n.[created_dtm] as CreatedDate,
  n.[modified_by] as ModifiedBy,
  n.[modified_dtm] as ModifiedDate,
  n.[deleted_flg] as DeletedFlag
from
  intelliset.dbo.claimnote n with(nolock)
inner join intelliset.dbo.code c1 with(nolock) on
  c1.code_id = n.notetype_id
inner join intelliset.dbo.claim c with(nolock) on
  c.claim_id = n.claim_id
inner join intelliset.dbo.subscriber s with(nolock) on
  s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by n.[created_dtm] asc;`,
  mapper: [
    { o: 'ClaimNoteId', d: 'ClaimNoteId' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'CallId', d: 'CallId' },
    { o: 'NoteType', d: 'NoteType' },
    { o: 'NoteText', d: 'NoteText' },
    { o: 'RuleId', d: 'RuleId' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
