import { IClaimInformation } from '../types'

export const initialState: IClaimInformation = {
  IsLoading: false,
  IsSuccess: false,
  IsOtherOperationLoading: false,
  IsOtherOperationSuccess: false,
  Errors: [],
  Details: null,
}
