import { call, take } from 'redux-saga/effects'
import { actions } from './actions'
import { getServiceOrderDetails } from './sagas/getServiceOrderDetails'
import { serviceOrderOpration } from './sagas/serviceOrderOpration'

export function* getServiceOrderDetailsFlow() {
  while (true) {
    const { input } = yield take(actions.GET_SERVICE_ORDER_DETAILS_REQUEST)
    yield call(getServiceOrderDetails, input)
  }
}
export function* serviceOrderOprationFlow() {
  while (true) {
    const { input } = yield take(actions.PERFORM_SERVICE_ORDER_OPRATION)
    yield call(serviceOrderOpration, input)
  }
}
