export default {
  query: `select
      distinct cc.name ,
      cc.client_channel_id ,
      vas.vendor_asset_sku_nbr as SKU,
      asctlg.asset_catalog_name as Catalog_Name,
      coalesce( ( select act.asset_catalog_tl_desc from asset.asset_catalog_tl act where act.asset_catalog_id = asctlg.asset_catalog_id and act.language_code = ('ja-jp') and act.active_ind = 1 ), asctlg.asset_catalog_desc) as Catalog_Description,
      asmk.asset_make_name as Make,
      asmdl.asset_model_nbr as Model,
      coalesce( ( select asclr.asset_color_name from asset.asset_color_tl aclrt where aclrt.asset_color_id = asclr.asset_color_id and aclrt.language_code = ('ja-jp')), asclr.asset_color_name) as Color,
      tier.tier_type_code as Plan_Id,
      cofa.client_offer_feature_attr_val as Monthly_Fee,
      arm.replacement_priority as Replacement_Priority
    from
      asset.asset_replacement_matrix arm
    inner join asset.asset_catalog asctlg on
      asctlg.asset_catalog_id = arm.replacement_asset_catalog_id
      and asctlg.active_ind = 1
      and arm.active_ind = 1
    inner join client.vendor_asset_catalog_xref vasctlg on
      asctlg.asset_catalog_id = vasctlg.asset_catalog_id
    inner join client.vendor_asset vas on
      vasctlg.vendor_asset_id = vas.vendor_asset_id
      and vas.active_ind = 1
    inner join asset.asset_model asmdl on
      asctlg.asset_model_id = asmdl.asset_model_id
    inner join asset.asset_make asmk on
      asctlg.asset_make_id = asmk.asset_make_id
    inner join asset.asset_color asclr on
      asctlg.asset_color_id = asclr.asset_color_id
    inner join product.tier_asset_catalog_xref tacx on
      asctlg.asset_catalog_id = tacx.asset_catalog_id
      and now() between tacx.effective_start_date and tacx.effective_end_date
    inner join product.tier tier on
      tier.tier_id = tacx.tier_id
      and tier.client_channel_id = arm.client_channel_id
    inner join product.client_offer_tier_xref cotx on
      cotx.tier_id = tier.tier_id
    inner join product.client_offer co on
      co.client_offer_id = cotx.client_offer_id
      and co.client_channel_id = arm.client_channel_id
    inner join product.client_offer_feature_xref cofx on
      co.client_offer_id = cofx.client_offer_id
    inner join product.client_offer_feature cof on
      cof.client_offer_feature_id = cofx.client_offer_feature_id
      and cof.client_offer_feature_type_code = 'tierprice'
    inner join product.client_offer_feature_attr_map cofam on
      cofam.client_offer_feature_id = cof.client_offer_feature_id
      and cofam.criteria_value = tier.tier_id
    inner join product.client_offer_feature_attr_xref cofax on
      cofax.combo_set_nbr = cofam.combo_set_nbr
      and now() between cofax.effective_start_date and cofax.effective_end_date
    inner join product.client_offer_feature_attribute cofa on
      cofa.client_offer_feature_attr_id = cofax.client_offer_feature_attr_id
      and cofa.client_offer_feature_attr_name = 'amount'
    inner join client.client_channel cc on
      cc.client_channel_id = arm.client_channel_id
    where
      1 = 1
      and vas.vendor_channel_id = '1BE72E2536A30FC0A9E956EE0BD630AC'
      and arm.asset_catalog_id = (
      select
        asset_catalog_id
      from
        asset.asset
      where
       (mobile_device_nbr = :mdn or subscription_nbr = :subnbr)
        and asset_instance_code = 'enrolled'
      order by
        asset_start_date desc
      limit 1)
      and locate(replace(substring_index(vas.vendor_asset_sku_nbr, '-', 1), right(substring_index(vas.vendor_asset_sku_nbr, '-', 1), 3), ''), arm.asset_condition_priority)>0
      and case 
          replace(substring_index(vas.vendor_asset_sku_nbr, '-', 1), right(substring_index(vas.vendor_asset_sku_nbr, '-', 1), 3), '')
          when '' then 0
          else locate(replace(substring_index(vas.vendor_asset_sku_nbr, '-', 1), right(substring_index(vas.vendor_asset_sku_nbr, '-', 1), 3), ''), arm.asset_condition_priority)
      end >0
      and vas.kit = '1'
    order by
      cc.name,
      arm.replacement_priority asc,
      locate(replace(substring_index(vas.vendor_asset_sku_nbr, '-', 1), right(substring_index(vas.vendor_asset_sku_nbr, '-', 1), 3), ''), arm.asset_condition_priority),
      if(right(vas.vendor_asset_sku_nbr,
      2) = '-U',
      0,
      1) asc;`,
  mapper: [
    { o: 'Name', d: 'name' },
    { o: 'ClientChannelId ', d: 'client_channel_id' },
    { o: 'SKU', d: 'SKU' },
    { o: 'CatalogName', d: 'Catalog_Name' },
    { o: 'CatalogDescription', d: 'Catalog_Description' },
    { o: 'Make', d: 'Make' },
    { o: 'Model', d: 'Model' },
    { o: 'Color', d: 'Color' },
    { o: 'PlanId', d: 'Plan_Id' },
    { o: 'MonthlyFee', d: 'Monthly_Fee' },
    { o: 'ReplacementPriority', d: 'Replacement_Priority' },
  ],
}
