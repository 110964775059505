export default {
  query: `select distinct va.vendor_asset_sku_nbr, vaa.vendor_asset_attribute_name, vaav.vendor_asset_attribute_value
    from asset.asset a
    inner join asset.asset_catalog ac on ac.asset_catalog_id = a.asset_catalog_id and a.active_ind = 1 and ac.active_ind = 1
    inner join client.vendor_asset_catalog_xref vacx on vacx.asset_catalog_id = ac.asset_catalog_id
    inner join client.vendor_asset va on va.vendor_asset_id = vacx.vendor_asset_id and va.active_ind = 1 and va.base_item_ind = 1
    inner join client.vendor_asset_attribute_value vaav on vaav.vendor_asset_id = va.vendor_asset_id and vaav.active_ind = 1
    inner join client.vendor_asset_attribute vaa on vaa.vendor_asset_attribute_id = vaav.vendor_asset_attribute_id and vaa.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by va.vendor_asset_sku_nbr, a.created_date asc;`,
  mapper: [
    { o: 'VendorAssetSkuNbr', d: 'vendor_asset_sku_nbr' },
    { o: 'AttributeName', d: 'vendor_asset_attribute_name' },
    { o: 'AttributeValue', d: 'vendor_asset_attribute_value' },
  ],
}
