import React from 'react'
import { Alert } from 'react-bootstrap'
import { IMessage, IMessageType } from '../../store/types'

export const AlterMessage: React.FunctionComponent<IMessage> = (props) => {
  const { MessageType, Message, MessageCode } = props
  if (MessageCode) {
    return (
      <Alert style={{ marginTop: '1em' }} variant={MessageType === IMessageType.ERROR ? 'danger' : 'success'}>
        <span>
          <strong className="me-auto">Error Code: </strong>
          {MessageCode}
        </span>
        <br />
        <span>
          <strong className="me-auto">Error Message: </strong>
          {Message}
        </span>
      </Alert>
    )
  }
  return (
    <Alert style={{ marginTop: '1em' }} variant={MessageType === IMessageType.ERROR ? 'danger' : 'success'}>
      {Message}
    </Alert>
  )
}
