/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Token } from './token'

// Following 2 are taken from the official amazon-cognito-identity-js Repo
// The amazon-cognito-identity-js package was once a part of the this project, but was removed
/**
 * COGNITO TYPES
 */
export interface ICookieStorageData {
  domain: string
  path?: string
  expires?: number
  secure?: boolean
}

export interface ICognitoStorage {
  setItem(key: string, value: string): void
  getItem(key: string): string
  removeItem(key: string): void
  clear(): void
}

/**
 * Auth instance options
 */
export interface IAuthOptions {
  userPoolId?: string
  providerId?: string
  userPoolWebClientId?: string
  identityPoolId?: string
  region?: string
  mandatorySignIn?: boolean
  cookieStorage?: ICookieStorageData
  oauth?: IOAuthOpts
  refreshHandlers?: any
  storage?: ICognitoStorage
  authenticationFlowType?: string
  identityPoolRegion?: string
}

export enum CognitoHostedUIIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Amazon = 'LoginWithAmazon',
}

export interface IFederatedSignInOptions {
  provider: CognitoHostedUIIdentityProvider
}

export interface IFederatedSignInOptionsCustom {
  customProvider: string
}

export interface ICognitoUserSession {
  idToken: Token
  accessToken: Token
  refreshToken: Token
}

export function isFederatedSignInOptions(obj: any): obj is IFederatedSignInOptions {
  const key: keyof IFederatedSignInOptions = 'provider'
  return obj && obj[key] !== undefined
}

export function isFederatedSignInOptionsCustom(obj: any): obj is IFederatedSignInOptionsCustom {
  const key: keyof IFederatedSignInOptionsCustom = 'customProvider'
  return obj && obj[key] !== undefined
}

export interface IOAuthOpts {
  domain: string
  scope: string[]
  redirectSignIn: string
  redirectSignOut: string
  responseType: string
  options?: any
  urlOpener?: (url: string, redirectUrl: string) => Promise<any>
  idp?: { title: string; color: string }[]
}

export interface IUsernamePasswordOpts {
  username: string
  password: string
  validationData?: { [key: string]: any }
}

// We can extend this in the future if needed
export type SignInOpts = IUsernamePasswordOpts

export function isUsernamePasswordOpts(obj: any): obj is IUsernamePasswordOpts {
  return !!(obj as IUsernamePasswordOpts).username
}
