import { IEndpoint, IKibanaConfig } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-uat2.uap.jpnmob-acyan.npr.aws.asurion.net/api',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '9b979510-270f-11ef-91d6-698f0d392d95',
  kibanaGQLIndex: '0db6f9b0-2710-11ef-84b9-b919e236a59a',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}
