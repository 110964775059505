export default {
  query: `select
	clc.ClaimLockControl_ID as ClaimLockContorlId,
	clc.Claim_ID as ClaimId,
	clc.UserName as UserName,
	clc.LockDateTime as LockDateTime,
	clc.IsOverride as IsOverride,
	clc.Override_By as OverrideBy,
	clc.Override_Dtm as OverrideDate,
	clc.Created_By as CreatedBy,
	clc.Created_Dtm as CreatedDate,
	clc.Modified_By as ModifiedBy,
	clc.Modified_Dtm as ModifiedDate,
	clc.Deleted_Flg as DeletedFlag
from
	Intelliset.dbo.ClaimLockControl clc with(nolock)
inner join intelliset.dbo.claim c with(nolock) on
	c.claim_id = clc.claim_id
inner join intelliset.dbo.subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by clc.Created_Dtm asc;`,
  mapper: [
    { o: 'ClaimLockContorlId', d: 'ClaimLockContorlId' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'UserName', d: 'UserName' },
    { o: 'LockDateTime', d: 'LockDateTime' },
    { o: 'IsOverride', d: 'IsOverride' },
    { o: 'OverrideBy', d: 'OverrideBy' },
    { o: 'OverrideDate', d: 'OverrideDate' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
