export default {
  query: `SELECT
  c.[ClaimTrace_ID] ClaimTraceId,
  c.[ClaimID] ,
  c.[OrderID] ,
  d.[name] ClaimStatus,
  c.claimstatus_id ClaimStatusId,
  c.OrderStatus_ID OrderStatusId,
  e.[name] OrderStatus,
  c.[Application] ,
  c.[Channel_ID] ChannelId,
  c.[ClaimTransmission_Status] ClaimTransmissionStatus,
  c.[ClaimTransmission_RetryCount] ClaimTransmissionRetryCount,
  c.[Created_By] CreatedBy,
  c.[Created_Dtm] CreatedDate,
  c.[Modified_By] ModifiedBy,
  c.[Modified_Dtm] ModifiedDate
from
  intelliset.dbo.claimtrace c with(nolock)
inner join intelliset.dbo.code d with(nolock) on
  d.code_id = c.claimstatus_id
left join intelliset.dbo.code e with(nolock) on
  c.OrderStatus_ID = e.code_id
inner join intelliset.dbo.claim c1 with(nolock) on
  c1.claim_id = c.claimid
inner join intelliset.dbo.subscriber s with(nolock) on
  s.subscriber_id = c1.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by c.Created_Dtm asc;`,
  mapper: [
    { o: 'ClaimTraceId', d: 'ClaimTraceId' },
    { o: 'ClaimId', d: 'ClaimID' },
    { o: 'OrderId', d: 'OrderID' },
    { o: 'ClaimStatusId', d: 'ClaimStatusId' },
    { o: 'ClaimStatus', d: 'ClaimStatus' },
    { o: 'OrderStatusId', d: 'OrderStatusId' },
    { o: 'OrderStatus', d: 'OrderStatus' },
    { o: 'Application', d: 'Application' },
    { o: 'ChannelId', d: 'ChannelId' },
    { o: 'ClaimTransmissionStatus', d: 'ClaimTransmissionStatus' },
    { o: 'ClaimTransmissionRetryCount', d: 'ClaimTransmissionRetryCount' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
  ],
}
