import AceEditor from 'react-ace'
import { connect } from 'react-redux'
import React, { Component, ReactNode } from 'react'
import { Container, Row, Col, Button, Alert } from 'react-bootstrap'

// import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/snippets/json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'

import { actionCreator } from '../../store/rootActions'
import { orangeActionCreator } from '../../store/orange/actions'
import { IComponents, IState } from '../../store/types'
import { OrangeServiceType } from '../../utils/constants'

import Spinner from '../../components/spinner'
import SearchForm from '../../components/search-form-v1'
import { isClientEnvironmentSelected } from '../../store/selectors'

const mapStateToProps = (state: IState) => {
  return {
    data: state.OrangeInformation,
    IsLoading: state.OrangeInformation.IsLoading,
    IsSuccess: state.OrangeInformation.IsSuccess,
    Errors: state.OrangeInformation.Errors,
    isClientEnvironmentSelected: isClientEnvironmentSelected(),
  }
}

const mapDispatchToProps = {
  getOrangeServiceResponse: orangeActionCreator.getOrangeServiceResponse,
  updateOrangeServiceResponse: orangeActionCreator.updateOrangeServiceResponse,
  saveOrangeServiceResponse: orangeActionCreator.saveOrangeServiceResponse,
  resetSuccessFlag: actionCreator.resetSuccessFlag,
  resetError: actionCreator.resetError,
  updateOrangeSearchForm: orangeActionCreator.updateOrangeSearchForm,
  loadSampleSuccessResponse: orangeActionCreator.loadSampleSuccessResponse,
  loadSampleErrorResponse: orangeActionCreator.loadSampleErrorResponse,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents

class OrangeService extends Component<IProps, IState> {
  initialFormData: any

  constructor(props: any) {
    super(props)
    this.initialFormData = {
      Client: 'kddi',
      MobileDeviceNumber: '',
      Stack: localStorage.getItem('stack'),
    }
  }

  onLoad(editor) {
    editor.setShowPrintMargin(false)
  }

  onChange(value) {
    this.props.updateOrangeServiceResponse({ type: this.props.type, response: value ? JSON.parse(value) : '' })
  }

  onSave() {
    this.props.saveOrangeServiceResponse({
      key: this.props.data.SearchKey,
      data: this.props.data[this.props.type],
      operation: this.props.type,
    })
  }

  render(): ReactNode {
    return (
      <Container fluid>
        {this.props.IsLoading && <Spinner />}
        {
          <Row>
            <Col className="formDiv col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
              <div style={{ minHeight: `calc(78vh - 70px)` }}>
                {!this.props.IsLoading && this.props.IsSuccess === '2' && (
                  <Alert variant="success" onClose={() => this.props.resetSuccessFlag()} dismissible>
                    {'Stub data saved successfully.'}
                  </Alert>
                )}
                {!this.props.IsLoading && this.props.IsSuccess === '3' && (
                  <Alert variant="danger" onClose={() => this.props.resetSuccessFlag()} dismissible>
                    {`Stub data not present for ${
                      this.props?.data?.SearchKey?.split('|')[0]
                    }. Click on create stub button to setup data.`}
                  </Alert>
                )}
                {!this.props.IsLoading && this.props.Errors && this.props.Errors.length > 0 && (
                  <Alert variant="danger" onClose={() => this.props.resetError()} dismissible>
                    <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                    {this.props.Errors.map((e, i) => {
                      return <p key={i}>{e || 'Details not available!!!'}</p>
                    })}
                  </Alert>
                )}
                <h6>{this.props.type}</h6> <br />
                <SearchForm
                  showInstruction={true}
                  initialFormData={this.initialFormData}
                  searchByAccountNbr={this.props.type === OrangeServiceType.ChargeOrderResponse}
                  search={() =>
                    this.props.getOrangeServiceResponse({ type: this.props.type, data: this.props.data.Form })
                  }
                  updateForm={(e) => this.props.updateOrangeSearchForm(e)}
                />
              </div>
            </Col>
            <Col className="formDiv col-xl-8 col-lg-7 col-md-6 col-sm-12 col-12">
              {this.props.data[this.props.type] !== null && this.props.data[this.props.type] !== undefined && (
                <fieldset className="border p-2">
                  <>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.loadSampleErrorResponse({ type: this.props.type })
                      }}
                      className="editXmlBtn"
                      style={{ float: 'right', marginLeft: '1em' }}
                    >
                      <strong>Load error response </strong>
                    </a>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.loadSampleSuccessResponse({ type: this.props.type })
                      }}
                      className="editXmlBtn"
                      style={{ float: 'right' }}
                    >
                      <strong>Load success Response</strong>
                    </a>
                    <AceEditor
                      name="copxmlEditor"
                      theme="github"
                      mode="json"
                      onLoad={(e) => this.onLoad(e)}
                      onChange={(e) => this.onChange(e)}
                      width="100%"
                      height="74vh"
                      fontSize={10}
                      showPrintMargin={true}
                      showGutter={true}
                      highlightActiveLine={true}
                      debounceChangePeriod={500}
                      value={
                        this.props.data[this.props.type]
                          ? JSON.stringify(this.props.data[this.props.type], null, 5)
                          : this.props.data[this.props.type]
                      }
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        readOnly: false,
                        highlightActiveLine: true,
                        highlightGutterLine: true,
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        showLineNumbers: true,
                        autoScrollEditorIntoView: true,
                        fixedWidthGutter: true,
                        animatedScroll: true,
                        wrap: true,
                      }}
                    />
                    <Button
                      variant="success"
                      type="submit"
                      style={{ margin: '0.5em', float: 'right' }}
                      onClick={() => this.onSave()}
                    >
                      {this.props.IsSuccess === '3' ? 'Create Stub' : 'Save Changes'}
                    </Button>
                  </>
                </fieldset>
              )}
            </Col>
          </Row>
        }
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrangeService)
