export default {
  query: `select distinct ca.client_account_id, ca.client_channel_id,ca.client_account_status_code,ca.client_account_nbr,ca.client_account_start_date,ca.client_account_end_date,ca.external_reference_id,ca.client_account_type_code,ca.created_system_id,ca.created_by,ca.created_date,ca.updated_by,ca.updated_date,ca.active_ind
    from asset.agreement a
    join asset.agreement_asset_xref aax on aax.agreement_id = a.agreement_id and a.active_ind = 1
    join client.client_account ca on ca.external_reference_id = a.subscription_nbr and ca.active_ind = 1
    join asset.asset a2 on a2.asset_id = aax.asset_id and a2.active_ind = 1 and a2.asset_instance_code = 'enrolled'
    where a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr order by a.created_date asc;`,
  mapper: [
    { o: 'ClientAccountId', d: 'client_account_id' },
    { o: 'ClientChannelId', d: 'client_channel_id' },
    { o: 'ClientAccountStatus', d: 'client_account_status_code' },
    { o: 'ClientAccountNbr', d: 'client_account_nbr' },
    { o: 'ClientAccountStartDate', d: 'client_account_start_date' },
    { o: 'ClientAccountEndDate', d: 'client_account_end_date' },
    { o: 'ExternalReferenceId', d: 'external_reference_id' },
    { o: 'ClientAccountType', d: 'client_account_type_code' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
