import React, { PropsWithChildren, useEffect } from 'react'
import { Routes, Route, HashRouter as Router, Outlet, Navigate } from 'react-router-dom'

import NavBar from './components/navbar'
import Startup from './containers/startup'
import Analysis from './containers/analysis'
import OrangeSystem from './containers/orange-system'
import CreateEnrollment from './containers/enrollment/create'
import UpdateEnrollment from './containers/enrollment/update'
import SagawaOrderOpration from './containers/sagawa-opration'
import OfferAndProductFeatures from './containers/configuration'

import { isClientEnvironmentSelected } from './store/selectors'
import { OrangeServiceType, SagawaOpration, SagawaOprationRoute, ApplicationRoute } from './utils/constants'

import 'bootstrap/dist/css/bootstrap.min.css'
import './application.css'

const ProtectedRoute: React.FunctionComponent<PropsWithChildren> = (props) => {
  if (!isClientEnvironmentSelected()) {
    return <Navigate to={'/'} replace />
  }
  return props.children ? <>{props.children}</> : <Outlet />
}

function Application() {
  // prevent back button navigation
  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.history.go(1)
    })
  }, [])
  return (
    <Router>
      <NavBar />
      <br />
      <Routes>
        {/* COMMON Routes */}
        <Route path={'/'} element={<Startup />} />

        {/* ACYAN Routes */}
        <Route
          path={`/${ApplicationRoute.DataAnalysis}`}
          element={
            <ProtectedRoute>
              <Analysis />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.Configuration}`}
          element={
            <ProtectedRoute>
              <OfferAndProductFeatures />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.CreateEnrollment}`}
          element={
            <ProtectedRoute>
              <CreateEnrollment type={OrangeServiceType.EnrollmentResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.UpdateEnrollment}`}
          element={
            <ProtectedRoute>
              <UpdateEnrollment type={OrangeServiceType.EnrollmentResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.CreateLoanerClaim}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.LonerOrderResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.CreateNoLoanerClaim}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.NoLonerOrderResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.SimActivation}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.ICActivationResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.ForceCompleteClaim}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.ForceCompleteResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.ChargeClaim}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.ChargeOrderResponse} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${ApplicationRoute.SubmitOrder}`}
          element={
            <ProtectedRoute>
              <OrangeSystem type={OrangeServiceType.SubmitOrderResponse} />
            </ProtectedRoute>
          }
        />

        {/* AJKK Routes */}
        <Route
          path={`/${SagawaOprationRoute.Search}`}
          element={
            <ProtectedRoute>
              <SagawaOrderOpration opration={SagawaOpration.Search} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${SagawaOprationRoute.Delete}`}
          element={
            <ProtectedRoute>
              <SagawaOrderOpration opration={SagawaOpration.Delete} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${SagawaOprationRoute.Update}`}
          element={
            <ProtectedRoute>
              <SagawaOrderOpration opration={SagawaOpration.Update} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/${SagawaOprationRoute.Create}`}
          element={
            <ProtectedRoute>
              <SagawaOrderOpration opration={SagawaOpration.Create} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  )
}

export default Application
