import { IActiveConfig, IConstants } from '../types'

import { defaultEndpointConfig as SQA1Endpoints } from './sqa1'
import { defaultEndpointConfig as SQA2Endpoints } from './sqa2'
import { defaultEndpointConfig as SQA3Endpoints } from './sqa3'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: false,
  defaultUrl: 'claim/information',
  allowLegacySearch: false,
  kibanaIndex: 'uap-ajkk-npr-api',
  kibanaGQLIndex: 'uap-ajkk-npr-cluster',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-ajkk-sqa-jpmob-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: 'b4pep9r27di1piga850jtsl0',
    userPoolId: 'ap-northeast-1_seEFiyKqL',
    identityPoolId: 'ap-northeast-1:c50c3072-d985-4301-a006-cf93865fff67',
    identityProvider: 'ajkk-sqa-jpmob-SSO',
  },
  constants: defaultConstants,
  endpoints: [
    {
      key: 'sqa1',
      value: SQA1Endpoints,
    },
    {
      key: 'sqa2',
      value: SQA2Endpoints,
    },
    {
      key: 'sqa3',
      value: SQA3Endpoints,
    },
  ],
}
