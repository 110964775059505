import { Buffer } from 'buffer/'

export class Token {
  public jwtToken: string
  public payload: { [key: string]: any }

  public constructor(token: string) {
    this.jwtToken = token || ''
    this.payload = this.decodePayload()
  }

  /**
   * @returns {string} the record's token.
   */
  public getJwtToken() {
    return this.jwtToken
  }

  /**
   * @returns {int} the token's expiration (exp member).
   */
  public getExpiration() {
    return this.payload.exp
  }

  /**
   * @returns {int} the token's "issued at" (iat member).
   */
  public getIssuedAt() {
    return this.payload.iat
  }

  /**
   * @returns {object} the token's payload.
   */
  private decodePayload() {
    const payload = this.jwtToken.split('.')[1]
    try {
      return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'))
    } catch (err) {
      return {}
    }
  }
}
