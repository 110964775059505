export default {
  query: `select distinct il.SERVICE_REQUEST_ID, ila.INTERACTION_LINE_ATTRIBUTE_ID, ila.INTERACTION_LINE_ID, ila.ATTRIBUTE_NAME, ila.ATTRIBUTE_VALUE, ila.EFFECTIVE_START_DATE, ila.EFFECTIVE_END_DATE, ila.CREATED_SYSTEM_ID, ila.CREATED_BY, ila.CREATED_DATE, ila.UPDATED_BY, ila.UPDATED_DATE, ila.BULK_UPDATED_BY, ila.BULK_UPDATED_DATE, ila.ACTIVE_IND
    from interaction.interaction_line il
    join interaction.interaction_line_attribute ila on ila.interaction_line_id = il.interaction_line_id and il.active_ind = 1 and ila.active_ind = 1
    join asset.asset a on a.asset_id = il.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by il.created_date asc, ila.created_date asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'InteractionLineAttributeId', d: 'INTERACTION_LINE_ATTRIBUTE_ID' },
    { o: 'InteractionLineId', d: 'INTERACTION_LINE_ID' },
    { o: 'AttributeName', d: 'ATTRIBUTE_NAME' },
    { o: 'AttributeValue', d: 'ATTRIBUTE_VALUE' },
    { o: 'EffectiveStartDate', d: 'EFFECTIVE_START_DATE' },
    { o: 'EffectiveEndDate', d: 'EFFECTIVE_END_DATE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
