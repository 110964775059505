import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default (props) => {
  const { onHide, onOk, message, header, data } = props
  return (
    <Modal
      centered={true}
      backdrop="static"
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="primary" onClick={() => onOk(data)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
