import { produce } from 'immer'
import { Reducer } from 'redux'
import { actions } from './actions'
import { initialState } from './initialState'
import { actions as orangeActions } from '../orange/actions'
import { actions as commonActions } from '../rootActions'

export const claimInfoReducer: Reducer<typeof initialState> = (state = initialState, { type, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case actions.LOAD_CLAIM_DATA_REQUEST:
        draft.IsLoading = true
        draft.IsSuccess = false
        draft.Errors = []
        draft.Details = null
        return draft
      case actions.LOAD_CLAIM_DATA_SUCCESS:
        draft.IsLoading = false
        draft.IsSuccess = true
        draft.Errors = []
        draft.Details = input
        return draft
      case actions.LOAD_CLAIM_DATA_ERROR:
        draft.IsLoading = false
        draft.Errors = [input.message]
        return draft
      case actions.RELEASE_LOCK_REQUEST:
        draft.IsOtherOperationLoading = true
        draft.IsOtherOperationSuccess = false
        draft.Errors = []
        return draft
      case actions.RELEASE_LOCK_SUCCESS:
        draft.IsOtherOperationLoading = false
        draft.IsOtherOperationSuccess = true
        draft.Details.horizon.Ownership.CustomerCase.data = draft.Details.horizon.Ownership.CustomerCase.data.map(
          (c) => {
            if (c.ENTITY_OWNERSHIP_ID === input.EntityOwnershipId) {
              c.ENTITY_OWNERSHIP_STATUS_CODE = 'INACTV'
            }
            return c
          }
        )
        return draft
      case actions.RELEASE_LOCK_ERROR:
        draft.IsOtherOperationLoading = false
        draft.Errors = [input.message]
        return draft
      case actions.LOAD_LOGS_REQUEST:
        draft.IsOtherOperationLoading = true
        draft.IsOtherOperationSuccess = false
        draft.Errors = []
        return draft
      case actions.LOAD_LOGS_SUCCESS:
        draft.IsOtherOperationLoading = false
        draft.IsOtherOperationSuccess = true
        draft.Details.legacy[`${input.type}Logs`][input.type].data = input.data
        draft.Details.legacy[`${input.type}Logs`][input.type].total = input.total
        return draft
      case actions.LOAD_LOGS_ERROR:
        draft.IsOtherOperationLoading = false
        draft.Errors = [input.message]
        return draft

      //reset claiminfo when orange_search_form action is called
      case orangeActions.RESET_ORANGE_SEARCH_FORM:
        draft.Details = null
        draft.IsSuccess = false
        draft.Errors = []
        return draft

      //Common Actions
      case commonActions.RESET_SUCCESS_FLAG:
        draft.IsSuccess = false
        return draft
      case commonActions.RESET_ERROR:
        draft.Errors = []
        return draft
      case commonActions.ADD_ERROR:
        return draft

      default:
        return draft
    }
  })
}
