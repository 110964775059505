import queryEnrollmentInfo from './queryEnrollmentInfo'
import queryEnrollmentAnnex from './queryEnrollmentAnnex'
import queryContact from './queryContact'
import querySubscriber from './querySubscriber'
import queryClaimInfo from './queryClaimInfo'
import queryOrderInfo from './queryOrderInfo'
import queryOrderXml from './queryOrderXml'
import queryOrderXmlStatus from './queryOrderXmlStatus'
import queryClaimNotes from './queryClaimNotes'
import querySalesTable from './querySalesTable'
import querySalesLine from './querySalesLine'
import queryAccountHold from './queryAccountHold'
import queryClaimTrace from './queryClaimTrace'
import queryAddress from './queryAddress'
import queryOrderExtention from './queryOrderExtention'
import queryClaimLock from './queryClaimLock'
import queryClaimHold from './queryClaimHold'
import queryAuPointsInfo from './queryAuPointsInfo'

export default {
  Enrollment: {
    Enrollment: queryEnrollmentInfo,
    EnrollmentAnnex: queryEnrollmentAnnex,
    Contact: queryContact,
    Subscriber: querySubscriber,
    AccounHold: queryAccountHold,
  },
  Claim: {
    Claim: queryClaimInfo,
    ClaimNote: queryClaimNotes,
    ClaimLock: queryClaimLock,
    AuPoints: queryAuPointsInfo,
    ClaimHold: queryClaimHold,
  },
  Order: {
    Order: queryOrderInfo,
    OrderExtention: queryOrderExtention,
    Address: queryAddress,
  },
  SalesOrder: {
    SalesTable: querySalesTable,
    SalesLine: querySalesLine,
  },
  Integration: {
    OrderXmlStatus: queryOrderXmlStatus,
    OrderXml: queryOrderXml,
  },
  Trace: {
    ClaimTrace: queryClaimTrace,
  },
}
