export default {
  query: `select
  xmldocument_id as XmlDocumentId,
  [xml] as XML,
  xmlschema_id as XmlSchemaId,
  reference_id as ReferenceId,
  x.created_by as CreatedBy,
  x.created_dtm as CreatedDate,
  x.modified_by as UpdatedBy,
  x.modified_dtm as UpdatedDate
from
  intelliset.dbo.xmldocuments x with(nolock)
inner join intelliset.dbo.[order] o with(nolock) on
  o.order_id = x.reference_id
inner join intelliset.dbo.claim c with(nolock) on
  c.claim_id = o.claim_id
inner join intelliset.dbo.subscriber s with(nolock) on
  s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by x.created_dtm asc;`,
  mapper: [
    { o: 'XmlDocumentId', d: 'XmlDocumentId' },
    { o: 'XML', d: 'XML' },
    { o: 'XmlSchemaId', d: 'XmlSchemaId' },
    { o: 'ReferenceId', d: 'ReferenceId' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'UpdatedBy', d: 'UpdatedBy' },
    { o: 'UpdatedDate', d: 'UpdatedDate' },
  ],
}
