export default {
  query: `select
  distinct pf.criteria_type_code as FeatureType,
  null as Criteria
from
  product.product_feature pf
inner join product.product_feature_xref pxref on
  pf.product_feature_id = pxref.feature_id
  and now() between pxref.effective_start_date and pxref.effective_end_date
  and pf.active_ind = 1
  and pf.criteria_type_code = 'SIMPLE'
  and pf.feature_type_code = :code
  and pxref.client_channel_id = :clientchannelid
union 
select
  distinct pf.criteria_type_code as FeatureType,
  fc.criteria_code as Criteria
from
  product.criteria_group cg
inner join product.feature_criteria_xref fcx on
  fcx.criteria_group_id = cg.criteria_group_id
  and cg.active_ind = 1
inner join product.feature_criteria fc on
  fc.criteria_group_id = cg.criteria_group_id
  and fc.active_ind = 1
inner join product.product_feature pf on
  pf.product_feature_id = fcx.product_feature_id
  and pf.active_ind = 1
inner join product.product_feature_xref pxref on
  1 = 1
  and pxref.feature_id = pf.product_feature_id
  and now() between pxref.effective_start_date and pxref.effective_end_date
where
  pf.feature_type_code not in ('Equipment Non Return Fee',
  'Incident Sub Type Question')
  and pf.feature_type_code = :code
  and pxref.client_channel_id = :clientchannelid
order by
  FeatureType,
  Criteria;`,
  mapper: [
    { o: 'FeatureType', d: 'FeatureType' },
    { o: 'Criteria', d: 'Criteria', unique: true },
  ],
}
