export default {
  query: `select
	distinct cco.CUSTOMER_CASE_ID,
	cco.CASE_TYPE_CODE,
	cco.SERVICE_REQUEST_STATUS_CODE,
	cco.SERVICE_REQUEST_TYPE_CODE,
	cco.CUSTOMER_CASE_NBR,
	cco.CREATED_DATE,
	cco.MODIFIED_DATE,
	cco.CASE_STATUS_CODE,
	cco.CASE_STATE_CODE,
	cco.ISRESHIP_IND,
	cco.CREATED_BY,
	cco.AGREEMENT_ID,
	cco.SUBSCRIBER_REFERNCE_ID,
	cco.EXTERNAL_REFERENCE_ID,
	cco.MODIFIED_BY,
	cco.INCIDENT_TYPE,
	cco.SR_EXTERNAL_REFERENCE_ID,
	cco.SERVICE_REQUEST_ID,
	cco.PARTIALDAMAGEDETAILS,
	cco.DISASTERAID_IND,
	cco.POWERON_IND,
	cco.REASONIDNAME,
	cco.OPERATIONAL_IND,
	cco.REPORT_DATE,
	cco.REPORTNUMBER,
	cco.DRSERVICETYPE_CODE,
	cco.CALLBACKCREATEDON,
	cco.CALLBACKREQUEST,
	cco.CALLBACKSTARTTIME,
	cco.CALLBACKENDTIME,
	cco.ENDTIME,
	cco.STARTTIME,
	cco.CALLBACKREASON,
	cco.DESIREDCALLBACKNUMBER,
	cco.CALLBACKSTATUS,
	cco.DATARECOVERY,
	cco.DRSERVICEORDERID,
	cco.CALLBACKDATE,
	cco.CALLBACKAGENT,
	cco.ASSET_ID,
	cco.STAGE_ID,
	cco.ESN,
	cco.ASU_DRAPPLICABLEMESSAGE,
	cco.EXTERNAL_REFERNCE_ID,
	cco.SHOP_CODE
from
	asset.agreement a
join asset.agreement_asset_xref aax on
	aax.agreement_id = a.agreement_id
	and a.active_ind = 1
join asset.asset a2 on
	a2.asset_id = aax.asset_id
	and a2.active_ind = 1
	and a2.asset_instance_code = 'enrolled'
join customer.customer_case_oth cco on
	cco.agreement_id = a.agreement_id
where
	a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr
order by
	cco.created_date asc limit 100;`,
  mapper: [
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'CaseType', d: 'CASE_TYPE_CODE' },
    { o: 'ServiceRequestStatus', d: 'SERVICE_REQUEST_STATUS_CODE' },
    { o: 'ServiceRequestType', d: 'SERVICE_REQUEST_TYPE_CODE' },
    { o: 'CustomerCaseNbr', d: 'CUSTOMER_CASE_NBR' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'ModifiedDate', d: 'MODIFIED_DATE' },
    { o: 'CaseStatus', d: 'CASE_STATUS_CODE' },
    { o: 'CaseState', d: 'CASE_STATE_CODE' },
    { o: 'IsreshipInd', d: 'ISRESHIP_IND' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'SubscriberRefernceId', d: 'SUBSCRIBER_REFERNCE_ID' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ModifiedBy', d: 'MODIFIED_BY' },
    { o: 'IncidentType', d: 'INCIDENT_TYPE' },
    { o: 'SrExternalReferenceId', d: 'SR_EXTERNAL_REFERENCE_ID' },
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'Partialdamagedetails', d: 'PARTIALDAMAGEDETAILS' },
    { o: 'DisasteraidInd', d: 'DISASTERAID_IND' },
    { o: 'PoweronInd', d: 'POWERON_IND' },
    { o: 'Reasonidname', d: 'REASONIDNAME' },
    { o: 'OperationalInd', d: 'OPERATIONAL_IND' },
    { o: 'ReportDate', d: 'REPORT_DATE' },
    { o: 'Reportnumber', d: 'REPORTNUMBER' },
    { o: 'Drservicetype', d: 'DRSERVICETYPE_CODE' },
    { o: 'Callbackcreatedon', d: 'CALLBACKCREATEDON' },
    { o: 'Callbackrequest', d: 'CALLBACKREQUEST' },
    { o: 'Callbackstarttime', d: 'CALLBACKSTARTTIME' },
    { o: 'Callbackendtime', d: 'CALLBACKENDTIME' },
    { o: 'Endtime', d: 'ENDTIME' },
    { o: 'Starttime', d: 'STARTTIME' },
    { o: 'Callbackreason', d: 'CALLBACKREASON' },
    { o: 'Desiredcallbacknumber', d: 'DESIREDCALLBACKNUMBER' },
    { o: 'Callbackstatus', d: 'CALLBACKSTATUS' },
    { o: 'Datarecovery', d: 'DATARECOVERY' },
    { o: 'Drserviceorderid', d: 'DRSERVICEORDERID' },
    { o: 'Callbackdate', d: 'CALLBACKDATE' },
    { o: 'Callbackagent', d: 'CALLBACKAGENT' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'StageId', d: 'STAGE_ID' },
    { o: 'Esn', d: 'ESN' },
    { o: 'AsuDrapplicablemessage', d: 'ASU_DRAPPLICABLEMESSAGE' },
    { o: 'ExternalRefernceId', d: 'EXTERNAL_REFERNCE_ID' },
    { o: 'Shop', d: 'SHOP_CODE' },
  ],
}
