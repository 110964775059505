import { IActiveConfig, IConstants } from '../types'

import { defaultEndpointConfig as TRN1Endpoints } from './trn1'
import { defaultEndpointConfig as TRN2Endpoints } from './trn2'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: 'uap-acyan-prd-api',
  kibanaGQLIndex: 'uap-acyan-prd-cluster',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-trn-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '5fc3d8qim1jje5efg0fs5mvju9',
    userPoolId: 'ap-northeast-1_RW1Mjt0qt',
    identityPoolId: '',
    identityProvider: 'acyan-trn-SSO',
  },
  constants: defaultConstants,
  endpoints: [
    {
      key: 'trn1',
      value: TRN1Endpoints,
    },
    {
      key: 'trn2',
      value: TRN2Endpoints,
    },
  ],
}
