export default {
  query: `select distinct sr.SERVICE_REQUEST_ID, co.CHARGE_ORDER_ID, co.PAYMENT_METHOD_TYPE_CODE, co.CURRENCY_CODE, co.CHARGE_ORDER_STATUS_CODE, co.TOTAL_AMOUNT, co.TAX_AMOUNT, co.EXTERNAL_REFERENCE_ID, co.EXTERNAL_SYSTEM_NAME, co.OVERRIDE_BY, co.OVERRIDE_TIME, co.OVERRIDE_REASON_CODE, co.OVERRIDE_REMARK_TEXT, co.PCI_TOKEN, co.CHARGE_ORDER_TYPE_CODE, co.CARD_TYPE_CODE, co.CARD_CHECK_NBR, co.ADDRESS_ID, co.PRE_AUTH_AMOUNT, co.ADDITIONAL_CHARGE_AUTH_IND, co.CARD_HOLDER_NAME, co.IDENTIFICATION_TYPE_CODE, co.IDENTIFICATION_NBR, co.CANCELLATION_REASON_CODE, co.CANCELLATION_SUB_REASON_CODE, co.CARD_BRAND_CODE, co.DISCOUNT_CODE, co.CREATED_SYSTEM_ID, co.CREATED_BY, co.CREATED_DATE, co.UPDATED_BY, co.UPDATED_DATE, co.BULK_UPDATED_BY, co.BULK_UPDATED_DATE, co.ACTIVE_IND
    from customer.service_request sr
    join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
    join customer.charge_order co on co.charge_order_id = ccc.item_id and co.active_ind = 1
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by co.created_date asc;`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ChargeOrderId', d: 'CHARGE_ORDER_ID' },
    { o: 'PaymentMethodType', d: 'PAYMENT_METHOD_TYPE_CODE' },
    { o: 'Currency', d: 'CURRENCY_CODE' },
    { o: 'ChargeOrderStatus', d: 'CHARGE_ORDER_STATUS_CODE' },
    { o: 'TotalAmount', d: 'TOTAL_AMOUNT' },
    { o: 'TaxAmount', d: 'TAX_AMOUNT' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ExternalSystemName', d: 'EXTERNAL_SYSTEM_NAME' },
    { o: 'OverrideBy', d: 'OVERRIDE_BY' },
    { o: 'OverrideTime', d: 'OVERRIDE_TIME' },
    { o: 'OverrideReason', d: 'OVERRIDE_REASON_CODE' },
    { o: 'OverrideRemarkText', d: 'OVERRIDE_REMARK_TEXT' },
    { o: 'PciToken', d: 'PCI_TOKEN' },
    { o: 'ChargeOrderType', d: 'CHARGE_ORDER_TYPE_CODE' },
    { o: 'CardType', d: 'CARD_TYPE_CODE' },
    { o: 'CardCheckNbr', d: 'CARD_CHECK_NBR' },
    { o: 'AddressId', d: 'ADDRESS_ID' },
    { o: 'PreAuthAmount', d: 'PRE_AUTH_AMOUNT' },
    { o: 'AdditionalChargeAuthInd', d: 'ADDITIONAL_CHARGE_AUTH_IND' },
    { o: 'CardHolderName', d: 'CARD_HOLDER_NAME' },
    { o: 'IdentificationType', d: 'IDENTIFICATION_TYPE_CODE' },
    { o: 'IdentificationNbr', d: 'IDENTIFICATION_NBR' },
    { o: 'CancellationReason', d: 'CANCELLATION_REASON_CODE' },
    { o: 'CancellationSubReason', d: 'CANCELLATION_SUB_REASON_CODE' },
    { o: 'CardBrand', d: 'CARD_BRAND_CODE' },
    { o: 'Discount', d: 'DISCOUNT_CODE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
