import * as Yup from 'yup'
import { SagawaOpration } from '../utils/constants'

export const searchOrderSchema = Yup.object({
  ServiceOrderNumber: Yup.string().length(8).required('Required'),
})
export const updateOrderSchema = Yup.object({
  ServiceOrderNumber: Yup.string().length(8).required('Required'),
  OrderStatus: Yup.string().required('Required'),
})

export const getFormSchema = (opration) => {
  switch (true) {
    case opration === SagawaOpration.Search:
    case opration === SagawaOpration.Create:
    case opration === SagawaOpration.Delete:
      return searchOrderSchema
    case opration === SagawaOpration.Update:
      return updateOrderSchema
    default:
      return ''
  }
}
