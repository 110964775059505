export default {
  query: `
	select
		ax.SubId,
		ax.Purchase_Date as PurchaseDate,
		ax.Reference_mdn as ReferenceMDN,
		ax.Email_Address as EmailAddress,
		ax.SerialNumber2 as SerialNumber2,
		ax.Network_Type as NetworkType,
		ax.ProductCode,
		ax.FName_Kana as FNameKana,
		ax.LName_Kana as LNameKana,
		ax.Home_Phone as HomePhone,
		ax.BillingFName_Kanji as BillingFNameKanji,
		ax.BillingLName_Kanji as BillingLNameKanji,
		ax.BillingFName_Kana as BillingFNameKana,
		ax.BillingLName_Kana as BillingLNameKana,
		ax.BillingAddress as BillingAddress,
		ax.BillingCity as BillingCity,
		ax.BillingState as BillingState,
		ax.BillingZip as BillingZip,
		ax.BillingPhoneNumber as BillingPhoneNumber,
		ax.FirstUseDate as FirstUseDate,
		ax.FirstPurchaseDate as FirstPurchaseDate,
		ax.WarrantyStartDate as WarrantyStartDate,
		ax.EnrollmentCancelDate as EnrollmentCancelDate,
		ax.Notes as Notes,
		ax.Emergency as Emergency,
		ax.PointsAvailable COLLATE Japanese_90_CI_AS_KS as PointsAvailable,
		ax.Existing_Repair_Flg as ExistingRepairFlg,
		ax.Sim_Card_Version as SimCardVersion,
		ax.Sim_Card_Type as SimCardType,
		ax.Discount_Flg as DiscountFlg,
		ax.LTE_Flg as LTE_Flg,
		ax.User_Type as User_Type,
		ax.Channel as Channel,
		ax.ClientColor as ClientColor,
		ax.ApplicationNumber as ApplicationNumber,
		ax.ShopCode as ShopCode,
		ax.upgrade_apply_status as UpgradeApplyStatus,
		ax.upgrade_advance_status as UpgradeAdvanceStatus,
		ax.upgrade_advance_start as UpgradeAdvanceStart,
		ax.upgrade_apply_start as UpgradeApplyStart,
		ax.upgrade_apply_end as UpgradeApplyEnd,
		ax.KappuRemainMonths as KappuRemainMonths,
		ax.ActiveTsukasapo as ActiveTsukasapo,
		ax.Tsukasapofromdate as TsukasapoFromDate,
		ax.Tsukasapoendate as TsukasapoEndDate,
		ax.ESimPlanType as ESimPlanType,
		ax.ESimProfileDownloadStatus as ESimProfileDownloadStatus,
		ax.device_reg_date as DeviceRegDate,
		ax.termination_date as TerminationDate,
		'' as TerminationReason,
		'' as DeviceRegModifiedDate,
		ax.OSVer,
		ax.MVNO_Type as MVNOType,
		ax.CreatedUser,
		ax.ModifiedUser,
		ax.InsertDt as CreatedDate,
		ax.ModifiedBy as ModifiedBy,
		ax.ModifiedDt as ModifiedDate
	from
		hsenroll..enrollment_annex_naksp ax with(nolock)
	inner join 
		hsenroll..enrollment_naksp e with(nolock) on ax.subid = e.subid
	where
		cell_number = @mdn or account_number = @subnbr
	union all
	select
		ax.SubId,
		ax.Purchase_Date as PurchaseDate,
		ax.Reference_mdn as ReferenceMDN,
		ax.Email_Address as EmailAddress,
		ax.SerialNumber2 as SerialNumber2,
		ax.Network_Type as NetworkType,
		ax.ProductCode,
		ax.FName_Kana as FNameKana,
		ax.LName_Kana as LNameKana,
		ax.Home_Phone as HomePhone,
		ax.BillingFName_Kanji as BillingFNameKanji,
		ax.BillingLName_Kanji as BillingLNameKanji,
		ax.BillingFName_Kana as BillingFNameKana,
		ax.BillingLName_Kana as BillingLNameKana,
		ax.BillingAddress as BillingAddress,
		ax.BillingCity as BillingCity,
		ax.BillingState as BillingState,
		ax.BillingZip as BillingZip,
		ax.BillingPhoneNumber as BillingPhoneNumber,
		ax.FirstUseDate as FirstUseDate,
		ax.FirstPurchaseDate as FirstPurchaseDate,
		ax.WarrantyStartDate as WarrantyStartDate,
		ax.EnrollmentCancelDate as EnrollmentCancelDate,
		ax.Notes as Notes,
		ax.Emergency as Emergency,
		ax.PointsAvailable as PointsAvailable,
		ax.Existing_Repair_Flg as ExistingRepairFlg,
		ax.Sim_Card_Version as SimCardVersion,
		ax.Sim_Card_Type as SimCardType,
		ax.Discount_Flg as DiscountFlg,
		ax.LTE_Flg as LTE_Flg,
		ax.User_Type as User_Type,
		ax.Channel as Channel,
		ax.ClientColor as ClientColor,
		ax.ApplicationNumber as ApplicationNumber,
		ax.ShopCode as ShopCode,
		ax.upgrade_apply_status as UpgradeApplyStatus,
		ax.upgrade_advance_status as UpgradeAdvanceStatus,
		ax.upgrade_advance_start as UpgradeAdvanceStart,
		ax.upgrade_apply_start as UpgradeApplyStart,
		ax.upgrade_apply_end as UpgradeApplyEnd,
		ax.KappuRemainMonths as KappuRemainMonths,
		ax.ActiveTsukasapo as ActiveTsukasapo,
		ax.Tsukasapofromdate as TsukasapoFromDate,
		ax.Tsukasapoendate as TsukasapoEndDate,
		ax.ESimPlanType as ESimPlanType,
		ax.ESimProfileDownloadStatus as ESimProfileDownloadStatus,
		ax.device_reg_date as DeviceRegDate,
		ax.termination_date as TerminationDate,
		'' as TerminationReason,
		ax.device_reg_modified_dt as DeviceRegModifiedDate,
		ax.OSVer,
		ax.MVNO_Type as MVNOType,
		ax.CreatedUser,
		ax.ModifiedUser,
		ax.InsertDt as CreatedDate,
		ax.ModifiedBy as ModifiedBy,
		ax.ModifiedDt as ModifiedDate
	from
		hsenroll..enrollment_annex ax with(nolock)
	inner join 
		hsenroll..enrollment e with(nolock) on ax.subid = e.subid
	where
		cell_number = @mdn or account_number = @subnbr
	order by CreatedDate asc;`,
  mapper: [
    { o: 'SubId', d: 'SubId' },
    { o: 'PurchaseDate', d: 'PurchaseDate' },
    { o: 'ReferenceMDN', d: 'ReferenceMDN' },
    { o: 'EmailAddress', d: 'EmailAddress' },
    { o: 'SerialNumber2', d: 'SerialNumber2' },
    { o: 'NetworkType', d: 'NetworkType' },
    { o: 'ProductCode', d: 'ProductCode' },
    { o: 'FNameKana', d: 'FNameKana' },
    { o: 'LNameKana', d: 'LNameKana' },
    { o: 'HomePhone', d: 'HomePhone' },
    { o: 'BillingFNameKanji', d: 'BillingFNameKanji' },
    { o: 'BillingLNameKanji', d: 'BillingLNameKanji' },
    { o: 'BillingFNameKana', d: 'BillingFNameKana' },
    { o: 'BillingLNameKana', d: 'BillingLNameKana' },
    { o: 'BillingAddress', d: 'BillingAddress' },
    { o: 'BillingCity', d: 'BillingCity' },
    { o: 'BillingState', d: 'BillingState' },
    { o: 'BillingZip', d: 'BillingZip' },
    { o: 'BillingPhoneNumber', d: 'BillingPhoneNumber' },
    { o: 'FirstUseDate', d: 'FirstUseDate' },
    { o: 'FirstPurchaseDate', d: 'FirstPurchaseDate' },
    { o: 'WarrantyStartDate', d: 'WarrantyStartDate' },
    { o: 'EnrollmentCancelDate', d: 'EnrollmentCancelDate' },
    { o: 'Notes', d: 'Notes' },
    { o: 'Emergency', d: 'Emergency' },
    { o: 'PointsAvailable', d: 'PointsAvailable' },
    { o: 'ExistingRepairFlg', d: 'ExistingRepairFlg' },
    { o: 'SimCardVersion', d: 'SimCardVersion' },
    { o: 'SimCardType', d: 'SimCardType' },
    { o: 'DiscountFlg', d: 'DiscountFlg' },
    { o: 'LTE_Flg', d: 'LTE_Flg' },
    { o: 'User_Type', d: 'User_Type' },
    { o: 'Channel', d: 'Channel' },
    { o: 'ClientColor', d: 'ClientColor' },
    { o: 'ApplicationNumber', d: 'ApplicationNumber' },
    { o: 'ShopCode', d: 'ShopCode' },
    { o: 'UpgradeApplyStatus', d: 'UpgradeApplyStatus' },
    { o: 'UpgradeAdvanceStatus', d: 'UpgradeAdvanceStatus' },
    { o: 'UpgradeAdvanceStart', d: 'UpgradeAdvanceStart' },
    { o: 'UpgradeApplyStart', d: 'UpgradeApplyStart' },
    { o: 'UpgradeApplyEnd', d: 'UpgradeApplyEnd' },
    { o: 'KappuRemainMonths', d: 'KappuRemainMonths' },
    { o: 'ActiveTsukasapo', d: 'ActiveTsukasapo' },
    { o: 'TsukasapoFromDate', d: 'TsukasapoFromDate' },
    { o: 'TsukasapoEndDate', d: 'TsukasapoEndDate' },
    { o: 'ESimPlanType', d: 'ESimPlanType' },
    { o: 'ESimProfileDownloadStatus', d: 'ESimProfileDownloadStatus' },
    { o: 'DeviceRegDate', d: 'DeviceRegDate' },
    { o: 'TerminationDate', d: 'TerminationDate' },
    { o: 'TerminationReason', d: 'TerminationReason' },
    { o: 'DeviceRegModifiedDate', d: 'DeviceRegModifiedDate' },
    { o: 'OSVer', d: 'OSVer' },
    { o: 'MVNOType', d: 'MVNOType' },
    { o: 'CreatedUser', d: 'CreatedUser' },
    { o: 'ModifiedUser', d: 'ModifiedUser' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
  ],
}
