import axios from './axios'
import { BaseAPI } from './base-api'

class KDDIAPI extends BaseAPI {
  public searchData = (request: any) => {
    return axios
      .post(`${this.getConfigKey('baseURL')}${this.endpoints.dal}`, request, {
        headers: this.headers(),
      })
      .catch(() => {
        //igonre error message for data sync
        return {}
      })
  }
}

export default KDDIAPI
