export default {
  query: `select
  sl.*
from
  Axapta..SALESline sl with(nolock)
inner join Axapta..SALESTABLE st with(nolock) on
  sl.salesID = st.salesID
inner join intelliset.dbo.claim c with(nolock) on
  c.claim_id = st.asu_claimid
inner join intelliset.dbo.subscriber s with(nolock) on
  s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr`,
  mapper: [
    { o: 'SALESID', d: 'SALESID' },
    { o: 'LINENUM', d: 'LINENUM' },
    { o: 'ITEMID', d: 'ITEMID' },
    { o: 'SALESSTATUS', d: 'SALESSTATUS' },
    { o: 'LEDGERACCOUNT', d: 'LEDGERACCOUNT' },
    { o: 'NAME', d: 'NAME' },
    { o: 'EXTERNALITEMID', d: 'EXTERNALITEMID' },
    { o: 'QTYORDERED', d: 'QTYORDERED' },
    { o: 'SALESDELIVERNOW', d: 'SALESDELIVERNOW' },
    { o: 'REMAINSALESPHYSICAL', d: 'REMAINSALESPHYSICAL' },
    { o: 'REMAINSALESFINANCIAL', d: 'REMAINSALESFINANCIAL' },
    { o: 'COSTPRICE', d: 'COSTPRICE' },
    { o: 'SALESPRICE', d: 'SALESPRICE' },
    { o: 'CURRENCYCODE', d: 'CURRENCYCODE' },
    { o: 'LINEAMOUNT', d: 'LINEAMOUNT' },
    { o: 'CONFIRMEDDLV', d: 'CONFIRMEDDLV' },
    { o: 'RESERVATION', d: 'RESERVATION' },
    { o: 'SALESUNIT', d: 'SALESUNIT' },
    { o: 'PRICEUNIT', d: 'PRICEUNIT' },
    { o: 'PROJTRANSID', d: 'PROJTRANSID' },
    { o: 'INVENTTRANSID', d: 'INVENTTRANSID' },
    { o: 'CUSTGROUP', d: 'CUSTGROUP' },
    { o: 'CUSTACCOUNT', d: 'CUSTACCOUNT' },
    { o: 'SALESQTY', d: 'SALESQTY' },
    { o: 'SALESMARKUP', d: 'SALESMARKUP' },
    { o: 'INVENTDELIVERNOW', d: 'INVENTDELIVERNOW' },
    { o: 'SALESTYPE', d: 'SALESTYPE' },
    { o: 'BLOCKED', d: 'BLOCKED' },
    { o: 'COMPLETE', d: 'COMPLETE' },
    { o: 'REMAININVENTPHYSICAL', d: 'REMAININVENTPHYSICAL' },
    { o: 'BARCODE', d: 'BARCODE' },
    { o: 'BARCODETYPE', d: 'BARCODETYPE' },
    { o: 'INVENTREFTRANSID', d: 'INVENTREFTRANSID' },
    { o: 'INVENTREFTYPE', d: 'INVENTREFTYPE' },
    { o: 'INVENTREFID', d: 'INVENTREFID' },
    { o: 'INTERCOMPANYORIGIN', d: 'INTERCOMPANYORIGIN' },
    { o: 'ITEMROUTEID', d: 'ITEMROUTEID' },
    { o: 'LINEHEADER', d: 'LINEHEADER' },
    { o: 'SCRAP', d: 'SCRAP' },
    { o: 'DLVMODE', d: 'DLVMODE' },
    { o: 'INVENTTRANSIDRETURN', d: 'INVENTTRANSIDRETURN' },
    { o: 'PROJCATEGORYID', d: 'PROJCATEGORYID' },
    { o: 'PROJID', d: 'PROJID' },
    { o: 'INVENTDIMID', d: 'INVENTDIMID' },
    { o: 'PROJLINEPROPERTYID', d: 'PROJLINEPROPERTYID' },
    { o: 'CUSTOMERLINENUM', d: 'CUSTOMERLINENUM' },
    { o: 'INTERCOMPANYINVENTTRANSID', d: 'INTERCOMPANYINVENTTRANSID' },
    { o: 'REMAININVENTFINANCIAL', d: 'REMAININVENTFINANCIAL' },
    { o: 'DELIVERYTYPE', d: 'DELIVERYTYPE' },
    { o: 'CUSTOMERREF', d: 'CUSTOMERREF' },
    { o: 'PURCHORDERFORMNUM', d: 'PURCHORDERFORMNUM' },
    { o: 'BLANKETREFTRANSID', d: 'BLANKETREFTRANSID' },
    { o: 'SHIPPINGDATEREQUESTED', d: 'SHIPPINGDATEREQUESTED' },
    { o: 'SHIPPINGDATECONFIRMED', d: 'SHIPPINGDATECONFIRMED' },
    { o: 'ADDRESSREFRECID', d: 'ADDRESSREFRECID' },
    { o: 'ADDRESSREFTABLEID', d: 'ADDRESSREFTABLEID' },
    { o: 'SERVICEORDERID', d: 'SERVICEORDERID' },
    { o: 'EINVOICEACCOUNTCODE', d: 'EINVOICEACCOUNTCODE' },
    { o: 'ACTIVITYNUMBER', d: 'ACTIVITYNUMBER' },
    { o: 'RETURNALLOWRESERVATION', d: 'RETURNALLOWRESERVATION' },
    { o: 'ITEMPBAID', d: 'ITEMPBAID' },
    { o: 'CREATEDDATETIME', d: 'CREATEDDATETIME' },
    { o: 'DATAAREAID', d: 'DATAAREAID' },
    { o: 'RECVERSION', d: 'RECVERSION' },
    { o: 'RECID', d: 'RECID' },
    { o: 'DIMENSION', d: 'DIMENSION' },
    { o: 'DIMENSION2_', d: 'DIMENSION2_' },
    { o: 'DIMENSION3_', d: 'DIMENSION3_' },
    { o: 'DIMENSION4_', d: 'DIMENSION4_' },
    { o: 'DIMENSION5_', d: 'DIMENSION5_' },
    { o: 'ASU_SID', d: 'ASU_SID' },
    { o: 'ASU_ESN', d: 'ASU_ESN' },
    { o: 'ASU_CELLNUMBER', d: 'ASU_CELLNUMBER' },
    { o: 'ASU_MOBILEIDN', d: 'ASU_MOBILEIDN' },
    { o: 'ASU_SHIPLINENUMBER', d: 'ASU_SHIPLINENUMBER' },
    { o: 'ASU_PURCHID', d: 'ASU_PURCHID' },
    { o: 'ASU_POID', d: 'ASU_POID' },
    { o: 'ASU_MANUFACTURERID', d: 'ASU_MANUFACTURERID' },
    { o: 'ASU_MODEL', d: 'ASU_MODEL' },
    { o: 'ASU_NEWREFURB', d: 'ASU_NEWREFURB' },
    { o: 'ASU_SHIPINSTANCE', d: 'ASU_SHIPINSTANCE' },
    { o: 'ASU_VERSION', d: 'ASU_VERSION' },
    { o: 'ASU_SHIPVENDORID', d: 'ASU_SHIPVENDORID' },
    { o: 'ASU_PROCESSFLAG', d: 'ASU_PROCESSFLAG' },
    { o: 'ASU_WIRELESSCARRIER', d: 'ASU_WIRELESSCARRIER' },
    { o: 'TMPUPDATE', d: 'TMPUPDATE' },
    { o: 'ENTMARKETID', d: 'ENTMARKETID' },
    { o: 'ENTREASONCODE', d: 'ENTREASONCODE' },
    { o: 'ENTPURCHID', d: 'ENTPURCHID' },
    { o: 'ENTISRRM', d: 'ENTISRRM' },
    { o: 'ENTAPPROVEDFORINVOICING', d: 'ENTAPPROVEDFORINVOICING' },
    { o: 'ENT_RETURN', d: 'ENT_RETURN' },
    { o: 'ASU_CLAIMESN', d: 'ASU_CLAIMESN' },
    { o: 'ASU_CLAIMEQUIPID', d: 'ASU_CLAIMEQUIPID' },
    { o: 'ASU_CLAIMRECEIVEDDATE', d: 'ASU_CLAIMRECEIVEDDATE' },
    { o: 'ASU_CLAIMRECEIVEDTIME', d: 'ASU_CLAIMRECEIVEDTIME' },
    { o: 'ASU_IPADDRESS', d: 'ASU_IPADDRESS' },
    { o: 'ASU_MDN', d: 'ASU_MDN' },
    { o: 'ASU_ESNCHECKED', d: 'ASU_ESNCHECKED' },
    { o: 'ASU_INSTOREUNITPRICEADJUSTED', d: 'ASU_INSTOREUNITPRICEADJUSTED' },
    { o: 'ASU_INSTOREUNITPRICEDIRECT', d: 'ASU_INSTOREUNITPRICEDIRECT' },
    { o: 'ASU_NOTINSTOCK', d: 'ASU_NOTINSTOCK' },
    { o: 'CUSTOMSNAME_MX', d: 'CUSTOMSNAME_MX' },
    { o: 'CUSTOMSDOCNUMBER_MX', d: 'CUSTOMSDOCNUMBER_MX' },
    { o: 'CUSTOMSDOCDATE_MX', d: 'CUSTOMSDOCDATE_MX' },
    { o: 'PROPERTYNUMBER_MX', d: 'PROPERTYNUMBER_MX' },
    { o: 'TAXATIONORIGIN', d: 'TAXATIONORIGIN' },
    { o: 'CUSTRECEIPTISSUECODE', d: 'CUSTRECEIPTISSUECODE' },
    { o: 'CUSTCFOPID', d: 'CUSTCFOPID' },
    { o: 'CUSTFISCALCLASSIFICATIONID', d: 'CUSTFISCALCLASSIFICATIONID' },
    { o: 'WITHOUTINVENTORY', d: 'WITHOUTINVENTORY' },
    { o: 'SALESPURCH', d: 'SALESPURCH' },
    { o: 'DELIVERYDISTRICTNAME', d: 'DELIVERYDISTRICTNAME' },
    { o: 'SUFRAMADISCOUNT', d: 'SUFRAMADISCOUNT' },
    { o: 'DELIVERYADDRESSNUMBER', d: 'DELIVERYADDRESSNUMBER' },
    { o: 'DELIVERYADDRESSCOMPLEMENT', d: 'DELIVERYADDRESSCOMPLEMENT' },
    { o: 'TAXSERVICECODEID', d: 'TAXSERVICECODEID' },
    { o: 'TAXINVENTVATCOMMODITYCODEID_IN', d: 'TAXINVENTVATCOMMODITYCODEID_IN' },
    { o: 'EXCISETARIFFCODE_IN', d: 'EXCISETARIFFCODE_IN' },
    { o: 'CUSTOMSTARIFFCODE_IN', d: 'CUSTOMSTARIFFCODE_IN' },
    { o: 'SALESTAXFORMTYPE_IN', d: 'SALESTAXFORMTYPE_IN' },
    { o: 'ASSESSABLEVALUE_IN', d: 'ASSESSABLEVALUE_IN' },
    { o: 'MAXIMUMRETAILPRICE_IN', d: 'MAXIMUMRETAILPRICE_IN' },
    { o: 'NAME_IN', d: 'NAME_IN' },
    { o: 'ADDRESS_IN', d: 'ADDRESS_IN' },
    { o: 'ECCNUMBER_IN', d: 'ECCNUMBER_IN' },
    { o: 'SALESTAXREGISTRATIONNUMBER_IN', d: 'SALESTAXREGISTRATIONNUMBER_IN' },
    { o: 'TIN_IN', d: 'TIN_IN' },
    { o: 'STCNUMBER_IN', d: 'STCNUMBER_IN' },
    { o: 'IECNUMBER_IN', d: 'IECNUMBER_IN' },
    { o: 'EXCISERECORDTYPE_IN', d: 'EXCISERECORDTYPE_IN' },
    { o: 'SERVICECODE_IN', d: 'SERVICECODE_IN' },
    { o: 'CONSIGNMENT_IN', d: 'CONSIGNMENT_IN' },
    { o: 'DSA_IN', d: 'DSA_IN' },
    { o: 'DIRECTSETTLEMENT_IN', d: 'DIRECTSETTLEMENT_IN' },
    { o: 'EXCISETYPE_IN', d: 'EXCISETYPE_IN' },
    { o: 'STATE_IN', d: 'STATE_IN' },
    { o: 'TAXASPERORIGINALINVOICE_IN', d: 'TAXASPERORIGINALINVOICE_IN' },
    { o: 'CUSTOMSMAXRETAILPRICE_IN', d: 'CUSTOMSMAXRETAILPRICE_IN' },
    { o: 'TDSGROUP_IN', d: 'TDSGROUP_IN' },
    { o: 'TCSGROUP_IN', d: 'TCSGROUP_IN' },
    { o: 'TAN_IN', d: 'TAN_IN' },
    { o: 'CREDITNOTEDATE_IN', d: 'CREDITNOTEDATE_IN' },
    { o: 'TAXWITHHOLDLINENUM_IN', d: 'TAXWITHHOLDLINENUM_IN' },
    { o: 'TAXWITHHOLDVOUCHER_IN', d: 'TAXWITHHOLDVOUCHER_IN' },
    { o: 'DELIVERYCUSTCATEGORY', d: 'DELIVERYCUSTCATEGORY' },
    { o: 'DELIVERYCUSTCNPJCPFNUM', d: 'DELIVERYCUSTCNPJCPFNUM' },
    { o: 'DELIVERYCUSTIENUM', d: 'DELIVERYCUSTIENUM' },
    { o: 'DELIVERYCFOPID', d: 'DELIVERYCFOPID' },
    { o: 'INVOICEREFRECID', d: 'INVOICEREFRECID' },
    { o: 'EXCEPTIONCODE_BR', d: 'EXCEPTIONCODE_BR' },
    { o: 'FUELCODE_BR', d: 'FUELCODE_BR' },
    { o: 'TAXGROUP', d: 'TAXGROUP' },
    { o: 'LINEPERCENT', d: 'LINEPERCENT' },
    { o: 'LINEDISC', d: 'LINEDISC' },
    { o: 'MULTILNDISC', d: 'MULTILNDISC' },
    { o: 'MULTILNPERCENT', d: 'MULTILNPERCENT' },
    { o: 'TRANSACTIONCODE', d: 'TRANSACTIONCODE' },
    { o: 'TAXITEMGROUP', d: 'TAXITEMGROUP' },
    { o: 'TAXAUTOGENERATED', d: 'TAXAUTOGENERATED' },
    { o: 'UNDERDELIVERYPCT', d: 'UNDERDELIVERYPCT' },
    { o: 'OVERDELIVERYPCT', d: 'OVERDELIVERYPCT' },
    { o: 'ITEMBOMID', d: 'ITEMBOMID' },
    { o: 'TRANSPORT', d: 'TRANSPORT' },
    { o: 'STATPROCID', d: 'STATPROCID' },
    { o: 'PORT', d: 'PORT' },
    { o: 'RECEIPTDATEREQUESTED', d: 'RECEIPTDATEREQUESTED' },
    { o: 'PACKINGUNITQTY', d: 'PACKINGUNITQTY' },
    { o: 'PACKINGUNIT', d: 'PACKINGUNIT' },
    { o: 'DELIVERYADDRESS', d: 'DELIVERYADDRESS' },
    { o: 'DELIVERYNAME', d: 'DELIVERYNAME' },
    { o: 'DELIVERYSTREET', d: 'DELIVERYSTREET' },
    { o: 'DELIVERYZIPCODE', d: 'DELIVERYZIPCODE' },
    { o: 'DELIVERYCITY', d: 'DELIVERYCITY' },
    { o: 'DELIVERYCOUNTY', d: 'DELIVERYCOUNTY' },
    { o: 'DELIVERYSTATE', d: 'DELIVERYSTATE' },
    { o: 'DELIVERYCOUNTRYREGIONID', d: 'DELIVERYCOUNTRYREGIONID' },
    { o: 'RECEIPTDATECONFIRMED', d: 'RECEIPTDATECONFIRMED' },
    { o: 'STATTRIANGULARDEAL', d: 'STATTRIANGULARDEAL' },
    { o: 'ITEMTAGGING', d: 'ITEMTAGGING' },
    { o: 'CASETAGGING', d: 'CASETAGGING' },
    { o: 'PALLETTAGGING', d: 'PALLETTAGGING' },
    { o: 'SHIPCARRIERID', d: 'SHIPCARRIERID' },
    { o: 'SHIPCARRIERACCOUNT', d: 'SHIPCARRIERACCOUNT' },
    { o: 'SHIPCARRIERDLVTYPE', d: 'SHIPCARRIERDLVTYPE' },
    { o: 'SHIPCARRIERACCOUNTCODE', d: 'SHIPCARRIERACCOUNTCODE' },
    { o: 'DELIVERYDATECONTROLTYPE', d: 'DELIVERYDATECONTROLTYPE' },
    { o: 'ATPINCLPLANNEDORDERS', d: 'ATPINCLPLANNEDORDERS' },
    { o: 'ATPTIMEFENCE', d: 'ATPTIMEFENCE' },
    { o: 'ITEMREPLACED', d: 'ITEMREPLACED' },
    { o: 'RETURNDEADLINE', d: 'RETURNDEADLINE' },
    { o: 'EXPECTEDRETQTY', d: 'EXPECTEDRETQTY' },
    { o: 'RETURNSTATUS', d: 'RETURNSTATUS' },
    { o: 'RETURNARRIVALDATE', d: 'RETURNARRIVALDATE' },
    { o: 'RETURNCLOSEDDATE', d: 'RETURNCLOSEDDATE' },
    { o: 'RETURNDISPOSITIONCODEID', d: 'RETURNDISPOSITIONCODEID' },
    { o: 'MODIFIEDDATETIME', d: 'MODIFIEDDATETIME' },
    { o: 'MODIFIEDBY', d: 'MODIFIEDBY' },
    { o: 'CREATEDBY', d: 'CREATEDBY' },
    { o: 'ASU_ENROLLESN', d: 'ASU_ENROLLESN' },
  ],
}
