export enum actions {
  REQUEST_CREATE_ENROLLMENT = 'REQUEST_CREATE_ENROLLMENT',
  SUCCESS_CREATE_ENROLLMENT = 'SUCCESS_CREATE_ENROLLMENT',
  ERROR_CREATE_ENROLLMENT = 'ERROR_CREATE_ENROLLMENT',

  UPDATE_FORM_FIELDS = 'UPDATE_FORM_FIELDS',
  UPDATE_ENROLLMENT_XML = 'UPDATE_ENROLLMENT_XML',
  TOGGLE_EDIT_XML_FLAG = 'TOGGLE_EDIT_XML_FLAG',
  RESET_ENROLLMENT_FORM = 'RESET_ENROLLMENT_FORM',

  REQUEST_GENERATE_RANDOM_ID = 'REQUEST_GENERATE_RANDOM_ID',
  SUCCESS_GENERATE_RANDOM_ID = 'SUCCESS_GENERATE_RANDOM_ID',
  ERROR_GENERATE_RANDOM_ID = 'ERROR_GENERATE_RANDOM_ID',

  ADD_DEVICES = 'ADD_DEVICES',
}

export const pascalActionCreator = {
  startGenerateMDN: () => ({
    type: actions.REQUEST_GENERATE_RANDOM_ID,
  }),
  endGenerateMDN: () => ({
    type: actions.SUCCESS_GENERATE_RANDOM_ID,
  }),
  createEnrollment: (input: any) => ({
    type: actions.REQUEST_CREATE_ENROLLMENT,
    input,
  }),
  creatEnrollmentSucess: (input: any) => ({
    type: actions.SUCCESS_CREATE_ENROLLMENT,
    input,
  }),
  createEnrollmentFail: (error: any) => ({
    type: actions.ERROR_CREATE_ENROLLMENT,
    input: error,
  }),
  updateEnrollmentForm: (input: any) => ({
    type: actions.UPDATE_FORM_FIELDS,
    input,
  }),
  updateEnrollmentXML: (input: any) => ({
    type: actions.UPDATE_ENROLLMENT_XML,
    input,
  }),
  resetEnrollmentForm: () => ({
    type: actions.RESET_ENROLLMENT_FORM,
  }),
  toggleEditXML: () => ({
    type: actions.TOGGLE_EDIT_XML_FLAG,
  }),
}
