export default {
  query: `select distinct il.INTERACTION_LINE_ID, il.INTERACTION_ID, il.CLIENT_CHANNEL_ID, il.CUSTOMER_ID, il.SERVICE_REQUEST_ID, il.CLIENT_ACCOUNT_ID, il.CUSTOMER_CASE_ID, il.ASSET_ID, il.AGREEMENT_ID, il.INTERACTION_LINE_STATUS_CODE, il.INTERACTION_LINE_RESULT_TYP_CD, il.INTERACTION_LINE_NBR, il.CHANNEL_NAME, il.AGENT_NAME, il.INTERACTION_LINE_START_TIME, il.INTERACTION_LINE_END_TIME, il.LANGUAGE_CODE, il.INTERACTION_LINE_WRAP_1, il.INTERACTION_LINE_WRAP_2, il.INTERACTION_LINE_WRAP_3, il.INTERACTION_LINE_WRAP_4, il.INTERACTION_LINE_WRAP_TYPE_COD, il.INTERACTION_LINE_WRAP_REMARK, il.VERIFICATION_METHOD_TYPE_CODE, il.SURVEY_OFFERED, il.BROWSER_AGENT, il.MACHINE_ID, il.SESSION_ID, il.CLIENT_ACCOUNT_TYPE_CODE, il.STORE_NBR, il.SHOP_NAME, il.PREFECTURE, il.SHOP_CODE, il.SHOP_STAFF_NAME, il.CALL_ROUTE_CODE, il.SUB_CALL_ROUTE_CODE, il.VERIFICATION_STATUS, il.SUBSTITUTE_ASSET, il.CALLER_CODE, il.SUB_CALL_ROUTE_DESC, il.INTERACTION_LINE_REASON_CODE_2, il.INTERACTION_LINE_REASON_CODE_1, il.INTERACTION_LINE_REASON_CODE, il.EXTERNAL_REFERENCE_ID, il.ACTUAL_TIME_SPENT, il.RESUME_TIME, il.CHAT_ID, il.INTERACTION_LINE_TYPE_CODE, il.INTERACTION_LINE_TYPE_DETAIL_CODE, il.ACTUAL_CALL_ROUTE, il.CALLER_NAME, il.CREATED_SYSTEM_ID, il.CREATED_BY, il.CREATED_DATE, il.UPDATED_BY, il.UPDATED_DATE, il.BULK_UPDATED_BY, il.BULK_UPDATED_DATE, il.ACTIVE_IND
    from interaction.interaction_line il
    join asset.asset a on a.asset_id = il.ASSET_ID
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by created_date asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'InteractionLineId', d: 'INTERACTION_LINE_ID' },
    { o: 'InteractionId', d: 'INTERACTION_ID' },
    { o: 'ClientChannelId', d: 'CLIENT_CHANNEL_ID' },
    { o: 'CustomerId', d: 'CUSTOMER_ID' },
    { o: 'ClientAccountId', d: 'CLIENT_ACCOUNT_ID' },
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'InteractionLineStatus', d: 'INTERACTION_LINE_STATUS_CODE' },
    { o: 'InteractionLineResultTypCd', d: 'INTERACTION_LINE_RESULT_TYP_CD' },
    { o: 'InteractionLineNbr', d: 'INTERACTION_LINE_NBR' },
    { o: 'ChannelName', d: 'CHANNEL_NAME' },
    { o: 'AgentName', d: 'AGENT_NAME' },
    { o: 'InteractionLineStartTime', d: 'INTERACTION_LINE_START_TIME' },
    { o: 'InteractionLineEndTime', d: 'INTERACTION_LINE_END_TIME' },
    { o: 'Language', d: 'LANGUAGE_CODE' },
    { o: 'InteractionLineWrap1', d: 'INTERACTION_LINE_WRAP_1' },
    { o: 'InteractionLineWrap2', d: 'INTERACTION_LINE_WRAP_2' },
    { o: 'InteractionLineWrap3', d: 'INTERACTION_LINE_WRAP_3' },
    { o: 'InteractionLineWrap4', d: 'INTERACTION_LINE_WRAP_4' },
    { o: 'InteractionLineWrapTypeCod', d: 'INTERACTION_LINE_WRAP_TYPE_COD' },
    { o: 'InteractionLineWrapRemark', d: 'INTERACTION_LINE_WRAP_REMARK' },
    { o: 'VerificationMethodType', d: 'VERIFICATION_METHOD_TYPE_CODE' },
    { o: 'SurveyOffered', d: 'SURVEY_OFFERED' },
    { o: 'BrowserAgent', d: 'BROWSER_AGENT' },
    { o: 'MachineId', d: 'MACHINE_ID' },
    { o: 'SessionId', d: 'SESSION_ID' },
    { o: 'ClientAccountType', d: 'CLIENT_ACCOUNT_TYPE_CODE' },
    { o: 'StoreNbr', d: 'STORE_NBR' },
    { o: 'ShopName', d: 'SHOP_NAME' },
    { o: 'Prefecture', d: 'PREFECTURE' },
    { o: 'Shop', d: 'SHOP_CODE' },
    { o: 'ShopStaffName', d: 'SHOP_STAFF_NAME' },
    { o: 'CallRoute', d: 'CALL_ROUTE_CODE' },
    { o: 'SubCallRoute', d: 'SUB_CALL_ROUTE_CODE' },
    { o: 'VerificationStatus', d: 'VERIFICATION_STATUS' },
    { o: 'SubstituteAsset', d: 'SUBSTITUTE_ASSET' },
    { o: 'Caller', d: 'CALLER_CODE' },
    { o: 'SubCallRouteDesc', d: 'SUB_CALL_ROUTE_DESC' },
    { o: 'InteractionLineReason2', d: 'INTERACTION_LINE_REASON_CODE_2' },
    { o: 'InteractionLineReason1', d: 'INTERACTION_LINE_REASON_CODE_1' },
    { o: 'InteractionLineReason', d: 'INTERACTION_LINE_REASON_CODE' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ActualTimeSpent', d: 'ACTUAL_TIME_SPENT' },
    { o: 'ResumeTime', d: 'RESUME_TIME' },
    { o: 'ChatId', d: 'CHAT_ID' },
    { o: 'InteractionLineType', d: 'INTERACTION_LINE_TYPE_CODE' },
    { o: 'InteractionLineTypeDetail', d: 'INTERACTION_LINE_TYPE_DETAIL_CODE' },
    { o: 'ActualCallRoute', d: 'ACTUAL_CALL_ROUTE' },
    { o: 'CallerName', d: 'CALLER_NAME' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
