export default {
  query: `SELECT
    PARAMETER_ID AS ParameterId,
    NAME AS Name,
    VALUE AS Value,
    CREATED_BY AS CreatedBy,
    CREATED_DATE AS CreatedDate,
    UPDATED_BY AS UpdatedBy,
    UPDATED_DATE AS UpdatedDate,
    ACTIVE_IND AS ActiveInd
FROM
    integration.parameter_store
WHERE
    (name LIKE 'REFER_LEGACY%' OR name IN ('FACE_API_MODE', 'YAMATO_API_MODE', 'PASCAL_API_MODE', 'ARENA_API_MODE', 'ORDER_XML_MODE', 'DEV_S_USERS', 'PASCAL_JOB_MODE'))
    AND environment = :env
    AND active_ind = 1
ORDER BY
    NAME ASC;
`,
  mapper: [
    { d: 'ParameterId', o: 'ParameterId' },
    { d: 'Name', o: 'Name' },
    { d: 'Value', o: 'Value' },
    { d: 'CreatedBy', o: 'CreatedBy' },
    { d: 'CreatedDate', o: 'CreatedDate' },
    { d: 'UpdatedBy', o: 'UpdatedBy' },
    { d: 'UpdatedDate', o: 'UpdatedDate' },
    { d: 'ActiveInd', o: 'ActiveInd' },
  ],
}
