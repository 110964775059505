export default {
  query: `select distinct sr.service_request_id, sol.service_order_line_id, sol.service_order_id, sol.service_order_line_type_code, sol.vendor_xref_id, sol.asset_id, sol.asset_catalog_id, sol.quantity, sol.shipping_order_id, sol.vendor_asset_sku_nbr, sol.service_order_line_status_code, sol.service_order_line_prirty_code, sol.vendor_item_description, sol.created_system_id, sol.created_by, sol.created_date, sol.updated_by, sol.updated_date, sol.bulk_updated_by, sol.bulk_updated_date, sol.active_ind
    from customer.service_request sr
    join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
    join customer.service_order so on so.service_order_id = ccc.item_id -- and so.active_ind = 1
    join customer.service_order_line sol on sol.service_order_id = so.service_order_id and sol.active_ind = 1
    join asset.asset a2 on a2.asset_id = sr.asset_id and a2.active_ind = 1
    where a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr order by sol.created_date asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ServiceOrderId', d: 'service_order_id' },
    { o: 'ServiceOrderLineId', d: 'service_order_line_id' },
    { o: 'ServiceOrderLineType', d: 'service_order_line_type_code' },
    { o: 'VendorXrefId', d: 'vendor_xref_id' },
    { o: 'AssetId', d: 'asset_id' },
    { o: 'AssetCatalogId', d: 'asset_catalog_id' },
    { o: 'Quantity', d: 'quantity' },
    { o: 'ShippingOrderId', d: 'shipping_order_id' },
    { o: 'VendorAssetSkuNbr', d: 'vendor_asset_sku_nbr' },
    { o: 'ServiceOrderLineStatus', d: 'service_order_line_status_code' },
    { o: 'ServiceOrderLinePrirty', d: 'service_order_line_prirty_code' },
    { o: 'VendorItemDescription', d: 'vendor_item_description' },
    { o: 'CreatedSystemId', d: 'created_system_id' },
    { o: 'CreatedBy', d: 'created_by' },
    { o: 'CreatedDate', d: 'created_date' },
    { o: 'UpdatedBy', d: 'updated_by' },
    { o: 'UpdatedDate', d: 'updated_date' },
    { o: 'BulkUpdatedBy', d: 'bulk_updated_by' },
    { o: 'BulkUpdatedDate', d: 'bulk_updated_date' },
    { o: 'ActiveInd', d: 'active_ind' },
  ],
}
