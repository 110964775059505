export default {
  query: `select distinct sr.service_request_id, qt.interaction_line_id, qt.service_request_line_id, qt.history_number, qt.questionnaire_id,
    q2.questionnaire_name, q.question_id, q.question_name, q.question_desc, a2.answer_id, a2.answer_name, a2.answer_desc, qt.comment_text, q2.questionnaire_type_code, q.question_nbr, a2.answer_nbr
    from customer.service_request sr
    join product.questionnaire_trn qt on qt.service_request_id = sr.service_request_id and sr.active_ind = 1 and qt.active_ind = 1
    join product.questionnaire q2 on q2.questionnaire_id = qt.questionnaire_id and q2.active_ind = 1
    join product.question q on q.question_id = qt.question_id and q.active_ind = 1
    join product.answer a2 on a2.answer_id = qt.answer_id and a2.active_ind = 1
    join asset.asset a on a.asset_id = sr.asset_id and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by sr.created_date asc, qt.history_number asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'service_request_id' },
    { o: 'ServiceRequestLineId', d: 'service_request_line_id' },
    { o: 'QuestionnaireId', d: 'questionnaire_id' },
    { o: 'QuestionnaireName', d: 'questionnaire_name' },
    { o: 'QuestionnaireType', d: 'questionnaire_type_code' },
    { o: 'QuestionId', d: 'question_id' },
    { o: 'QuestionName', d: 'question_name' },
    { o: 'QuestionDesc', d: 'question_desc' },
    { o: 'QuestionNumber', d: 'question_nbr' },
    { o: 'AnswerId', d: 'answer_id' },
    { o: 'AnswerName', d: 'answer_name' },
    { o: 'AnswerDesc', d: 'answer_desc' },
    { o: 'AnswerNumber', d: 'answer_nbr' },
    { o: 'CommentText', d: 'comment_text' },
    { o: 'HistoryNumber', d: 'history_number' },
  ],
}
