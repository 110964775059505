export default {
  query: ` select distinct ca.client_account_id, caa.client_account_attribute_name, caav.value
    from asset.agreement a
    join asset.agreement_asset_xref aax on aax.agreement_id = a.agreement_id and a.active_ind = 1
    join client.client_account ca on ca.external_reference_id = a.subscription_nbr and ca.active_ind = 1
    join client.client_account_attribute_value caav on caav.client_account_id = ca.client_account_id and caav.active_ind = 1
    join client.client_account_attribute caa on caa.client_account_attribute_id = caav.client_account_attribute_id and caa.active_ind = 1
    join asset.asset a2 on a2.asset_id = aax.asset_id and a2.active_ind = 1 and a2.asset_instance_code = 'enrolled'
    where a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr order by a.created_date desc;`,
  mapper: [
    { o: 'AttributeName', d: 'client_account_attribute_name' },
    { o: 'Value', d: 'value' },
  ],
}
