module.exports.ChargeOrderSuccess = {
  Result: '      ',
  CommandCodeErr: '',
  ApplicationNumberErr: '',
  CustomerCodeErr: '',
  ServiceCodeErr: '',
  BillingServiceCategoryErr: '',
  DeductibleErr: '',
  AuPointsServiceCodeErr: '',
  AuPointsServiceCategoryErr: '',
  AuPointssUsedErr: '',
  PascalCodeErr: '',
  ApplicationNumber: '',
}

module.exports.ChargeOrderError = { ErrorCode: 'KHE118' }
