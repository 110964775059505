import { IConstants, IEndpoint, IActiveConfig } from '../types'

const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: false,
  kibanaIndex: 'uap-acyan-prd-api',
  kibanaGQLIndex: 'uap-ajkk-prd-cluster',
  kibanaLink: 'https://co-japan-elk.japan.prd.aws.asurion.net',
}

const defaultEndpointConfig: IEndpoint = {
  baseURL: 'https://common-int.uap.japan.npr.aws.asurion.net/api',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-trn-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '5fc3d8qim1jje5efg0fs5mvju9',
    userPoolId: 'ap-northeast-1_RW1Mjt0qt',
    identityPoolId: '',
    identityProvider: 'acyan-trn-SSO',
  },
  constants: defaultConstants,
  endpoints: [
    {
      key: 'trn',
      value: defaultEndpointConfig,
    },
  ],
}
