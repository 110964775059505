import axios from './axios'
import { BaseAPI } from './base-api'

class SEQUENCEAPI extends BaseAPI {
  public generate = () => {
    return axios
      .post(
        `${this.getConfigKey('baseURL')}${this.endpoints.sequence}`,
        { BatchSize: 1 },
        {
          headers: this.headers(),
        }
      )
      .then((response) => {
        return response?.data?.Ids[0]?.toString() || ''
      })
      .catch(() => {
        //igonre error message for data sync
        return ''
      })
  }
}

export default SEQUENCEAPI
