export default {
  query: `select distinct a.agreement_id, a.agreement_status_code, a.client_account_id, co.CLIENT_OFFER_NAME , a.agreement_start_date, a.agreement_end_date, ap.purchase_date , ap.ORIGINAL_PURCHASE_DATE , a.termination_date, a.subscription_nbr, a.client_product_sku_nbr, a.active_ind, a.CLIENT_AGREEMENT_START_DATE, a.created_system_id, a.created_by, a.created_date, a.updated_by, a.updated_date
    from
      asset.agreement a
    join asset.agreement_asset_xref aax on
      aax.agreement_id = a.agreement_id
      and a.active_ind = 1
    join asset.asset a2 on
      a2.asset_id = aax.asset_id
      and a2.active_ind = 1
      and a2.asset_instance_code = 'enrolled'
    join asset.agreement_purchase ap on
      ap.agreement_id = a.agreement_id
      and a.active_ind = 1
      and ap.active_ind = 1
    join product.client_offer co on
      co.client_offer_id = a.client_offer_id
    where
      a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr
    order by
      a2.asset_start_date asc,
      agreement_start_date asc`,
  mapper: [
    { d: 'agreement_id', o: 'AgreementId' },
    { d: 'agreement_status_code', o: 'AgreementStatus' },
    { d: 'client_account_id', o: 'ClientAccountId' },
    { d: 'CLIENT_OFFER_NAME', o: 'ClientOfferName' },
    { d: 'agreement_start_date', o: 'AgreementStartDate' },
    { d: 'agreement_end_date', o: 'AgreementEndDate' },
    { d: 'purchase_date', o: 'PurchaseDate' },
    { d: 'ORIGINAL_PURCHASE_DATE', o: 'OriginalPurchaseDate' },
    { d: 'termination_date', o: 'TerminationDate' },
    { d: 'subscription_nbr', o: 'SubscriptionNbr' },
    { d: 'client_product_sku_nbr', o: 'ClientProductSkuNbr' },
    { d: 'CLIENT_AGREEMENT_START_DATE', o: 'ClientAgreementStartDate' },
    { d: 'created_system_id', o: 'CreatedSystemId' },
    { d: 'created_by', o: 'CreatedBy' },
    { d: 'created_date', o: 'CreatedDate' },
    { d: 'updated_by', o: 'UpdatedBy' },
    { d: 'updated_date', o: 'UpdatedDate' },
    { d: 'active_ind', o: 'ActiveInd' },
  ],
}
