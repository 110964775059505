export default {
  query: `select distinct eo.ENTITY_OWNERSHIP_ID, eo.ENTITY_OWNERSHIP_TYPE_CODE, eo.ENTITY_TYPE_CODE, eo.ENTITY_REFERENCE_ID, eo.OWNED_BY, eo.OWNER_GROUP, eo.CORRELATION_CODE, eo.OVERRIDE_BY, eo.OVERRIDE_DATE, eo.SECURITY_TOKEN, eo.ENTITY_OWNERSHIP_STATUS_CODE, eo.CREATED_SYSTEM_ID, eo.CREATED_BY, eo.CREATED_DATE, eo.UPDATED_BY, eo.UPDATED_DATE, eo.ACTIVE_IND
    from customer.entity_ownership eo 
    join customer.service_request sr on eo.entity_reference_id = sr.customer_case_id 
    join asset.asset a on a.asset_id = sr.asset_id and sr.active_ind = 1 and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by eo.created_date asc`,
  mapper: [
    { o: 'EntityOwnershipId', d: 'ENTITY_OWNERSHIP_ID' },
    { o: 'EntityOwnershipType', d: 'ENTITY_OWNERSHIP_TYPE_CODE' },
    { o: 'EntityType', d: 'ENTITY_TYPE_CODE' },
    { o: 'EntityReferenceId', d: 'ENTITY_REFERENCE_ID' },
    { o: 'OwnedBy', d: 'OWNED_BY' },
    { o: 'OwnerGroup', d: 'OWNER_GROUP' },
    { o: 'Correlation', d: 'CORRELATION_CODE' },
    { o: 'OverrideBy', d: 'OVERRIDE_BY' },
    { o: 'OverrideDate', d: 'OVERRIDE_DATE' },
    { o: 'SecurityToken', d: 'SECURITY_TOKEN' },
    { o: 'EntityOwnershipStatus', d: 'ENTITY_OWNERSHIP_STATUS_CODE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
