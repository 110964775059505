export default {
  query: `select distinct a.asset_id,aa.asset_attribute_name,aav.value
    from asset.asset a
    join asset.asset_attribute_value aav on aav.asset_id = a.asset_id and a.active_ind = 1 and aav.active_ind = 1
    join asset.asset_attribute aa on aa.asset_attribute_id = aav.asset_attribute_id and aa.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by a.asset_id, a.created_date asc`,
  mapper: [
    { o: 'AssetId', d: 'asset_id' },
    { o: 'AttributeName', d: 'asset_attribute_name' },
    { o: 'Value', d: 'value' },
  ],
}
