import axios from './axios'
import { BaseAPI } from './base-api'

class KDDIAPI extends BaseAPI {
  public syncEnrollment = (request: any) => {
    return axios.post(`${this.getConfigKey('baseURL')}${this.endpoints.syncenrolment}`, request, {
      headers: this.headers(),
    })
  }
}

export default KDDIAPI
