export default {
  query: `select distinct 
	t.tier_type_code as PlanId,
	cofa.client_offer_feature_attr_val as MonthlyFee
from
	product.client_offer co
inner join product.client_offer_feature_xref cofx on
	co.client_offer_id = cofx.client_offer_id
inner join product.client_offer_feature cof on
	cof.client_offer_feature_id = cofx.client_offer_feature_id
	and cof.client_offer_feature_type_code = 'tierprice'
inner join product.client_offer_feature_attr_map cofam on
	cofam.client_offer_feature_id = cof.client_offer_feature_id
inner join product.client_offer_feature_attr_xref cofax on
	cofax.combo_set_nbr = cofam.combo_set_nbr
	and now() between cofax.effective_start_date and cofax.effective_end_date 
inner join product.client_offer_feature_attribute cofa on
	cofa.client_offer_feature_attr_id = cofax.client_offer_feature_attr_id
	and cofa.client_offer_feature_attr_name = 'amount'
inner join product.tier t on
	t.tier_id = cofam.criteria_value
	and t.active_ind = 1
inner join client.client_channel cc on
	cc.client_channel_id = co.client_channel_id
where
	co.client_channel_id =:clientchannelid
order by
	co.client_offer_name desc,
	convert(t.tier_type_code, unsigned integer) asc;`,
  mapper: [
    { o: 'Plan Id', d: 'PlanId', unique: true },
    { o: 'Monthly Fee', d: 'MonthlyFee', unique: true },
  ],
}
