export default {
  query: `select
	sd.shippingAddressAudit_id as shippingAddressAuditId,
	sd.claim_id as ClaimId,
	sd.order_id as OrderId,
	sd.businessName as BusinessName,
	sd.businessName_ML as BusinessNameML,
	sd.lastName as LastName,
	sd.lastName_ml as LastNameML,
	sd.address1 as Address1,
	sd.address2 as Address2,
	sd.city as City,
	sd.region as Region,
	sd.country as Country,
	sd.postalCode as PostalCode,
	sd.deliveryContactNumber as DeliveryContactNumber,
	sd.email as Email,
	sd.AUShopSelected,
	sd.ShopCode,
	sd.CVSStoreCode,
	sd.CVSStoreType,
	sd.DeliveryServiceType,
	sd.DeliveryServiceFlag,
	sd.Preferred_DeliveryDate as PreferredDeliveryDate,
	sd.Preferred_DeliveryTime as PreferredDeliveryTime,
	sd.DELIVERYZONEID as DeliveryZoneId,
	sd.DELIVERYOPTION as DeliveryOption,
	sd.Preferred_DeliveryTime_4HD as PreferredDeliveryTime4HD,
	sd.FraudAddress_flg as FraudAddressFlag,
	sd.created_dtm as CreatedDate,
	sd.created_by as CreatedBy,
	sd.modified_dtm as ModifiedDate,
	sd.modified_by as ModifedBy,
	sd.deleted_flg as DeletedFlag
from
	intelliset.dbo.shippingaddress_aud sd with(nolock)
inner join intelliset.dbo.claim c with(nolock) on
	c.claim_id = sd.claim_id
inner join intelliset.dbo.subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by sd.created_dtm asc;`,
  mapper: [
    { o: 'ShippingAddressAuditId', d: 'shippingAddressAuditId' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'OrderId', d: 'OrderId' },
    { o: 'BusinessName', d: 'BusinessName' },
    { o: 'BusinessNameML', d: 'BusinessNameML' },
    { o: 'LastName', d: 'LastName' },
    { o: 'LastNameML', d: 'LastNameML' },
    { o: 'Address1', d: 'Address1' },
    { o: 'Address2', d: 'Address2' },
    { o: 'City', d: 'City' },
    { o: 'Region', d: 'Region' },
    { o: 'Country', d: 'Country' },
    { o: 'PostalCode', d: 'PostalCode' },
    { o: 'DeliveryContactNumber', d: 'DeliveryContactNumber' },
    { o: 'Email', d: 'Email' },
    { o: 'AUShopSelected', d: 'AUShopSelected' },
    { o: 'ShopCode', d: 'ShopCode' },
    { o: 'CVSStoreCode', d: 'CVSStoreCode' },
    { o: 'CVSStoreType', d: 'CVSStoreType' },
    { o: 'DeliveryServiceType', d: 'DeliveryServiceType' },
    { o: 'DeliveryServiceFlag', d: 'DeliveryServiceFlag' },
    { o: 'PreferredDeliveryDate', d: 'PreferredDeliveryDate' },
    { o: 'PreferredDeliveryTime', d: 'PreferredDeliveryTime' },
    { o: 'DeliveryZoneId', d: 'DeliveryZoneId' },
    { o: 'DeliveryOption', d: 'DeliveryOption' },
    { o: 'PreferredDeliveryTime4HD', d: 'PreferredDeliveryTime4HD' },
    { o: 'FraudAddressFlag', d: 'FraudAddressFlag' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'ModifedBy', d: 'ModifedBy' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
