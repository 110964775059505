export const defaultNoLoanerObject = [
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    RegisteredDate: '',
    ClosureDate: '',
    SalvageImei: '',
    SalvageProductCode: '',
    ShippedImei: '',
    ShippedProductCode: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    ProcessingNumber: '',
    PerilType: '',
    ShopCode: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    RegisteredDate: '',
    ClosureDate: '',
    SalvageImei: '',
    SalvageProductCode: '',
    ShippedImei: '',
    ShippedProductCode: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    ProcessingNumber: '',
    PerilType: '',
    ShopCode: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    RegisteredDate: '',
    ClosureDate: '',
    SalvageImei: '',
    SalvageProductCode: '',
    ShippedImei: '',
    ShippedProductCode: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    ProcessingNumber: '',
    PerilType: '',
    ShopCode: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    RegisteredDate: '',
    ClosureDate: '',
    SalvageImei: '',
    SalvageProductCode: '',
    ShippedImei: '',
    ShippedProductCode: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    ProcessingNumber: '',
    PerilType: '',
    ShopCode: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    RegisteredDate: '',
    ClosureDate: '',
    SalvageImei: '',
    SalvageProductCode: '',
    ShippedImei: '',
    ShippedProductCode: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    ProcessingNumber: '',
    PerilType: '',
    ShopCode: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
]

export const defaultLoanerObject = [
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    NumberOfReplacements: '',
    RegisteredDate: '',
    SalvageImei: '',
    ShippedImei: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    PerilType: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    NumberOfReplacements: '',
    RegisteredDate: '',
    SalvageImei: '',
    ShippedImei: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    PerilType: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    NumberOfReplacements: '',
    RegisteredDate: '',
    SalvageImei: '',
    ShippedImei: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    PerilType: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    NumberOfReplacements: '',
    RegisteredDate: '',
    SalvageImei: '',
    ShippedImei: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    PerilType: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
  {
    ApplicationNumber: '',
    IcCardReissue: '',
    NumberOfReplacements: '',
    RegisteredDate: '',
    SalvageImei: '',
    ShippedImei: '',
    ShippedIccId: '',
    DeductibleAmount: '',
    PointsUsed: '',
    PerilType: '',
    Plus1ProductBillingAmount: '',
    NoLoanerDiscount: '',
    WebDiscount: '',
    IcCardCharge: '',
    LossCompensationServiceAmount: '',
    LossCompensationCardExchangeAmount: '',
    Reserve4: '',
    Reserve5: '',
    Reserve6: '',
    Reserve7: '',
    KanryouKubun: '',
  },
]

export const defaultICActivationObject = {
  ContractName: '',
  OldICCID: '',
  SoldOut: '',
  NewICCID: '',
  ApplicationCategory: '',
  ApplicationName: '',
}

export const defaultForceCompleteObject = {
  ApplicationCategory: '',
  ApplicationName: '',
  ApplicationNumber: '',
  RegisteredDate: '',
  PascalID: '',
  ShopCode: '',
  PascalForcedCompletionAvailable: '',
  FamilyDiscountGroup: '',

  ApplicationCategory1: '',
  ApplicationName1: '',
  ApplicationNumber1: '',
  RegisteredDate1: '',
  PascalID1: '',
  ShopCode1: '',
  PascalForcedCompletionAvailable1: '',
  FamilyDiscountGroup1: '',

  ApplicationCategory2: '',
  ApplicationName2: '',
  ApplicationNumber2: '',
  RegisteredDate2: '',
  PascalID2: '',
  ShopCode2: '',
  PascalForcedCompletionAvailable2: '',
  FamilyDiscountGroup2: '',

  ApplicationCategory3: '',
  ApplicationName3: '',
  ApplicationNumber3: '',
  RegisteredDate3: '',
  PascalID3: '',
  ShopCode3: '',
  PascalForcedCompletionAvailable3: '',
  FamilyDiscountGroup3: '',
  ApplicationCategory4: '',
  ApplicationName4: '',
  ApplicationNumber4: '',
  RegisteredDate4: '',
  PascalID4: '',
  ShopCode4: '',
  PascalForcedCompletionAvailable4: '',
  FamilyDiscountGroup4: '',
}

export const defaultChargeOrderObject = {
  Result: '',
  CommandCodeErr: '',
  ApplicationNumberErr: '',
  CustomerCodeErr: '',
  ServiceCodeErr: '',
  BillingServiceCategoryErr: '',
  DeductibleErr: '',
  AuPointsServiceCodeErr: '',
  AuPointsServiceCategoryErr: '',
  AuPointssUsedErr: '',
  PascalCodeErr: '',
  ApplicationNumber: '',
}

export const defaultSubmitOrderObject = {
  ApplicationNumber: 'QZ9999998',
}
