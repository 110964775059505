import { IMessage, IServiceOrderRequest } from '../types'

export enum actions {
  UPDATE_ORDER_FORM_FIELDS = 'UPDATE_ORDER_FORM_FIELDS',
  RESET_ORDER_FORM = 'RESET_ORDER_FORM',
  UPDATE_LOADER = 'UPDATE_LOADER',

  GET_SERVICE_ORDER_DETAILS_REQUEST = 'GET_SERVICE_ORDER_DETAILS_REQUEST',
  GET_SERVICE_ORDER_DETAILS_SUCCESS = 'GET_SERVICE_ORDER_DETAILS_SUCCESS',
  GET_SERVICE_ORDER_DETAILS_ERROR = 'GET_SERVICE_ORDER_DETAILS_ERROR',
  RESET_SERVICE_ORDER_DETAILS_DATA = 'RESET_SERVICE_ORDER_DETAILS_DATA',

  PERFORM_SERVICE_ORDER_OPRATION = 'PERFORM_SERVICE_ORDER_OPRATION',
  PERFORM_SERVICE_ORDER_OPRATION_SUCCESS = 'PERFORM_SERVICE_ORDER_OPRATION_SUCCESS',
  PERFORM_SERVICE_ORDER_OPRATION_ERROR = 'PERFORM_SERVICE_ORDER_OPRATION_ERROR',

  SET_MESSAGE = 'SET_MESSAGE',
  RESET_MESSAGE = 'RESET_MESSAGE',
}

export const sagawaActionCreator = {
  updateOrderForm: (input: any) => ({
    type: actions.UPDATE_ORDER_FORM_FIELDS,
    input,
  }),
  resetOrderForm: () => ({
    type: actions.RESET_ORDER_FORM,
  }),
  getServiceOrderDetails: {
    getServiceOrderDetailsRequest: (input: any) => ({
      type: actions.GET_SERVICE_ORDER_DETAILS_REQUEST,
      input,
    }),
    getServiceOrderDetailsSuccess: (input: any) => ({
      type: actions.GET_SERVICE_ORDER_DETAILS_SUCCESS,
      input,
    }),
    getServiceOrderDetailsError: (input: any) => ({
      type: actions.GET_SERVICE_ORDER_DETAILS_ERROR,
      input,
    }),
    resetServiceOrderDetailsData: () => ({
      type: actions.RESET_SERVICE_ORDER_DETAILS_DATA,
    }),
  },
  updateLoaderState: (input: boolean) => ({
    type: actions.UPDATE_LOADER,
    input,
  }),
  performServiceOrderOpration: {
    request: (input: IServiceOrderRequest) => ({
      type: actions.PERFORM_SERVICE_ORDER_OPRATION,
      input,
    }),
    success: (input: any) => ({
      type: actions.PERFORM_SERVICE_ORDER_OPRATION_SUCCESS,
      input,
    }),
    error: (input: any) => ({
      type: actions.PERFORM_SERVICE_ORDER_OPRATION_ERROR,
      input,
    }),
  },
  updateMessage: {
    setMessage: (input: IMessage) => ({
      type: actions.SET_MESSAGE,
      input,
    }),
    reSetMessage: () => ({
      type: actions.RESET_MESSAGE,
    }),
  },
}
