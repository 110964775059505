export default {
  query: `select
	os.RecordID as RecordID,
	os.OrderID as OrderID,
	os.ProcessResult as ProcessResult,
	os.Status as Status,
	os.MsgType as MsgType,
	os.created_by as CreatedBy,
	os.CreatedDate as CreatedDate,
	os.modified_by as ModifiedBy,
	os.ModifiedDate as ModifiedDate
from
	intelliset.dbo.[order] o with(nolock)
inner join intelliset.dbo.IntelliSetOrderStatus os with(nolock) on
	o.order_id = os.orderid
inner join intelliset.dbo.claim c with(nolock) on
	c.claim_ID = o.claim_ID
inner join intelliset.dbo.subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by os.CreatedDate asc;`,
  mapper: [
    { o: 'RecordID', d: 'RecordID' },
    { o: 'OrderID', d: 'OrderID' },
    { o: 'ProcessResult', d: 'ProcessResult' },
    { o: 'Status', d: 'Status' },
    { o: 'MsgType', d: 'MsgType' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
  ],
}
