import axios from 'axios'
import * as log from 'loglevel'
import { Token } from './token'
import OAuth from './oauth/oauth'
import { logger } from '../../utils/logger'
import { IActiveConfig } from '../../config/types'
import { clearAuthToken } from './oauth/oauth-storage'
import { IAuthOptions, ICognitoUserSession } from './types'

export interface IAuthResponse {
  session: ICognitoUserSession | undefined
  newlySignedIn: boolean
  redirect?: string | any
}

class AuthClass {
  private config: IAuthOptions | undefined = undefined
  private oauth: OAuth | undefined
  private alreadyRefreshing?: Promise<IAuthResponse> = undefined

  public configure(activeConfig: IActiveConfig) {
    if (activeConfig) {
      this.config = {
        identityPoolId: activeConfig.authConfig.identityPoolId,
        region: activeConfig.authConfig.region,
        userPoolId: activeConfig.authConfig.userPoolId,
        providerId: activeConfig.authConfig.identityProvider,
        userPoolWebClientId: activeConfig.authConfig.userPoolWebClientId,
        mandatorySignIn: false,
        oauth: {
          domain: activeConfig.authConfig.domainName + '.auth.' + activeConfig.authConfig.region + '.amazoncognito.com',
          redirectSignIn: window.location.protocol + '//' + window.location.host + '/',
          redirectSignOut: window.location.protocol + '//' + window.location.host + '/',
          responseType: 'code',
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
          idp: [
            {
              title: 'Login with Asurion SSO',
              color: 'yellow',
            },
          ],
          options: {
            AdvancedSecurityDataCollectionFlag: true,
          },
        },
      }
      if (this.config.oauth) {
        this.oauth = new OAuth({
          cognitoClientId: this.config.userPoolWebClientId!,
          config: this.config.oauth!,
          scopes: this.config.oauth!.scope,
        })
      }
    }
  }

  private getTokenName(field: string, userName?: string) {
    return (
      'CognitoIdentityServiceProvider.' +
      this.config?.userPoolWebClientId +
      '.' +
      (userName ? userName + '.' : '') +
      field
    )
  }

  public currentSession(stack: string, client: string): Promise<IAuthResponse> {
    return new Promise((resolve, reject) => {
      if (client) {
        localStorage.setItem('client', client)
      } else {
        client = localStorage.getItem('client') || ''
      }
      if (stack) {
        localStorage.setItem('stack', stack)
      } else {
        stack = localStorage.getItem('stack') || ''
      }
      if (this.alreadyRefreshing) {
        logger.info('already refreshing, waiting for response instead')
        this.alreadyRefreshing.then((info) => {
          logger.info('already refreshing resolved')
          resolve(info)
        })
        return
      }
      if (this.hasValidIdentity(stack)) {
        //already signed in with local token
        resolve({
          session: this.loadIdentity(stack),
          newlySignedIn: false,
        })
      } else if (window.location.href.indexOf('error_description') > -1) {
        reject(window.location.search)
      } else if (window.location.href.indexOf('code') > -1) {
        //use code to retrieve tokens
        logger.info('authenticating using code')

        this.oauth
          ?.handleAuthResponse(window.location.href)
          .then(({ accessToken, refreshToken, idToken }: any) => {
            const token = new Token(accessToken)
            localStorage.setItem(this.getTokenName(`${stack}-LastAuthUser`), token.payload.username)
            localStorage.setItem(this.getTokenName(`${stack}-idToken`, token.payload.username), idToken)
            localStorage.setItem(this.getTokenName(`${stack}-accessToken`, token.payload.username), accessToken)
            localStorage.setItem(this.getTokenName(`${stack}-clockDrift`, token.payload.username), '0')
            localStorage.setItem(this.getTokenName(`${stack}-refreshToken`, token.payload.username), refreshToken)

            const issuer = token.payload.iss.replace(/https?:\/\//, '')

            if (this.config?.identityPoolId) {
              localStorage.setItem('aws.cognito.identity-providers.' + this.config?.identityPoolId, issuer)

              return axios
                .post(
                  'https://cognito-identity.' + this.config.region + '.amazonaws.com/',
                  {
                    IdentityPoolId: this.config.identityPoolId,
                    Logins: {
                      [issuer]: idToken,
                    },
                  },
                  {
                    headers: {
                      'content-type': 'application/x-amz-json-1.1',
                      'X-Amz-Target': 'AWSCognitoIdentityService.GetId',
                    },
                  }
                )
                .then((response) => {
                  localStorage.setItem(
                    'aws.cognito.identity-id.' + this.config?.identityPoolId,
                    response.data.IdentityId
                  )
                  return axios.post(
                    'https://cognito-identity.' + this.config?.region + '.amazonaws.com/',
                    {
                      IdentityId: response.data.IdentityId,
                      Logins: {
                        [issuer]: idToken,
                      },
                    },
                    {
                      headers: {
                        'content-type': 'application/x-amz-json-1.1',
                        'X-Amz-Target': 'AWSCognitoIdentityService.GetCredentialsForIdentity',
                      },
                    }
                  )
                })
                .then(() => {
                  return axios.post(
                    'https://cognito-idp.' + this.config?.region + '.amazonaws.com/',
                    {
                      AccessToken: accessToken,
                    },
                    {
                      headers: {
                        'content-type': 'application/x-amz-json-1.1',
                        'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
                      },
                    }
                  )
                })
                .then(() => {
                  return axios.post(
                    'https://cognito-idp.' + this.config?.region + '.amazonaws.com/',
                    {
                      AuthFlow: 'REFRESH_TOKEN_AUTH',
                      AuthParameters: {
                        DEVICE_KEY: '',
                        REFRESH_TOKEN: refreshToken,
                      },
                      ClientId: this.config?.userPoolWebClientId,
                    },
                    {
                      headers: {
                        'content-type': 'application/x-amz-json-1.1',
                        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                      },
                    }
                  )
                })
                .then((response) => {
                  if (response.data.AuthenticationResult) {
                    localStorage.setItem(
                      this.getTokenName(`${stack}-idToken`, token.payload.username),
                      response.data.AuthenticationResult.IdToken
                    )
                    localStorage.setItem(
                      this.getTokenName(`${stack}-accessToken`, token.payload.username),
                      response.data.AuthenticationResult.AccessToken
                    )
                  }

                  resolve({
                    session: this.loadIdentity(stack),
                    newlySignedIn: true,
                    redirect: sessionStorage.getItem('url'),
                  })
                })
                .catch((error: any) => {
                  logger.error('failed to get identity', error)
                  resolve({
                    session: this.loadIdentity(stack),
                    newlySignedIn: true,
                    redirect: sessionStorage.getItem('url'),
                  })
                })
            } else {
              resolve({
                session: this.loadIdentity(stack),
                newlySignedIn: true,
                redirect: sessionStorage.getItem('url'),
              })
            }
            return
          })
          .catch((error: any) => {
            log.warn('authenticating with code failed')
            reject(new Error(error))
          })
      } else if (this.isRefreshTokenValid(stack)) {
        this.alreadyRefreshing = new Promise<IAuthResponse>((resolveOther, rejectOther) => {
          //try refreshing using refresh token
          logger.info('refreshing session using refresh token')
          const authUser = this.getAuthUser(stack)
          const refreshToken = localStorage.getItem(this.getTokenName(`${stack}-refreshToken`, authUser))

          this.oauth
            ?.refreshToken(refreshToken!)
            .then(({ accessToken, idToken }) => {
              localStorage.setItem(this.getTokenName(`${stack}-idToken`, authUser), idToken)
              localStorage.setItem(this.getTokenName(`${stack}-accessToken`, authUser), accessToken)

              logger.info('refreshed using refresh token')
              resolveOther({
                session: this.loadIdentity(stack),
                newlySignedIn: false,
              })
              resolve({
                session: this.loadIdentity(stack),
                newlySignedIn: false,
              })
              this.alreadyRefreshing = undefined
            })
            .catch((error: any) => {
              logger.info('refreshing with refresh token failed, redirect to authenticate')
              clearAuthToken()
              this.oauth?.oauthSignIn(
                this.config?.oauth?.responseType,
                this.config?.oauth?.domain || '',
                this.config?.oauth?.redirectSignIn || '',
                this.config?.userPoolWebClientId || '',
                this.config?.providerId
              )
              rejectOther(error)
              reject(error)
              this.alreadyRefreshing = undefined
            })
        })
      } else {
        //redirect to authenticate
        log.info('redirect to authenticate')
        clearAuthToken()
        sessionStorage.setItem('url', window.location.href)
        this.oauth!.oauthSignIn(
          this.config!.oauth!.responseType,
          this.config!.oauth!.domain,
          this.config!.oauth!.redirectSignIn,
          this.config!.userPoolWebClientId!,
          this.config!.providerId
        )
      }
    })
  }

  public hasValidIdentity(env: string) {
    const accessToken = localStorage.getItem(this.getTokenName(`${env}-accessToken`, this.getAuthUser(env)))
    const idToken = localStorage.getItem(this.getTokenName(`${env}-idToken`, this.getAuthUser(env)))
    if (accessToken && idToken && this.isValid(env)) {
      return true
    }
    return false
  }

  public isValid(env: string) {
    const accessToken = localStorage.getItem(this.getTokenName(`${env}-accessToken`, this.getAuthUser(env)))
    const idToken = localStorage.getItem(this.getTokenName(`${env}-idToken`, this.getAuthUser(env)))

    if (accessToken && idToken) {
      const token = new Token(accessToken)
      const id = new Token(idToken)
      // adding a 10 second buffer to the token expiration check
      const expiry = new Date(new Date().getTime() + 10000)
      return token.getExpiration() * 1000 > expiry.getTime() || id.getExpiration() * 1000 > expiry.getTime()
    }

    return false
  }

  private isRefreshTokenValid(env: string) {
    //We cannot actually confirm whether the token is still valid since it's not a JWT token...
    const refreshToken = localStorage.getItem(this.getTokenName(`${env}-refreshToken`, this.getAuthUser(env)))
    return !!refreshToken
  }

  public getAuthUser(env: string): string {
    return localStorage.getItem(this.getTokenName(`${env}-LastAuthUser`))!
  }

  public getClientId(): string {
    return localStorage.getItem('clientId')!
  }

  public loadIdentity(env: string): ICognitoUserSession {
    const userName = this.getAuthUser(env)
    const idToken = localStorage.getItem(this.getTokenName(`${env}-idToken`, userName!))
    const accessToken = localStorage.getItem(this.getTokenName(`${env}-accessToken`, userName!))
    const refreshToken = localStorage.getItem(this.getTokenName(`${env}-refreshToken`, userName!))

    return {
      idToken: new Token(idToken!),
      accessToken: new Token(accessToken!),
      refreshToken: new Token(refreshToken!),
    }
  }
}

const Auth = new AuthClass()
export { Auth }
