import React from 'react'
import schema from '../../form-schemas/enrollment'
import { Formik, FieldArray, getIn } from 'formik'
import { programs, clientChannels, serviceTypeFlg } from '../../data/master-data'
import { Col, Form, Button, Badge, Row, FormSelect } from 'react-bootstrap'

const EnrollmentForm: React.FunctionComponent<{
  isDataLoaded: boolean
  data: any
  initialFormData: any
  customeStyle: { fieldsetMargin: number; formMargin: number }
  formReference: any
  toggleEditXML: any
  handleOnSave: any
  handleFormChange: any
  isUpdateFormOperation?: boolean
}> = (props) => {
  const onSubmit = () => {
    props.handleOnSave(Object.assign({}, props.data))
  }

  const handleFormChange = (handleChange, e, removeIndex = -1) => {
    const {
      target: { name, value, checked, type },
    } = e
    const input = {}
    if (removeIndex >= 0) {
      const localCopy = JSON.parse(JSON.stringify(props.data.Form.PastAssetInfo))
      if (removeIndex > -1 && removeIndex <= localCopy.length - 1) {
        localCopy.splice(removeIndex, 1)
      }
      props.handleFormChange({ PastAssetInfo: localCopy })
    } else if (name.includes('PastAssetInfo')) {
      const splitKeys = name.replace('PastAssetInfo.', '').split('.')
      const localCopy = JSON.parse(JSON.stringify(props.data.Form.PastAssetInfo))
      if (localCopy.length == splitKeys[0]) {
        const newObj = {}
        newObj[splitKeys[1]] = value
        localCopy.push(newObj)
      } else {
        localCopy[splitKeys[0]][splitKeys[1]] = value
      }
      props.handleFormChange({ PastAssetInfo: localCopy })
    } else {
      input[name] = type === 'checkbox' ? checked : value
      props.handleFormChange(input)
    }

    handleChange(e)
  }

  const newAsset = {
    ClientAssetSku: '',
    IMEI: '',
    PurchaseDate: '',
    AssetStartDate: '',
    AssetEndDate: '',
  }
  return (
    <Col
      className={`formDiv ${
        props.data.EditXML
          ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'
          : 'col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12'
      }`}
    >
      {props.isDataLoaded && (
        <fieldset
          className="border p-2"
          style={{ margin: `${props.customeStyle.fieldsetMargin}em`, maxHeight: '89vh', overflowY: 'scroll' }}
          disabled={props.data.EditXML}
        >
          <legend className="w-auto">
            <Badge pill bg="secondary"></Badge>
          </legend>
          {!props.isUpdateFormOperation && !props.data.EditXML && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault()
                props.toggleEditXML()
              }}
              className="editXmlBtn"
              style={{ float: 'right', marginLeft: '1em' }}
            >
              <strong>{'Edit Enrollment XML'} </strong>
            </a>
          )}
          <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialFormData}>
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleBlur,
              handleSubmit,
              handleReset,
              handleChange,
              isValid,
            }) => {
              const isSimonlyProgram = ['18', '19'].includes(values.ProgramType)
              return (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{ margin: `${props.customeStyle.formMargin}em`, minHeight: '75vh' }}
                  ref={props.formReference}
                >
                  <legend className="w-auto">
                    <Badge pill bg="secondary">
                      Offer Information
                    </Badge>
                  </legend>
                  <Row>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridServiceFlag"
                    >
                      <Form.Label>Service Type Flg</Form.Label>
                      <FormSelect
                        name="ServiceFlag"
                        value={values.ServiceFlag}
                        isInvalid={!!errors.ServiceFlag}
                        isValid={!errors.ServiceFlag}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        {serviceTypeFlg
                          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                          .map((p) => (
                            <option key={p.Code} value={p.Code}>
                              {p.DisaplayName}
                            </option>
                          ))}
                      </FormSelect>
                      {errors.ServiceFlag && touched.ServiceFlag ? (
                        <Form.Control.Feedback type="invalid">{!errors.ServiceFlag}</Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGrideClientAccountType"
                    >
                      <Form.Label>Account Type</Form.Label>
                      <FormSelect
                        name="ClientAccountType"
                        value={values.ClientAccountType}
                        isInvalid={!!errors.ClientAccountType}
                        isValid={!errors.ClientAccountType}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="1">Individual</option>
                        <option value="2">Business</option>
                      </FormSelect>
                      {errors.ClientAccountType && touched.ClientAccountType ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ClientAccountType}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6" controlId="formGridMDN">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="80-1234-5678"
                        name="MobileDeviceNumber"
                        value={values.MobileDeviceNumber}
                        isInvalid={!!errors.MobileDeviceNumber && !!touched.MobileDeviceNumber}
                        isValid={!!values.MobileDeviceNumber && !errors.MobileDeviceNumber}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.MobileDeviceNumber && touched.MobileDeviceNumber ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.MobileDeviceNumber}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridSubscriberNumber"
                    >
                      <Form.Label>Subscriber Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="HRZN3800"
                        name="SubscriberNumber"
                        value={values.SubscriberNumber}
                        isInvalid={!!errors.SubscriberNumber && !!touched.SubscriberNumber}
                        isValid={!!values.SubscriberNumber && !errors.SubscriberNumber}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SubscriberNumber && touched.SubscriberNumber ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SubscriberNumber}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridClientChannel"
                    >
                      <Form.Label>Brand</Form.Label>
                      <FormSelect
                        name="ClientChannel"
                        value={values.ClientChannel}
                        isInvalid={!!errors.ClientChannel}
                        isValid={!!values.ClientChannel && !errors.ClientChannel}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="">...</option>
                        {clientChannels
                          .filter((c) => c.EnrollmentAllowed)
                          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                          .map((p) => (
                            <option key={p.Code} value={p.Code}>
                              {p.DisaplayName}
                            </option>
                          ))}
                      </FormSelect>
                      {errors.ClientChannel && touched.ClientChannel ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ClientChannel}</>
                        </Form.Control.Feedback>
                      ) : (
                        <div style={{ marginTop: '.25rem' }}>&nbsp;</div>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridIsSolutoAgreement"
                    >
                      <Form.Label>Offer Name</Form.Label>
                      <FormSelect
                        name="ProgramType"
                        value={values.ProgramType}
                        isInvalid={!!errors.ProgramType}
                        isValid={!!values.ProgramType && !errors.ProgramType}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="">...</option>
                        {programs
                          .filter(
                            (p) =>
                              p.Active &&
                              p.ApplicableClientChannels.includes(values.ClientChannel) &&
                              ((p.PureKFSAllowed && values.ServiceFlag === '4') || values.ServiceFlag !== '4')
                          )
                          .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                          .map((p) => (
                            <option key={p.Code} value={p.Code}>
                              {p.DisaplayName}
                            </option>
                          ))}
                      </FormSelect>
                      {errors.ProgramType && touched.ProgramType ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ProgramType}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridClientAccountStartDate"
                    >
                      <Form.Label>Account Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="YYYYMMDD"
                        max="2999-12-31"
                        name="ClientAccountStartDate"
                        value={values.ClientAccountStartDate}
                        isInvalid={!!errors.ClientAccountStartDate && !!touched.ClientAccountStartDate}
                        isValid={!errors.ClientAccountStartDate}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.ClientAccountStartDate && touched.ClientAccountStartDate ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ClientAccountStartDate}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridAgreementStartDate"
                    >
                      <Form.Label>Agreement Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="YYYYMMDD"
                        max="2999-12-31"
                        name="AgreementStartDate"
                        value={values.AgreementStartDate}
                        isInvalid={!!errors.AgreementStartDate && !!touched.AgreementStartDate}
                        isValid={!errors.AgreementStartDate}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.AgreementStartDate && touched.AgreementStartDate ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.AgreementStartDate}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <legend className="w-auto">
                    <Badge pill bg="secondary">
                      Asset Information
                    </Badge>
                  </legend>

                  <Row>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridClientAssetSku"
                    >
                      <Form.Label>Asset Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Client Asset Sku"
                        name="ClientAssetSku"
                        value={values.ClientAssetSku}
                        isInvalid={!!errors.ClientAssetSku && !!touched.ClientAssetSku}
                        isValid={!errors.ClientAssetSku}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                        disabled={isSimonlyProgram}
                      />
                      {errors.ClientAssetSku && touched.ClientAssetSku ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ClientAssetSku}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6" controlId="formGridIMEI">
                      <Form.Label>Serial Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Serial Number"
                        name="IMEI"
                        value={values.IMEI}
                        isInvalid={!!errors.IMEI && !!touched.IMEI}
                        isValid={!!values.IMEI && !errors.IMEI}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                        disabled={isSimonlyProgram}
                      />
                      {errors.IMEI && touched.IMEI ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.IMEI}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridHandsetType"
                    >
                      <Form.Label>Handset Type</Form.Label>
                      <FormSelect
                        name="HandsetType"
                        value={values.HandsetType}
                        isInvalid={!!errors.HandsetType}
                        isValid={!errors.HandsetType}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="">...</option>
                        <option value="W">W</option>
                        <option value="L">L</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="F">F</option>
                      </FormSelect>
                      {errors.HandsetType && touched.HandsetType ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.HandsetType}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridICCId"
                    >
                      <Form.Label>ICC Id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ICC Id"
                        name="ICCId"
                        value={values.ICCId}
                        isInvalid={!!errors.ICCId && !!touched.ICCId}
                        isValid={!errors.ICCId}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.ICCId && touched.ICCId ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.ICCId}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridFirstPurchaseDate"
                    >
                      <Form.Label>Warranty Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="YYYYMMDD"
                        max="2999-12-31"
                        name="PurchaseDate"
                        value={values.PurchaseDate}
                        isInvalid={!!errors.PurchaseDate && !!touched.PurchaseDate}
                        isValid={!errors.PurchaseDate}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                        disabled={isSimonlyProgram}
                      />
                      {errors.PurchaseDate && touched.PurchaseDate ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.PurchaseDate}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGrideSIMPlanType"
                    >
                      <Form.Label>eSIM Plan</Form.Label>
                      <FormSelect
                        name="eSIMPlanType"
                        value={values.eSIMPlanType}
                        isInvalid={!!errors.eSIMPlanType}
                        isValid={!errors.eSIMPlanType}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                        disabled={isSimonlyProgram}
                      >
                        <option value="0">Not eSIM</option>
                        <option value="1">eSIM</option>
                      </FormSelect>
                      {errors.eSIMPlanType && touched.eSIMPlanType ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.eSIMPlanType}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGrideSIMProfileDownloadStatus"
                    >
                      <Form.Label>eSIM Profile Status</Form.Label>
                      <FormSelect
                        name="eSIMProfileDownloadStatus"
                        value={values.eSIMProfileDownloadStatus}
                        isInvalid={!!errors.eSIMProfileDownloadStatus}
                        isValid={!errors.eSIMProfileDownloadStatus}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                        disabled={isSimonlyProgram}
                      >
                        <option value="">...</option>
                        <option value="0">Incomplete</option>
                        <option value="1">Failed</option>
                        <option value="2">Success</option>
                      </FormSelect>
                      {errors.eSIMProfileDownloadStatus && touched.eSIMProfileDownloadStatus ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.eSIMProfileDownloadStatus}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridIsLatestDeviceSubscriber"
                    >
                      <Form.Label>Is Subscriber</Form.Label>
                      <FormSelect
                        name="IsLatestDeviceSubscriber"
                        value={values.IsLatestDeviceSubscriber}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      >
                        <option value="">...</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </FormSelect>
                    </Form.Group>

                    <Form.Group as={Col}></Form.Group>
                  </Row>

                  <legend className="w-auto">
                    <Badge pill bg="secondary">
                      Past Asset Information
                    </Badge>
                  </legend>

                  <Row>
                    <FieldArray name="PastAssetInfo">
                      {({ push, remove }) => {
                        return values.PastAssetInfo.length === 0 ? (
                          <Form.Group as={Col} className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
                            <Button
                              size="sm"
                              type="button"
                              variant="success"
                              style={{ marginLeft: '15em', marginTop: '-4em' }}
                              name="PastAssetInfo.0.ClientAssetSku"
                              onClick={(e) => {
                                handleFormChange(handleChange, e)
                              }}
                            >
                              Add
                            </Button>
                          </Form.Group>
                        ) : (
                          values.PastAssetInfo.map((p, index, row) => {
                            return (
                              <React.Fragment key={index}>
                                <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <Form.Label>Asset Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Client Asset Sku"
                                    name={`PastAssetInfo.${index}.ClientAssetSku`}
                                    value={p?.ClientAssetSku}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.ClientAssetSku`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.ClientAssetSku`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.ClientAssetSku`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  />
                                  {getIn(errors, `PastAssetInfo.${index}.ClientAssetSku`) &&
                                  getIn(touched, `PastAssetInfo.${index}.ClientAssetSku`) ? (
                                    <Form.Control.Feedback type="invalid">
                                      <>{getIn(errors, `PastAssetInfo.${index}.ClientAssetSku`)}</>
                                    </Form.Control.Feedback>
                                  ) : (
                                    <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                                  )}
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <Form.Label>Serial Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Serial Number"
                                    name={`PastAssetInfo.${index}.IMEI`}
                                    value={p?.IMEI}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.IMEI`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.IMEI`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.IMEI`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  ></Form.Control>
                                  {getIn(errors, `PastAssetInfo.${index}.IMEI`) &&
                                  getIn(touched, `PastAssetInfo.${index}.IMEI`) ? (
                                    <Form.Control.Feedback type="invalid">
                                      <>{getIn(errors, `PastAssetInfo.${index}.IMEI`)}</>
                                    </Form.Control.Feedback>
                                  ) : (
                                    <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                                  )}
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <Form.Label>Warranty Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    max="2999-12-31"
                                    placeholder="YYYYMMDD"
                                    name={`PastAssetInfo.${index}.PurchaseDate`}
                                    value={p?.PurchaseDate}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.PurchaseDate`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.PurchaseDate`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.PurchaseDate`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  ></Form.Control>
                                  {getIn(errors, `PastAssetInfo.${index}.PurchaseDate`) &&
                                  getIn(touched, `PastAssetInfo.${index}.PurchaseDate`) ? (
                                    <Form.Control.Feedback type="invalid">
                                      <>{getIn(errors, `PastAssetInfo.${index}.PurchaseDate`)}</>
                                    </Form.Control.Feedback>
                                  ) : (
                                    <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                                  )}
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <Form.Label>Anshin Service Start Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    placeholder="YYYYMMDD"
                                    max="2999-12-31"
                                    name={`PastAssetInfo.${index}.AssetStartDate`}
                                    value={p?.AssetStartDate}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.AssetStartDate`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.AssetStartDate`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.AssetStartDate`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  ></Form.Control>
                                  {getIn(errors, `PastAssetInfo.${index}.AssetStartDate`) &&
                                  getIn(touched, `PastAssetInfo.${index}.AssetStartDate`) ? (
                                    <Form.Control.Feedback type="invalid">
                                      <>{getIn(errors, `PastAssetInfo.${index}.AssetStartDate`)}</>
                                    </Form.Control.Feedback>
                                  ) : (
                                    <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                                  )}
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                                  <Form.Label>Anshin Service End Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    placeholder="YYYYMMDD"
                                    max="2999-12-31"
                                    name={`PastAssetInfo.${index}.AssetEndDate`}
                                    value={p?.AssetEndDate}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.AssetEndDate`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.AssetEndDate`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.AssetEndDate`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  ></Form.Control>
                                  {getIn(errors, `PastAssetInfo.${index}.AssetEndDate`) &&
                                  getIn(touched, `PastAssetInfo.${index}.AssetEndDate`) ? (
                                    <Form.Control.Feedback type="invalid">
                                      <>{getIn(errors, `PastAssetInfo.${index}.AssetEndDate`)}</>
                                    </Form.Control.Feedback>
                                  ) : (
                                    <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                                  )}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                                  controlId="formGridIsPastDeviceSubscriber"
                                >
                                  <Form.Label>Is Subscriber</Form.Label>
                                  <FormSelect
                                    name={`PastAssetInfo.${index}.IsPastDeviceSubscriber`}
                                    value={p?.IsPastDeviceSubscriber}
                                    isInvalid={
                                      !!getIn(errors, `PastAssetInfo.${index}.IsPastDeviceSubscriber`) &&
                                      !!getIn(touched, `PastAssetInfo.${index}.IsPastDeviceSubscriber`)
                                    }
                                    isValid={!getIn(errors, `PastAssetInfo.${index}.IsPastDeviceSubscriber`)}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleFormChange(handleChange, e)}
                                  >
                                    <option value="">...</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </FormSelect>
                                </Form.Group>

                                <Form.Group as={Col} className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12 col">
                                  <Button
                                    size="sm"
                                    type="button"
                                    variant="danger"
                                    style={{ marginTop: '2.5em' }}
                                    onClick={(e) => {
                                      handleFormChange(handleChange, e, index)
                                      remove(index)
                                    }}
                                  >
                                    Remove
                                  </Button>
                                  {index + 1 === row.length ? (
                                    <Button
                                      size="sm"
                                      type="button"
                                      variant="success"
                                      style={{ marginLeft: '1em', marginTop: '2.5em' }}
                                      onClick={(e) => {
                                        handleFormChange(handleChange, e)
                                        push(newAsset)
                                      }}
                                    >
                                      Add
                                    </Button>
                                  ) : null}
                                </Form.Group>
                              </React.Fragment>
                            )
                          })
                        )
                      }}
                    </FieldArray>
                  </Row>

                  <legend className="w-auto">
                    <Badge pill bg="secondary">
                      Keiyakusha Information
                    </Badge>
                  </legend>
                  <Row>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridKeiyakushaKanaName"
                    >
                      <Form.Label>Kana Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kana Name"
                        name="KeiyakushaKanaName"
                        value={values.KeiyakushaKanaName}
                        isInvalid={!!errors.KeiyakushaKanaName && !!touched.KeiyakushaKanaName}
                        isValid={!errors.KeiyakushaKanaName}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.KeiyakushaKanaName && touched.KeiyakushaKanaName ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.KeiyakushaKanaName}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridKeiyakushaKanjiName"
                    >
                      <Form.Label>Kanji Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kanji Name"
                        name="KeiyakushaKanjiName"
                        value={values.KeiyakushaKanjiName}
                        isInvalid={!!errors.KeiyakushaKanjiName && !!touched.KeiyakushaKanjiName}
                        isValid={!errors.KeiyakushaKanjiName}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.KeiyakushaKanjiName && touched.KeiyakushaKanjiName ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.KeiyakushaKanjiName}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridDateOfBirth"
                    >
                      <Form.Label>Date Of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="YYYYMMDD"
                        max="2999-12-31"
                        name="DateOfBirth"
                        value={values.DateOfBirth}
                        isInvalid={!!errors.DateOfBirth && !!touched.DateOfBirth}
                        isValid={!errors.DateOfBirth}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.DateOfBirth && touched.DateOfBirth ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.DateOfBirth}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridKeiyakushaHomeTelNo"
                    >
                      <Form.Label>Home Tel No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Home Telephone Number"
                        name="KeiyakushaHomeTelNo"
                        value={values.KeiyakushaHomeTelNo}
                        isInvalid={!!errors.KeiyakushaHomeTelNo && !!touched.KeiyakushaHomeTelNo}
                        isValid={!errors.KeiyakushaHomeTelNo}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.KeiyakushaHomeTelNo && touched.KeiyakushaHomeTelNo ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.KeiyakushaHomeTelNo}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridKeiyakushaPostNo"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Post Number"
                        name="KeiyakushaPostNo"
                        value={values.KeiyakushaPostNo}
                        isInvalid={!!errors.KeiyakushaPostNo && !!touched.KeiyakushaPostNo}
                        isValid={!errors.KeiyakushaPostNo}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.KeiyakushaPostNo && touched.KeiyakushaPostNo ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.KeiyakushaPostNo}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridLoyaltyPoints"
                    >
                      <Form.Label>Loyalty Points</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="1000"
                        name="LoyaltyPoints"
                        value={values.LoyaltyPoints}
                        isInvalid={!!errors.LoyaltyPoints && !!touched.LoyaltyPoints}
                        isValid={!errors.LoyaltyPoints}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.LoyaltyPoints && touched.LoyaltyPoints ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.LoyaltyPoints}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12"
                      controlId="formGridKeiyakushaAddress"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        name="KeiyakushaAddress"
                        value={values.KeiyakushaAddress}
                        isInvalid={!!errors.KeiyakushaAddress && !!touched.KeiyakushaAddress}
                        isValid={!errors.KeiyakushaAddress}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.KeiyakushaAddress && touched.KeiyakushaAddress ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.KeiyakushaAddress}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>
                    {props.data.Form.ServiceFlag === '4' ? (
                      <>
                        <Form.Group
                          as={Col}
                          className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12"
                          controlId="formGridKeiyakushaAddress2"
                        >
                          <Form.Label>Address2</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address2"
                            name="KeiyakushaAddress2"
                            value={values.KeiyakushaAddress2}
                            isInvalid={!!errors.KeiyakushaAddress2 && !!touched.KeiyakushaAddress2}
                            isValid={!errors.KeiyakushaAddress2}
                            onBlur={handleBlur}
                            onChange={(e) => handleFormChange(handleChange, e)}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12"
                          controlId="formGridKeiyakushaAddress3"
                        >
                          <Form.Label>Address3</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address3"
                            name="KeiyakushaAddress3"
                            value={values.KeiyakushaAddress3}
                            isInvalid={!!errors.KeiyakushaAddress3 && !!touched.KeiyakushaAddress3}
                            isValid={!errors.KeiyakushaAddress3}
                            onBlur={handleBlur}
                            onChange={(e) => handleFormChange(handleChange, e)}
                          />
                        </Form.Group>
                      </>
                    ) : null}

                    <Form.Group as={Col} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6" controlId="formGridAUID">
                      <Form.Label>AU ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="AU ID"
                        name="AUID"
                        value={values.AUID}
                        isInvalid={!!errors.AUID && !!touched.AUID}
                        isValid={!errors.AUID}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.AUID && touched.AUID ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.AUID}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <legend className="w-auto">
                    <Badge pill bg="secondary">
                      Seikyusha Information
                    </Badge>
                  </legend>
                  <Row>
                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridSeikyushaKanaName"
                    >
                      <Form.Label>Kana Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kana Name"
                        name="SeikyushaKanaName"
                        value={values.SeikyushaKanaName}
                        isInvalid={!!errors.SeikyushaKanaName && !!touched.SeikyushaKanaName}
                        isValid={!errors.SeikyushaKanaName}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SeikyushaKanaName && touched.SeikyushaKanaName ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SeikyushaKanaName}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridSeikyushaKanjiName"
                    >
                      <Form.Label>Kanji Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Kanji Name"
                        name="SeikyushaKanjiName"
                        value={values.SeikyushaKanjiName}
                        isInvalid={!!errors.SeikyushaKanjiName && !!touched.SeikyushaKanjiName}
                        isValid={!errors.SeikyushaKanjiName}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SeikyushaKanjiName && touched.SeikyushaKanjiName ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SeikyushaKanjiName}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridSeikyushaHomeTelNo"
                    >
                      <Form.Label>Home Tel No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Home Telephone Number"
                        name="SeikyushaHomeTelNo"
                        value={values.SeikyushaHomeTelNo}
                        isInvalid={!!errors.SeikyushaHomeTelNo && !!touched.SeikyushaHomeTelNo}
                        isValid={!errors.SeikyushaHomeTelNo}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SeikyushaHomeTelNo && touched.SeikyushaHomeTelNo ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SeikyushaHomeTelNo}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6"
                      controlId="formGridSeikyushaPostNo"
                    >
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Post Number"
                        name="SeikyushaPostNo"
                        value={values.SeikyushaPostNo}
                        isInvalid={!!errors.SeikyushaPostNo && !!touched.SeikyushaPostNo}
                        isValid={!errors.SeikyushaPostNo}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SeikyushaPostNo && touched.SeikyushaPostNo ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SeikyushaPostNo}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-12"
                      controlId="formGridSeikyushaAddress"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        name="SeikyushaAddress"
                        value={values.SeikyushaAddress}
                        isInvalid={!!errors.SeikyushaAddress && !!touched.SeikyushaAddress}
                        isValid={!errors.SeikyushaAddress}
                        onBlur={handleBlur}
                        onChange={(e) => handleFormChange(handleChange, e)}
                      />
                      {errors.SeikyushaAddress && touched.SeikyushaAddress ? (
                        <Form.Control.Feedback type="invalid">
                          <>{errors.SeikyushaAddress}</>
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="col-xl-6 col-lg-12 col-md-12 col-sm-6 col-12"
                      style={{ float: 'right', marginTop: '1.4rem' }}
                    >
                      <Button
                        variant="outline-secondary"
                        size="lg"
                        type="button"
                        onClick={handleReset}
                        disabled={isSubmitting}
                        style={{ float: 'right', marginLeft: '1em' }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="success"
                        size="lg"
                        type="submit"
                        disabled={!isValid}
                        style={{ float: 'right', marginLeft: '1em' }}
                      >
                        Save Enrollment
                      </Button>
                    </Form.Group>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </fieldset>
      )}
    </Col>
  )
}

export default EnrollmentForm
