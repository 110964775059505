import { produce } from 'immer'
import { Reducer } from 'redux'

import {
  defaultNoLoanerObject,
  defaultLoanerObject,
  defaultICActivationObject,
  defaultForceCompleteObject,
  defaultChargeOrderObject,
  defaultSubmitOrderObject,
} from '../../data/default-objects'
import { initialState } from './initialState'
import { actions as orangeActions } from './actions'
import { actions as commonActions } from '../rootActions'
import { OrangeServiceType } from '../../utils/constants'

import { LoanerSuccess, LoanerError } from '../../utils/sample-data-templates/loaner'
import { NoLoanerSuccess, NoLoanerError } from '../../utils/sample-data-templates/no-loaner'
import { ICActivationSuccess, ICActivationError } from '../../utils/sample-data-templates/ic-activation'
import { ForceCompleteSuccess, ForceCompleteError } from '../../utils/sample-data-templates/force-complete'
import { ChargeOrderSuccess, ChargeOrderError } from '../../utils/sample-data-templates/charge-order'
import { SubmitOrderSuccess, SubmitOrderError } from '../../utils/sample-data-templates/submit-order'

export const orangeInfoReducer: Reducer<typeof initialState> = (state = initialState, { type, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case orangeActions.UPDATE_ORANGE_FORM_FIELDS:
        draft.Form = Object.assign({}, draft.Form, input)
        return draft
      case orangeActions.REQUEST_GET_ORANGE_RESPONSE:
        if (input.type === OrangeServiceType.LonerOrderResponse) {
          draft.LonerOrder = null
        }
        if (input.type === OrangeServiceType.NoLonerOrderResponse) {
          draft.NoLonerOrder = null
        }
        if (input.type === OrangeServiceType.ICActivationResponse) {
          draft.ICActivation = null
        }
        if (input.type === OrangeServiceType.ForceCompleteResponse) {
          draft.ForceComplete = null
        }
        if (input.type === OrangeServiceType.ChargeOrderResponse) {
          draft.ChargeOrder = null
        }
        if (input.type === OrangeServiceType.SubmitOrderResponse) {
          draft.SubmitOrder = null
        }
        draft.IsLoading = true
        draft.IsSuccess = '0'
        draft.SearchKey = ''
        draft.Errors = []
        return draft
      case orangeActions.SUCCESS_GET_ORANGE_RESPONSE:
        draft.IsLoading = false
        draft.IsSuccess = input.response ? '1' : '3'
        draft.Errors = []
        draft.SearchKey = draft.Form.MobileDeviceNumber
          ? `${draft.Form.MobileDeviceNumber}|0`
          : `${draft.Form.SubscriberNumber}|0`

        if (input.type === OrangeServiceType.LonerOrderResponse) {
          draft.LonerOrder = input.response || defaultLoanerObject
        }
        if (input.type === OrangeServiceType.NoLonerOrderResponse) {
          draft.NoLonerOrder = input.response || defaultNoLoanerObject
        }
        if (input.type === OrangeServiceType.ICActivationResponse) {
          draft.ICActivation = input.response || defaultICActivationObject
        }
        if (input.type === OrangeServiceType.ForceCompleteResponse) {
          draft.ForceComplete = input.response || defaultForceCompleteObject
        }
        if (input.type === OrangeServiceType.ChargeOrderResponse) {
          draft.ChargeOrder = input.response || defaultChargeOrderObject
        }
        if (input.type === OrangeServiceType.SubmitOrderResponse) {
          draft.SubmitOrder = input.response || defaultSubmitOrderObject
        }
        return draft
      case orangeActions.ERROR_GET_ORANGE_RESPONSE:
      case orangeActions.ERROR_SAVE_ORANGE_RESPONSE:
        draft.IsLoading = false
        draft.Errors = [input.message]
        return draft
      case orangeActions.UPDATE_ORANGE_RESPONSE:
        if (input.type === OrangeServiceType.LonerOrderResponse) {
          draft.LonerOrder = input.response
        }
        if (input.type === OrangeServiceType.NoLonerOrderResponse) {
          draft.NoLonerOrder = input.response
        }
        if (input.type === OrangeServiceType.ICActivationResponse) {
          draft.ICActivation = input.response
        }
        if (input.type === OrangeServiceType.ForceCompleteResponse) {
          draft.ForceComplete = input.response
        }
        if (input.type === OrangeServiceType.ChargeOrderResponse) {
          draft.ChargeOrder = input.response
        }
        if (input.type === OrangeServiceType.SubmitOrderResponse) {
          draft.SubmitOrder = input.response
        }
        return draft
      case orangeActions.REQUEST_SAVE_ORANGE_RESPONSE:
        draft.IsLoading = true
        draft.IsSuccess = '0'
        draft.Errors = []
        return draft
      case orangeActions.SUCCESS_SAVE_ORANGE_RESPONSE:
        draft.IsLoading = false
        draft.IsSuccess = '2'
        draft.Errors = []
        return draft
      case orangeActions.RESET_ORANGE_SEARCH_FORM:
        draft.Form = {
          MobileDeviceNumber: '',
          eSIMPlanType: '',
          eSIMProfileDownloadStatus: '',
          PastAssetInfo: [],
        }
        draft.Errors = []
        draft.IsSuccess = '0'
        draft.LonerOrder = null
        draft.NoLonerOrder = null
        draft.ICActivation = null
        draft.ForceComplete = null
        draft.ChargeOrder = null

        return draft
      case orangeActions.LOAD_SAMPLE_SUCCESS_RESPONSE:
        if (input.type === OrangeServiceType.LonerOrderResponse) {
          draft.LonerOrder = [LoanerSuccess]
        }
        if (input.type === OrangeServiceType.NoLonerOrderResponse) {
          draft.NoLonerOrder = [NoLoanerSuccess]
        }
        if (input.type === OrangeServiceType.ICActivationResponse) {
          draft.ICActivation = ICActivationSuccess
        }
        if (input.type === OrangeServiceType.ForceCompleteResponse) {
          draft.ForceComplete = ForceCompleteSuccess
        }
        if (input.type === OrangeServiceType.ChargeOrderResponse) {
          draft.ChargeOrder = ChargeOrderSuccess
        }
        if (input.type === OrangeServiceType.SubmitOrderResponse) {
          draft.SubmitOrder = SubmitOrderSuccess
        }
        return draft
      case orangeActions.LOAD_SAMPLE_ERROR_RESPONSE:
        if (input.type === OrangeServiceType.LonerOrderResponse) {
          draft.LonerOrder = LoanerError
        }
        if (input.type === OrangeServiceType.NoLonerOrderResponse) {
          draft.NoLonerOrder = NoLoanerError
        }
        if (input.type === OrangeServiceType.ICActivationResponse) {
          draft.ICActivation = ICActivationError
        }
        if (input.type === OrangeServiceType.ForceCompleteResponse) {
          draft.ForceComplete = ForceCompleteError
        }
        if (input.type === OrangeServiceType.ChargeOrderResponse) {
          draft.ChargeOrder = ChargeOrderError
        }
        if (input.type === OrangeServiceType.SubmitOrderResponse) {
          draft.SubmitOrder = SubmitOrderError
        }
        return draft

      //Common Actions
      case commonActions.RESET_SUCCESS_FLAG:
        draft.IsSuccess = '0'
        return draft
      case commonActions.RESET_ERROR:
        draft.Errors = []
        return draft
      case commonActions.ADD_ERROR:
        return draft
      default:
        return draft
    }
  })
}
