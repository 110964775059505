import API from '../../../services'
import { connect } from 'react-redux'
import React, { Component, ReactNode } from 'react'
import { Row, Container, Alert } from 'react-bootstrap'

import { IComponents, IState } from '../../../store/types'
import { actionCreator } from '../../../store/rootActions'
import { pascalActionCreator } from '../../../store/pascal/actions'
import { isClientEnvironmentSelected } from '../../../store/selectors'

import Editor from '../../../components/editor'
import Spinner from '../../../components/spinner'
import EnrollmentForm from '../../../components/enrollment-form'
import DefaultEnrollmentFormData from '../../../utils/sample-data-templates/enrollment'

const mapStateToProps = (state: IState) => {
  return {
    PascalInformation: state.PascalInformation,
    IsClientEnvironmentSelected: isClientEnvironmentSelected(),
  }
}

const mapDispatchToProps = {
  createEnrollment: pascalActionCreator.createEnrollment,
  startGenerateMDN: pascalActionCreator.startGenerateMDN,
  endGenerateMDN: pascalActionCreator.endGenerateMDN,
  updateEnrollmentForm: pascalActionCreator.updateEnrollmentForm,
  resetSuccessFlag: actionCreator.resetSuccessFlag,
  resetError: actionCreator.resetError,
  addError: actionCreator.addError,
  toggleEditXML: pascalActionCreator.toggleEditXML,
  updateEnrollmentXML: pascalActionCreator.updateEnrollmentXML,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents

class CreateEnrollment extends Component<IProps, IState> {
  inputEl: any
  enrollmentForm: any
  fieldsetMargin = 0.2
  formMargin = 1.5

  constructor(props: any) {
    super(props)
    this.enrollmentForm = React.createRef()
  }

  async componentDidMount() {
    this.props.resetSuccessFlag()
    if (!this.props.PascalInformation.IsLoading) {
      try {
        this.props.startGenerateMDN()
        const ramdomNumber = await API.SequenceService.generate()

        this.props.updateEnrollmentForm(
          Object.assign({}, DefaultEnrollmentFormData, {
            MobileDeviceNumber: ramdomNumber,
            SubscriberNumber: ramdomNumber.substring(0, 8),
            IMEI: ramdomNumber.concat('0000'),
          })
        )
        this.props.endGenerateMDN()
      } catch (err) {
        this.props.endGenerateMDN()
        this.props.updateEnrollmentForm(DefaultEnrollmentFormData)
      }
    }
  }

  saveEnrollmentDetails(e) {
    this.props.createEnrollment(e)
  }

  render(): ReactNode {
    return (
      <Container fluid>
        {this.props.PascalInformation.IsLoading && <Spinner />}
        {!this.props.PascalInformation.IsLoading && this.props.PascalInformation.IsSuccess && (
          <Alert variant="success" onClose={() => this.props.resetSuccessFlag()} dismissible>
            {this.props.PascalInformation.StagedMDN &&
              `Mobile number: ${this.props.PascalInformation.StagedMDN} enrolled successfully.`}
            {this.props.PascalInformation.StagedSubNo &&
              `Subscriber Number: ${this.props.PascalInformation.StagedSubNo} enrolled successfully.`}
          </Alert>
        )}
        {!this.props.PascalInformation.IsLoading &&
          this.props.PascalInformation.Errors &&
          this.props.PascalInformation.Errors.length > 0 && (
            <Alert variant="danger" onClose={() => this.props.resetError()} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              {this.props.PascalInformation.Errors.map((e, i) => {
                return <p key={i}>{e || 'Details not available!!!'}</p>
              })}
            </Alert>
          )}
        {this.props.PascalInformation.FormInitialized && !this.props.PascalInformation.IsLoading ? (
          <Row>
            <EnrollmentForm
              isDataLoaded={true}
              data={this.props.PascalInformation}
              initialFormData={this.props.PascalInformation.Form}
              customeStyle={{ fieldsetMargin: this.fieldsetMargin, formMargin: this.formMargin }}
              formReference={this.enrollmentForm}
              toggleEditXML={() => this.props.toggleEditXML()}
              handleOnSave={(e) => this.saveEnrollmentDetails(e)}
              handleFormChange={(e) => this.props.updateEnrollmentForm(e)}
            />
            <Editor
              customeStyle={{ fieldsetMargin: this.fieldsetMargin, formMargin: this.formMargin }}
              data={this.props.PascalInformation}
              externalFormRef={this.enrollmentForm}
              toggleEditXML={() => this.props.toggleEditXML()}
              handleFormChange={(e) => this.props.updateEnrollmentXML(e)}
              handleOnSave={(e) => this.saveEnrollmentDetails(e)}
            />
          </Row>
        ) : null}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnrollment)
