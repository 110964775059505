import { produce } from 'immer'
import { Reducer } from 'redux'
import { actions } from './actions'
import { actions as commonActions } from '../rootActions'

import { initialState } from './initialState'

export const productConfigurationReducer: Reducer<typeof initialState> = (state = initialState, { type, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case actions.UPDATE_CONFIGURATION_FORM_FIELDS:
        draft = Object.assign({}, draft, input)
        return draft

      case actions.REQUEST_PRODUCT_FEATURE:
        draft.Errors = []
        draft.Details = []
        draft.FeatureList = []
        draft.IsLoading = true
        draft.IsSuccess = false
        draft.SelectedFeature = ''
        draft.SelectedClient = input.clientchannelid
        return draft
      case actions.SUCCESS_PRODUCT_FEATURE:
        draft.Errors = []
        draft.IsSuccess = true
        draft.IsLoading = false
        draft.FeatureList = input
        return draft
      case actions.ERROR_PARAMETER_STORE:
      case actions.ERROR_PRODUCT_FEATURE:
      case actions.ERROR_PRODUCT_FEATURE_DETAILS:
        draft.IsLoading = false
        draft.Errors = [input.message]
        return draft

      case actions.REQUEST_PRODUCT_FEATURE_DETAILS:
        draft.Errors = []
        draft.Details = []
        draft.IsLoading = true
        draft.IsSuccess = false
        draft.SelectedFeature = input.code
        return draft

      case actions.SUCCESS_PRODUCT_FEATURE_DETAILS:
        draft.Errors = []
        draft.IsSuccess = true
        draft.IsLoading = false
        draft.Details = input
        return draft

      case actions.REQUEST_PARAMETER_STORE:
      case actions.REQUEST_UPDATE_PARAMETER_STORE:
        draft.Errors = []
        draft.IsLoading = true
        draft.IsSuccess = false
        return draft

      case actions.SUCCESS_PARAMETER_STORE:
        draft.Errors = []
        draft.IsSuccess = true
        draft.IsLoading = false
        draft.ParameterStore = input
        return draft

      //Common Actions
      case commonActions.RESET_SUCCESS_FLAG:
        draft.IsSuccess = false
        return draft
      case commonActions.RESET_ERROR:
        draft.Errors = []
        return draft
      case commonActions.ADD_ERROR:
        draft.Errors = input
        return draft

      default:
        return draft
    }
  })
}
