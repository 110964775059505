import { config } from '../../config'
import { ILoginInfoState } from '../types'
import { Auth } from '../../services/auth'

const stack = localStorage.getItem('stack') || ''
const client = localStorage.getItem('client') || ''
const environment = localStorage.getItem('environment') || ''

if (client && stack) {
  Auth.configure(config[client][stack])
}

const hasValidIdentity = Auth.hasValidIdentity(stack)
const session = hasValidIdentity ? Auth.loadIdentity(stack) : undefined

export const initialState: ILoginInfoState = {
  agentLoaded: !!session,
  agentInfo: {
    UserId: session?.idToken?.payload['cognito:username'],
    FirstName: session?.idToken?.payload?.given_name,
    LastName: session?.idToken?.payload?.family_name,
    DisplayName: session?.idToken?.payload?.name,
    UserName: session?.idToken?.payload['cognito:username'],
  },
  clientInfo: {
    Form: {
      Stack: stack,
      Client: client,
      Environment: environment,
    },
  },
}
