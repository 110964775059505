import { produce } from 'immer'
import { Reducer } from 'redux'
import { actions } from './actions'
import { actions as orangeActions } from '../orange/actions'
import { actions as commonActions } from '../rootActions'

import { OrangeServiceType } from '../../utils/constants'
import { initialState } from './initialState'
import requestXMLGenerator from '../../utils/xml-request-generator'
import DefaultEnrollmentFormData from '../../utils/sample-data-templates/enrollment'
import { extractClientOffer, extractDateFromEraDate } from '../../utils/helper'

function getValue(input) {
  return JSON.stringify(input) === JSON.stringify({}) ? '' : input
}

export const pascalReducer: Reducer<typeof initialState> = (state = initialState, { type, input }) => {
  return produce(state, (draft) => {
    switch (type) {
      case actions.REQUEST_GENERATE_RANDOM_ID:
        draft.IsLoading = true
        draft.FormInitialized = false
        return draft
      case actions.SUCCESS_GENERATE_RANDOM_ID:
        draft.IsLoading = false
        draft.FormInitialized = true
        draft.Errors = []
        return draft
      case actions.UPDATE_FORM_FIELDS:
        draft.Form = Object.assign({}, draft.Form, input)
        draft.XML = requestXMLGenerator(draft.Form)
        return draft
      case actions.UPDATE_ENROLLMENT_XML:
        draft.XML = input
        return draft
      case actions.TOGGLE_EDIT_XML_FLAG:
        draft.EditXML = !draft.EditXML
        return draft
      case actions.REQUEST_CREATE_ENROLLMENT:
        draft.IsLoading = true
        draft.IsSuccess = false
        draft.Errors = []
        draft.StagedMDN = ''
        draft.StagedSubNo = ''
        draft.FormInitialized = false
        return draft
      case actions.SUCCESS_CREATE_ENROLLMENT:
        draft.IsLoading = false
        draft.IsSuccess = true
        draft.EditXML = false
        draft.Errors = []
        draft.StagedMDN = input.MDN
        draft.StagedSubNo = input.SubscriberNumber
        draft.Form = Object.assign({}, DefaultEnrollmentFormData, input.initialFormData)
        draft.XML = requestXMLGenerator(draft.Form)
        draft.FormInitialized = true
        return draft
      case actions.ERROR_CREATE_ENROLLMENT:
      case actions.ERROR_GENERATE_RANDOM_ID:
        draft.IsLoading = false
        draft.Errors = [input.message]
        draft.FormInitialized = true
        return draft
      case actions.RESET_ENROLLMENT_FORM:
        draft = {
          Form: DefaultEnrollmentFormData,
          FormInitialized: true,
          XML: ``,
          IsLoading: false,
          IsSuccess: false,
          Errors: [],
          EditXML: false,
          StagedMDN: '',
        }
        return draft
      //ORANGE for enrollment
      case orangeActions.SUCCESS_GET_ORANGE_RESPONSE:
        if (input.type === OrangeServiceType.EnrollmentResponse && input.response) {
          if (getValue(input.response.ResponseData.KyakuKbn === '4')) {
            draft.Form.MobileDeviceNumber = ''
            draft.Form.IMEI = getValue(input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.MblBuyHis?.Auicrdmblno)
            draft.Form.KeiyakushaKanaName = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaShimeiKana
            draft.Form.KeiyakushaKanjiName = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaShimeiKanji
            draft.Form.KeiyakushaPostNo = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaYubinNo
            draft.Form.KeiyakushaAddress = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaJusho1
            draft.Form.KeiyakushaAddress2 = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaJusho2
            draft.Form.KeiyakushaAddress3 = input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaJusho3
            draft.Form.KeiyakushaHomeTelNo = input.response.ResponseData?.OpenUserInfoShokai?.RenrakusakiTelNo
            draft.Form.SeikyushaKanaName = input.response.ResponseData?.OpenUserInfoShokai?.SeikyusakiShimeiKana
            draft.Form.SeikyushaKanjiName = input.response.ResponseData?.OpenUserInfoShokai?.SeikyusakiShimeiKanji
            draft.Form.SeikyushaPostNo = input.response.ResponseData?.OpenUserInfoShokai?.SeikyusakiYubinNo
            draft.Form.SeikyushaAddress = input.response.ResponseData?.OpenUserInfoShokai?.SeikyusakiJusho
            draft.Form.SeikyushaHomeTelNo = getValue(
              input.response.ResponseData?.OpenUserInfoShokai?.SeikyusakiTelNo || ''
            )
            draft.Form.ClientAssetSku = getValue(
              input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.MblBuyHis?.Gdscd
            )
            draft.Form.LoyaltyPoints = getValue(input.response.ResponseData?.OpenUserInfoShokai?.WalletPoint || '')

            draft.Form.ClientAccountType = getValue(
              input.response.ResponseData?.OpenUserInfoShokai?.KojinHojinKbn || ''
            )
            draft.Form.DateOfBirth = extractDateFromEraDate(
              input.response.ResponseData?.OpenUserInfoShokai?.KeiyakushaSeinenGappi || ''
            )
          } else {
            draft.Form.MobileDeviceNumber = getValue(input.response.ResponseData.KyakuShokai?.AuTelNum) || ''
            draft.Form.IMEI = getValue(input.response.ResponseData.KyakuShokai.SaisinTorokuIdokiSeizoNum)
            draft.Form.KeiyakushaKanaName = input.response.ResponseData.KyakuShokai.KeiyakuShaSimeiKana
            draft.Form.KeiyakushaKanjiName = input.response.ResponseData.KyakuShokai.KeiyakuShaSimeiKanji
            draft.Form.KeiyakushaPostNo = input.response.ResponseData.KyakuShokai.KeiyakuShaYubinNum
            draft.Form.KeiyakushaAddress = input.response.ResponseData.KyakuShokai.KeiyakuShaJusho
            draft.Form.KeiyakushaHomeTelNo = input.response.ResponseData.KyakuShokai.KeiyakuShaJitakuTelNum
            draft.Form.SeikyushaKanaName = input.response.ResponseData.KyakuShokai.SeikyuShaSimeiKana
            draft.Form.SeikyushaKanjiName = input.response.ResponseData.KyakuShokai.SeikyuShaSimeiKanji
            draft.Form.SeikyushaPostNo = input.response.ResponseData.KyakuShokai.SeikyuShaYubinNum
            draft.Form.SeikyushaAddress = input.response.ResponseData.KyakuShokai.SeikyuShaJusho
            draft.Form.SeikyushaHomeTelNo = getValue(input.response.ResponseData.KyakuShokai.SeikyuShaTelNum)
            draft.Form.ClientAssetSku = getValue(
              input.response.ResponseData.ShohinInfoShutoku.ShohinInfoMeisai.ShohinCd
            )
            draft.Form.LoyaltyPoints = getValue(input.response.ResponseData.RuikeiPointShokai.RuikeiPointSu)
            draft.Form.ClientAccountType = getValue(input.response.ResponseData.KyakuShokai.KojinHojinFlg)
            draft.Form.DateOfBirth = extractDateFromEraDate(input.response.ResponseData.KyakuShokai.Birthday)
          }
          draft.Form.ServiceFlag = getValue(input.response.ResponseData.KyakuKbn || '1')
          draft.Form.SubscriberNumber = getValue(input.response.ResponseData.KyakuShokai.KyakuCd)

          draft.Form.ICCId = getValue(input.response.ResponseData.KyakuShokai.SeizoNumIccid)
          draft.Form.HandsetType = getValue(input.response.ResponseData.KyakuShokai.KaisenType)

          draft.Form.eSIMPlanType = getValue(input.response.ResponseData.KyakuShokai.EsimShuruiKbn)
          draft.Form.eSIMProfileDownloadStatus =
            getValue(
              input.response.ResponseData.EsimInfoListShutoku &&
                input.response.ResponseData.EsimInfoListShutoku.ProfileDownloadJokyoCd
            ) || ''

          const offerDetails = extractClientOffer(input.response.ResponseData)
          draft.Form.ClientChannel = offerDetails.ClientChannelId
          draft.Form.ProgramType = offerDetails.ClientProductNumber

          draft.Form.AgreementStartDate = (
            getValue(input.response.ResponseData.KyakuShokai.KanyuShaOptionTekiyoKaisiYmd1) ||
            getValue(input.response.ResponseData.KyakuShokai.KanyuShaOptionTekiyoKaisiYmd2) ||
            getValue(input.response.ResponseData.KyakuShokai.AuServiceKaisiDay)
          ).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
          draft.Form.ClientAccountStartDate = getValue(
            input.response.ResponseData.KyakuShokai.AuServiceKaisiDay
          ).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

          draft.Form.PurchaseDate = getValue(
            input.response.ResponseData.KyakuShokai.HoshoKaisiDay1 ||
              input.response.ResponseData.KyakuShokai.KaiageIdokiShokaiSiyoKaisiDay
          ).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')

          draft.Form.AUID =
            getValue(input.response.ResponseData?.AuIdShareKanyuShaListShokaiOrange?.AliasAuId || '') ||
            getValue(input.response.ResponseData?.OpenUserInfoShokai?.AliasAuId || '')

          draft.Form.PastAssetInfo = input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.PastMblBuyHisLst
            ? (Array.isArray(input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.PastMblBuyHisLst)
                ? input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.PastMblBuyHisLst
                : [input.response.ResponseData?.AuTanmatuHoshoServiceShokai?.PastMblBuyHisLst]
              )?.map((a: any) => ({
                ClientAssetSku: getValue(a.Gdscd),
                IMEI: getValue(a.Auicrdmblno),
                PurchaseDate: getValue(a.SelRegymd).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                AssetStartDate: getValue(a.Apybgymd).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                AssetEndDate: getValue(a.ApyEndymd).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
              }))
            : []
          draft.XML = requestXMLGenerator(draft.Form)
        }

        return draft

      //Common Actions
      case commonActions.RESET_SUCCESS_FLAG:
        draft.IsSuccess = false
        return draft
      case commonActions.RESET_ERROR:
        draft.Errors = []
        return draft
      case commonActions.ADD_ERROR:
        draft.Errors = input
        return draft

      default:
        return draft
    }
  })
}
