export default {
  query: `
	select
		top 10 c.ContractID,
		c.ParentContractID,
		c.PrimarySubID,
		c.ExternalAccountID,
		c.ProgramTypeID,
		c.StartDate,
		c.EndDate,
		c.AllowedClaims,
		c.AllowedClaimPeriod,
		c.CreatedDT as CreatedDate,
		c.ModifiedDT as ModifiedDate
	from
		hsenroll..Contract c with(nolock) 
	inner join
		hsenroll..enrollment e with(nolock) on e.subid = c.primarysubid
	where
		cell_number = @mdn or account_number = @subnbr
	union all
	select
		top 10 c.ContractID,
		c.ParentContractID,
		c.PrimarySubID,
		c.ExternalAccountID,
		c.ProgramTypeID,
		c.StartDate,
		c.EndDate,
		c.AllowedClaims,
		c.AllowedClaimPeriod,
		c.CreatedDT as CreatedDate,
		c.ModifiedDT as ModifiedDate
	from
		hsenroll..Contract c with(nolock) 
	inner join
		hsenroll..enrollment_naksp e with(nolock) on e.subid = c.primarysubid
	where
		cell_number = @mdn or account_number = @subnbr
	order by CreatedDate asc;`,
  mapper: [
    { o: 'ContractID', d: 'ContractID' },
    { o: 'ParentContractID', d: 'ParentContractID' },
    { o: 'PrimarySubID', d: 'PrimarySubID' },
    { o: 'ExternalAccountID', d: 'ExternalAccountID' },
    { o: 'ProgramTypeID', d: 'ProgramTypeID' },
    { o: 'StartDate', d: 'StartDate' },
    { o: 'EndDate', d: 'EndDate' },
    { o: 'AllowedClaims', d: 'AllowedClaims' },
    { o: 'AllowedClaimPeriod', d: 'AllowedClaimPeriod' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
  ],
}
