import React from 'react'
import { Formik } from 'formik'
import schema from '../../form-schemas/analysis-form'
import { Col, Form, Button, Row, FormSelect } from 'react-bootstrap'

const AnalysisForm: React.FunctionComponent<{
  initialFormData: any
  search: any
  updateForm: any
  searchByAccountNbr?: boolean
  showInstruction?: boolean
}> = (props) => {
  const onSubmit = (form) => {
    props.search(form)
  }

  const handleFormChange = (handleChange, e) => {
    const {
      target: { name, value, checked, type },
    } = e
    const input = {}
    input[name] = type === 'checkbox' ? checked : value
    props.updateForm(input)
    handleChange(e)
  }

  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={props.initialFormData}>
      {({ values, touched, errors, handleBlur, handleSubmit, handleChange, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12" controlId="formGrideSearchType">
              <Form.Label>Search By</Form.Label>
              <FormSelect
                name="SearchType"
                value={values.SearchType}
                isInvalid={!!errors.SearchType}
                isValid={!errors.SearchType}
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(handleChange, e)}
              >
                <option value="1">Mobile Number</option>
                <option value="2">Subscriber Number</option>
                <option value="3">Reference Mobile Number</option>
              </FormSelect>
              {errors.SearchType && touched.SearchType ? (
                <Form.Control.Feedback type="invalid">
                  <>{errors.SearchType}</>
                </Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="valid">&nbsp;</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12" controlId="formGridSearchValue">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="text"
                placeholder="80-1234-5678"
                name="SearchValue"
                value={values.SearchValue}
                isInvalid={!!errors.SearchValue && !!touched.SearchValue}
                isValid={touched.SearchValue && !errors.SearchValue}
                onBlur={handleBlur}
                onChange={(e) => handleFormChange(handleChange, e)}
              />
              {errors.SearchValue && touched.SearchValue && (
                <Form.Control.Feedback type="invalid">
                  <>{errors.SearchValue}</>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              className="col-xl-3 mt-xl-4 pt-xl-1 col-lg-3 mt-lg-4 pt-lg-1 col-md-3 mt-md-4 pt-md-1 col-sm-2 pt-sm-1 mt-sm-4 col-2 mt-1"
              controlId="formGridButton"
            >
              <Form.Label>&nbsp;</Form.Label>
              <Button variant="success" type="submit" disabled={!isValid || !values.SearchValue}>
                Search
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default AnalysisForm
