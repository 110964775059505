export default {
  query: `select distinct
  -- co.client_channel_id as client_channel_id,
  -- p.product_name as ProductName,
  ifnull(t.tier_type_code, 'ALL') as TIER,
  concat(dr.minimum_value, '-', dr.maximum_value) as DTRNG,
  fam.incident_type_code as INCTYP,
  fam.network_type as NTWRKTYP,
  fam.covered_claim_count as CLAIMCOUNT,
  fam.service_used as SVCUSD,
  fam.disaster_aid as DSSTRAID,
  fa.attribute_name as AttributeName,
  fa.attribute_value as AttributeValue  
from
  product.client_offer co
inner join product.client_offer_solution_xref cosx on
  co.client_offer_id = cosx.client_offer_id
  and now() between cosx.effective_start_date and IFNULL(cosx.effective_end_date, STR_TO_DATE('31-DEC-2099', 'DD-MON-YYYY'))
  and co.active_ind = 1
inner join product.solution_product_xref spx on
  cosx.solution_id = spx.solution_id
  and now() between spx.effective_start_date and IFNULL(spx.effective_end_date, STR_TO_DATE('31-DEC-2099', 'DD-MON-YYYY'))
inner join product.product p on
  spx.product_id = p.product_id
  and p.active_ind = 1
inner join product.product_feature_xref pfxref on
   p.product_id = pfxref.product_id
inner join product.product_feature pf on
   pfxref.feature_id = pf.product_feature_id
  and pf.product_feature_id = pfxref.feature_id
  and pf.active_ind = 1
inner join product.feature_attribute_map fam on
  fam.product_feature_id = pf.product_feature_id
inner join product.feature_attribute_xref faxref on
  faxref.feature_attribute_map_id = fam.feature_attribute_map_id
inner join product.feature_attribute fa on
  fa.feature_attribute_id = faxref.feature_attribute_id
left join product.feature_criteria_xref fcx on
  fcx.product_feature_id = pf.product_feature_id
left join product.tier t on
  t.tier_id = fam.tier_id
  and t.active_ind = 1
left join product.criteria_group cg on
  cg.criteria_group_id = fcx.criteria_group_id
left join product.date_range dr on
  dr.date_range_id = fam.date_range_id
where
  pf.active_ind = 1
  and pf.feature_type_code = :code
  and now() between fam.effective_start_date and fam.effective_end_date
  and now() between faxref.effective_start_date and faxref.effective_end_date
  and co.client_channel_id = :clientchannelid
  and pfxref.client_channel_id = :clientchannelid
order by
  p.product_name,
  convert(t.tier_type_code, unsigned integer),
  fam.date_range_id,
  fam.incident_type_code,
  fam.network_type,
  fam.covered_claim_count,
  fam.service_used,
  fam.disaster_aid,
  case
		fa.attribute_name
         when 'Switch' then 1
		else fa.attribute_name
	end;`,
  mapper: [
    { o: 'PlanId', d: 'TIER' },
    { o: 'DateRange', d: 'DTRNG' },
    { o: 'IncidentType', d: 'INCTYP' },
    { o: 'NetworkType', d: 'NTWRKTYP' },
    { o: 'ClaimCount', d: 'CLAIMCOUNT' },
    { o: 'ServiceUsed', d: 'SVCUSD' },
    { o: 'DisasterAid', d: 'DSSTRAID' },
    { o: 'AttributeName', d: 'AttributeName', unique: true },
    { o: 'AttributeValue', d: 'AttributeValue', unique: true },
  ],
}
