import { IOrangeServiceResponseDetails } from '../types'

export const initialState: IOrangeServiceResponseDetails = {
  Form: {
    SearchValue: '',
    SearchType: '1',
    MobileDeviceNumber: '',
    PastAssetInfo: [],
  },
  ChargeOrder: null,
  ForceComplete: null,
  ICActivation: null,
  LonerOrder: null,
  SubmitOrder: null,
  NoLonerOrder: null,
  IsLoading: false,
  IsSuccess: '0',
  Errors: [],
}
