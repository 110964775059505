export default {
  query: ` select distinct aa.asset_attribute_name, acav.asset_attribute_value,ac.asset_catalog_name
    from asset.asset a
    inner join asset.asset_catalog ac on ac.asset_catalog_id = a.asset_catalog_id and a.active_ind = 1 and ac.active_ind = 1
    inner join asset.asset_catalog_attribute_value acav on acav.asset_catalog_id = ac.asset_catalog_id and acav.active_ind = 1
    join asset.asset_attribute aa on aa.asset_attribute_id = acav.asset_attribute_id and aa.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by acav.created_date asc;`,
  mapper: [
    { o: 'AssetCatalogName', d: 'asset_catalog_name' },
    { o: 'AssetAttributeName', d: 'asset_attribute_name' },
    { o: 'AssetAttributeValue', d: 'asset_attribute_value' },
  ],
}
