export default {
  query: `
	SELECT
		[SubID] ,
		[Client_Program_ID] as ClientProgramId,
		[Active],
		[Cell_Number] as CellNumber,
		[ESN] as IMEI,
		[Business_Name] as BusinessName,
		[Auser] as AUser,
		[Lname] ,
		[Fname] ,
		[Address1] ,
		[Address2] ,
		[City] ,
		[State] ,
		[Zip] ,
		[CountryID] ,
		[FDate] ,
		[EDate] ,
		[Activation_Date] as ActivationDate,
		[PMake] ,
		[Pmodel] ,
		[Market_Code] as MarketCode,
		[Feature_Code] as FeatureCode,
		[Equip_Type] as EquipmentType,
		[Source] ,
		[Mobile_IDN] as MobileIDN,
		[Status] ,
		[Device_ID] as DeviceId,
		[Account_Number] as AccountNumber,
		[AccountType] ,
		[ClientMarket_Id] as ClientMarketId,
		[ClientId] ,
		[AltReportingPointer]
	from
		hsenroll..enrollment_naksp with(nolock)
	where
		cell_number = @mdn or account_number = @subnbr
	union all
	SELECT
		[SubID] ,
		[Client_Program_ID] as ClientProgramId,
		[Active],
		[Cell_Number] as CellNumber,
		[ESN] as IMEI,
		[Business_Name] as BusinessName,
		[Auser] as AUser,
		[Lname] ,
		[Fname] ,
		[Address1] ,
		[Address2] ,
		[City] ,
		[State] ,
		[Zip] ,
		[CountryID] ,
		[FDate] ,
		[EDate] ,
		[Activation_Date] as ActivationDate,
		[PMake] ,
		[Pmodel] ,
		[Market_Code] as MarketCode,
		[Feature_Code] as FeatureCode,
		[Equip_Type] as EquipmentType,
		[Source] ,
		[Mobile_IDN] as MobileIDN ,
		[Status] ,
		[Device_ID] as DeviceId,
		[Account_Number] as AccountNumber,
		[AccountType] ,
		[ClientMarket_Id] as ClientMarketId,
		[ClientId] ,
		[AltReportingPointer]
	from
		hsenroll..enrollment with(nolock)
	where
		cell_number = @mdn or account_number = @subnbr;`,
  mapper: [
    { o: 'SubID', d: 'SubID' },
    { o: 'ClientProgramId', d: 'ClientProgramId' },
    { o: 'Active', d: 'Active' },
    { o: 'CellNumber', d: 'CellNumber' },
    { o: 'IMEI', d: 'IMEI' },
    { o: 'BusinessName', d: 'BusinessName' },
    { o: 'AUser', d: 'AUser' },
    { o: 'Lname', d: 'Lname' },
    { o: 'Fname', d: 'Fname' },
    { o: 'Address1', d: 'Address1' },
    { o: 'Address2', d: 'Address2' },
    { o: 'City', d: 'City' },
    { o: 'State', d: 'State' },
    { o: 'Zip', d: 'Zip' },
    { o: 'CountryID', d: 'CountryID' },
    { o: 'FDate', d: 'FDate' },
    { o: 'EDate', d: 'EDate' },
    { o: 'ActivationDate', d: 'ActivationDate' },
    { o: 'PMake', d: 'PMake' },
    { o: 'Pmodel', d: 'Pmodel' },
    { o: 'MarketCode', d: 'MarketCode' },
    { o: 'FeatureCode', d: 'FeatureCode' },
    { o: 'EquipmentType', d: 'EquipmentType' },
    { o: 'Source', d: 'Source' },
    { o: 'MobileIDN', d: 'MobileIDN' },
    { o: 'Status', d: 'Status' },
    { o: 'DeviceId', d: 'DeviceId' },
    { o: 'AccountNumber', d: 'AccountNumber' },
    { o: 'AccountType', d: 'AccountType' },
    { o: 'ClientMarketId', d: 'ClientMarketId' },
    { o: 'ClientId', d: 'ClientId' },
    { o: 'AltReportingPointer', d: 'AltReportingPointer' },
  ],
}
