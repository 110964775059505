export default {
  query: `select distinct
	clc.claimhold_id as ClaimHoldId,
	clc.Claim_ID as ClaimId,
	co.name as HoldType,
	clc.releasereason_code as Reason,
	clc.released_by as ReleasedBy,
	clc.released_dtm as ReleasedDateTime,
	clc.Created_By as CreatedBy,
	clc.Created_Dtm as CreatedDate,
	clc.Modified_By as ModifiedBy,
	clc.Modified_Dtm as ModifiedDate,
	clc.Deleted_Flg as DeletedFlag
from
	Intelliset.dbo.ClaimHold clc with(nolock)
left join intelliset.dbo.code co with(nolock) on 
  co.code_id = clc.holdtype_id 
inner join intelliset.dbo.claim c with(nolock) on
	c.claim_id = clc.claim_id
inner join intelliset.dbo.subscriber s with(nolock) on
	s.subscriber_id = c.subscriber_id
where
  s.wireless_number = @mdn or s.account_number = @subnbr
order by clc.Created_Dtm asc;`,
  mapper: [
    { o: 'ClaimHoldId', d: 'ClaimHoldId' },
    { o: 'ClaimId', d: 'ClaimId' },
    { o: 'HoldType', d: 'HoldType' },
    { o: 'Reason', d: 'Reason' },
    { o: 'ReleasedBy', d: 'ReleasedBy' },
    { o: 'ReleasedDateTime', d: 'ReleasedDateTime' },
    { o: 'CreatedBy', d: 'CreatedBy' },
    { o: 'CreatedDate', d: 'CreatedDate' },
    { o: 'ModifiedBy', d: 'ModifiedBy' },
    { o: 'ModifiedDate', d: 'ModifiedDate' },
    { o: 'DeletedFlag', d: 'DeletedFlag' },
  ],
}
