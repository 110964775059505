import log from 'loglevel'

log.setDefaultLevel('WARN')
let latestLogs: any[] = []

const wrapLogLevel = (callback: any, pre: string | undefined = undefined) => {
  return (message: string, ...args: any) => {
    const newArgs = [new Date().toISOString(), pre, message, ...args]
    callback.apply(callback, newArgs)
    if (pre !== 'TRACE' && pre !== 'DEBUG') {
      latestLogs.push({
        level: pre,
        date: new Date(),
        message,
        arguments: args.length > 1 ? args : args[0],
      })
    }
  }
}

export const logger = {
  info: wrapLogLevel(log.info, 'INFO'),
  debug: wrapLogLevel(log.debug, 'DEBUG'),
  error: wrapLogLevel(log.error, 'ERROR'),
  warning: wrapLogLevel(log.warn, 'WARN'),
  warn: wrapLogLevel(log.warn, 'WARN'),
  trace: wrapLogLevel(log.trace, 'TRACE'),
  setLevel: log.setLevel,
  flushLogs: () => {
    const logs = [...latestLogs]
    latestLogs = []
    return logs
  },
}
