export default {
  query: ` select distinct a.agreement_id, aa.agreement_attribute_name, aav.value
    from asset.agreement a
    join asset.agreement_asset_xref aax on aax.agreement_id = a.agreement_id and a.active_ind = 1
    join asset.agreement_attribute_value aav on aav.agreement_id = a.agreement_id and aav.active_ind = 1
    join asset.agreement_attribute aa on aa.agreement_attribute_id = aav.agreement_attribute_id and aa.active_ind = 1
    join asset.asset a2 on a2.asset_id = aax.asset_id and a2.active_ind = 1 and a2.asset_instance_code = 'enrolled'
    where a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr order by a.created_date asc;`,
  mapper: [
    { o: 'AgreementId', d: 'agreement_id' },
    { o: 'AgreementAttributeName', d: 'agreement_attribute_name' },
    { o: 'Value', d: 'value' },
  ],
}
