export default {
  query: `select distinct cc.CUSTOMER_CASE_ID, cc.CUSTOMER_ID, cc.ASSET_ID, cc.AGREEMENT_ID, cc.CLIENT_ACCOUNT_ID, cc.CUSTOMER_CASE_STATUS_CODE, cc.CUSTOMER_CASE_NBR, cc.CUSTOMER_CASE_START_DATE, cc.CUSTOMER_CASE_END_DATE, cc.CANCELLATION_REASON_CODE, cc.INCIDENT_TYPE_CODE, cc.WARRANTY_TYPE_CODE, cc.ORIGINAL_INCIDENT_DATE, cc.ORIGINAL_COVERED_EVENT_CODE, cc.INVOICE_ACCOUNT_NBR, cc.CUSTOMER_CATEGORY_CODE, cc.EXTERNAL_REFERENCE_ID, cc.REFERENCE_STATUS_CODE, cc.CREATED_SYSTEM_ID, cc.CREATED_BY, cc.CREATED_DATE, cc.UPDATED_BY, cc.UPDATED_DATE, cc.BULK_UPDATED_BY, cc.BULK_UPDATED_DATE, cc.ACTIVE_IND
    from customer.customer_case cc
    join asset.asset a on a.asset_id = cc.asset_id and cc.active_ind = 1 and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by cc.created_date asc`,
  mapper: [
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'CustomerId', d: 'CUSTOMER_ID' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'ClientAccountId', d: 'CLIENT_ACCOUNT_ID' },
    { o: 'CustomerCaseStatus', d: 'CUSTOMER_CASE_STATUS_CODE' },
    { o: 'CustomerCaseNbr', d: 'CUSTOMER_CASE_NBR' },
    { o: 'CustomerCaseStartDate', d: 'CUSTOMER_CASE_START_DATE' },
    { o: 'CustomerCaseEndDate', d: 'CUSTOMER_CASE_END_DATE' },
    { o: 'CancellationReason', d: 'CANCELLATION_REASON_CODE' },
    { o: 'IncidentType', d: 'INCIDENT_TYPE_CODE' },
    { o: 'WarrantyType', d: 'WARRANTY_TYPE_CODE' },
    { o: 'OriginalIncidentDate', d: 'ORIGINAL_INCIDENT_DATE' },
    { o: 'OriginalCoveredEvent', d: 'ORIGINAL_COVERED_EVENT_CODE' },
    { o: 'InvoiceAccountNbr', d: 'INVOICE_ACCOUNT_NBR' },
    { o: 'CustomerCategory', d: 'CUSTOMER_CATEGORY_CODE' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ReferenceStatus', d: 'REFERENCE_STATUS_CODE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
