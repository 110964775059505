import { connect } from 'react-redux'
import React, { Component, ReactNode } from 'react'
import { Row, Container, Alert, Col } from 'react-bootstrap'

import { IComponents, IState } from '../../../store/types'
import { actionCreator } from '../../../store/rootActions'
import { orangeActionCreator } from '../../../store/orange/actions'
import { pascalActionCreator } from '../../../store/pascal/actions'
import { isClientEnvironmentSelected } from '../../../store/selectors'

import Spinner from '../../../components/spinner'
import SearchForm from '../../../components/search-form-v2'
import EnrollmentForm from '../../../components/enrollment-form'

const mapStateToProps = (state: IState) => {
  return {
    StubDetails: state.OrangeInformation.Form,
    PascalInformation: state.PascalInformation,
    IsSearchLoading: state.OrangeInformation.IsLoading,
    IsLoading: state.PascalInformation.IsLoading,
    IsSuccess: state.PascalInformation.IsSuccess,
    IsSearchSuccess: state.OrangeInformation.IsSuccess,
    Errors: state.PascalInformation.Errors.concat(
      state.OrangeInformation && state.OrangeInformation.Errors ? state.OrangeInformation.Errors : []
    ),
    IsClientEnvironmentSelected: isClientEnvironmentSelected(),
  }
}

const mapDispatchToProps = {
  createEnrollment: pascalActionCreator.createEnrollment,
  updateEnrollmentForm: pascalActionCreator.updateEnrollmentForm,
  resetSuccessFlag: actionCreator.resetSuccessFlag,
  resetError: actionCreator.resetError,
  addError: actionCreator.addError,
  toggleEditXML: pascalActionCreator.toggleEditXML,
  updateEnrollmentXML: pascalActionCreator.updateEnrollmentXML,
  updateOrangeSearchForm: orangeActionCreator.updateOrangeSearchForm,
  getOrangeServiceResponse: orangeActionCreator.getOrangeServiceResponse,
}

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IComponents

class UpdateEnrollment extends Component<IProps, IState> {
  inputEl: any
  enrollmentForm: any
  initialFormData: any
  fieldsetMargin = 0.2
  formMargin = 0.5

  constructor(props: any) {
    super(props)
    this.enrollmentForm = React.createRef()
    this.initialFormData = {
      MobileDeviceNumber: '',
    }
  }

  componentDidMount() {
    this.props.resetSuccessFlag()
  }

  saveEnrollmentDetails(data) {
    this.props.createEnrollment(Object.assign({}, data, { isUpdateOperation: true }))
  }

  render(): ReactNode {
    return (
      <Container fluid>
        {(this.props.IsLoading || this.props.IsSearchLoading) && <Spinner />}
        {!this.props.IsLoading && this.props.IsSuccess && (
          <Alert variant="success" onClose={() => this.props.resetSuccessFlag()} dismissible>
            {this.props.PascalInformation.StagedMDN &&
              `Enrollment details successfully updated for mobile number: ${this.props.PascalInformation.StagedMDN}.`}
            {this.props.PascalInformation.StagedSubNo &&
              `Enrollment details successfully updated for Subscriber Number: ${this.props.PascalInformation.StagedSubNo}`}
          </Alert>
        )}
        {!this.props.IsSearchLoading && this.props.IsSearchSuccess === '3' && (
          <Alert variant="danger" onClose={() => this.props.resetSuccessFlag()} dismissible>
            {`Enrollment details not present for mobile number ${
              this.props?.StubDetails?.MobileDeviceNumber?.split('|')[0]
            }.`}
          </Alert>
        )}
        {!this.props.IsLoading && !this.props.IsSearchLoading && this.props.Errors && this.props.Errors.length > 0 && (
          <Alert variant="danger" onClose={() => this.props.resetError()} dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            {this.props.Errors.map((e, i) => {
              return <p key={i}>{e || 'Details not available!!!'}</p>
            })}
          </Alert>
        )}
        {this.initialFormData && (
          <Row>
            <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <SearchForm
                showInstruction={false}
                initialFormData={this.initialFormData}
                searchByAccountNbr={false}
                search={() =>
                  this.props.getOrangeServiceResponse({ type: this.props.type, data: this.props.StubDetails })
                }
                updateForm={(e) => this.props.updateOrangeSearchForm(e)}
              />
            </Col>
            {!this.props.IsSearchLoading ? (
              <EnrollmentForm
                isUpdateFormOperation={true}
                isDataLoaded={
                  !!(
                    this.props.PascalInformation.Form.MobileDeviceNumber ||
                    this.props.PascalInformation.Form.SubscriberNumber
                  )
                }
                data={this.props.PascalInformation}
                initialFormData={this.props.PascalInformation.Form}
                customeStyle={{ fieldsetMargin: this.fieldsetMargin, formMargin: this.formMargin }}
                formReference={this.enrollmentForm}
                toggleEditXML={() => this.props.toggleEditXML()}
                handleOnSave={(e) => this.saveEnrollmentDetails(e)}
                handleFormChange={(e) => this.props.updateEnrollmentForm(e)}
              />
            ) : (
              <Col className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"></Col>
            )}
          </Row>
        )}
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEnrollment)
