import API from '../../services'
import { call, take, put } from 'redux-saga/effects'
import { actions, orangeActionCreator as actionCreator } from './actions'

import {
  mapKDDIResponseObject,
  mapKDDIRequestObject,
  generateGetStubRequest,
  generateDefaultStubResponses,
  generateErrorStubResponses,
} from '../../utils/helper'
import { OrangeServiceType, OperationTypes, URITypes } from '../../utils/constants'

function* getOrangeServiceResponse(input: {
  type: string | number
  data: { MobileDeviceNumber: string; SearchType: string; SearchValue: string }
}) {
  try {
    const url =
      input.type === OrangeServiceType.EnrollmentResponse && input.data.SearchType === '2'
        ? URITypes.SubEnrollment
        : URITypes[input.type]
    const request: any = generateGetStubRequest(
      input.type,
      input.data.MobileDeviceNumber,
      input.type === OrangeServiceType.ChargeOrderResponse ? '2' : '0'
    )
    const response = yield call(API.OrangeService.search, request, url)

    if (response && (response.status === 200 || response.status === 204)) {
      const data: any = mapKDDIResponseObject(response.data, input.type)
      yield put(actionCreator.getOrangeServiceResponseSuccess({ type: input.type, response: data }))
    } else {
      yield put(actionCreator.getOrangeServiceResponseError({ type: input.type, error: response.data }))
    }
  } catch (error) {
    yield put(actionCreator.getOrangeServiceResponseError({ type: input.type, error: error }))
  }
}

export function* getOrangeServiceResponseFlow() {
  while (true) {
    const { input } = yield take(actions.REQUEST_GET_ORANGE_RESPONSE)
    yield call(getOrangeServiceResponse, input)
  }
}

function* saveOrangeServiceResponse(data: any) {
  const input = JSON.parse(JSON.stringify(data))
  const skipType =
    [
      OrangeServiceType.ICActivationResponse,
      OrangeServiceType.ForceCompleteResponse,
      OrangeServiceType.ChargeOrderResponse,
      OrangeServiceType.SubmitOrderResponse,
    ].indexOf(input.operation) > -1
  let isEmpty = false
  let isErrorResponse = false

  if (Array.isArray(input.data)) {
    isEmpty =
      input.data.length === 0 ||
      input.data.every((o: { [s: string]: unknown } | ArrayLike<unknown>) => {
        return !Object.values(o).some((x) => x !== null && x !== '')
      })
  } else {
    isEmpty = !input.data || !Object.values(input.data).some((x) => x !== null && x !== '')
    isErrorResponse =
      input.data &&
      Object.keys(input.data).some((x) => x && (x.toLowerCase() === 'error' || x.toLowerCase() === 'errorcode'))
  }
  let request: any
  if (isEmpty) {
    request = generateDefaultStubResponses(input.operation, input.key.split('|')[0], input.key.split('|')[1])
  } else if (isErrorResponse) {
    request = {
      CreateStubRequest: {
        Uri: URITypes[input.operation],
        Key: input.key.split('|')[0],
        OperationName: OperationTypes[input.operation],
        Type: skipType ? '' : input.key.split('|')[1],
        ContentType: 'xml',
        Body: generateErrorStubResponses(input.data.Error || input.data.ErrorCode, input.operation),
      },
    }
  } else {
    request = {
      CreateStubRequest: {
        Uri: URITypes[input.operation],
        Key: input.key.split('|')[0],
        OperationName: OperationTypes[input.operation],
        Type: skipType ? '' : input.key.split('|')[1],
        ContentType: 'xml',
        Body: mapKDDIRequestObject(input.data, input.operation),
      },
    }
  }
  try {
    const response = yield call(API.OrangeService.save, request)
    if (response && response.status === 200) {
      yield put(actionCreator.saveOrangeServiceResponseSuccess({ type: input.type, response: response.data }))
    } else {
      yield put(actionCreator.saveOrangeServiceResponseError({ type: input.type, error: response.data }))
    }
  } catch (error) {
    yield put(actionCreator.saveOrangeServiceResponseError({ type: input.type, error: error }))
  }
}

export function* saveOrangeServiceResponseFlow() {
  while (true) {
    const { input } = yield take(actions.REQUEST_SAVE_ORANGE_RESPONSE)
    yield call(saveOrangeServiceResponse, input)
  }
}
