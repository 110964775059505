import axios from './axios'
import { BaseAPI } from './base-api'
import { activeStack } from '../store/selectors'

class KDDIAPI extends BaseAPI {
  public searchData = (request: any) => {
    return axios
      .post(`${this.getConfigKey('baseURL')}${this.endpoints.msDal}`, request, {
        headers: this.headers(),
      })
      .catch(() => {
        //igonre error message for data sync
        return {}
      })
  }

  public searchLogs = (request: any) => {
    return Promise.resolve([])
    return axios
      .post(
        `${this.getConfigKey('baseLegacyURL')}${this.endpoints.findLogs}`,
        Object.assign(request, {
          Environment: this.getConfigKey('searchLogEnvironment') || activeStack().replace(/\d+/g, '').toUpperCase(),
        }),
        {
          headers: this.headers(),
        }
      )
      .catch(() => {
        //igonre error message for data sync
        return {}
      })
  }
}

export default KDDIAPI
