import { IKibanaConfig, IEndpoint } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-sqa1.uap.jpnmob-acyan.npr.aws.asurion.net/api',
  searchLogEnvironment: 'SQAD365',
}

export const kibanaConfig: IKibanaConfig = {
  kibanaIndex: '02e73c50-fe0e-11ee-84b9-b919e236a59a',
  kibanaGQLIndex: '0b986cc0-fe0e-11ee-8e62-1b550b2b5585',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}
