import React from 'react'
import beautify from 'xml-beautifier'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

export default (props) => {
  let message = ''
  try {
    if (props.message.includes('xml version="1.0"') || props.message.includes('/>') || props.message.includes('</')) {
      message = props.message.replace(/\sxmlns[^"]+"[^"]+"/g, '')
      message = beautify(
        message
          .replace(' xmlns:xsd="http://www.w3.org/2001/XMLSchema"', '')
          .replace(' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"', '')
      )
    } else {
      const parsedMessage = JSON.parse(props.message.split("' : ")[1] || props.message)
      message = JSON.stringify(parsedMessage, null, 2)
    }
  } catch (err) {
    message = beautify(
      props.message
        .replace(' xmlns:xsd="http://www.w3.org/2001/XMLSchema"', '')
        .replace(' xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"', '')
    )
  }

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" className="formDiv" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5">
          {props.header}
        </Modal.Title>
        <div style={{ marginLeft: '1rem' }}>
          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-modal">copy to clipboard</Tooltip>}>
            <CopyToClipboard text={beautify(message)}>
              <Button variant="outline-dark float-end" size="sm">
                Copy Message
              </Button>
            </CopyToClipboard>
          </OverlayTrigger>
        </div>
      </Modal.Header>
      <Modal.Body className="formDiv modal-body-custom">
        <pre>{beautify(message)}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
