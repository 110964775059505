import { IActiveConfig, IConstants } from '../types'

import { defaultEndpointConfig as UAT1Endpoints } from './uat1'
import { defaultEndpointConfig as UAT2Endpoints } from './uat2'
import { defaultEndpointConfig as UAT3Endpoints } from './uat3'
import { defaultEndpointConfig as UAT4Endpoints } from './uat4'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: false,
  defaultUrl: 'claim/information',
  allowLegacySearch: false,
  kibanaIndex: 'uap-ajkk-npr-api',
  kibanaGQLIndex: 'uap-ajkk-npr-cluster',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-ajkk-uat-jpmob-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '331f6fa8gsea7fquajv2u4tlt',
    userPoolId: 'ap-northeast-1_BqB4Y3XX0',
    identityPoolId: 'ap-northeast-1:6484550a-d8f0-475f-8675-a33470a1204d',
    identityProvider: 'ajkk-uat-jpmob-SSO',
  },
  constants: defaultConstants,
  endpoints: [
    {
      key: 'uat1',
      value: UAT1Endpoints,
    },
    {
      key: 'uat2',
      value: UAT2Endpoints,
    },
    {
      key: 'uat3',
      value: UAT3Endpoints,
    },
    {
      key: 'uat4',
      value: UAT4Endpoints,
    },
  ],
}
