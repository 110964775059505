const clients = [
  {
    DisaplayName: 'KDDI',
    Code: '42818A88285511E9A38A063D3002FD4C',
    ClientChannel: '7D42D83E285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    Stacks: [
      {
        DisaplayName: 'LT',
        Code: 'lt',
        DisplayOrder: 1,
        IsProductionAccount: false,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'SQA',
        Code: 'sqa',
        DisplayOrder: 2,
        IsProductionAccount: false,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'UAT',
        Code: 'uat',
        DisplayOrder: 3,
        IsProductionAccount: false,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'TRN',
        Code: 'trn',
        DisplayOrder: 4,
        IsProductionAccount: true,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'PRD',
        Code: 'prd',
        DisplayOrder: 5,
        IsProductionAccount: true,
        IsProductionEnvironment: true,
      },
    ],
    Enable: true,
  },
  {
    DisaplayName: 'AJKK',
    Code: '1E8EBE7748A60600865EE185A02770C7',
    ClientChannel: '7D44A33997683398E0535E38060ADC0C',
    DisplayOrder: 2,
    Stacks: [
      {
        DisaplayName: 'SQA',
        Code: 'sqa',
        DisplayOrder: 1,
        IsProductionAccount: false,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'UAT',
        Code: 'uat',
        DisplayOrder: 2,
        IsProductionAccount: false,
        IsProductionEnvironment: false,
      },
      {
        DisaplayName: 'TRN',
        Code: 'trn',
        DisplayOrder: 3,
        IsProductionAccount: true,
        IsProductionEnvironment: true,
      },
    ],
    Enable: true,
  },
]

const clientChannels = [
  {
    DisaplayName: 'AU',
    Code: '7D42D83E285511E9A38A063D3002FD4C',
    ClientId: '42818A88285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    EnrollmentAllowed: true,
  },
  {
    DisaplayName: 'UQ',
    Code: '1EAA245F60F40364938AFF1A774566E5',
    ClientId: '42818A88285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    EnrollmentAllowed: true,
  },
  {
    DisaplayName: 'POVO',
    Code: '1EAA245F60DE03D5938AFF1A774566E5',
    ClientId: '42818A88285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    EnrollmentAllowed: false,
  },
  {
    DisaplayName: 'OPEN KFS',
    Code: '09E81242783E11EC8C1C06635BE973AB',
    ClientId: '42818A88285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    EnrollmentAllowed: false,
  },
  {
    DisaplayName: 'AU/UQ SIM Only',
    Code: '09E81242783E11EC8C1C06635BE973E5',
    ClientId: '42818A88285511E9A38A063D3002FD4C',
    DisplayOrder: 1,
    EnrollmentAllowed: false,
  },
]

const programs = [
  {
    DisaplayName: 'Soluto',
    Code: '5',
    DisplayOrder: 1,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(NP)',
    Code: '1',
    DisplayOrder: 2,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
    PureKFSAllowed: true,
  },
  {
    DisaplayName: 'KFS(NP)+Soluto',
    Code: '15',
    DisplayOrder: 3,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(NF)',
    Code: '2',
    DisplayOrder: 4,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
    PureKFSAllowed: true,
  },
  {
    DisaplayName: 'KFS(NF)+Soluto',
    Code: '25',
    DisplayOrder: 5,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(OP)',
    Code: '3',
    DisplayOrder: 6,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(OP)+Soluto',
    Code: '35',
    DisplayOrder: 7,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(OF)',
    Code: '4',
    DisplayOrder: 8,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'KFS(OF)+Soluto',
    Code: '45',
    DisplayOrder: 9,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only',
    Code: '18',
    DisplayOrder: 10,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only+Soluto',
    Code: '185',
    DisplayOrder: 11,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only+KFS(NP)',
    Code: '181',
    DisplayOrder: 12,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only+KFS(NP)+Soluto',
    Code: '1815',
    DisplayOrder: 13,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only+KFS(NF)',
    Code: '182',
    DisplayOrder: 14,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only Cncl',
    Code: '19',
    DisplayOrder: 15,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Cncl+Soluto',
    Code: '195',
    DisplayOrder: 16,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only Cncl+KFS(NP)',
    Code: '191',
    DisplayOrder: 17,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
  {
    DisaplayName: 'SIM Only Cncl+KFS(NF)',
    Code: '192',
    DisplayOrder: 18,
    Active: true,
    ApplicableClientChannels: ['7D42D83E285511E9A38A063D3002FD4C', '1EAA245F60F40364938AFF1A774566E5'],
  },
]

const sagawaOrderStatus = [
  {
    disaplyName: '...',
    value: '',
  },
  {
    disaplyName: 'Accepted',
    value: 'Accepted',
  },
  {
    disaplyName: 'Started',
    value: 'Started',
  },
  {
    disaplyName: 'Departed',
    value: 'Departed',
  },
  {
    disaplyName: 'Arrived',
    value: 'Arrived',
  },
  {
    disaplyName: 'Finished',
    value: 'Finished',
  },
]

const eventOptions = [
  {
    DisaplayName: 'Invalid content',
    Code: 'INVLDCONT',
    DisplayOrder: 1,
  },
  {
    DisaplayName: 'Certification Failed',
    Code: 'CERTFLD',
    DisplayOrder: 2,
  },
  {
    DisaplayName: "Request can't be processed",
    Code: 'CANTPROCES',
    DisplayOrder: 3,
  },
  {
    DisaplayName: 'Too Many Requests',
    Code: 'MANYREQ',
    DisplayOrder: 4,
  },
  {
    DisaplayName: 'Internal Server Error',
    Code: 'INTRNALERR',
    DisplayOrder: 5,
  },
  {
    DisaplayName: 'Service Down',
    Code: 'SVCDWN',
    DisplayOrder: 6,
  },
]

const productFeatureMaster = {
  'Adult Age': 'Adult Age',
  'Data Recovery Fee': 'Data Recovery Fee',
  'Data Recovery Tax Rate': 'Data Recovery Tax Rate',
  'HD Fee': 'Hour Delivery Fee',
  HDSLA: 'Hour Delivery SLA',
  'Model Upgrade Switch': 'Model Upgrade Switch',
  'Service Fee Discount': 'Service Fee Discount',
  'Tax Rate': 'Tax Rate',
  'Upgrade Term': 'Upgrade Term',
  'Asurion Warranty Period': 'Asurion Warranty Period',
  'Claim Period': 'Claim Period',
  'Data Recovery': 'Data Recovery Switch Old',
  'Data Recovery Eligibilty': 'Data Recovery Switch',
  'ESIM Fee': 'ESIM Fee',
  'ESIM Fee Discount': 'ESIM Fee Discount',
  LONGTERMDISCOUNT: 'Long Term Discount',
  'LTE Discount': 'SIM Discount',
  'Max Claim Limit': 'Max Claim Limit',
  NOLOANERDISCOUNT: 'No Loaner Discount',
  REUSEKFS: 'Reuse KFS Switch',
  'Service Fee': 'Service Fee',
  SHIPPINGOPTN: 'Applicable Shipping Options',
  'SIM Fee': 'SIM Fee',
  'SNR FEE': 'Penalty Fee',
  WEBDISCOUNT: 'Web Discount',
  'NETWORK MISMATCH CONFIG': 'Network Mismatch Config',
  AllowSaturdayDelivery: 'Allow Saturday Delivery',
  DefaultShippingMethod: 'Default Shipping Method',
  OvernightShippingRate: 'Overnight Shipping Rate',
  SaturdayPickupFee: 'Saturday Pickup Fee',
  SaturdayShippingRate: 'Saturday Shipping Rate',
  SecondDayShippingRate: 'Second Day Shipping Rate',
}

const serviceTypeFlg = [
  {
    DisaplayName: 'In Service',
    Code: '1',
    DisplayOrder: 1,
  },
  {
    DisaplayName: 'Service On Pause',
    Code: '2',
    DisplayOrder: 2,
  },
  {
    DisaplayName: 'Service Canceled',
    Code: '3',
    DisplayOrder: 3,
  },
  {
    DisaplayName: 'Pure Open',
    Code: '4',
    DisplayOrder: 4,
  },
]

export { programs, clients, clientChannels, eventOptions, sagawaOrderStatus, productFeatureMaster, serviceTypeFlg }
