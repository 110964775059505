import { IActiveConfig, IConstants } from '../types'

import { defaultEndpointConfig as UAT1Endpoints, kibanaConfig as UAT1KibanaConfig } from './uat1'
import { defaultEndpointConfig as UAT2Endpoints, kibanaConfig as UAT2KibanaConfig } from './uat2'
// import { defaultEndpointConfig as UAT3Endpoints } from './uat3'
// import { defaultEndpointConfig as UAT4Endpoints } from './uat4'
// import { defaultEndpointConfig as UAT5Endpoints } from './uat5'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: 'uap-acyan-npr-api',
  kibanaGQLIndex: 'uap-acyan-npr-cluster',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-uat-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '4ivufqh7qs4p27bacmlkufpucn',
    userPoolId: 'ap-northeast-1_zaaivQPhB',
    identityPoolId: '',
    identityProvider: 'acyan-uat-SSO',
  },
  constants: defaultConstants,
  uat1: UAT1KibanaConfig,
  uat2: UAT2KibanaConfig,
  endpoints: [
    {
      key: 'uat1',
      value: UAT1Endpoints,
    },
    {
      key: 'uat2',
      value: UAT2Endpoints,
    },
    // {
    //   key: 'uat3',
    //   value: UAT3Endpoints,
    // },
    // {
    //   key: 'uat4',
    //   value: UAT4Endpoints,
    // },
    // {
    //   key: 'uat5',
    //   value: UAT5Endpoints,
    // },
  ],
}
