import { extractEraDateFromDate } from './helper'

export default (data) => {
  const _isNewKFSOffer = ['1', '15'].includes(data['ProgramType'])
  const _isOldFSOffer = ['3', '35'].includes(data['ProgramType'])
  const _isSolutoProgram = ['5', '15', '25', '35', '45', '185', '195'].includes(data['ProgramType'])

  const _isSimOnlyOffer = ['18', '19'].includes(data['ProgramType'])
  const _isSimOnlyCnclOffer = ['19', '191', '192', '195'].includes(data['ProgramType'])
  const _isSimOnlyPlusSolutoOffer = ['185', '195'].includes(data['ProgramType'])
  const _isSimOnlyPlusKFSOffer = ['181', '182', '191', '192'].includes(data['ProgramType'])
  const _isSimOnlyPluseKFSFreeOffer = ['182'].includes(data['ProgramType'])
  const _isSimOnlyCnclPluseKFSFreeOffer = ['192'].includes(data['ProgramType'])
  const _isSimOnlyPlusKFSPluseSolutoOffer = ['1815'].includes(data['ProgramType'])
  const _isSimOnlyPlusKFSFreePluseSolutoOffer = ['1915'].includes(data['ProgramType'])

  const _agreementStartDate = data['AgreementStartDate'] ? data['AgreementStartDate'].replace(/-/g, '') : ''
  const _agreementEndDate = new Date()
    .toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo', month: '2-digit', day: '2-digit', year: 'numeric' })
    .replace(/\//g, '')
  const _clientAccountStartDate = data['ClientAccountStartDate'] ? data['ClientAccountStartDate'].replace(/-/g, '') : ''
  const _purchaseDate = data['PurchaseDate'] && !_isSimOnlyOffer ? data['PurchaseDate'].replace(/-/g, '') : ''
  const _dateOfBirth = data['DateOfBirth'] ? extractEraDateFromDate(data['DateOfBirth']) : ''
  const _isPureOpen = ['4'].includes(data['ServiceFlag'])
  const _isNonOpen = ['1'].includes(data['ServiceFlag'])
  const _isOpenKFS = ['2', '3', '4'].includes(data['ServiceFlag'])
  const _isLatestDeviceSubscriber = data['IsLatestDeviceSubscriber']

  const toXml = (data: any) => {
    return data?.reduce((result: any, el: any) => {
      return el?.ClientAssetSku && el?.IMEI && el?.PurchaseDate && el?.AssetStartDate
        ? result +
            `<PastMblBuyHisLst>
               <SvType>1</SvType>
               <Auicrdmblno>${el?.IMEI}</Auicrdmblno>
               <Gdscd>${el?.ClientAssetSku}</Gdscd>
               <SelRegymd>${el?.PurchaseDate?.replace(/-/g, '')}</SelRegymd>
               <ApySts>${el?.IsPastDeviceSubscriber || ''}</ApySts>
               <Apybgymd>${el?.AssetStartDate?.replace(/-/g, '')}</Apybgymd>
               <ApyEndymd>${el?.AssetEndDate ? el.AssetEndDate.replace(/-/g, '') : ''}</ApyEndymd>
               <GdsOfclnm>ＳＯＶ３６ 携帯電話本体 ブラック</GdsOfclnm>              
            </PastMblBuyHisLst>`
        : result
    }, '')
  }
  return `<?xml version="1.0" encoding="UTF-8"?>
<OrangeResponseInterface>
   <ResponseHeader>
      <StatusCode>0</StatusCode>
      <GyomuErrorCode>000000</GyomuErrorCode>
   </ResponseHeader>
   <ResponseData>
  <KyakuKbn>${data['ServiceFlag']}</KyakuKbn>					  
      <KyakuShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>DP</DenbunType>
         <MessageId />
         <KeiyakuSisha>001</KeiyakuSisha>
         <AuTelNum>${data['MobileDeviceNumber']}</AuTelNum>
         <SeizoNumIccid>${data['ICCId']}</SeizoNumIccid>
         <KyakuCd>${data['SubscriberNumber']}</KyakuCd>
         <EsimShuruiKbn>${_isSimOnlyOffer || _isPureOpen ? '' : data['eSIMPlanType']}</EsimShuruiKbn>
         <NetworkKbn>5</NetworkKbn>
         <TogoBillingSeikyuKbn>3</TogoBillingSeikyuKbn>
         <JigyoshaTuteiFlg>0</JigyoshaTuteiFlg>
         <RyokinTuteiFlg>0</RyokinTuteiFlg>
         <RyokinHatuteiFlg>0</RyokinHatuteiFlg>
         <KinkyuTuteiFlg>0</KinkyuTuteiFlg>
         <IchijiChudanFlg>0</IchijiChudanFlg>
         <AnsinTeisiFlg>0</AnsinTeisiFlg>
         <RyokinTuteiFlgTekiyoDay />
         <RyokinHatuteiFlgTekiyoDay />
         <KinkyuTuteiFlgTekiyoDay />
         <IchijiChudanFlgTekiyoDay />
         <AnsinTeisiFlgTekiyoDay />
         <PrepaidKino>0</PrepaidKino>
        <KojinHojinFlg>${_isPureOpen ? '' : data['ClientAccountType']}</KojinHojinFlg>
         <HojinwaribikiGroupCd />
         <SiteiWariTelNum1 />
         <SiteiWariTelNum2 />
         <SiteiWariTelNum3 />
         <SiteiWariTekiyoKaisiNengetu />
         <SiteiWariTekiyoShuryoNengetu />
         <SiteiWariTelNumTekiyoKaisiNengetu1 />
         <SiteiWariTelNumTekiyoKaisiNengetu2 />
         <SiteiWariTelNumTekiyoKaisiNengetu3 />
         <RyokinOption1>R412</RyokinOption1>
         <RyokinOption2 />
         <RyokinOption3 />
         <RyokinOption4 />
         <RyokinOption5 />
         <RyokinOption6 />
         <RyokinOption7 />
         <RyokinOption8 />
         <RyokinOption9 />
         <RyokinOption10 />
         <RyokinOption11 />
         <RyokinOption12 />
         <RyokinOption13 />
         <RyokinOption14 />
         <RyokinOption15 />
         <RyokinOption16 />
         <RyokinOption17 />
         <RyokinOption18 />
         <RyokinOption19 />
         <RyokinOption20 />
         <RyokinOption21 />
         <RyokinOption22 />
         <RyokinOption23 />
         <RyokinOption24 />
         <RyokinOption25 />
         <RyokinOption26 />
         <RyokinOption27 />
         <RyokinOption28 />
         <RyokinOption29 />
         <RyokinOption30 />
         <RyokinOptionTekiyoKaisiYmd1 />
         <RyokinOptionTekiyoKaisiYmd2 />
         <RyokinOptionTekiyoKaisiYmd3 />
         <RyokinOptionTekiyoKaisiYmd4 />
         <RyokinOptionTekiyoKaisiYmd5 />
         <RyokinOptionTekiyoKaisiYmd6 />
         <RyokinOptionTekiyoKaisiYmd7 />
         <RyokinOptionTekiyoKaisiYmd8 />
         <RyokinOptionTekiyoKaisiYmd9 />
         <RyokinOptionTekiyoKaisiYmd10 />
         <RyokinOptionTekiyoKaisiYmd11 />
         <RyokinOptionTekiyoKaisiYmd12 />
         <RyokinOptionTekiyoKaisiYmd13 />
         <RyokinOptionTekiyoKaisiYmd14 />
         <RyokinOptionTekiyoKaisiYmd15 />
         <RyokinOptionTekiyoKaisiYmd16 />
         <RyokinOptionTekiyoKaisiYmd17 />
         <RyokinOptionTekiyoKaisiYmd18 />
         <RyokinOptionTekiyoKaisiYmd19 />
         <RyokinOptionTekiyoKaisiYmd20 />
         <RyokinOptionTekiyoKaisiYmd21 />
         <RyokinOptionTekiyoKaisiYmd22 />
         <RyokinOptionTekiyoKaisiYmd23 />
         <RyokinOptionTekiyoKaisiYmd24 />
         <RyokinOptionTekiyoKaisiYmd25 />
         <RyokinOptionTekiyoKaisiYmd26 />
         <RyokinOptionTekiyoKaisiYmd27 />
         <RyokinOptionTekiyoKaisiYmd28 />
         <RyokinOptionTekiyoKaisiYmd29 />
         <RyokinOptionTekiyoKaisiYmd30 />
         <RyokinOptionTekiyoEndYmd1 />
         <RyokinOptionTekiyoEndYmd2 />
         <RyokinOptionTekiyoEndYmd3 />
         <RyokinOptionTekiyoEndYmd4 />
         <RyokinOptionTekiyoEndYmd5 />
         <RyokinOptionTekiyoEndYmd6 />
         <RyokinOptionTekiyoEndYmd7 />
         <RyokinOptionTekiyoEndYmd8 />
         <RyokinOptionTekiyoEndYmd9 />
         <RyokinOptionTekiyoEndYmd10 />
         <RyokinOptionTekiyoEndYmd11 />
         <RyokinOptionTekiyoEndYmd12 />
         <RyokinOptionTekiyoEndYmd13 />
         <RyokinOptionTekiyoEndYmd14 />
         <RyokinOptionTekiyoEndYmd15 />
         <RyokinOptionTekiyoEndYmd16 />
         <RyokinOptionTekiyoEndYmd17 />
         <RyokinOptionTekiyoEndYmd18 />
         <RyokinOptionTekiyoEndYmd19 />
         <RyokinOptionTekiyoEndYmd20 />
         <RyokinOptionTekiyoEndYmd21 />
         <RyokinOptionTekiyoEndYmd22 />
         <RyokinOptionTekiyoEndYmd23 />
         <RyokinOptionTekiyoEndYmd24 />
         <RyokinOptionTekiyoEndYmd25 />
         <RyokinOptionTekiyoEndYmd26 />
         <RyokinOptionTekiyoEndYmd27 />
         <RyokinOptionTekiyoEndYmd28 />
         <RyokinOptionTekiyoEndYmd29 />
         <RyokinOptionTekiyoEndYmd30 />
         <KanyuShaOption1>${
           _isSimOnlyOffer ||
           _isSimOnlyPlusSolutoOffer ||
           _isSimOnlyPlusKFSOffer ||
           _isSimOnlyPlusKFSPluseSolutoOffer ||
           _isSimOnlyPlusKFSFreePluseSolutoOffer
             ? 'K213'
             : _isNewKFSOffer
             ? 'K205'
             : _isOldFSOffer
             ? 'K203'
             : ''
         }</KanyuShaOption1>
         <KanyuShaOption2>${
           _isSolutoProgram
             ? 'K211'
             : _isSimOnlyPlusKFSOffer || _isSimOnlyPlusKFSPluseSolutoOffer || _isSimOnlyPlusKFSFreePluseSolutoOffer
             ? 'K205'
             : ''
         }</KanyuShaOption2>
         <KanyuShaOption3>${
           _isSimOnlyPlusKFSPluseSolutoOffer || _isSimOnlyPlusKFSFreePluseSolutoOffer ? 'K211' : ''
         }</KanyuShaOption3>
         <KanyuShaOption4 />
         <KanyuShaOption5 />
         <KanyuShaOption6 />
         <KanyuShaOption7 />
         <KanyuShaOption8 />
         <KanyuShaOption9 />
         <KanyuShaOption10 />
         <KanyuShaOption11 />
         <KanyuShaOption12 />
         <KanyuShaOption13 />
         <KanyuShaOption14 />
         <KanyuShaOption15 />
         <KanyuShaOption16 />
         <KanyuShaOption17 />
         <KanyuShaOption18 />
         <KanyuShaOption19 />
         <KanyuShaOption20 />
         <KanyuShaOption21 />
         <KanyuShaOption22 />
         <KanyuShaOption23 />
         <KanyuShaOption24 />
         <KanyuShaOption25 />
         <KanyuShaOption26 />
         <KanyuShaOption27 />
         <KanyuShaOption28 />
         <KanyuShaOption29 />
         <KanyuShaOption30 />
         <KanyuShaOption31 />
         <KanyuShaOption32 />
         <KanyuShaOption33 />
         <KanyuShaOption34 />
         <KanyuShaOption35 />
         <KanyuShaOption36 />
         <KanyuShaOption37 />
         <KanyuShaOption38 />
         <KanyuShaOption39 />
         <KanyuShaOption40 />
         <KanyuShaOptionTekiyoKaisiYmd1>${
           _isNewKFSOffer ||
           _isOldFSOffer ||
           _isSimOnlyOffer ||
           _isSimOnlyPlusSolutoOffer ||
           _isSimOnlyPlusKFSOffer ||
           _isSimOnlyPlusKFSPluseSolutoOffer
             ? _agreementStartDate
             : ''
         }</KanyuShaOptionTekiyoKaisiYmd1>
         <KanyuShaOptionTekiyoKaisiYmd2>${
           _isSolutoProgram || _isSimOnlyPlusKFSOffer || _isSimOnlyPlusKFSPluseSolutoOffer ? _agreementStartDate : ''
         }</KanyuShaOptionTekiyoKaisiYmd2>
         <KanyuShaOptionTekiyoKaisiYmd3>${
           _isSimOnlyPlusKFSPluseSolutoOffer ? _agreementStartDate : ''
         }</KanyuShaOptionTekiyoKaisiYmd3>
         <KanyuShaOptionTekiyoKaisiYmd4 />
         <KanyuShaOptionTekiyoKaisiYmd5 />
         <KanyuShaOptionTekiyoKaisiYmd6 />
         <KanyuShaOptionTekiyoKaisiYmd7 />
         <KanyuShaOptionTekiyoKaisiYmd8 />
         <KanyuShaOptionTekiyoKaisiYmd9 />
         <KanyuShaOptionTekiyoKaisiYmd10 />
         <KanyuShaOptionTekiyoKaisiYmd11 />
         <KanyuShaOptionTekiyoKaisiYmd12 />
         <KanyuShaOptionTekiyoKaisiYmd13 />
         <KanyuShaOptionTekiyoKaisiYmd14 />
         <KanyuShaOptionTekiyoKaisiYmd15 />
         <KanyuShaOptionTekiyoKaisiYmd16 />
         <KanyuShaOptionTekiyoKaisiYmd17 />
         <KanyuShaOptionTekiyoKaisiYmd18 />
         <KanyuShaOptionTekiyoKaisiYmd19 />
         <KanyuShaOptionTekiyoKaisiYmd20 />
         <KanyuShaOptionTekiyoKaisiYmd21 />
         <KanyuShaOptionTekiyoKaisiYmd22 />
         <KanyuShaOptionTekiyoKaisiYmd23 />
         <KanyuShaOptionTekiyoKaisiYmd24 />
         <KanyuShaOptionTekiyoKaisiYmd25 />
         <KanyuShaOptionTekiyoKaisiYmd26 />
         <KanyuShaOptionTekiyoKaisiYmd27 />
         <KanyuShaOptionTekiyoKaisiYmd28 />
         <KanyuShaOptionTekiyoKaisiYmd29 />
         <KanyuShaOptionTekiyoKaisiYmd30 />
         <KanyuShaOptionTekiyoKaisiYmd31 />
         <KanyuShaOptionTekiyoKaisiYmd32 />
         <KanyuShaOptionTekiyoKaisiYmd33 />
         <KanyuShaOptionTekiyoKaisiYmd34 />
         <KanyuShaOptionTekiyoKaisiYmd35 />
         <KanyuShaOptionTekiyoKaisiYmd36 />
         <KanyuShaOptionTekiyoKaisiYmd37 />
         <KanyuShaOptionTekiyoKaisiYmd38 />
         <KanyuShaOptionTekiyoKaisiYmd39 />
         <KanyuShaOptionTekiyoKaisiYmd40 />
        <KanyuShaOptionTekiyoShuryoYmd1>${_isSimOnlyCnclOffer ? _agreementEndDate : ''}</KanyuShaOptionTekiyoShuryoYmd1>
         <KanyuShaOptionTekiyoShuryoYmd2>${
           _isSimOnlyCnclPluseKFSFreeOffer || _isSimOnlyPluseKFSFreeOffer || _isSimOnlyPlusKFSFreePluseSolutoOffer
             ? _agreementEndDate
             : ''
         }</KanyuShaOptionTekiyoShuryoYmd2>
         <KanyuShaOptionTekiyoShuryoYmd3 />
         <KanyuShaOptionTekiyoShuryoYmd4 />
         <KanyuShaOptionTekiyoShuryoYmd5 />
         <KanyuShaOptionTekiyoShuryoYmd6 />
         <KanyuShaOptionTekiyoShuryoYmd7 />
         <KanyuShaOptionTekiyoShuryoYmd8 />
         <KanyuShaOptionTekiyoShuryoYmd9 />
         <KanyuShaOptionTekiyoShuryoYmd10 />
         <KanyuShaOptionTekiyoShuryoYmd11 />
         <KanyuShaOptionTekiyoShuryoYmd12 />
         <KanyuShaOptionTekiyoShuryoYmd13 />
         <KanyuShaOptionTekiyoShuryoYmd14 />
         <KanyuShaOptionTekiyoShuryoYmd15 />
         <KanyuShaOptionTekiyoShuryoYmd16 />
         <KanyuShaOptionTekiyoShuryoYmd17 />
         <KanyuShaOptionTekiyoShuryoYmd18 />
         <KanyuShaOptionTekiyoShuryoYmd19 />
         <KanyuShaOptionTekiyoShuryoYmd20 />
         <KanyuShaOptionTekiyoShuryoYmd21 />
         <KanyuShaOptionTekiyoShuryoYmd22 />
         <KanyuShaOptionTekiyoShuryoYmd23 />
         <KanyuShaOptionTekiyoShuryoYmd24 />
         <KanyuShaOptionTekiyoShuryoYmd25 />
         <KanyuShaOptionTekiyoShuryoYmd26 />
         <KanyuShaOptionTekiyoShuryoYmd27 />
         <KanyuShaOptionTekiyoShuryoYmd28 />
         <KanyuShaOptionTekiyoShuryoYmd29 />
         <KanyuShaOptionTekiyoShuryoYmd30 />
         <KanyuShaOptionTekiyoShuryoYmd31 />
         <KanyuShaOptionTekiyoShuryoYmd32 />
         <KanyuShaOptionTekiyoShuryoYmd33 />
         <KanyuShaOptionTekiyoShuryoYmd34 />
         <KanyuShaOptionTekiyoShuryoYmd35 />
         <KanyuShaOptionTekiyoShuryoYmd36 />
         <KanyuShaOptionTekiyoShuryoYmd37 />
         <KanyuShaOptionTekiyoShuryoYmd38 />
         <KanyuShaOptionTekiyoShuryoYmd39 />
         <KanyuShaOptionTekiyoShuryoYmd40 />
         <TuwaOption1>T803</TuwaOption1>
         <TuwaOption2>T807</TuwaOption2>
         <TuwaOption3>T815</TuwaOption3>
         <TuwaOption4>T818</TuwaOption4>
         <TuwaOption5>T839</TuwaOption5>
         <TuwaOption6>T845</TuwaOption6>
         <TuwaOption7>T847</TuwaOption7>
         <TuwaOption8 />
         <TuwaOption9 />
         <TuwaOption10 />
         <TuwaOption11 />
         <TuwaOption12 />
         <TuwaOption13 />
         <TuwaOption14 />
         <TuwaOption15 />
         <TuwaOption16 />
         <TuwaOption17 />
         <TuwaOption18 />
         <TuwaOption19 />
         <TuwaOption20 />
         <TuwaOption21 />
         <TuwaOption22 />
         <TuwaOption23 />
         <TuwaOption24 />
         <TuwaOption25 />
         <TuwaOption26 />
         <TuwaOption27 />
         <TuwaOption28 />
         <TuwaOption29 />
         <TuwaOption30 />
         <TuwaOption31 />
         <TuwaOption32 />
         <TuwaOption33 />
         <TuwaOption34 />
         <TuwaOption35 />
         <TuwaOption36 />
         <TuwaOption37 />
         <TuwaOption38 />
         <TuwaOption39 />
         <TuwaOption40 />
         <TuwaOption41 />
         <TuwaOption42 />
         <TuwaOption43 />
         <TuwaOption44 />
         <TuwaOption45 />
         <TuwaOption46 />
         <TuwaOption47 />
         <TuwaOption48 />
         <TuwaOption49 />
         <TuwaOption50 />
         <TuwaOption51 />
         <TuwaOption52 />
         <TuwaOption53 />
         <TuwaOption54 />
         <TuwaOption55 />
         <TuwaOption56 />
         <TuwaOption57 />
         <TuwaOption58 />
         <TuwaOption59 />
         <TuwaOption60 />
         <TuwaOptionTekiyoKaisiYmd1 />
         <TuwaOptionTekiyoKaisiYmd2 />
         <TuwaOptionTekiyoKaisiYmd3 />
         <TuwaOptionTekiyoKaisiYmd4 />
         <TuwaOptionTekiyoKaisiYmd5 />
         <TuwaOptionTekiyoKaisiYmd6 />
         <TuwaOptionTekiyoKaisiYmd7 />
         <TuwaOptionTekiyoKaisiYmd8 />
         <TuwaOptionTekiyoKaisiYmd9 />
         <TuwaOptionTekiyoKaisiYmd10 />
         <TuwaOptionTekiyoKaisiYmd11 />
         <TuwaOptionTekiyoKaisiYmd12 />
         <TuwaOptionTekiyoKaisiYmd13 />
         <TuwaOptionTekiyoKaisiYmd14 />
         <TuwaOptionTekiyoKaisiYmd15 />
         <TuwaOptionTekiyoKaisiYmd16 />
         <TuwaOptionTekiyoKaisiYmd17 />
         <TuwaOptionTekiyoKaisiYmd18 />
         <TuwaOptionTekiyoKaisiYmd19 />
         <TuwaOptionTekiyoKaisiYmd20 />
         <TuwaOptionTekiyoKaisiYmd21 />
         <TuwaOptionTekiyoKaisiYmd22 />
         <TuwaOptionTekiyoKaisiYmd23 />
         <TuwaOptionTekiyoKaisiYmd24 />
         <TuwaOptionTekiyoKaisiYmd25 />
         <TuwaOptionTekiyoKaisiYmd26 />
         <TuwaOptionTekiyoKaisiYmd27 />
         <TuwaOptionTekiyoKaisiYmd28 />
         <TuwaOptionTekiyoKaisiYmd29 />
         <TuwaOptionTekiyoKaisiYmd30 />
         <TuwaOptionTekiyoKaisiYmd31 />
         <TuwaOptionTekiyoKaisiYmd32 />
         <TuwaOptionTekiyoKaisiYmd33 />
         <TuwaOptionTekiyoKaisiYmd34 />
         <TuwaOptionTekiyoKaisiYmd35 />
         <TuwaOptionTekiyoKaisiYmd36 />
         <TuwaOptionTekiyoKaisiYmd37 />
         <TuwaOptionTekiyoKaisiYmd38 />
         <TuwaOptionTekiyoKaisiYmd39 />
         <TuwaOptionTekiyoKaisiYmd40 />
         <TuwaOptionTekiyoKaisiYmd41 />
         <TuwaOptionTekiyoKaisiYmd42 />
         <TuwaOptionTekiyoKaisiYmd43 />
         <TuwaOptionTekiyoKaisiYmd44 />
         <TuwaOptionTekiyoKaisiYmd45 />
         <TuwaOptionTekiyoKaisiYmd46 />
         <TuwaOptionTekiyoKaisiYmd47 />
         <TuwaOptionTekiyoKaisiYmd48 />
         <TuwaOptionTekiyoKaisiYmd49 />
         <TuwaOptionTekiyoKaisiYmd50 />
         <TuwaOptionTekiyoKaisiYmd51 />
         <TuwaOptionTekiyoKaisiYmd52 />
         <TuwaOptionTekiyoKaisiYmd53 />
         <TuwaOptionTekiyoKaisiYmd54 />
         <TuwaOptionTekiyoKaisiYmd55 />
         <TuwaOptionTekiyoKaisiYmd56 />
         <TuwaOptionTekiyoKaisiYmd57 />
         <TuwaOptionTekiyoKaisiYmd58 />
         <TuwaOptionTekiyoKaisiYmd59 />
         <TuwaOptionTekiyoKaisiYmd60 />
         <TuwaOptionTekiyoShuryoYmd1 />
         <TuwaOptionTekiyoShuryoYmd2 />
         <TuwaOptionTekiyoShuryoYmd3 />
         <TuwaOptionTekiyoShuryoYmd4 />
         <TuwaOptionTekiyoShuryoYmd5 />
         <TuwaOptionTekiyoShuryoYmd6 />
         <TuwaOptionTekiyoShuryoYmd7 />
         <TuwaOptionTekiyoShuryoYmd8 />
         <TuwaOptionTekiyoShuryoYmd9 />
         <TuwaOptionTekiyoShuryoYmd10 />
         <TuwaOptionTekiyoShuryoYmd11 />
         <TuwaOptionTekiyoShuryoYmd12 />
         <TuwaOptionTekiyoShuryoYmd13 />
         <TuwaOptionTekiyoShuryoYmd14 />
         <TuwaOptionTekiyoShuryoYmd15 />
         <TuwaOptionTekiyoShuryoYmd16 />
         <TuwaOptionTekiyoShuryoYmd17 />
         <TuwaOptionTekiyoShuryoYmd18 />
         <TuwaOptionTekiyoShuryoYmd19 />
         <TuwaOptionTekiyoShuryoYmd20 />
         <TuwaOptionTekiyoShuryoYmd21 />
         <TuwaOptionTekiyoShuryoYmd22 />
         <TuwaOptionTekiyoShuryoYmd23 />
         <TuwaOptionTekiyoShuryoYmd24 />
         <TuwaOptionTekiyoShuryoYmd25 />
         <TuwaOptionTekiyoShuryoYmd26 />
         <TuwaOptionTekiyoShuryoYmd27 />
         <TuwaOptionTekiyoShuryoYmd28 />
         <TuwaOptionTekiyoShuryoYmd29 />
         <TuwaOptionTekiyoShuryoYmd30 />
         <TuwaOptionTekiyoShuryoYmd31 />
         <TuwaOptionTekiyoShuryoYmd32 />
         <TuwaOptionTekiyoShuryoYmd33 />
         <TuwaOptionTekiyoShuryoYmd34 />
         <TuwaOptionTekiyoShuryoYmd35 />
         <TuwaOptionTekiyoShuryoYmd36 />
         <TuwaOptionTekiyoShuryoYmd37 />
         <TuwaOptionTekiyoShuryoYmd38 />
         <TuwaOptionTekiyoShuryoYmd39 />
         <TuwaOptionTekiyoShuryoYmd40 />
         <TuwaOptionTekiyoShuryoYmd41 />
         <TuwaOptionTekiyoShuryoYmd42 />
         <TuwaOptionTekiyoShuryoYmd43 />
         <TuwaOptionTekiyoShuryoYmd44 />
         <TuwaOptionTekiyoShuryoYmd45 />
         <TuwaOptionTekiyoShuryoYmd46 />
         <TuwaOptionTekiyoShuryoYmd47 />
         <TuwaOptionTekiyoShuryoYmd48 />
         <TuwaOptionTekiyoShuryoYmd49 />
         <TuwaOptionTekiyoShuryoYmd50 />
         <TuwaOptionTekiyoShuryoYmd51 />
         <TuwaOptionTekiyoShuryoYmd52 />
         <TuwaOptionTekiyoShuryoYmd53 />
         <TuwaOptionTekiyoShuryoYmd54 />
         <TuwaOptionTekiyoShuryoYmd55 />
         <TuwaOptionTekiyoShuryoYmd56 />
         <TuwaOptionTekiyoShuryoYmd57 />
         <TuwaOptionTekiyoShuryoYmd58 />
         <TuwaOptionTekiyoShuryoYmd59 />
         <TuwaOptionTekiyoShuryoYmd60 />
         <RyokinPlan>RD70</RyokinPlan>
         <RyokinPlanTekiyoKaisiYmd />
         <IchiritugataTekiyoKaisiYmd />
         <KomikomiKazokuwaribikiKaisenSu>00</KomikomiKazokuwaribikiKaisenSu>
         <KazokuwaribikiGroupCd />
         <TelShuruiMeisho>ｎａｕＩＣカード４</TelShuruiMeisho>
        ${
          _isPureOpen
            ? `<KeiyakuShaSimeiKana/>
            <KeiyakuShaSimeiKanji/>
            <KeiyakuShaYubinNum/>
            <KeiyakuShaJusho/>
            <KeiyakuShaJitakuTelNum/>
            <Birthday/>
            <SeikyuShaSimeiKana/>
            <SeikyuShaSimeiKanji/>
            <SeikyuShaYubinNum/>
            <SeikyuShaJusho/>
            <SeikyuShaTelNum/>`
            : `<KeiyakuShaSimeiKana>${data['KeiyakushaKanaName']}</KeiyakuShaSimeiKana>
         <KeiyakuShaSimeiKanji>${data['KeiyakushaKanjiName']}</KeiyakuShaSimeiKanji>
         <KeiyakuShaYubinNum>${data['KeiyakushaPostNo']}</KeiyakuShaYubinNum>
         <KeiyakuShaJusho>${data['KeiyakushaAddress']}</KeiyakuShaJusho>
         <KeiyakuShaJitakuTelNum>${data['KeiyakushaHomeTelNo']}</KeiyakuShaJitakuTelNum>
         <Birthday>${_dateOfBirth}</Birthday>
         <SeikyuShaSimeiKana>${data['SeikyushaKanaName']}</SeikyuShaSimeiKana>
         <SeikyuShaSimeiKanji>${data['SeikyushaKanjiName']}</SeikyuShaSimeiKanji>
         <SeikyuShaYubinNum>${data['SeikyushaPostNo']}</SeikyuShaYubinNum>
         <SeikyuShaJusho>${data['SeikyushaAddress']}</SeikyuShaJusho>
        <SeikyuShaTelNum>${data['SeikyushaHomeTelNo']}</SeikyuShaTelNum>`
        }
         <AuServiceKaisiDay>${_clientAccountStartDate}</AuServiceKaisiDay>
         <GenzaiRiyoChuNwRiyoKaisiDay>${_purchaseDate || _clientAccountStartDate}</GenzaiRiyoChuNwRiyoKaisiDay>
         <KaiageIdokiShokaiSiyoKaisiDay>${_purchaseDate}</KaiageIdokiShokaiSiyoKaisiDay>
         <KaiageIdokiSaisinSiyoKaisiDay>${_agreementStartDate}</KaiageIdokiSaisinSiyoKaisiDay>
         <RiyoKikan>1200</RiyoKikan>
         <YakusokuWaribikiKaisiNengetu />
         <YakusokuWaribikiManryoMonth>202212</YakusokuWaribikiManryoMonth>
         <SumaWariKaisiNengetu />
         <JizenHaisiFlg />
         <UnyoHizuke>20210131</UnyoHizuke>
         <KaiageIdokiShuriHoshoKaisiDay>00000000</KaiageIdokiShuriHoshoKaisiDay>
         <SinkenShaSimeiKana />
         <SinkenShaSimeiKanji />
         <SinkenShaYubinNum />
         <SinkenShaJushoKanji />
         <SinkenShaRenrakuSakiTelNum />
         <SinkenShaBirthday />
         <SinkenShaZokugara />
         <IyakukinKbn>2</IyakukinKbn>
         <YearKeiyakuKaijoRyo>00000</YearKeiyakuKaijoRyo>
         <KamiSeikyuYohi />
         <WinSingleSetWariKbn>0</WinSingleSetWariKbn>
         <WinSingleSetWariTelNum1 />
         <WinSingleSetWariTelNum2 />
         <SetWariTelNumTekiyoKaisiNengetu1 />
         <SetWariTelNumTekiyoKaisiNengetu2 />
         <SetWariTelNumTekiyoShuryoNengetu1 />
         <SetWariTelNumTekiyoShuryoNengetu2 />
         <RyokinAnsinServiceKbn>3</RyokinAnsinServiceKbn>
         <ServiceCls />
         <RiyoTeisiGendoGaku />
         <RiyoTeisiKaituStatus />
         <RyokinAnsinServiceTekiyoKaisiNengetu />
         <RyokinAnsinServiceTekiyoShuryoNengetu />
         <KyakuCdNw>4100005649</KyakuCdNw>
         <KyoyuKurikosiCourseKbn>1</KyoyuKurikosiCourseKbn>
         <KyoyuKurikosiTekiyoKbn>0</KyoyuKurikosiTekiyoKbn>
         <KishuHenkoKiseiFlg />
         <KoshoKiseiFlg />
         <PointKangenKiseiFlg />
         <SiharaiHoho>3</SiharaiHoho>
         <CreditKaishaCd />
         <RiyoShaKanaSimei />
         <RiyoShaKanjiSimei />
         <RiyoShaBirthday>${_dateOfBirth}</RiyoShaBirthday>
         <DaiyokiKasidasiUmu>0</DaiyokiKasidasiUmu>
         <MosikomishoNum1 />
         <HitoriWakeaiFlg />
         <TanmatuKeikaTukisu>026</TanmatuKeikaTukisu>
         <TanmatuKeiyakuKaijoRyoTogetu>000000</TanmatuKeiyakuKaijoRyoTogetu>
         <AnsinGenmenKingaku />
         <AnsinTekiyoKahi>1</AnsinTekiyoKahi>
         <GenmenTaishoFlg />
         <DenchiServiceKanoKikanFrom />
         <DenchiServiceKanoKikanTo />
         <ShopKaiinTorokuDairiten />
         <ShopKaiinShuryoDay />
         <AnsinKetaiSupportMuryokaGenzaiStatus />
         <MujikoKikan />
         <PointBackPlusYoteiNengetu />
         <MvnoSikibetuKbn>${
           ['1EAA245F60F40364938AFF1A774566E5'].includes(data['ClientChannel']) ? '87' : ''
         }</MvnoSikibetuKbn>
         <KeiyakuShaKinmuSakiTelNum />
         <RenrakuSakiKbn>1</RenrakuSakiKbn>
         <TokutenRiyoKikanFrom />
         <TokutenRiyoKikanTo />
         <FilteringServiceFuyoJiyuCd />
        <SaisinTorokuIdokiSeizoNum>${_isPureOpen || _isSimOnlyOffer ? '' : data['IMEI']}</SaisinTorokuIdokiSeizoNum>
         <SaisinTorokuIdoTelShuruiMeisho>ＳＯＶ３６携帯本体Ｋ</SaisinTorokuIdoTelShuruiMeisho>
         <ZenkaiRyokinPlan />
         <ZenkaiRyokinPlanTekiyoKaisiYmd />
         <ZenkaiRyokinPlanTekiyoShuryoYmd />
         <Packetwari />
         <ZenkaiPacketwari />
         <ZenkaiPacketwariTekiyoShuryoYmd />
         <ZenkaiPacketwariTekiyoKaisiYmd />
         <ZenkaiKyoyuKurikosiCourseKbn />
         <ZenkaiRyokinAnsinServiceKbn>3</ZenkaiRyokinAnsinServiceKbn>
         <ZenkaiServiceCls />
         <ZenkaiRiyoTeisiGendoGaku />
         <ZenkaiRiyoTeisiKaituStatus />
         <ZenkaiRyokinAnsinServiceTekiyoKaisiNengetu />
         <ZenkaiRyokinAnsinServiceTekiyoShuryoNengetu />
         <ZenkaiSiteiWariTekiyoKaisiNengetu />
         <ZenkaiSiteiWariTelNumTekiyoShuryoNengetu1 />
         <ZenkaiSiteiWariTelNumTekiyoShuryoNengetu2 />
         <ZenkaiSiteiWariTelNumTekiyoShuryoNengetu3 />
         <SinpinKokanTaioKahi>0</SinpinKokanTaioKahi>
         <TuwaMeisaiKamiYohi />
         <MaitukiwariSisakuNum />
         <MaitukiwariWaribikiKaisiMonth />
         <MaitukiwariWaribikiShuryoYoteiMonth />
         <MaitukiwariWaribikiGaku />
         <MaitukiwariTaishoKishu />
         <MicrosimUimCardSikibetu>0</MicrosimUimCardSikibetu>
         <MnpYoyakuUmu>0</MnpYoyakuUmu>
         <IdokiRiyoKaisiDay />
         <IdokiRiyoTukisu>026</IdokiRiyoTukisu>
         <SinpinKokanTaioKahiHanbaiSeizoNum>0</SinpinKokanTaioKahiHanbaiSeizoNum>
         <AnsinTeisiKaijoDay />
         <DenchiPackKokanYmd1 />
         <DenchiPackKokanYmd2 />
         <DokyoWariCd />
         <DokyoWariKaisenSu />
         <KoteiKeiyakuId1 />
         <JigyoshaCd1 />
         <CourseMeisho1 />
         <MenuMeisho1 />
         <KoteiKeiyakuId2 />
         <JigyoshaCd2 />
         <CourseMeisho2 />
         <MenuMeisho2 />
         <DokyoWariCdnaiRouterTokubetuWaridUmu />
         <TabletSetWariKbn>0</TabletSetWariKbn>
         <TabletSetWariKeiyakuKaisiNengetu />
         <TabletSetWariWaribikiShuryoNengetu />
         <SmartphoneSetWariKbn>0</SmartphoneSetWariKbn>
         <SmartphoneSetWariTelNum />
         <SmartphoneSetWariTelNumTekiyoKaisiNengetu />
         <SmartphoneSetWariTelNumTekiyoShuryoNengetu />
         <CoreServiceCd1>K205</CoreServiceCd1>
         <FukaServiceCd1>Y201</FukaServiceCd1>
         <FukaServiceTekiyoKaisiDay1 />
         <FukaServiceTekiyoShuryoDay1>00000000</FukaServiceTekiyoShuryoDay1>
         <CoreServiceCd2 />
         <FukaServiceCd2 />
         <FukaServiceTekiyoKaisiDay2 />
         <FukaServiceTekiyoShuryoDay2 />
         <CoreServiceCd3 />
         <FukaServiceCd3 />
         <FukaServiceTekiyoKaisiDay3 />
         <FukaServiceTekiyoShuryoDay3 />
         <CoreServiceCd4 />
         <FukaServiceCd4 />
         <FukaServiceTekiyoKaisiDay4 />
         <FukaServiceTekiyoShuryoDay4 />
         <CoreServiceCd5 />
         <FukaServiceCd5 />
         <FukaServiceTekiyoKaisiDay5 />
         <FukaServiceTekiyoShuryoDay5 />
         <CoreServiceCd6 />
         <FukaServiceCd6 />
         <FukaServiceTekiyoKaisiDay6 />
         <FukaServiceTekiyoShuryoDay6 />
         <CoreServiceCd7 />
         <FukaServiceCd7 />
         <FukaServiceTekiyoKaisiDay7 />
         <FukaServiceTekiyoShuryoDay7 />
         <CoreServiceCd8 />
         <FukaServiceCd8 />
         <FukaServiceTekiyoKaisiDay8 />
         <FukaServiceTekiyoShuryoDay8 />
         <CoreServiceCd9 />
         <FukaServiceCd9 />
         <FukaServiceTekiyoKaisiDay9 />
         <FukaServiceTekiyoShuryoDay9 />
         <CoreServiceCd10 />
         <FukaServiceCd10 />
         <FukaServiceTekiyoKaisiDay10 />
         <FukaServiceTekiyoShuryoDay10 />
         <CoreServiceCd11 />
         <FukaServiceCd11 />
         <FukaServiceTekiyoKaisiDay11 />
         <FukaServiceTekiyoShuryoDay11 />
         <CoreServiceCd12 />
         <FukaServiceCd12 />
         <FukaServiceTekiyoKaisiDay12 />
         <FukaServiceTekiyoShuryoDay12 />
         <CoreServiceCd13 />
         <FukaServiceCd13 />
         <FukaServiceTekiyoKaisiDay13 />
         <FukaServiceTekiyoShuryoDay13 />
         <CoreServiceCd14 />
         <FukaServiceCd14 />
         <FukaServiceTekiyoKaisiDay14 />
         <FukaServiceTekiyoShuryoDay14 />
         <CoreServiceCd15 />
         <FukaServiceCd15 />
         <FukaServiceTekiyoKaisiDay15 />
         <FukaServiceTekiyoShuryoDay15 />
         <ManryoGoRyokinPlanCoreServiceCd />
         <ManryoGoPacketwariCoreServiceCd />
         <KaisenType>${data['HandsetType']}</KaisenType>
         <MonthChuPlanHenkoKaisu>00</MonthChuPlanHenkoKaisu>
         <SaisinTorokuIdokiSinHoshuFlg>${
           ['2', '25', '182', '192'].includes(data['ProgramType'])
             ? '1'
             : ['4', '45'].includes(data['ProgramType'])
             ? '0'
             : ''
         }</SaisinTorokuIdokiSinHoshuFlg>
         <TaioIdokiSinHoshuFlg>1</TaioIdokiSinHoshuFlg>
         <SeizoNumIccidMaesedai />
         <SeizoNumIccidMaemaesedai />
         <SeizoNumIccidMaesedaiKaisiDay />
         <SeizoNumIccidMaemaesedaiKaisiDay />
         <DareWariKosinMonthFrom>202101</DareWariKosinMonthFrom>
         <DareWariKosinMonthTo>202102</DareWariKosinMonthTo>
         <KaiyakuDay />
         <IchijiKyusiDay />
      <JizenHaisiTorokuYmd />
      <JizenHaisiTorikesiYmd />
      <HenkomaeContentsSetWariTokutenTypeTekiyoKaisiYmd />
      <ContentsSetWariTokutenTypeTekiyoKaisiYmd />                                                                                                          		   
      </KyakuShokai>
      <PascalKyakuShokaiTemplate>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <NextPlanKosinMonthFrom />
         <NextPlanKosinMonthTo />
         <NextPlanKeiyakuKikanKaisiMonth />
         <NextPlanKeiyakuKikanManryoMonth />
         <NextPlanKeiyakuKikanIyakukinKingaku>0</NextPlanKeiyakuKikanIyakukinKingaku>
         <NextPlanKeiyakuKikanIyakukinMenjoFlg />
         <NextDaredemoWariJidoTekiyoHaisiFlg />
      </PascalKyakuShokaiTemplate>
      <ContentsKanyuStatusKakunin>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <KekkaCd>00</KekkaCd>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>111</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>555</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>CCC</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>222</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>333</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>888</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>444</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>AAA</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>BBB</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>666</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
         <ContentsYoso>
            <ContentsKekkaCd>00</ContentsKekkaCd>
            <KanyuStatus>0</KanyuStatus>
            <OrderCd>999</OrderCd>
            <NyukaiNitiji />
            <TaikaiNitiji />
         </ContentsYoso>
      </ContentsKanyuStatusKakunin>
      <KyakuShokaiBassuibanSmartphoneSetWariTelNum>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>ER</DenbunType>
         <RiyoKikan />
      </KyakuShokaiBassuibanSmartphoneSetWariTelNum>
      <KyakuShokaiBassuibanWinSingleSetWariTelNum1>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>ER</DenbunType>
         <RiyoKikan />
      </KyakuShokaiBassuibanWinSingleSetWariTelNum1>
      <KyakuShokaiBassuibanWinSingleSetWariTelNum2>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>ER</DenbunType>
         <RiyoKikan />
      </KyakuShokaiBassuibanWinSingleSetWariTelNum2>
      <KazokuwariHojinwariInfoShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>0</SosinJokenHanteiKekka>
      </KazokuwariHojinwariInfoShokai>
      <ShohinInfoShutoku>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <ShohinInfoMeisai>
           <ShohinCd>${_isPureOpen || _isSimOnlyOffer ? '' : data['ClientAssetSku']}</ShohinCd>
            <KappuYoTusho1>ＳＯＶ３６ブラック</KappuYoTusho1>
         </ShohinInfoMeisai>
      </ShohinInfoShutoku>
      <RuikeiPointShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
        <RuikeiPointSu>${_isPureOpen ? '' : data['LoyaltyPoints']}</RuikeiPointSu>
      </RuikeiPointShokai>
      <SetWariShosaiShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <SetWariCdMeisaiRyoikiOtoMeisaiSu>0</SetWariCdMeisaiRyoikiOtoMeisaiSu>
         <SetWariCdMeisaiRyoiki>
            <SetWariServiceType />
            <JikaisenYakuwariType />
            <SetWariShosaiMeisaiRyoiki>
               <SetWariHaikaTelNum />
               <SetWariYakuwariType />
            </SetWariShosaiMeisaiRyoiki>
         </SetWariCdMeisaiRyoiki>
      </SetWariShosaiShokai>
      <PascalKyakuShokaiHttp>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <TogoStatus />
         <SeikyuType />
         <TogoSeikyuAcount />
         <KoteiTelNum1 />
         <KoteiTelNum2 />
      </PascalKyakuShokaiHttp>
      <BassuibanSetaiInfoKensaku>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <KeiyakuShaJushoAreaHanteiKekkaSetaiGroupZone />
      </BassuibanSetaiInfoKensaku>
      <Orangekyakushokaiseikyu>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <SeikyuSakiKokyakuMeiKana>${data['SeikyushaKanaName']}</SeikyuSakiKokyakuMeiKana>
         <SeikyuSakiKokyakuMeiKanji>${data['SeikyushaKanjiName']}</SeikyuSakiKokyakuMeiKanji>
         <SeikyuSakiYubinNum>${data['SeikyushaPostNo']}</SeikyuSakiYubinNum>
         <SeikyuSakiJushoTodofuken />
         <SeikyuSakiJushoSikuchoson />
         <SeikyuSakiJushoOazacho />
         <SeikyuSakiJushoAzachome />
         <SeikyuSakiHokiJusho>${data['SeikyushaAddress']}</SeikyuSakiHokiJusho>
         <SeikyuSakiTelNum>${data['SeikyushaHomeTelNo']}</SeikyuSakiTelNum>
         <SiharaiHoho>0</SiharaiHoho>
         <SiharaiHohoSetteiJiyuKbn>05</SiharaiHohoSetteiJiyuKbn>
         <KamiSeikyushoYohiKbn>1</KamiSeikyushoYohiKbn>
         <SeikyuSyuki>02</SeikyuSyuki>
         <YukoAuKeiyakuKaisenSu>1</YukoAuKeiyakuKaisenSu>
         <YukoBbcKeiyakuKaisenSu>0</YukoBbcKeiyakuKaisenSu>
      </Orangekyakushokaiseikyu>
      <Orangekyakuinfoshokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <KappuKeiyakuSu1>0</KappuKeiyakuSu1>
         <KappuKeiyakuSu2>0</KappuKeiyakuSu2>
         <MinoUmu>1</MinoUmu>
         <AliasAuIdShuturyoku>09093022906</AliasAuIdShuturyoku>
         <AuIdLoginRireki>0</AuIdLoginRireki>
         <RoyalityProgramTekiyoKbn>3</RoyalityProgramTekiyoKbn>
         <TokutenSofuSakiJushoKbn />
         <AuIdCardInfoShokaiKekkaOtoKensu>0</AuIdCardInfoShokaiKekkaOtoKensu>
         <AuIdCardInfoMeisai>
            <CardKeiyakuStatusCd />
            <CardRiyoStatusCd />
            <CardHakoStatusCd />
            <CardMosikomiJiyu />
         </AuIdCardInfoMeisai>
         <AuIdCrecaCardInfoShokaiKekkaOtoKensu>0</AuIdCrecaCardInfoShokaiKekkaOtoKensu>
         <AuIdCrecaCardInfoMeisai>
            <KeiyakuStatusCdCredit />
            <RiyoStatusCdCredit />
         </AuIdCrecaCardInfoMeisai>
      </Orangekyakuinfoshokai>
      <UpgradeProgramTekiyoShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <UgMeisaiInfo>
            <GradeNum />
            <KappuKeiyakuNum />
            <TokutenPlusKanoKikanFrom />
            <TokutenPlusKanoKikanTo />
            <TokutenPlusKaisiNengetu />
            <MaedaosiKenriKousiUmu />
            <MaedaosiKenriKousiKanoKikanFrom />
            <UimTaioIdokiSeizoNum />
            <HaisiKbn />
            <SitadoriHoho />
            <KaishuJokyo />
            <KenriKousiStatus />
            <UpgradeSetWariTekiyoUmu />
         </UgMeisaiInfo>
      </UpgradeProgramTekiyoShokai>
      <KeiyakuShomenkoufuHohoShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <KeiyakuShomenkoufuHoho>0000</KeiyakuShomenkoufuHoho>
      </KeiyakuShomenkoufuHohoShokai>
      <KappuKeiyakuKiseiShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <IdokiKappuKeiyakuKisei />
         <AccessoryKappuKeiyakuKisei />
      </KappuKeiyakuKiseiShokai>
      <WebmoneyZandakaShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>0</SosinJokenHanteiKekka>
      <Status>0000</Status>
      <ReturnCd>0000</ReturnCd>
        <ZandakaFugo />
        <Zandaka>0</Zandaka>				   					  
      </WebmoneyZandakaShokai>
      <YukoKigenGotoPointShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <AuIdPoint>0</AuIdPoint>
         <KyoyuPoint>1970</KyoyuPoint>
         <HikitugiKanoPoint>1970</HikitugiKanoPoint>
         <RiyoSakiGenteiPoint>0</RiyoSakiGenteiPoint>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230131</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>0</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230228</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>40</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230331</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>70</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230430</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230531</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230630</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230731</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230831</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20230930</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20231031</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20231130</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20231231</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240131</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240229</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240331</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240430</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240531</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240630</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240731</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240831</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20240930</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20241031</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20241130</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>180</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20241231</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <SlYukoKigenGotoPointInfo>
            <SlSikkoYoteiYmd>20250131</SlSikkoYoteiYmd>
            <SlSikkoYoteiPoint>80</SlSikkoYoteiPoint>
         </SlYukoKigenGotoPointInfo>
         <IdYukoKigenGotoPointInfo>
            <IdSikkoYoteiYmd>0</IdSikkoYoteiYmd>
            <IdSikkoYoteiPoint>0</IdSikkoYoteiPoint>
         </IdYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230131</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>0</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230228</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>40</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230331</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>70</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230430</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230531</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230630</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230731</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230831</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20230930</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20231031</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20231130</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20231231</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240131</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240229</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240331</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240430</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240531</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240630</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240731</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240831</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20240930</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20241031</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20241130</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>180</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20241231</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <SlHikitugiKanopYukoKigenGotoPointInfo>
            <SlHikitugiKanopSikkoYoteiYmd>20250131</SlHikitugiKanopSikkoYoteiYmd>
            <SlHikitugiKanopSikkoYoteiPoint>80</SlHikitugiKanopSikkoYoteiPoint>
         </SlHikitugiKanopYukoKigenGotoPointInfo>
         <RiyoSakiGenteiYukoKigenGotoPointInfo>
            <RiyoSakiGenteiSikkoYoteiYmd />
            <RiyoSakiGenteiSikkoYoteiPoint />
         </RiyoSakiGenteiYukoKigenGotoPointInfo>
      </YukoKigenGotoPointShokai>
      <PointMeisaiShokai_Orange>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <AuIdPointMeisaiKeizokuFlg />
         <AuIdPointMeisaiInfo>
            <AuIdPointMeisaiHasseiYmd />
            <AuIdMeisaiPointSu />
            <AuIdPointKeisanGroupHyojiMeisho />
         </AuIdPointMeisaiInfo>
         <RiyoSakiGenteiPointMeisaiKeizokuFlg />
         <RiyoSakiGenteiPointMeisaiInfo>
            <RiyoSakiGenteiPointMeisaiHasseiYmd />
            <RiyoSakiGenteiPointMeisaiPointFugo />
            <RiyoSakiGenteiPointMeisaiPointSu />
            <RiyoSakiGenteiPointMeisaiKeisanGroupHyojiMeisho />
         </RiyoSakiGenteiPointMeisaiInfo>
      </PointMeisaiShokai_Orange>
      <DenkiSetWariNaiyoShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <OtoMeisaiSu>0</OtoMeisaiSu>
      </DenkiSetWariNaiyoShokai>
      <AuhomeKeiyakuShokai>
         <KaiheikyokuCheckKekka>0</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>0</SosinJokenHanteiKekka>
      <Status>0700</Status>
        <ReturnCd>7000</ReturnCd>
     <OtoProductKeiyakuInfo>
       <OtoProductServiceCd />
       <OtoKeiyakuStatus />
       <OtoMosikomiDay />
       <OtoKaiyakuMosikomiDay />
       <OtoKaiyakuDay />
       <OtoAuhomeKaisiDay />
       <OtoAuhomeShuryoDay />
       <OtoMinRiyoKikan />
       <OtoTankiKaiyakuIyakukin />
     </OtoProductKeiyakuInfo>					
      </AuhomeKeiyakuShokai>
      <MonthChuRyokinShokaiTogetu>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <PacketSuGokei>0</PacketSuGokei>
         <LteSoryoKiseiShukeiTaishoTushinKiroByteSu>0</LteSoryoKiseiShukeiTaishoTushinKiroByteSu>
         <LteTushinSoryoKiseiSikiiChiKiroByteSuGokei>20971520</LteTushinSoryoKiseiSikiiChiKiroByteSuGokei>
      </MonthChuRyokinShokaiTogetu>
      <MonthChuRyokinShokaiZengetu>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <PacketSuGokei>0</PacketSuGokei>
         <LteSoryoKiseiShukeiTaishoTushinKiroByteSu>0</LteSoryoKiseiShukeiTaishoTushinKiroByteSu>
      </MonthChuRyokinShokaiZengetu>
      <WaribikiChojoKekkaListShokaiOrange>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202101</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202012</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202011</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202010</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202009</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
         <WaribikiChojoKekkaMeisai>
            <SeikyuNengetu>202008</SeikyuNengetu>
            <WaribikiTekiyo01 />
            <WaribikiTekiyoSisakuCd01 />
            <WaribikiTekiyo02 />
            <WaribikiTekiyoSisakuCd02 />
            <WaribikiTekiyo03 />
            <WaribikiTekiyoSisakuCd03 />
            <WaribikiTekiyo04 />
            <WaribikiTekiyoSisakuCd04 />
            <WaribikiTekiyo05 />
            <WaribikiTekiyoSisakuCd05 />
            <WaribikiTekiyo06 />
            <WaribikiTekiyoSisakuCd06 />
            <WaribikiTekiyo07 />
            <WaribikiTekiyoSisakuCd07 />
            <WaribikiTekiyo08 />
            <WaribikiTekiyoSisakuCd08 />
            <WaribikiTekiyo09 />
            <WaribikiTekiyoSisakuCd09 />
            <WaribikiTekiyo10 />
            <WaribikiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo01 />
            <WaribikiHiTekiyoSisakuCd01 />
            <WaribikiHiTekiyo02 />
            <WaribikiHiTekiyoSisakuCd02 />
            <WaribikiHiTekiyo03 />
            <WaribikiHiTekiyoSisakuCd03 />
            <WaribikiHiTekiyo04 />
            <WaribikiHiTekiyoSisakuCd04 />
            <WaribikiHiTekiyo05 />
            <WaribikiHiTekiyoSisakuCd05 />
            <WaribikiHiTekiyo06 />
            <WaribikiHiTekiyoSisakuCd06 />
            <WaribikiHiTekiyo07 />
            <WaribikiHiTekiyoSisakuCd07 />
            <WaribikiHiTekiyo08 />
            <WaribikiHiTekiyoSisakuCd08 />
            <WaribikiHiTekiyo09 />
            <WaribikiHiTekiyoSisakuCd09 />
            <WaribikiHiTekiyo10 />
            <WaribikiHiTekiyoSisakuCd10 />
            <WaribikiHiTekiyo11 />
            <WaribikiHiTekiyoSisakuCd11 />
            <WaribikiHiTekiyo12 />
            <WaribikiHiTekiyoSisakuCd12 />
            <WaribikiHiTekiyo13 />
            <WaribikiHiTekiyoSisakuCd13 />
            <WaribikiHiTekiyo14 />
            <WaribikiHiTekiyoSisakuCd14 />
            <WaribikiHiTekiyo15 />
            <WaribikiHiTekiyoSisakuCd15 />
            <WaribikiHiTekiyo16 />
            <WaribikiHiTekiyoSisakuCd16 />
            <WaribikiHiTekiyo17 />
            <WaribikiHiTekiyoSisakuCd17 />
            <WaribikiHiTekiyo18 />
            <WaribikiHiTekiyoSisakuCd18 />
            <WaribikiHiTekiyo19 />
            <WaribikiHiTekiyoSisakuCd19 />
            <WaribikiHiTekiyo20 />
            <WaribikiHiTekiyoSisakuCd20 />
         </WaribikiChojoKekkaMeisai>
      </WaribikiChojoKekkaListShokaiOrange>
      <IcCardTaioIdokiShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>DP</DenbunType>
         <GenzaiRiyoChuNwRiyoKaisiDay>${_clientAccountStartDate}</GenzaiRiyoChuNwRiyoKaisiDay>
        <TaioIdokiSeizoNum>${_isPureOpen || _isSimOnlyOffer ? '' : data['IMEI']}</TaioIdokiSeizoNum>
        <IdokiSeizoNum1>${_isPureOpen || _isSimOnlyOffer ? '' : data['IMEI']}</IdokiSeizoNum1>
         <IdokiSeizoNum2 />
         <IdokiSeizoNum3 />
         <IdokiSeizoNum4 />
         <IdokiSeizoNum5 />
         <IdokiSeizoNum6 />
         <IdokiSeizoNum7 />
         <IdokiSeizoNum8 />
         <IdokiSeizoNum9 />
         <IdokiSeizoNum10 />
         <TelShurui1>ＳＯＶ３６携帯本体Ｋ</TelShurui1>
         <TelShurui2 />
         <TelShurui3 />
         <TelShurui4 />
         <TelShurui5 />
         <TelShurui6 />
         <TelShurui7 />
         <TelShurui8 />
         <TelShurui9 />
         <TelShurui10 />
         <HoshoKaisiDay1>${_purchaseDate}</HoshoKaisiDay1>
         <HoshoKaisiDay2 />
         <HoshoKaisiDay3 />
         <HoshoKaisiDay4 />
         <HoshoKaisiDay5 />
         <HoshoKaisiDay6 />
         <HoshoKaisiDay7 />
         <HoshoKaisiDay8 />
         <HoshoKaisiDay9 />
         <HoshoKaisiDay10 />
         <KeikaTukisu1>0026</KeikaTukisu1>
         <KeikaTukisu2 />
         <KeikaTukisu3 />
         <KeikaTukisu4 />
         <KeikaTukisu5 />
         <KeikaTukisu6 />
         <KeikaTukisu7 />
         <KeikaTukisu8 />
         <KeikaTukisu9 />
         <KeikaTukisu10 />
        <ShohinCd1>${_isPureOpen || _isSimOnlyOffer ? '' : data['ClientAssetSku']}</ShohinCd1>
         <ShohinCd2 />
         <ShohinCd3 />
         <ShohinCd4 />
         <ShohinCd5 />
         <ShohinCd6 />
         <ShohinCd7 />
         <ShohinCd8 />
         <ShohinCd9 />
         <ShohinCd10 />
         <CampaignTekiyoFlg1 />
         <CampaignTekiyoFlg2 />
         <CampaignTekiyoFlg3 />
         <CampaignTekiyoFlg4 />
         <CampaignTekiyoFlg5 />
         <CampaignTekiyoFlg6 />
         <CampaignTekiyoFlg7 />
         <CampaignTekiyoFlg8 />
         <CampaignTekiyoFlg9 />
         <CampaignTekiyoFlg10 />
      </IcCardTaioIdokiShokai>
      <KappuSiharaiJokyoShokaiKeiyaku>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <MeisaiRyoiki>
            <KappuKeiyakuNum />
            <KeiyakuStatus />
            <KappuAssenKeiyakuDay />
            <SiharaiKaisu />
        <KappuKeiyakuType />				
           <IdokiSeizoNum />
           <IdokiMeisho />
           <HanbaiKeitai />
        <KenriKousiMonth />
     </MeisaiRyoiki>
     <MeisaiRyoiki>
       <KappuKeiyakuNum />
       <KeiyakuStatus />
       <KappuAssenKeiyakuDay />
       <SiharaiKaisu />
       <KappuKeiyakuType />
            <IdokiSeizoNum />
            <IdokiMeisho />
            <HanbaiKeitai />
            <KenriKousiMonth />
         </MeisaiRyoiki>
      </KappuSiharaiJokyoShokaiKeiyaku>
      <PinLockKaijoCdShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <PinLockKaijoCd>97265201</PinLockKaijoCd>
      </PinLockKaijoCdShokai>
      <KoshoTaioRirekiShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>ER</DenbunType>
         <MosikomishoNum1 />
         <MosikomishoNum2 />
         <MosikomishoNum3 />
         <MosikomishoNum4 />
      </KoshoTaioRirekiShokai>
      <SisakuInfoDenbun>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <DenbunType>DP</DenbunType>
         <MaitukiwariWaribikiShuryoYoteiMonth />
         <MaitukiwariSisakuNum1 />
         <MaitukiwariSisakuNum2 />
         <MaitukiwariSisakuNum3 />
         <MaitukiwariSisakuNum4 />
         <MaitukiwariSisakuNum5 />
         <MaitukiwariSisakuMeisho1 />
         <MaitukiwariSisakuMeisho2 />
         <MaitukiwariSisakuMeisho3 />
         <MaitukiwariSisakuMeisho4 />
         <MaitukiwariSisakuMeisho5 />
         <MaitukiwariWaribikiKaisiMonth1 />
         <MaitukiwariWaribikiKaisiMonth2 />
         <MaitukiwariWaribikiKaisiMonth3 />
         <MaitukiwariWaribikiKaisiMonth4 />
         <MaitukiwariWaribikiKaisiMonth5 />
         <MaitukiwariWaribikiShuryoMonth1 />
         <MaitukiwariWaribikiShuryoMonth2 />
         <MaitukiwariWaribikiShuryoMonth3 />
         <MaitukiwariWaribikiShuryoMonth4 />
         <MaitukiwariWaribikiShuryoMonth5 />
         <MaitukiwariWaribikiGaku1 />
         <MaitukiwariWaribikiGaku2 />
         <MaitukiwariWaribikiGaku3 />
         <MaitukiwariWaribikiGaku4 />
         <MaitukiwariWaribikiGaku5 />
         <MaitukiwariTaishoKishu1 />
         <MaitukiwariTaishoKishu2 />
         <MaitukiwariTaishoKishu3 />
         <MaitukiwariTaishoKishu4 />
         <MaitukiwariTaishoKishu5 />
         <ShuryoRiyu1 />
         <ShuryoRiyu2 />
         <ShuryoRiyu3 />
         <ShuryoRiyu4 />
         <ShuryoRiyu5 />
         <MaitukiwariHaisiJiyuFlg1 />
         <MaitukiwariHaisiJiyuFlg2 />
         <MaitukiwariHaisiJiyuFlg3 />
         <MaitukiwariHaisiJiyuFlg4 />
         <MaitukiwariHaisiJiyuFlg5 />
         <ThreemTokutenWaribikiGaku1 />
         <ThreemTokutenWaribikiGaku2 />
         <ThreemTokutenWaribikiGaku3 />
         <ThreemTokutenWaribikiGaku4 />
         <ThreemTokutenWaribikiGaku5 />
         <MaitukiwariSmartpathZogaku1 />
         <MaitukiwariSmartpathZogaku2 />
         <MaitukiwariSmartpathZogaku3 />
         <MaitukiwariSmartpathZogaku4 />
         <MaitukiwariSmartpathZogaku5 />
         <KoyoryoDataZogaku1 />
         <KoyoryoDataZogaku2 />
         <KoyoryoDataZogaku3 />
         <KoyoryoDataZogaku4 />
         <KoyoryoDataZogaku5 />
         <HikitugiMaitukiwariFlg1 />
         <HikitugiMaitukiwariFlg2 />
         <HikitugiMaitukiwariFlg3 />
         <HikitugiMaitukiwariFlg4 />
         <HikitugiMaitukiwariFlg5 />
      </SisakuInfoDenbun>
      <FmbcSetaiInfoKensaku>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0010</Status>
         <ReturnCd>0000</ReturnCd>
         <SeikyuKeiyakuKensuKei>0</SeikyuKeiyakuKensuKei>
         <SeikyuAuKeiyakuSu>0</SeikyuAuKeiyakuSu>
         <SeikyuNwKeiyakuSu>0</SeikyuNwKeiyakuSu>
         <SetaiGroupZoneMeisai>
            <SystemKbnSetaiGroupZone />
            <KeiyakuSakiCdSetaiGroupZone />
            <JigyoshaCdSetaiGroupZone />
            <ThreemKoteiJigyoshaMeishoSetaiGroupZone />
            <RaitenShaDoumeigiIcchiFlgSetaiGroupZone />
            <KeiyakuShaSimeiSetaiGroupZone />
            <ServiceRyakushoSetaiGroupZone />
            <ServiceStatusSetaiGroupZone />
            <TelNumSetaiGroupZone />
            <YearKeiyakuSetaiGroupZone />
            <IdokiKishuMeishoSetaiGroupZone />
            <RouterTokubetuWariKbnSetaiGroupZone />
            <AliasAuIdSetaiGroupZone />
         </SetaiGroupZoneMeisai>
      </FmbcSetaiInfoKensaku>
      <AuIdShareKanyuShaListShokaiOrange>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <AliasAuId>${_isNonOpen ? data['AUID'] : ''}</AliasAuId>
         <ShareKanyuShaInfo>
            <AuTelNumShuturyoku>09093022906</AuTelNumShuturyoku>
            <RealtimeKakinOptionKbn>1</RealtimeKakinOptionKbn>
            <GenShareStatusKbn>0</GenShareStatusKbn>
         </ShareKanyuShaInfo>
      </AuIdShareKanyuShaListShokaiOrange>
      <AuIdKokyakuKeiyakuInfoShokai>
         <KaiheikyokuCheckKekka>1</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>1</SosinJokenHanteiKekka>
         <Status>0000</Status>
         <ReturnCd>0000</ReturnCd>
         <KeiyakuList>
            <KeiyakuType>1</KeiyakuType>
            <KeiyakuCd>4F011414</KeiyakuCd>
            <AuTelNumShuturyoku>09093022906</AuTelNumShuturyoku>
            <KeiyakuStatus>01</KeiyakuStatus>
            <KeiyakuKaijoDay>00000000</KeiyakuKaijoDay>
         </KeiyakuList>
      </AuIdKokyakuKeiyakuInfoShokai>
      <KeiyakuInfoShokai>
         <KaiheikyokuCheckKekka>0</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>0</SosinJokenHanteiKekka>
      <Status />
     <ReturnCd />
     <ProductHoshoServiceKeiyakuInfo>
       <OtoOtoMosikomishoNum />
       <OtoKeiyakuMosikomiDay />
       <OtoProductServiceCd />
     </ProductHoshoServiceKeiyakuInfo>
     <ProductHoshoServiceKeiyakuInfo>
       <OtoOtoMosikomishoNum />
       <OtoKeiyakuMosikomiDay />
       <OtoProductServiceCd />
     </ProductHoshoServiceKeiyakuInfo>
     <ProductHoshoServiceKeiyakuInfo>
       <OtoOtoMosikomishoNum />
       <OtoKeiyakuMosikomiDay />
       <OtoProductServiceCd />
     </ProductHoshoServiceKeiyakuInfo>   
      </KeiyakuInfoShokai>
      <EsimInfoListShutoku>
         <KaiheikyokuCheckKekka>0</KaiheikyokuCheckKekka>
         <SosinJokenHanteiKekka>0</SosinJokenHanteiKekka>
         <ProfileDownloadJokyoCd>${
           _isSimOnlyOffer ? '' : data['eSIMProfileDownloadStatus'] || ''
         }</ProfileDownloadJokyoCd>
      </EsimInfoListShutoku>
    <AuTanmatuHoshoServiceShokai>
     <MblBuyHis>
       <Auicrdmblno>${data['IMEI']}</Auicrdmblno>
       <Gdscd>${data['ClientAssetSku']}</Gdscd>
       <SelRegymd>${_isPureOpen ? '' : _purchaseDate}</SelRegymd>       
       <FaultLostApybgymd>${
         ['1', '15', '2', '25', '181', '182', '191', '192', '1815'].includes(data['ProgramType'])
           ? _agreementStartDate
           : ''
       }</FaultLostApybgymd>
       <FaultLostApyEndymd>${
         ['2', '25', '182', '192'].includes(data['ProgramType']) ? _agreementEndDate : ''
       }</FaultLostApyEndymd>       
     </MblBuyHis>
     <FaultLostOpSv>
     <FaultLostApybgymd>${
       ['1', '15', '2', '25', '181', '182', '191', '192', '1815'].includes(data['ProgramType'])
         ? _agreementStartDate
         : ''
     }</FaultLostApybgymd>
    <FaultLostApyEndymd>${
      ['2', '25', '182', '192'].includes(data['ProgramType']) ? _agreementEndDate : ''
    }</FaultLostApyEndymd>
    <FaultLostApysts>${_isLatestDeviceSubscriber || ''}</FaultLostApysts>
   </FaultLostOpSv>
     ${toXml(data['PastAssetInfo'] || [])}
   </AuTanmatuHoshoServiceShokai>
   <IcCardKiHoshoKaisiDayShokai>
     <HoshoKaisiDay>${_isPureOpen ? _purchaseDate : ''}</HoshoKaisiDay>
   </IcCardKiHoshoKaisiDayShokai>
   <OpenUserInfoShokai>
     <AliasAuId>${_isOpenKFS ? data['AUID'] : ''}</AliasAuId>
     <SosinJokenHanteiKekka />
     ${
       _isPureOpen
         ? `<KojinHojinKbn>${data['ClientAccountType']}</KojinHojinKbn>
     <KeiyakushaShimeiKana>${data['KeiyakushaKanaName']}</KeiyakushaShimeiKana>
     <KeiyakushaShimeiKanji>${data['KeiyakushaKanjiName']}</KeiyakushaShimeiKanji>
     <KeiyakushaSeinenGappi>${_dateOfBirth}</KeiyakushaSeinenGappi>
     <RenrakusakiTelNo>${data['KeiyakushaHomeTelNo']}</RenrakusakiTelNo>
     <KeiyakushaYubinNo>${data['KeiyakushaPostNo']}</KeiyakushaYubinNo>
     <WalletPoint>${data['LoyaltyPoints']}</WalletPoint>
     <KeiyakushaJusho1>${data['KeiyakushaAddress']}</KeiyakushaJusho1>
     <KeiyakushaJusho2>${data['KeiyakushaAddress2']}</KeiyakushaJusho2>
     <KeiyakushaJusho3>${data['KeiyakushaAddress3']}</KeiyakushaJusho3>
     <SeikyusakiShimeiKana>${data['SeikyushaKanaName']}</SeikyusakiShimeiKana>
     <SeikyusakiShimeiKanji>${data['SeikyushaKanjiName']}</SeikyusakiShimeiKanji>
     <SeikyusakiTelNo>${data['SeikyushaHomeTelNo']}</SeikyusakiTelNo>
     <SeikyusakiYubinNo>${data['SeikyushaPostNo']}</SeikyusakiYubinNo>
     <SeikyusakiJusho>${data['SeikyushaAddress']}</SeikyusakiJusho>`
         : `<KojinHojinKbn/>
         <KeiyakushaShimeiKana/>
         <KeiyakushaShimeiKanji/>
         <KeiyakushaSeinenGappi/>
         <RenrakusakiTelNo/>
         <KeiyakushaYubinNo/>
         <WalletPoint/>
         <KeiyakushaJusho1/>
         <KeiyakushaJusho2/>
         <KeiyakushaJusho3/>
         <SeikyusakiShimeiKana/>
         <SeikyusakiShimeiKanji/>
         <SeikyusakiTelNo/>
         <SeikyusakiYubinNo/>
         <SeikyusakiJusho/>`
     }
   </OpenUserInfoShokai>
   </ResponseData>
</OrangeResponseInterface>`
}
