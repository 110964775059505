import React from 'react'
import { Spinner } from 'react-bootstrap'

export default () => {
  return (
    <div
      //className="overlay"
      style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
    >
      <Spinner animation="border" variant="success" style={{ marginTop: '20%' }} />
    </div>
  )
}
