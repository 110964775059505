import axios from './axios'
import { BaseAPI } from './base-api'
import { IServiceOrderOprationRequestParam } from '../store/types'

class SAGAWAAPI extends BaseAPI {
  public getServiceOrderDetails = (request: any) => {
    return axios.post(
      `${this.getConfigKey('baseURL')}${this.endpoints.findStub}${this.endpoints.sagawaOrders}`,
      request,
      {
        headers: this.headers(),
      }
    )
  }

  public serviceOrderOpration = (request: IServiceOrderOprationRequestParam) => {
    return axios.post(`${this.getConfigKey('baseURL')}${this.endpoints.carrierServices}`, request, {
      headers: this.headers(),
    })
  }
}

export default SAGAWAAPI
