export default {
  query: `select distinct sr.SERVICE_REQUEST_ID, sr.CUSTOMER_CASE_ID, sr.SERVICE_REQUEST_STATUS_CODE, sr.SERVICE_REQUEST_TYPE_CODE, sr.FULFILLMENT_METHOD_TYPE_CODE, sr.SERVICE_REQUEST_NBR, sr.ASSET_FAILURE_DATE, sr.RESOLUTION_TYPE_CODE, sr.COVERED_EVENT_CODE, sr.FAILURE_DESCRIPTIVE_TEXT, sr.EMAIL_CONTACT_POINT_ID, sr.ASSET_ID, sr.AGREEMENT_ID, sr.SERVICE_REQUEST_REASON_CODE, sr.SERVICE_REQUEST_SUB_RSN_CODE, sr.WARRANTY_TYPE_CODE, sr.PRODUCT_ID, sr.FULFILLMENT_OPTION_CODE, sr.INCIDENT_TYPE_CODE, sr.CLAIM_SUBMISSION_DATE, sr.PARENT_SERVICE_REQUEST_ID, sr.RISK_CODE, sr.COMPLETED_DATE, sr.RISK_CODE_EFFECTIVE_DATE, sr.SERVICE_REQUEST_APPROVAL_DATE, sr.SERVICE_REQUEST_APPROVED_BY, sr.ELIGIBLE_INCIDENT_DATE, sr.ELIGIBLE_SERVICE_REQUEST_DATE, sr.ASSET_NON_RETURN_FEE, sr.INCIDENT_SUB_TYPE_CODE, sr.COVERED_EVENT_DESCRIPTIVE_TEXT, sr.EXTERNAL_REFERENCE_ID, sr.SERVICE_REQUEST_SUB_TYPE, sr.APPROVED_DATE, sr.CREATED_SYSTEM_ID, sr.CREATED_BY, sr.CREATED_DATE, sr.UPDATED_BY, sr.UPDATED_DATE, sr.BULK_UPDATED_BY, sr.BULK_UPDATED_DATE, sr.ACTIVE_IND
    from customer.service_request sr
    join asset.asset a on a.asset_id = sr.asset_id and sr.active_ind = 1 and a.active_ind = 1
    where a.mobile_device_nbr = :mdn or a.subscription_nbr = :subnbr order by sr.created_date asc`,
  mapper: [
    { o: 'CustomerCaseId', d: 'CUSTOMER_CASE_ID' },
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ServiceRequestStatus', d: 'SERVICE_REQUEST_STATUS_CODE' },
    { o: 'ServiceRequestType', d: 'SERVICE_REQUEST_TYPE_CODE' },
    { o: 'FulfillmentMethodType', d: 'FULFILLMENT_METHOD_TYPE_CODE' },
    { o: 'ServiceRequestNbr', d: 'SERVICE_REQUEST_NBR' },
    { o: 'AssetFailureDate', d: 'ASSET_FAILURE_DATE' },
    { o: 'ResolutionType', d: 'RESOLUTION_TYPE_CODE' },
    { o: 'CoveredEvent', d: 'COVERED_EVENT_CODE' },
    { o: 'FailureDescriptiveText', d: 'FAILURE_DESCRIPTIVE_TEXT' },
    { o: 'EmailContactPointId', d: 'EMAIL_CONTACT_POINT_ID' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'AgreementId', d: 'AGREEMENT_ID' },
    { o: 'ServiceRequestReason', d: 'SERVICE_REQUEST_REASON_CODE' },
    { o: 'ServiceRequestSubRsn', d: 'SERVICE_REQUEST_SUB_RSN_CODE' },
    { o: 'WarrantyType', d: 'WARRANTY_TYPE_CODE' },
    { o: 'ProductId', d: 'PRODUCT_ID' },
    { o: 'FulfillmentOption', d: 'FULFILLMENT_OPTION_CODE' },
    { o: 'IncidentType', d: 'INCIDENT_TYPE_CODE' },
    { o: 'ClaimSubmissionDate', d: 'CLAIM_SUBMISSION_DATE' },
    { o: 'ParentServiceRequestId', d: 'PARENT_SERVICE_REQUEST_ID' },
    { o: 'Risk', d: 'RISK_CODE' },
    { o: 'CompletedDate', d: 'COMPLETED_DATE' },
    { o: 'RiskEffectiveDate', d: 'RISK_CODE_EFFECTIVE_DATE' },
    { o: 'ServiceRequestApprovalDate', d: 'SERVICE_REQUEST_APPROVAL_DATE' },
    { o: 'ServiceRequestApprovedBy', d: 'SERVICE_REQUEST_APPROVED_BY' },
    { o: 'EligibleIncidentDate', d: 'ELIGIBLE_INCIDENT_DATE' },
    { o: 'EligibleServiceRequestDate', d: 'ELIGIBLE_SERVICE_REQUEST_DATE' },
    { o: 'AssetNonReturnFee', d: 'ASSET_NON_RETURN_FEE' },
    { o: 'IncidentSubType', d: 'INCIDENT_SUB_TYPE_CODE' },
    { o: 'CoveredEventDescriptiveText', d: 'COVERED_EVENT_DESCRIPTIVE_TEXT' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ServiceRequestSubType', d: 'SERVICE_REQUEST_SUB_TYPE' },
    { o: 'ApprovedDate', d: 'APPROVED_DATE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
