function getOneMonthOldDate() {
  const d = new Date()
  d.setMonth(d.getMonth() - 1)
  return d.toISOString().split('T')[0]
}

module.exports = {
  ClientChannel: '7D42D83E285511E9A38A063D3002FD4C',
  ProgramType: '1',
  MobileDeviceNumber: '',
  IMEI: '',
  SubscriberNumber: '',
  ICCId: '8981300173300001141',
  KeiyakushaKanaName: 'ｴｲﾕｳ ｼﾞｭｳﾊﾁｺﾞｳ',
  KeiyakushaKanjiName: '英雄　十八号',
  KeiyakushaPostNo: '1800023',
  KeiyakushaAddress: '東京都武蔵野市境南町９９９９丁目１－９９',
  KeiyakushaAddress2: '',
  KeiyakushaAddress3: '',
  KeiyakushaHomeTelNo: '09000220022',
  SeikyushaKanaName: 'ｴｲﾕｳ ｼﾞｭｳﾅﾅｺ',
  SeikyushaKanjiName: '英雄　十七子',
  SeikyushaPostNo: '1800023',
  SeikyushaAddress: '東京都武蔵野市境南町９９９９丁目１－９９',
  SeikyushaHomeTelNo: '09000220022',
  HandsetType: 'A',
  ClientAssetSku: 'SOV36MKA',
  eSIMPlanType: '',
  eSIMProfileDownloadStatus: '',
  LoyaltyPoints: '1970',
  ClientAccountType: '1',
  DateOfBirth: '1994-01-01',

  AgreementStartDate: getOneMonthOldDate(),
  ClientAccountStartDate: getOneMonthOldDate(),

  PurchaseDate: getOneMonthOldDate(),
  ServiceFlag: '1',
  AUID: '',
  PastAssetInfo: [
    {
      ClientAssetSku: '',
      IMEI: '',
      PurchaseDate: '',
      AssetStartDate: '',
      AssetEndDate: '',
    },
  ],
}
