export default {
  query: `select distinct sr.SERVICE_REQUEST_ID, so.SERVICE_ORDER_ID, so.SERVICE_ORDER_TYPE_CODE, so.SERVICE_ORDER_STATUS_CODE, so.ASSET_ID, so.SERVICE_ORDER_NBR, so.SERVICE_UNIT_NBR, so.SCHEDULED_DATE, so.SERVICE_ORDER_START_TIME, so.SERVICE_ORDER_END_TIME, so.SERVICE_ORDER_COMMENT, so.PARENT_SERVICE_ORDER_ID, so.ORIGINAL_SERVICE_ORDER_NBR, so.SERVICE_ORDER_SUB_STATUS_CODE, so.VENDOR_XREF_ID, so.SCHEDULED_START_TIME, so.SCHEDULED_END_TIME, so.VENDOR_ID, so.EXTERNAL_REFERENCE_ID, so.SERVICE_PROVIDER_NBR, so.SPECIAL_INSTRUCTIONS, so.AVAILABILITY_TYPE, so.VENDOR_SEARCH_KEY, so.LOCATION_NUMBER, so.REFERENCE_STATUS_CODE, so.PROVISIONAL_COMPLETED_BY, so.PROVISIONAL_COMPLETED_DATE, so.SERVICE_ORDER_SUB_TYPE_CODE, so.CREATED_SYSTEM_ID, so.CREATED_BY, so.CREATED_DATE, so.UPDATED_BY, so.UPDATED_DATE, so.BULK_UPDATED_BY, so.BULK_UPDATED_DATE, so.ACTIVE_IND
    from customer.service_request sr
    join customer.customer_case_category ccc on ccc.service_request_id = sr.service_request_id and sr.active_ind = 1 and ccc.active_ind = 1
    join customer.service_order so on so.service_order_id = ccc.item_id -- and so.active_ind = 1
    join asset.asset a2 on a2.asset_id = sr.asset_id and a2.active_ind = 1
    where a2.mobile_device_nbr = :mdn or a2.subscription_nbr = :subnbr order by so.created_date asc`,
  mapper: [
    { o: 'ServiceRequestId', d: 'SERVICE_REQUEST_ID' },
    { o: 'ServiceOrderId', d: 'SERVICE_ORDER_ID' },
    { o: 'ServiceOrderType', d: 'SERVICE_ORDER_TYPE_CODE' },
    { o: 'ServiceOrderStatus', d: 'SERVICE_ORDER_STATUS_CODE' },
    { o: 'AssetId', d: 'ASSET_ID' },
    { o: 'ServiceOrderNbr', d: 'SERVICE_ORDER_NBR' },
    { o: 'ServiceUnitNbr', d: 'SERVICE_UNIT_NBR' },
    { o: 'ScheduledDate', d: 'SCHEDULED_DATE' },
    { o: 'ServiceOrderStartTime', d: 'SERVICE_ORDER_START_TIME' },
    { o: 'ServiceOrderEndTime', d: 'SERVICE_ORDER_END_TIME' },
    { o: 'ServiceOrderComment', d: 'SERVICE_ORDER_COMMENT' },
    { o: 'ParentServiceOrderId', d: 'PARENT_SERVICE_ORDER_ID' },
    { o: 'OriginalServiceOrderNbr', d: 'ORIGINAL_SERVICE_ORDER_NBR' },
    { o: 'ServiceOrderSubStatus', d: 'SERVICE_ORDER_SUB_STATUS_CODE' },
    { o: 'VendorXrefId', d: 'VENDOR_XREF_ID' },
    { o: 'ScheduledStartTime', d: 'SCHEDULED_START_TIME' },
    { o: 'ScheduledEndTime', d: 'SCHEDULED_END_TIME' },
    { o: 'VendorId', d: 'VENDOR_ID' },
    { o: 'ExternalReferenceId', d: 'EXTERNAL_REFERENCE_ID' },
    { o: 'ServiceProviderNbr', d: 'SERVICE_PROVIDER_NBR' },
    { o: 'SpecialInstructions', d: 'SPECIAL_INSTRUCTIONS' },
    { o: 'AvailabilityType', d: 'AVAILABILITY_TYPE' },
    { o: 'VendorSearchKey', d: 'VENDOR_SEARCH_KEY' },
    { o: 'LocationNumber', d: 'LOCATION_NUMBER' },
    { o: 'ReferenceStatus', d: 'REFERENCE_STATUS_CODE' },
    { o: 'ProvisionalCompletedBy', d: 'PROVISIONAL_COMPLETED_BY' },
    { o: 'ProvisionalCompletedDate', d: 'PROVISIONAL_COMPLETED_DATE' },
    { o: 'ServiceOrderSubType', d: 'SERVICE_ORDER_SUB_TYPE_CODE' },
    { o: 'CreatedSystemId', d: 'CREATED_SYSTEM_ID' },
    { o: 'CreatedBy', d: 'CREATED_BY' },
    { o: 'CreatedDate', d: 'CREATED_DATE' },
    { o: 'UpdatedBy', d: 'UPDATED_BY' },
    { o: 'UpdatedDate', d: 'UPDATED_DATE' },
    { o: 'BulkUpdatedBy', d: 'BULK_UPDATED_BY' },
    { o: 'BulkUpdatedDate', d: 'BULK_UPDATED_DATE' },
    { o: 'ActiveInd', d: 'ACTIVE_IND' },
  ],
}
